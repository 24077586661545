import React, {useState} from 'react';
import {Card, CardContent, Grid, InputBase, makeStyles, TextField} from '@material-ui/core';
import {LabelTypography, TitleTypography} from "../../common/typography";
import useForm from '../../../utils/hooks/useForm';
import {requiredFieldValidator} from "../../../utils/validators";
import {ReactComponent as ErrorIcon} from "../../../assets/error.svg";
import {FlatBlueButton} from "../../common/buttons";
import { UPDATE_SCHOOL_INFO } from "../../../services/school";
import { useMutation } from '@apollo/react-hooks';


const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            height: '100%'
        },
        card: {
            padding: theme.spacing(2),
            background: 'rgb(255, 255, 255)',
            boxShadow: '0px 2px 3px 0px rgba(83, 85, 86, 0.11), 0px 2px 9px 0px rgba(71, 76, 81, 0.09)',
            borderRadius: '6px',
            height: '100%'
        }
    })
);

const validationRules = {
    'name': [
        requiredFieldValidator('University name is required.')
    ]
};


export default function SchoolSettings({information}) {
    const classes = useStyles();

    const [isEditable, setEditable] = useState(false);

    const handleFormSubmit = (inputsState) => {
        setEditable(!isEditable);
        updateSchool();
    };

    const {
        errors,
        handleInputChange,
        handleOnSubmit,
        hasErrors,
        inputsState: inputs,
        triggerFieldValidation: validate
    } = useForm(information, handleFormSubmit, validationRules);

    const [updateSchool] = useMutation(UPDATE_SCHOOL_INFO, {
        variables: {
            input: {
                schoolCode: inputs.id,
                name: inputs.name
            }
        },
        onCompleted: (data) => console.log('Success updating school')
    });

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <form onSubmit={handleOnSubmit}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TitleTypography>YOUR SCHOOL INFORMATION</TitleTypography>
                            </Grid>

                            <Grid container alignItems="center" item xs={12} md={2}>
                                <LabelTypography>Name</LabelTypography>
                            </Grid>
                            <Grid container alignItems="center" item xs={12} md={7}>
                                <TextField
                                    name="name"
                                    variant="standard"
                                    fullWidth
                                    disabled={!isEditable}
                                    className={classes.label}
                                    InputProps={{disableUnderline: !isEditable}}
                                    value={inputs.name}
                                    onChange={handleInputChange}
                                    onBlur={validate}
                                    error={!!errors.name}
                                    helperText={errors.name ?
                                        <label>
                                            <ErrorIcon/> <span>{errors.name}</span>
                                        </label> :
                                        null
                                    }/>
                            </Grid>
                            <Grid item container alignItems="center" xs={12} md={3}>
                                {isEditable &&
                                <FlatBlueButton type="submit" disabled={hasErrors}> Save </FlatBlueButton>}
                                {!isEditable &&
                                <FlatBlueButton onClick={() => setEditable(!isEditable)}>
                                    Edit
                                </FlatBlueButton>}
                            </Grid>

                            <Grid container alignItems="center" item xs={2}>
                                <LabelTypography>ID</LabelTypography>
                            </Grid>
                            <Grid item xs={10}>
                                <InputBase variant="standard"
                                           fullWidth
                                           disabled={true}
                                           className={classes.label}
                                           defaultValue={information.id}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </form>
            </Card>
        </div>
    )
}