import { makeStyles, Paper } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { TitleTypography } from '../../common/typography';
import ActivePeriodForm from './ActivePeriodForm';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        height: '100%'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))

function ActivePeriod({schoolCode, semesterName, semesterStartDate, semesterEndDate, atRiskPercentage, attendanceAssertionTimeThreshold, minTimeSpentInClass }) {
    const classes = useStyles();
    
    return (
        <Paper className={classes.paper}>
            <TitleTypography align="left">
                YOUR ACTIVE TERM
            </TitleTypography>
            <br />
            <ActivePeriodForm period={{
                                  name: semesterName,
                                  startDate: moment(semesterStartDate),
                                  endDate: moment(semesterEndDate),
                                  toleranceMinutesForAttendance: attendanceAssertionTimeThreshold,
                                  minAttendancePercent: atRiskPercentage,
                                  minTime: minTimeSpentInClass
                              }} schoolCode={schoolCode} />
        </Paper>
    )
}

export default ActivePeriod;