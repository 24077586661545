import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Icon, Typography } from '@material-ui/core';
import { ReactComponent as SuccessIcon } from "../../../assets/check_mark.svg";
import { BlueButton } from "../../common/buttons";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: "20px 20px",
        height: 450,
        width: 282,
        "& .MuiInputLabel-formControl": {
            top: -6,
            left: -4
        }
    },
    container: {
        textAlign: 'center',
        height: "100%"
    },
    icon: {
        cursor: 'pointer',
        color: theme.palette.primary.main
    },
    title: {
        fontWeight: 900 
    },
}));

const SuccessChange = React.forwardRef((props, ref) => {
    const classes = useStyles();

    const onClose = () => {
        props.onClose();
    };

    return(
        <div className={classes.root}>
        <Grid container justify="space-between" direction="row-reverse">
            <Grid>
                <Icon className={classes.icon} onClick={onClose}>close</Icon>
            </Grid>
        </Grid>
        <Grid className={classes.container} container justify="center">
            <Grid item xs={12}>
                <Typography className={classes.title}  variant={'h3'} color={'primary'}>Change {props.type}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={'h5'} >Your new {props.type} <br/> was saved successfully</Typography>
            </Grid>
            <Grid item xs={12}>
                <SuccessIcon></SuccessIcon>
            </Grid>
            <Grid item xs={12}>
                <BlueButton onClick={onClose}>Ok</BlueButton>
            </Grid>

        </Grid>
           
</div>
    )
});

export default SuccessChange