import React from 'react';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {BlueButton} from "../../common/buttons";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {GET_SCHOOL_ADMINS} from "../../../services/school";
import {useLazyQuery} from '@apollo/react-hooks';

const useStyles = makeStyles(theme => ({
    dContent: {
        minWidth: 600,
        padding: '40px 25px 40px 40px !important'
    },

    title: {
        marginBottom: 16
    },

    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0
    },

    tableHeader: {
        fontWeight: 900
    },

    tableRow: {
        color: theme.palette.info.main
    },

    actionsRow: {
        marginTop: 45
    }
}));

const SchoolAdminsDialog = ({schoolCode, schoolName, onClose}) => {
    const classes = useStyles();

    const [loadAdmins, {loading, data, error}] = useLazyQuery(GET_SCHOOL_ADMINS);

    React.useEffect(() => {
        if (!schoolCode) return;

        loadAdmins({
            variables: {
                filter: {schoolCode: schoolCode}
            }
        });
    }, [schoolCode]);

    if (error)
        return <div>{error}</div>;

    const rows = data && data.listUsers.items.map(user => <Grid item container key={user.email}>
        <Grid item xs={4}>
            <Typography variant={'h3'} className={classes.tableRow}>{user.name}</Typography>
        </Grid>
        <Grid item xs={8}>
            <Typography variant={'h3'} className={classes.tableRow}>{user.email}</Typography>
        </Grid>
    </Grid>);

    return (
        <Dialog open={!!schoolCode}>
            <DialogContent classes={{root: classes.dContent}}>
                <Grid container direction={'column'} spacing={1}>
                    <Grid item className={classes.title}>
                        <Typography variant={'h1'} color={'primary'}>{schoolName}</Typography>
                        <IconButton aria-label="delete" color={'secondary'}
                                    className={classes.closeIcon}
                                    onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant={'body2'} color={'primary'}>ADMINS</Typography>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={4}>
                            <Typography variant={'h3'} className={classes.tableHeader}>Name</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant={'h3'} className={classes.tableHeader}>Email</Typography>
                        </Grid>
                    </Grid>

                    {loading && <div>loading ...</div>}
                    {rows}

                    <Grid item container justify={'flex-end'}>
                        <Grid item className={classes.actionsRow}>
                            <BlueButton onClick={onClose}>Ok</BlueButton>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default SchoolAdminsDialog;