import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ProfessorsTableCard from "./_ProfessorsTableCard";
import {useHistory} from 'react-router-dom';
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_PROFESSORS} from '../../../services/professors';

const PAGE_SIZE = 10;

const useStyles = makeStyles(theme => ({
    title: {
        marginLeft: 16,
        marginTop: 35
        
    }
}));

const Professors = () => {
    const classes = useStyles();
    const history = useHistory();
    const [page, setPage] = React.useState(0);

    const handleProfessorClick = (professor) => {
        history.push(`/professors/${professor.email}`, {professor});
    };

    const [getProfessors, {error, data, loading}] = useLazyQuery(GET_PROFESSORS);

    const refreshList = () => {
        const nextToken = page === 0 ? '' : data && data.getProfessorsTable.nextToken;
        getProfessors({
            variables: {
                limit: PAGE_SIZE,
                token: nextToken
            }
        });
    };

    React.useEffect(() => {
        refreshList();
    }, [page]);

    if (loading || !data)
        return <div>loading ...</div>;

    if (error)
        return <div>{error.toString()}</div>;


    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <Typography variant={'h1'} color={'primary'} className={classes.title}>Professors</Typography>
            </Grid>

            <Grid item xs={12}>
                <ProfessorsTableCard professors={data.getProfessorsTable.items}
                                     onProfessorClick={handleProfessorClick}
                                     page={page}
                                     total={data.getProfessorsTable.totalProfessors}
                                     nextToken={data.getProfessorsTable.nextToken}
                                     pageSize={PAGE_SIZE}
                                     onPageChange={setPage}
                />
            </Grid>

        </Grid>
    );
};

export default Professors;