import { Grid, Paper, withStyles, Hidden } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Mail } from '@material-ui/icons';
import React, { Fragment } from 'react';
import { BlueButton } from '../../common/buttons';
import { BodyTypography, SectionTypography } from '../../common/typography';


const useStyles = makeStyles(theme => ({

    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1)
    },
}))

const DarkerBlueButton = withStyles(() => ({
    root: {
        backgroundColor: '#177acc',
    }
}))(BlueButton)

function ReportProblem() {
    const classes = useStyles();

    const contact = () => {
        window.open("mailto:support@wiseeducationsystems.com","_blank");
    }
    return (
        <Fragment>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid alignItems="center" container>
                        <Grid container item sm={12} md={9}>
                            <Hidden xsDown>
                                <Grid container item xs={12} sm={1}>
                                    <Mail fontSize="large" style={{ color: blue[200] }} />
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} sm={10}>
                                <SectionTypography>
                                    Get help with the platform
                                </SectionTypography>
                                <p></p>
                                <BodyTypography>
                                    Send an email to Wise Education to report an issue with the platform.
                                </BodyTypography>
                                <BodyTypography>
                                    For phone support within the USA call to the +1-818-641-5936
                                </BodyTypography>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <DarkerBlueButton onClick={contact}>Contact</DarkerBlueButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Fragment>

    );
}


export default ReportProblem;
