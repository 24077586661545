import React, {useEffect, useState} from 'react';
import { Grid, makeStyles, Breadcrumbs, Link, Typography, Icon, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {isEmpty} from 'lodash'
import { GET_PROFESSOR_INFO } from '../../../services/professors';
import ProfessorInformationCard from './_ProfessorInformationCard'
import ProfAttendancePercentCard from "./_ProfAttendancePercentCard";
import ProfessorCoursesTable from "./_ProfessorCoursesTable";
import ProfAttendanceChart from './_ProfAttendanceChart'

const LoadingContainer = (props) => {
    return(<Paper className={props.class}>
        <Grid container alignItems='center'>
            <Typography variant={'h2'} color={'primary'}><strong>Loading...</strong></Typography>
        </Grid>
    </Paper>)
}

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
    },

    endLink: {
        color: theme.palette.info.gray
    },
    dataContainer: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        marginBottom: 35,
        padding: 15
    },
    breadcrumbSeparator: {
        height: 10,
        width: 10,
        color: theme.palette.info.gray,
        marginBottom: 2
    },
    titleContainer: {
        display: 'flex'
    },
    title: {
        padding: '33px 0 12px 16px'
    },
    tableContainer: {
        marginTop: 30
    },
    warningAttendance: {
        color: theme.palette.common.white,
        height: 22,
        width: 132,
        margin: '38px 0 12px 16px',
        borderRadius: 5,
        textAlign: 'center',
        fontWeight: 900,
        backgroundColor: theme.palette.warning.main,

    },
    warningDescription: {
        fontWeight: 900
    },
    warningIcon: {
        color: theme.palette.common.white,
        fontSize: 13,
        lineHeight: '18px',
    },
}));

export default function Professordetail(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = decodeURIComponent(history && history.location.pathname)
    const professorEmail = location.split('/')[2]
    const school = props.school
    const semester = school.semester
    const professor = history.location.state && history.location.state.professor || {}
    const separator = <ArrowForwardIosIcon color={'disabled'} className={classes.breadcrumbSeparator}/>;
    const goToCourseDetail = (course) => {
        history.push(`/courses/${encodeURIComponent(`${semester}#${course.code}`)}`);
    };

    // commenting this until there's an specific endpoint
    // const [professorInfo, setProfessorInfo] = useState({})
    // const professorInfoResult = useQuery(GET_PROFESSOR_INFO, {
    //     variables: {
    //         email: professorEmail
    //     },
    //     onCompleted: () => {
    //         // const studentData = studentInfoResult && studentInfoResult.data && studentInfoResult.data.getStudentInfo
    //         console.log(professorInfoResult)
    //     }
    // });

    return (
      <Grid container direction='column'>
          <Grid item>
              <Breadcrumbs separator={separator} aria-label="breadcrumb">
            <Link to="/professors" className={classes.link}>
                <Typography variant={'body2'} color={'secondary'}>
                    All professors
                </Typography>
            </Link>
            <Typography variant={'body2'} className={classes.endLink}>Professor details</Typography>
        </Breadcrumbs>
        </Grid>
        <Grid item className={classes.titleContainer}>
            <Typography variant={'h1'} color={'primary'} className={classes.title}>{professor.name}</Typography>
        </Grid>
        {isEmpty(professor) ? <LoadingContainer class={classes.dataContainer}></LoadingContainer> : <Grid item container spacing={2}>
            <Grid item xs={6}>
                <ProfessorInformationCard studentData={professor} email={professorEmail}/>
            </Grid>
            <Grid item xs={6}>
                <ProfAttendancePercentCard summary={professor.summary} school={school}></ProfAttendancePercentCard>
            </Grid>
        </Grid>}
        <br></br>
        <Grid item container className={classes.titleContainer}>
            {<ProfAttendanceChart school={school} professorEmail={professorEmail}></ProfAttendanceChart>}
        </Grid>
        <Grid item className={classes.tableContainer}>
                <ProfessorCoursesTable
                 professorEmail={professorEmail}
                 onNameClick={professor=> goToCourseDetail(professor)} 
                />
            </Grid>
      </Grid>
    )
}