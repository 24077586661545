import React, {Fragment} from 'react';
import {Grid, makeStyles, withStyles, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";
import {SectionTypography, BodyTypography} from "../../common/typography";
import Question from "./Question";
import attendance_code from "../../../assets/attendance_code.png";
import figure1 from "../../../assets/adminhelp/figure1.png";
import figure2 from "../../../assets/adminhelp/figure2.png";
import figure3 from "../../../assets/adminhelp/figure3.png";
import figure4 from "../../../assets/adminhelp/figure4.png";
import figure5 from "../../../assets/adminhelp/figure5.png";
import figure6 from "../../../assets/adminhelp/figure6.png";
import figure7 from "../../../assets/adminhelp/figure7.png";
import figure8 from "../../../assets/adminhelp/figure8.png";
import figure9 from "../../../assets/adminhelp/figure9.png";
import figure10 from "../../../assets/adminhelp/figure10.png";
import figure11 from "../../../assets/adminhelp/figure11.png";
import figure12 from "../../../assets/adminhelp/figure12.png";
// import figure13 from "../../../assets/adminhelp/figure13.png";
import figure14 from "../../../assets/adminhelp/figure14.png";
import figure15 from "../../../assets/adminhelp/figure15.png";
import figure16 from "../../../assets/adminhelp/figure16.png";
import figure17 from "../../../assets/adminhelp/figure17.png";
import figure18 from "../../../assets/adminhelp/figure18.png";
import figure19 from "../../../assets/adminhelp/figure19.png";
import figure20 from "../../../assets/adminhelp/figure20.png";


const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 900
    },
    oblique: {
        fontStyle: 'oblique'
    },
    administratorsGuideContainer: {
        display: 'block'
    }
})
);

const StrongContainer = props => {
    return (<strong>{props.text}</strong>)
}

const FigureContainer = (props) => {
    return (<div id="over" style={{width:'100%', height:'100%'}}>
    <img src={props.figure} style={{marginLeft: 'auto', marginRight: 'auto', display: 'block', width: '100%', ...props.style}}/>
</div>)
}


function FrequentlyQuestion({role}) {
    const classes = useStyles();

    const questionsProfessor = [
        {
            id: 1,
            question: 'Where can I find the attendance code for my class?',
            answer: <BodyTypography>Attendance codes are available <span className={classes.bold}>five minutes</span> before the time of the class. At that time, you will receive an automatic <span className={classes.bold}>email</span> with your code. <br/><br/>

            You can also check the attendance code in your <span className={classes.bold}>Professor portal</span>. To do this, click the <span className={classes.bold}>Show attendance code</span> button, at the top right side of the portal. The following message box appears: <br/><br/>

           <img src={attendance_code} alt="Attendance code" height={100} width={180}></img> <br/><br/>

            The message box includes the <span className={classes.bold}>name, location, schedule, and attendance code </span>of your current class. This information is also available <span className={classes.bold}>five minutes</span> before the time of the class. <br></br><br/>

            <span className={classes.bold}>Remember: </span> Share the code with your students within the <span className={classes.bold}>first ten minutes</span>  of your class. After this time frame, the code will no longer be valid.</BodyTypography>
        },
        {
            id: 1,
            question: 'What does the attendance percentage mean?',
            answer: <BodyTypography>
                In the course cards in the <span className={classes.bold}>My courses</span> tab, the <span className={classes.bold}>Attendance percentage</span> represents the average of <span className={classes.oblique}>Individual</span> attendance percentages of all your students of that course. Individual attendance percentages represent the proportion of classes that each student has attended until now, out of all the classes taught so far.
            </BodyTypography>
        },
        {
            id: 1,
            question: 'What is the difference between graded and nongraded classes?',
            answer: <BodyTypography>You specify whether the attendance to a course is <span className={classes.bold}>graded</span> or <span className={classes.bold}>nongraded</span>.<br/><br/>
            To register their <span className={classes.bold}>graded</span> attendance, you must provide students with a <span className={classes.bold}>four-digit code </span> when the class begins. Wise generates this code and delivers it to you through this portal and an email before the class starts. Students must enter the code in the mobile application. <br/> <br></br>
            To register their <span className={classes.bold}>nongraded</span> attendance, students just need to enter the classroom. After they arrive, Wise registers their location and marks their attendance.
            </BodyTypography>
        }, {
            id: 1,
            question: 'How do I select between graded and nongraded attendance?',
            answer: <BodyTypography>
            To change the attendance configuration:<br/><br/>
            1 Go to the <span className={classes.bold}>My Courses</span> tab. <br/>
            2. Click a course card to see the course details.<br/>
            3. In the <span className={classes.bold}>Attendance Configuration</span> section, select one of these options:<br/>
            <span className={classes.bold}>&nbsp;&nbsp;&nbsp;&nbsp;- Graded Attendance (manual)</span><br/>
            <span className={classes.bold}>&nbsp;&nbsp;&nbsp;&nbsp;- Nongraded (automatic)</span><br/>
            4. Click<span className={classes.bold}> Save.</span><br/> <br/>
            After this, a pop-up confirmation message appears. Click Ok to close it.
            </BodyTypography>
        }, {
            id: 1,
            question: 'What does it mean to have students with low attendance?',
            answer:
            <BodyTypography>
                Students with <span className={classes.bold}>low attendance</span> are at risk of failing a course because their attendance rate is below the established threshold. <br/> <br/>

                The attendance threshold is established by the <span className={classes.bold}>school administrators</span>. Contact them for more information.<br/> <br/>

                You can see which of your students have <span className={classes.bold}>low attendance</span> at the <span className={classes.bold}>My students </span>tab.<br/> <br/>
            </BodyTypography>
        }, {
            id: 1,
            question: 'How can I see the attendance information of my students?',
            answer:
            <BodyTypography>
                You can only see the attendance information of the following:<br/> <br/>
                    - Students enrolled in classes with <span className={classes.bold}>graded</span> attendance.<br/>
                    - Students with <span className={classes.bold}>low attendance</span> rate.<br/> <br/>
                To see the attendance information of your students:<br/>
                1. Go to the <span className={classes.bold}>My Courses </span> tab.<br/>
                2. Click a course card to see the course details.<br/>
                3. Scroll down until you see the <span className={classes.bold}>Students</span> section.<br/><br/>

                In the <span className={classes.bold}>Students</span> section you can find the following information sorted by student:<br/> <br/>

                - Name<br/>
                - Attendance status<br/>
                - Email<br/>
                - Attendances and absences by date<br/>
                - Attendance percentage<br/> <br/>

                You can also see attendance information of your students with <span className={classes.bold}>low attendance</span> in the <span className={classes.bold}>My Students</span> tab.
            </BodyTypography>
        }, {
            id: 1,
            question: 'Who are the school administrators?',
            answer:
            <BodyTypography>
            <span className={classes.bold}>School administrators</span> are employees of your institution that manage all the Wise accounts related to it. Wise authorizes  <span className={classes.bold}>school administrators</span> to do the following: <br/><br/>

            - See if you have an active Wise account<br/>
            - Enable you to create and change your password<br/>
            - See your <span className={classes.bold}>nickname</span><br/>
            - Define the <span className={classes.bold}>low attendance</span> limit<br/>
            - See your <span className={classes.bold}>attendance percentage</span> and records<br/>
            </BodyTypography>
        },
    ];

    const questionsAdmin = [
        {
            id: 1,
            question: 'How does Wise record attendance?',
            answer: <Typography variant={'h4'}>
                Wise Attend allows students to enter a 4-digit code on their mobile device provided to them by their professor every class.  Wise attend uses location services only to ensure that students who enter the code are really in class.  For anonymous attendance statistics, Wise uses location services, which are anonymized and provided as generalized statistics to professors and the administration.
            </Typography>
        },
        {
            id: 2,
            question: 'What can students use their mobile app for?',
            answer:
            <Typography variant={'h4'}>
                Students can do the following: <br/> <br/>

                -        See their class schedule. <br/>
                -        Mark their attendance via code. <br/>
                -        See their attendance records. <br/>
                -        Receive reminders and push notifications from the administration. <br/>
                -        Manage account services and get customer support. <br/>
            </Typography>
        }, {
            id: 3,
            question: 'What can professors use their web portal for?',
            answer:
            <Typography variant={'h4'}>
                Professors can do the following: <br/> <br/>

                -        View student attendance in each of their courses<br/>
                -        Configure their course with Automatic (ungraded) or manual (graded) attendance<br/>
                -        setup to receive their class attendance code by email and/or text<br/>
                -        See alerts about students with low attendance in their courses<br/>
                -        Download all attendance data<br/>
                -        Get customer support<br/>
            </Typography>
        }, {
            id: 4,
            question: 'What is the difference between manual and automatic attendance?',
            answer:
            <Typography variant={'h4'}>
                When the attendance configuration of a course is manual, it means that class attendance is graded. Alternatively, when the attendance configuration is automatic it means that class attendance is nongraded.<br/><br/>

                <strong>Manual attendance</strong><br/>
                To register manual attendance, professors must provide students with a four-digit attendance code when the class begins. Wise Attend generates this code and delivers it to professors through the Professor Portal. Additionally, Wise sends the attendance code to professors via email and text before their class starts. Students will be provided this code by the professor, and will input this code into their student app to mark attendance.<br/><br/>

                Configuring attendance as manual enables professors to see the attendance records of students.<br/>

                Remember: Professors specify whether the attendance to a course is manual or automatic.<br/><br/>

                <strong>Automatic attendance</strong><br/>
                To register automatic attendance, students just need to enter the classroom. After students arrive, before the attendance cutoff period, Wise attend registers their location and marks their attendance.<br/>
            </Typography>
        }
    ];

    const administratorsGuide = [
        {
            id: 1,
            question: 'Overview',
            answer:
            <Typography variant={'h4'}>
                The Administrator’s portal of Wise Attend provides you with complete attendance data, alerts about students and classes that are at-risk due to low attendance, customizable student push notifications, and more.  Setup of users, courses, facilities, and school term can be found in school settings.  The portal is organized into different tabs, which are detailed in the below sections.
            </Typography>
        },
        {
            id: 2,
            question: 'Courses',
            answer:
            <Fragment>
                <Typography variant={'h4'}>
                    The <strong>Courses</strong> home screen appears by default when you log in to the platform. It also appears when you click the <strong>Courses</strong> tab on the navigation bar at the left side of the portal. The screen displays general information about all the courses of your school. <strong>Figure 1</strong> depicts a typical <strong>Courses</strong> home screen. <br/> <br/>
                </Typography>
                <FigureContainer figure={figure1}></FigureContainer>
                <div style={{textAlign: 'center'}}><strong>Figure 1.</strong> Courses Home Screen.</div> <br/> <br/>
                <Typography variant={'h4'}>
                    The <strong>Courses</strong> home screen has two main sections: <br></br> <br/>
                    <strong>Overall Statistics</strong> <br/> <br/>
                    <FigureContainer figure={figure2}></FigureContainer>
                    This includes two tiles that describe statistics about the overall course attendance. <strong>Figure 2</strong> depicts a closer view of the Statistics section.

                </Typography>
                <div style={{textAlign: 'center'}}><strong>Figure 2.</strong> Statistics Section.</div> <br/> <br/>
                <Typography variant={'h4'}>

                    The top tile contains the following information, from left to right: <br/>

                    - The percentage of courses with low attendance. <br/>
                    - The average attendance percentage across all the active courses.<br/>
                    - The maximum possible attendances compared with the actual number of attendances.<br/><br/>

                    The bottom tile displays a chart that describes the overall attendance statistics of the current term. The chart contains the following information:<br/>

                    - The vertical axis of the chart represents the attendance percentage.<br/>
                    - The horizontal axis of the chart represents the time frame of the school term.<br/>
                    - The blue line represents the variation of the attendance percentage throughout the term.<br/>
                    - The dots represent the average attendance percentage of a specific interval. <strong>Hover</strong> the dots to see the attendance percentage they represent.<br/> <br/>

                    The horizontal axis is segmented into monthly intervals by default. You can change the scale of the intervals by clicking the dropdown button next to the chart. The button has two options available: <strong>monthly</strong> and <strong>weekly</strong>. <br/> <br/>

                    <strong>Course Cards</strong> <br/> <br/>

                    This section includes a table with information about all the active courses in your school. This information is divided into course cards. There is one course card for each active course. <strong>Figure 3</strong> depicts a closer view the Course Cards section. <br/> <br/>
                    </Typography>
                    <FigureContainer figure={figure3}></FigureContainer>
                    <div style={{textAlign:'center'}}><strong>Figure 3.</strong> Course Card Section <br/> <br/></div>

                    <Typography variant={'h4'}>
                    At the top left side of this section, you see the number of courses that are active during the current term. At the top right side of this section, you see the <strong>Low attendance</strong>  button. Toggling <strong>Low attendance</strong> enables you to see only the cards corresponding to courses with low attendance. <br/>

                    The rest of the section contains the course cards, which are displayed in an individual tile each. The course cards contain the following elements, from top to bottom and from left to right: <br/><br/>

                    - The <strong>Low attendance</strong> tag (Only visible when the attendance percentage is below the school threshold.) <br/>
                    - The name of the course. <br/>
                    - The school code of the course. <br/>
                    - The class schedule of the course. <br/>
                    - The average attendance percentage of the course (up to the current day). <br/>
                    - The percentage of students at risk. <br/>
                    - The <strong>Graded attendance</strong> or <strong>Nongraded attendance</strong> tag. <br/>
                    - The number of students with low attendance compared with the total number of students enrolled in the course.
                </Typography>
        </Fragment>
    },
    {
        id: 3,
        question: 'Course Details',
        answer:
        <Fragment>
            <Typography variant={'h4'}>Click a course card to see the details of that course. Then, the <strong>Course details</strong> screen appears. <strong>Figure 4</strong> depicts a typical <strong>Course details</strong> screen.</Typography> <br/> <br/>
            <FigureContainer figure={figure4} style={{maxWidth: '400px'}}></FigureContainer>
            <div style={{textAlign:'center'}}><strong>Figure 4.</strong> Course Details Screen <br/> <br/></div>
            <Typography variant={'h4'}>This screen contains five tiles: </Typography> <br/>
            <Typography variant={'h4'}><strong>Course Information</strong></Typography> <br/>
            <Typography variant={'h4'}>This tile shows general information about the course.</Typography> <br/>

            <Typography variant={'h4'}><strong>Attendance Percentage</strong></Typography> <br/>
            <Typography variant={'h4'}>This tile shows information about the class attendance percentage of enrolled students.</Typography> <br/>
            <Typography variant={'h4'}><strong>Attendance Over the Active Period</strong></Typography> <br/>
            <Typography variant={'h4'}>This tile displays a chart that compares the overall attendance statistics of the school with the attendance statistics of the <strong>course</strong> during the current term. The chart contains the following information: <br/> <br/>
            - The vertical axis of the chart represents the attendance percentage. <br/>
            - The horizontal axis of the chart represents the time frame of the current school term. <br/>
            - The blue line represents the variation of the overall attendance percentage of the school. <br/>
            - The light blue represents the variation of the attendance percentage of the course. <br/>
            - The dots represent the average attendance percentage of a specific interval. <strong>Hover</strong> the dots to see the attendance percentage they represent. <br/> <br/>
            The horizontal axis is segmented into monthly intervals by default. You can change the scale of the intervals by clicking the dropdown button at the right side of the chart. The button has two options available: <strong>monthly</strong> and <strong>weekly</strong>.
            </Typography>

            <Typography variant={'h4'}><strong>Course Progress</strong></Typography> <br/><br/>
            <Typography variant={'h4'}>
            This tile displays a bar that shows the number of classes taught and the number of classes left in the current term.
            </Typography> <br/>
            <Typography variant={'h4'}><strong>Students</strong></Typography> <br/>
            <Typography variant={'h4'}>
            This tile displays a table with information about the students enrolled in the course. <strong>Figure 5</strong> depicts a closer view of the <strong>Students</strong> tile.
            </Typography> <br/>
            <FigureContainer figure={figure5}></FigureContainer>
            <div style={{textAlign:'center'}}><strong>Figure 5.</strong> Students tile <br/> <br/></div>
            <Typography variant={'h4'}>
            At the top left side of the tile, below the <strong>Students</strong> heading, you see the number of students enrolled in the course.
            At the top right side of the tile, you see the <strong>Low Attendance</strong> and the <strong>Download Data</strong> buttons. Toggling <strong>Low Attendance</strong> enables you to see only the table rows corresponding to students with low attendance. Click <strong>Download data</strong> to download a CSV file with all the information of the table. <br/>
            The table includes the following information: <br/><br/>
            </Typography> <br/>
            <Grid container justify="center" style={{textAlign: 'center'}}>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Column Name</TableCell>
                                    <TableCell >Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Name student</TableCell>
                                    <TableCell>Specifies the name of the student. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Email student</TableCell>
                                    <TableCell>Specifies the email of the student. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Graded Attendances</TableCell>
                                    <TableCell>Specifies the average class attendance percentage of the course. It considers graded and nongraded </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Low Attendance</TableCell>
                                    <TableCell>Specifies if the student has a low attendance percentage. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Date [Graded]</TableCell>
                                    <TableCell>Specifies if the student attended a class on a particular date. There is one column for each class date. The **Graded** text appears below the date if attendance was graded that day.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <br/><br/>
            <Typography variant={'h4'}>
                You can perform the following actions on the table: <br/><br/>
                - Sort the data in the column in ascendant or descendant order by clicking the arrow ![Arrow](Arrow-button.png) besides the column name. <br/>
                - Click the name of a student to view the [Student details](#viewing-student-details). <br/>
                - Click the email of a student to create a new message addressed to this student.<br/>
                - Scroll from left to right to see all the columns of the table.<br/>
                - Click the page number to see the next page of the table. Each page shows 10 students at a time.<br/><br/>
            </Typography>
        </Fragment>
    },
    {
        id: 5,
        question: 'Professors',
        answer:
        <Fragment>
            <Typography variant={'h4'}>
            At the left side of the portal, on the navigation bar, click the <strong>Professors</strong> tab. Then, the <strong>Professors</strong> screen appears. This screen displays a table with information about every professor working for your school. <strong>Figure 6</strong> depicts a typical <strong>Professors</strong> screen.
            </Typography> <br/><br/>
            <FigureContainer figure={figure6}></FigureContainer>
            <div style={{textAlign:'center'}}><strong>Figure 6.</strong> Professors Screen <br/> <br/></div>
            <Typography variant={'h4'}>
            At the top left side of the tile, below the <strong>Professors</strong> heading, you see the number of professors working for your school. Click<strong>Download data</strong> at the top-right side of the tile to download a CSV file with all the information of the table. <br/>
            This table includes the following information:
            </Typography> <br/>
            <Grid container justify="center" style={{textAlign: 'center'}}>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Column Name</TableCell>
                                    <TableCell >Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Name professor</TableCell>
                                    <TableCell>Specifies the name of the professor. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Courses Teaching </TableCell>
                                    <TableCell>Specifies the number of courses that the professor is teaching in the current term. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Attendance Percentage (%)</TableCell>
                                    <TableCell>Specifies the average attendance percentage of all the courses that the professor is teaching. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Enrolled Students </TableCell>
                                    <TableCell>Specifies the total number of students that are enrolled with that professor.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Students with Low Attendance</TableCell>
                                    <TableCell>Specifies the percentage of students with low attendance that are enrolled with this professor.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Students with Low Attendance (%)</TableCell>
                                    <TableCell>Specifies the percentage of students with low attendance that are enrolled with this professor.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid> <br/><br/>
            <Typography variant={'h4'}>
                You can perform the following actions on the table: <br/><br/>
                - Sort the data in the column in ascendant or descendant order by clicking the arrow besides the column name. <br/>
                - Click the name of a professor to view the professor details.<br/><br/>
            </Typography>
        </Fragment>
    },
    {
        id: 3,
        question: 'Professor Details',
        answer:
        <Fragment>
            <Typography variant={'h4'}>On the <strong>Professors</strong> table, click the name of a professor to see their details. Then, the <strong>Professor details</strong> screen appears. <strong>Figure 7</strong> depicts a typical <strong>Professor details</strong> screen.</Typography> <br/> <br/>
            <FigureContainer figure={figure7} style={{maxWidth: '400px'}}></FigureContainer>
            <div style={{textAlign:'center'}}><strong>Figure 7.</strong> Professor Details Screen <br/> <br/></div>
            <Typography variant={'h4'}>This screen contains four tiles: </Typography> <br/>
            <Typography variant={'h4'}><strong>Professor Information</strong></Typography> <br/>
            <Typography variant={'h4'}>This tile shows general information about the professor.</Typography> <br/>
            <Typography variant={'h4'}><strong>Attendance Percentage</strong></Typography> <br/>
            <Typography variant={'h4'}>This tile shows information about the class attendance percentage of students enrolled with this professor.</Typography> <br/>
            <Typography variant={'h4'}><strong>Attendance Over the Active Period</strong></Typography> <br/>
            <Typography variant={'h4'}>This tile displays a chart that compares the overall attendance statistics of the school with the attendance statistics of the courses that the professor teaches. The chart contains the following information:<br/>
            - The vertical axis of the chart represents the attendance percentage. <br/>
            - The horizontal axis of the chart represents the time frame of the current school term. <br/>
            - The blue line represents the variation of the overall attendance percentage of the school. <br/>
            - The light blue represents the variation of the attendance percentage of the professor's courses. <br/>
            - The dots represent the average attendance percentage of a specific interval. <strong>Hover</strong> the dots to see the attendance percentage they represent. <br/> <br/>
            The horizontal axis is segmented into monthly intervals by default. You can change the scale of the intervals by clicking the dropdown button at the right side of the chart. The button has two options available: <strong>monthly</strong> and <strong>weekly</strong>.
            </Typography>
            <Typography variant={'h4'}><strong>Courses</strong></Typography> <br/>
            <Typography variant={'h4'}>
            TThis tile displays a table with information about the courses that the professor is teaching during the current term. <strong>Figure 8</strong> depicts a closer view of the <strong>Courses</strong> tile.
            </Typography> <br/>
            <FigureContainer figure={figure8}></FigureContainer>
            <div style={{textAlign:'center'}}><strong>Figure 8.</strong> Courses tile <br/> <br/></div>
            <Typography variant={'h4'}>
            At the top left side of the tile, below the <strong>Courses</strong> heading, you see the number of courses that the professor is teaching.
            Click <strong>Download data</strong> to download a CSV file with all the information of the table. <br/>
            The table includes the following information: <br/><br/>
            </Typography> <br/>
            <Grid container justify="center" style={{textAlign: 'center'}}>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Column Name</TableCell>
                                    <TableCell >Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Course name</TableCell>
                                    <TableCell>Specifies the name of the course. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell> Course ID</TableCell>
                                    <TableCell>Specifies the email of the student. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Attendance Configuration</TableCell>
                                    <TableCell>Specifies if attendance to the class is graded (manual) or nongraded (automatic).     </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Attendance Percentage (%)  </TableCell>
                                    <TableCell>Specifies the average attendance percentage of enrolled students.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Enrolled Students </TableCell>
                                    <TableCell>Specifies the number of students that are enrolled in the course.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Students with Low Attendance</TableCell>
                                    <TableCell>Specifies the total number of students with low attendance that are enrolled in this course.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <br/><br/>
            <Typography variant={'h4'}>
                You can perform the following actions on the table: <br/><br/>
                - Sort the data in the column in ascendant or descendant order by clicking the arrow ![Arrow](Arrow-button.png) besides the column name. <br/>
                - Click the name of a course to view the Course details <br/>
            </Typography>
        </Fragment>
    },
    {
        id: 3,
        question: 'Students ',
        answer:
        <Fragment>
            <Typography variant={'h4'}>At the left side of the portal, on the navigation bar, click the <strong>Students</strong> tab. Then, the <strong>Students</strong> screen appears. This screen displays information about every student enrolled in your school. <strong>Figure 9</strong> depicts the <strong>Students</strong> screen.</Typography> <br/> <br/>
            <FigureContainer figure={figure9}></FigureContainer>
            <div style={{textAlign:'center'}}><strong>Figure 9.</strong> Students Screen <br/> <br/></div>
            <Typography variant={'h4'}>The <strong>Students</strong> screen has two main sections. The first section includes two tiles that describe statistics about the overall attendance of students. <strong>Figure 10</strong> depicts a closer view of the first section.</Typography> <br/>
            <FigureContainer figure={figure10}></FigureContainer>
            <div style={{textAlign:'center'}}><strong>Figure 10.</strong> Statistics Section <br/> <br/></div>
            <Typography variant={'h4'}>
            The first tile contains the following information, from left to right: <br/>
            - The amount of students with low attendance rates. <br/>
            - The percentage of students with low attendance rates. <br/> <br/>
            The second tile displays a chart that shows the statistics of low-attendance students over the current term. The chart contains the following information: <br/><br/>
            - The vertical axis of the chart represents the attendance percentage.<br/>
            - The horizontal axis of the chart represents the time frame of the school term.<br/>
            - The blue line represents the variation of the percentage of low-attendance students throughout the term.<br/>
            - The dots represent the average percentage of low-attendance students on a specific interval. **Hover** the dots to see the attendance percentage they represent.

            The horizontal axis is segmented into monthly intervals by default. You can change the scale of the intervals by clicking the dropdown button at the right side of the chart. The button has two options available:<strong>monthly</strong> and <strong>weekly</strong>. <br/><br/>

            The second section includes a table with information about every student enrolled in your school. <strong>Figure 11</strong> depicts a closer view of the second section.
            </Typography> <br/><br/>
            <FigureContainer figure={figure11}></FigureContainer>
            <div style={{textAlign:'center'}}><strong>Figure 11.</strong> All school Students Section <br/> <br/></div>
            <Typography variant={'h4'}>
            At the top left side of the tile, you see the number of students enrolled in the current term.
            Click <strong>Download data</strong> to download a CSV file with all the information of the table. <br/>
            The table includes the following information: <br/><br/>
            </Typography> <br/>
            <Grid container justify="center" style={{textAlign: 'center'}}>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Column Name</TableCell>
                                    <TableCell >Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell> Name</TableCell>
                                    <TableCell>Specifies the name of the student. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell> Email</TableCell>
                                    <TableCell>Specifies the email of the student. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell> Classes Enrolled</TableCell>
                                    <TableCell>Specifies the number of classes that the student is enrolled in. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Attendance (%)  </TableCell>
                                    <TableCell>Specifies the average attendance percentage of the student.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Absences </TableCell>
                                    <TableCell>Specifies how many times the student has missed a class.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <br/><br/>
            <Typography variant={'h4'}>
                You can perform the following actions on the table: <br/><br/>
                - Sort the data in the column in ascendant or descendant order by clicking the arrow besides the column name. <br/>
                - Click the name of a course to view the Course details. <br/>
                - Click the name of a student to view the Student details.
            </Typography>
        </Fragment>
    },
    {
        id: 3,
        question: 'Student Details',
        answer:
        <Fragment>
            <Typography variant={'h4'}>On the <strong>Students</strong> table, click the name of a student to see their details. Then, the <strong>Student details</strong> screen appears. <strong>Figure 12</strong> depicts a typical <strong>Student details</strong> screen.</Typography> <br/> <br/>
            <FigureContainer figure={figure12}></FigureContainer>
            <div style={{textAlign:'center'}}><strong>Figure 12.</strong> Student Details Screen <br/> <br/></div>
            <Typography variant={'h4'}>This screen contains three tiles: </Typography> <br/>
            <Typography variant={'h4'}><strong>Student Information</strong></Typography> <br/>
            <Typography variant={'h4'}>This tile shows general information about the student.</Typography> <br/>
            <Typography variant={'h4'}><strong>Classes Attended </strong></Typography> <br/>
            <Typography variant={'h4'}>This tile shows information about the class attendance of the student.</Typography> <br/>
            <Typography variant={'h4'}><strong>Courses</strong></Typography> <br/>
            <Typography variant={'h4'}>
            This tile displays a table with information about he courses in which the student is currently enrolled. <br/>
            At the top left side of the tile, below the <strong>Courses</strong> heading, you see the number of courses in which the student is enrolled
            Click <strong>Download data</strong> to download a CSV file with all the information of the table. <br/>
            The table includes the following information: <br/><br/>
            </Typography> <br/>
            <Grid container justify="center" style={{textAlign: 'center'}}>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Column Name</TableCell>
                                    <TableCell >Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Course name</TableCell>
                                    <TableCell>Specifies the name of the course. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell> Course ID</TableCell>
                                    <TableCell>Specifies the code of the course. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Professor Course </TableCell>
                                    <TableCell>Specifies the name of the professor of the course. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Attendance Configuration  </TableCell>
                                    <TableCell> Specifies if attendance to the class is graded (manual) or nongraded (automatic).</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Attendance Percentage %  </TableCell>
                                    <TableCell>Specifies the average attendance percentage of enrolled students.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell> Low Attendance Percentage</TableCell>
                                    <TableCell>Specifies if the student has a low attendance percentage.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <br/><br/>
            <Typography variant={'h4'}>
                You can perform the following actions on the table: <br/><br/>
                - Sort the data in the column in ascendant or descendant order by clicking the arrow ![Arrow](Arrow-button.png) besides the column name. <br/>
                - Click the name of a course to view the Course details <br/>
                - Click the name of a professor to view the Professor details <br/>
            </Typography>
        </Fragment>
    },
    {
        id: 3,
        question: 'School Settings',
        answer:
        <Fragment>
            <Typography variant={'h4'}>At the left side of the portal, on the navigation bar, click the <StrongContainer text={'School Settings'}/> tab. Then, the <StrongContainer text={'School Settings'}/> screen appears. This screen displays information about your school and enables you to edit it. </Typography> <br/> <br/>
            <Typography variant={'h4'}>The following subsection explains the elements of the user interface.</Typography>
            <br/><br/>
            <Typography variant={'h4'}><StrongContainer text={'School Settings Interface'}/></Typography>
            <br/><br/>
            <Typography variant={'h4'}>The <StrongContainer text={'School Settings '}/>screen has three main sections:</Typography>
            <br/><br/>
            <Typography variant={'h4'}><StrongContainer text={'General Information'}/></Typography>
            <br/><br/>
            <Typography variant={'h4'}><StrongContainer text={'Figure 14 '}/> depicts a closer view of this section.</Typography>
            <br/><br/>
            <FigureContainer figure={figure14}></FigureContainer>
            <div style={{textAlign:'center'}}>
                <StrongContainer text={'Figure 14. '}/> 
                Current Term Section <br/> <br/></div>
            <Typography variant={'h4'}>The screen has the following tiles:</Typography>
            <ul>
                <li>
                    <Typography variant={'h4'}><StrongContainer text={'YOUR SCHOOL INFORMATION: '}/> This tile shows the following:</Typography>
                    <ul>
                        <li>
                            <Typography variant={'h4'}><StrongContainer text={'Name: '}/> It is the school name that you specified when you set up the school information. You can edit it by clicking Edit at the right side of the name. For more information, see the <StrongContainer text={'Edit the Name of the School'}/> section.</Typography>
                        </li>
                        <li>
                            <Typography variant={'h4'}><StrongContainer text={'ID '}/> It is the unique identifier of your school in the Wise Attend platform. The ID is generated by Wise Attend and you cannot edit it.</Typography>
                        </li>
                    </ul>
                </li>
                <br/>
                <li>
                    <Typography variant={'h4'}><StrongContainer text={'ACTIVATE NEW ACCOUNTS: '}/> It enables you to send an activation email to the following groups of users:</Typography>
                    <ul>
                        <li><Typography variant={'h4'}>All new professors</Typography></li>
                        <li><Typography variant={'h4'}>All new students</Typography></li>
                        <li><Typography variant={'h4'}>All new admins</Typography></li>
                    </ul>
                </li>
            </ul>
            <br/>
            <Typography variant={'h4'}><StrongContainer text={'Current Term'}/></Typography>
            <br/>
            <Typography variant={'h4'}><StrongContainer text={'Figure 15 '}/> depicts a closer view of the section.</Typography>
            <br/><br/>
            <FigureContainer figure={figure15}></FigureContainer>
            <div style={{textAlign:'center'}}>
                <StrongContainer text={'Figure 15. '}/> 
                Current Term Section <br/> <br/></div>
            <Typography variant={'h4'}>This section includes the following tiles:</Typography>
            <ul>
                <li>
                    <Typography variant={'h4'}><StrongContainer text={'YOUR ACTIVE TERM: '}/> It shows the following information about the term:</Typography>
                    <ul>
                        <li>
                            <Typography variant={'h4'}><StrongContainer text={'Name: '}/> The semester name.</Typography>
                        </li>
                        <li>
                            <Typography variant={'h4'}><StrongContainer text={'Dates: '}/> The start date and the end date of the semester.</Typography>
                        </li>
                        <li>
                            <Typography variant={'h4'}><StrongContainer text={'Tolerance time of attendance: '}/> The time frame after the class started when students can still mark their attendance. You can edit it by clicking the <StrongContainer text={'Edit '}/> link next to the value.</Typography>
                        </li>
                        <li>
                            <Typography variant={'h4'}><StrongContainer text={'Minimum attendance to pass: '}/> The minimum class attendance required to pass a course. You can edit it by clicking the <StrongContainer text={'Edit '}/>  link next to the value.</Typography>
                        </li>
                    </ul>
                </li>
                <br/>
                <li>
                    <Typography variant={'h4'}><StrongContainer text={'CLOSE TERM: '}/> It enables you to end the current term and archive all its data.</Typography>
                </li>
                <br/>
                <li>
                    <Typography variant={'h4'}><StrongContainer text={'YOUR UPLOADED INFORMATION: '}/> It enables you to edit the information you uploaded in your CSV files. This tile provides the templates to edit the following:</Typography>
                    <ul>
                        <li><Typography variant={'h4'}>Classroom locations</Typography></li>
                        <li><Typography variant={'h4'}>Students</Typography></li>
                        <li><Typography variant={'h4'}>Professors</Typography></li>
                        <li><Typography variant={'h4'}>Courses</Typography></li>
                        <li><Typography variant={'h4'}>Wise Attend Platform Administrators</Typography></li>
                    </ul>
                </li>
            </ul>
            <br/><br/>
            <Typography variant={'h4'}><StrongContainer text={'Notifications and Reminders'}/></Typography>
            <br/>
            <Typography variant={'h4'}><StrongContainer text={'Figure 16 '}/> depicts a closer view of the section.</Typography>
            <br/><br/>
            <FigureContainer figure={figure16} style={{maxWidth: '800px'}}></FigureContainer>
            <div style={{textAlign:'center'}}>
                <StrongContainer text={'Figure 16. '}/> 
                Current Term Section<br/> <br/></div>
            <Typography variant={'h4'}>This section includes a single tile that enables you to configure four different notification messages:</Typography>

            <ul>
                <li>
                    <Typography variant={'h4'}><StrongContainer text={'UPCOMING CLASS: '}/> To remind students that their class is starting soon.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}><StrongContainer text={'MISSED CLASS (MANUAL ATTENDANCE): '}/>To inform them that they were marked as absent on a class with graded attendance.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}><StrongContainer text={'MISSED CLASS (AUTOMATIC ATTENDANCE): '}/> To inform them that they were marked as absent on a class with nongraded attendance.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}><StrongContainer text={'ENCOURAGEMENT MESSAGE: '}/> To motivate students with low attendance.</Typography>
                </li>
            </ul>

            <Typography variant={'h4'}>Wise Attend automatically notifies students at the time of your choice.</Typography>
            <br/><br/>

            <Typography variant={'h4'}><StrongContainer text={'Edit the Name of the School'}/></Typography>
            <br/>
            <Typography variant={'h4'}>You can edit the name of the school on the <StrongContainer text={'YOUR SCHOOL INFORMATION'}/> tile. To do so, perform the following steps:</Typography>

            <ol>
                <li>
                    <Typography variant={'h4'}>Click the <StrongContainer text={'Edit'}/> link besides the name of the school. The school name becomes an editable field.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>Edit the name of the school.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>Click <StrongContainer text={'Save. '}/> The platform stores the school name.</Typography>
                </li>
            </ol>
            <br/><br/>
            <Typography variant={'h4'}><StrongContainer text={'Upload New CSV Files'}/></Typography>
            <br/>
            <Typography variant={'h4'}>The<StrongContainer text={'YOUR UPLOADED INFORMATION '}/> tile enables you to edit the information you uploaded in your CSV files. To do this, you must upload a new CSV file with the data that you want to add, update, or delete.</Typography>

            <Typography variant={'h4'}>To upload new CSV files:</Typography>

            <ol>
                <li>
                    <Typography variant={'h4'}>
                        Click <StrongContainer text={'Template'}/> to download the CSV templates corresponding to the data that you want to upload. <StrongContainer text={'Important:'}/> Always download new <StrongContainer text={'blank'}/> templates to upload data. Never use an earlier file containing previously uploaded data.
                        </Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>Open the files in Excel or a similar software.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>Edit the templates with the corresponding information. <StrongContainer text={'Important:'}/> All the fields in the CSV files are mandatory. See the <StrongContainer text={'Filling the CSV Files'}/> section to learn how to fill the files before uploading.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>Save the files in CSV format. <StrongContainer text={'Important: '}/> The files must follow this naming convention: schoolCode_termName_tableName. Example: UC123_Spring2020_students.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>Click the <StrongContainer text={'Upload'}/> link corresponding to the CSV template that you downloaded. A pop-up window appears.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>On the pop-up window, select the CSV file from your computer. The pop-up window closes.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>Repeat <StrongContainer text={'steps 5 and 6'}/> if you want to upload more than one file.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>On the tile, click <StrongContainer text={'Save. '}/> The following warning message appears. The platform stores the school name.</Typography>
                    <FigureContainer figure={figure17} style={{maxWidth: '400px'}}></FigureContainer>
                    <div style={{textAlign:'center'}}>
                        <StrongContainer text={'Figure 17. '}/> 
                        Upload Warning Message<br/> <br/></div>
                </li>
                <li>
                    <Typography variant={'h4'}>Click <StrongContainer text={'Ok'}/>  to upload the CSV or <StrongContainer text={'Cancel'}/> to dismiss the action.</Typography>
                </li>
            </ol>

            <Typography variant={'h4'}><StrongContainer text={'Filling the CSV Files'}/></Typography>
            <br/>
            <Typography variant={'h4'}>This section explains how to fill the CSV files with your school information before uploading them to the platform.</Typography>
            <Typography variant={'h4'}>On the CSV files, you specify one of the following actions per record:</Typography>
            <br/>
            <Grid container justify="center" style={{textAlign: 'center'}}>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Action</TableCell>
                                    <TableCell >Description</TableCell>
                                    <TableCell >Notes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>ADD</TableCell>
                                    <TableCell>Enables you to add a new record to the applicable category.</TableCell>
                                    <TableCell>If you duplicate an ADD request the newer one overwrites the previous one.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>UPDATE</TableCell>
                                    <TableCell>Enables you to modify an existing record in the applicable category.</TableCell>
                                    <TableCell>If you try to update a record that does not exist the platform implements an ADD request.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>DELETE</TableCell>
                                    <TableCell>Enables you to delete an existing record from the applicable category.</TableCell>
                                    <TableCell>If you try to delete a record that does not exist the platform displays an error.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <div style={{textAlign:'center'}}>
                        <StrongContainer text={'Table 6. '}/> 
                        Available Actions<br/> <br/></div>

            <Typography variant={'h4'}><StrongContainer text={'Filling the CSV Files'}/></Typography>

            <Typography variant={'h4'}>This section explains how to fill the CSV files with your school information before uploading them to the platform.</Typography>
            <br/>
            <Typography variant={'h4'}>On the CSV files, you specify one of the following actions per record:</Typography>
            <br/>
            <Grid container justify="center" style={{textAlign: 'center'}}>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Action</TableCell>
                                    <TableCell >Description</TableCell>
                                    <TableCell >Notes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>ADD</TableCell>
                                    <TableCell>Enables you to add a new record to the applicable category.</TableCell>
                                    <TableCell>If you duplicate an ADD request the newer one overwrites the previous one.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>UPDATE</TableCell>
                                    <TableCell>Enables you to modify an existing record in the applicable category.</TableCell>
                                    <TableCell>If you try to update a record that does not exist the platform implements an ADD request.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>DELETE</TableCell>
                                    <TableCell>Enables you to delete an existing record from the applicable category.</TableCell>
                                    <TableCell>If you try to delete a record that does not exist the platform displays an error.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <div style={{textAlign:'center'}}>
                        <StrongContainer text={'Table 6. '}/> 
                        Available Actions<br/> <br/></div>
                        
            <Typography variant={'h4'}><StrongContainer text={'Wise Attend Platform Administrators'}/></Typography>
            <br/>
            <Typography variant={'h4'}>This file includes the following data about the Wise Attend Platform administrators of your school.</Typography>
            <br/>
            <Grid container justify="center" style={{textAlign: 'center'}}>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Column Name</TableCell>
                                    <TableCell >Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>code</TableCell>
                                    <TableCell>Specifies the ID by which the school identifies the administrator.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>name</TableCell>
                                    <TableCell>Specifies the name of the person authorized as an administrator of the Wise Attend platform.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>email</TableCell>
                                    <TableCell>Specifies the email address of the administrator.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>phone</TableCell>
                                    <TableCell>Specifies the phone number of the administrator.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>action</TableCell>
                                    <TableCell>Specifies whether you want to add, update, or delete a record from the platform. For more information, see the <StrongContainer text={'Available Actions table.'}/> Valid values: ADD, UPDATE, DELETE.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <div style={{textAlign:'center'}}>
                        <StrongContainer text={'Table 7. '}/> 
                        Fields of the Wise Attend Platform Administrators CSV File<br/> <br/></div>

            
            <Typography variant={'h4'}><StrongContainer text={'Courses'}/></Typography>
            <br/>
            <Typography variant={'h4'}>This file includes the following data about the courses school.</Typography>
            <br/>
            <Grid container justify="center" style={{textAlign: 'center'}}>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Column Name</TableCell>
                                    <TableCell >Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>code</TableCell>
                                    <TableCell>Specifies the ID by which the school identifies the course.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>name</TableCell>
                                    <TableCell>Specifies the name of the course.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>facility_code</TableCell>
                                    <TableCell>Specifies the code of the facilities where the course takes place.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>graded</TableCell>
                                    <TableCell>Specifies whether the course takes attendance as a factor for grading students.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>start_date</TableCell>
                                    <TableCell>Specifies the starting date of the course. <StrongContainer text={'Date format:'}/> YYYY-MM-DD.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>end_date</TableCell>
                                    <TableCell>Specifies the ending date of the course. <StrongContainer text={'Date format:'}/> YYYY-MM-DD. </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>schedule</TableCell>
                                    <TableCell>Specifies the times in the week at which the course is taught.  <StrongContainer text={'Schedule format:'}/> Day1_StartTime_EndTime#Day2_StartTime_EndTime#.... Example: Mo_10:00_11:00#We_12:00_15:00#Fr_12:00_15:00.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>professor_email</TableCell>
                                    <TableCell>Specifies the email of the professor.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>student_emails</TableCell>
                                    <TableCell>Specifies the list of emails of the students enrolled in the course. <StrongContainer text={'List format:'}/> email1#email2#email3#.... Example: Selmer_Rodriguez@hotmail.com#Caroline.Conn@yahoo.com#sally@college.edu.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>student_emails</TableCell>
                                    <TableCell>Specifies whether you want to add, update, or delete a record from the platform. For more information, see the <StrongContainer text={'Available Actions table.'}/> Valid values: ADD, UPDATE, DELETE.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <div style={{textAlign:'center'}}>
                        <StrongContainer text={'Table 8. '}/> 
                        Fields of the Courses CSV File<br/> <br/></div>



            <Typography variant={'h4'}><StrongContainer text={'Classroom Locations'}/></Typography>
            <br/>
            <Typography variant={'h4'}>This file includes the following data about the classrooms or facilities of the school.</Typography>
            <br/>
            <Grid container justify="center" style={{textAlign: 'center'}}>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Column Name</TableCell>
                                    <TableCell >Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>code</TableCell>
                                    <TableCell>Specifies the ID by which the school identifies the facility.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>name</TableCell>
                                    <TableCell>Specifies the name of the facility.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>lat</TableCell>
                                    <TableCell>Latitude. Specifies the geographic coordinate that represents the north-south position of the facility location. It is used together with lon to determine the precise location of the facility.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>long</TableCell>
                                    <TableCell>Longitude. Specifies the geographic coordinate that represents the east–west position of the facility location. It is used together with lat to determine the precise location of the facility.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>rad</TableCell>
                                    <TableCell>Specifies the geofence radius in meters. It is used together with lat and lon to determine the user’s proximity to the facility.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>floor</TableCell>
                                    <TableCell>Specifies the floor where the facility is located.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>type</TableCell>
                                    <TableCell>Specifies the type of facility, such as Library, Classroom, Auditorium, etc.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>action</TableCell>
                                    <TableCell>Specifies whether you want to add, update, or delete a record from the platform. For more information, see the <StrongContainer text={'Available Actions table.'}/> Valid values: ADD, UPDATE, DELETE.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <div style={{textAlign:'center'}}>
                        <StrongContainer text={'Table 9. '}/> 
                        Fields of the Courses CSV File<br/> <br/></div>



            <Typography variant={'h4'}><StrongContainer text={'Professors'}/></Typography>
            <br/>
            <Typography variant={'h4'}>This file includes the following data about the school professors.</Typography>
            <br/>
            <Grid container justify="center" style={{textAlign: 'center'}}>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Column Name</TableCell>
                                    <TableCell >Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>code</TableCell>
                                    <TableCell>Specifies the ID by which the school identifies the professor.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>name</TableCell>
                                    <TableCell>Specifies the name of the professor.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>email</TableCell>
                                    <TableCell>Specifies the email address of the professor.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>phone</TableCell>
                                    <TableCell>Specifies the phone number of the professor.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>academic_rank</TableCell>
                                    <TableCell>Specifies the professor’s academic rank, such as Dr. or Prof.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>action</TableCell>
                                    <TableCell>Specifies whether you want to add, update, or delete a record from the platform. For more information, see the <StrongContainer text={'Available Actions table.'}/> Valid values: ADD, UPDATE, DELETE.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <div style={{textAlign:'center'}}>
                        <StrongContainer text={'Table 10. '}/> 
                        Fields of the Professors CSV File<br/> <br/></div>



            <Typography variant={'h4'}><StrongContainer text={'Students'}/></Typography>
            <br/>
            <Typography variant={'h4'}>This file includes the following data about the school professors.</Typography>
            <br/>
            <Grid container justify="center" style={{textAlign: 'center'}}>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Column Name</TableCell>
                                    <TableCell >Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>code</TableCell>
                                    <TableCell>Specifies the ID by which the school identifies the student.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>name</TableCell>
                                    <TableCell>Specifies the name of the student.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>email</TableCell>
                                    <TableCell>Specifies the email address of the student.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>phone</TableCell>
                                    <TableCell>Specifies the phone number of the student.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>action</TableCell>
                                    <TableCell>Specifies whether you want to add, update or delete a record from the platform. For more information, see the <StrongContainer text={'Available Actions table.'}/> Valid values: ADD, UPDATE, DELETE.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <div style={{textAlign:'center'}}>
                        <StrongContainer text={'Table 11. '}/> 
                        Fields of the Students CSV File<br/> <br/></div>


            <br/>
            <Typography variant={'h4'}><StrongContainer text={'Sending Activation Emails'}/></Typography>
            <br/>
            <Typography variant={'h4'}>The<StrongContainer text={' ACTIVATE NEW ACCOUNTS'}/> tile enables you to send an activation email to new users. This email includes the credentials they require to access their accounts for the first time. Wise Attend only sends the email to the selected user groups.</Typography>

            <Typography variant={'h4'}>To send activation emails:</Typography>

            <ol>
                <li>
                    <Typography variant={'h4'}>Click<StrongContainer text={' Send credentials.'}/> The following pop-up window appears.</Typography>
                    <FigureContainer figure={figure18} style={{maxWidth: '400px'}}></FigureContainer>
                    <div style={{textAlign:'center'}}>
                        <StrongContainer text={'Figure 18. '}/> 
                        Pop-Up Window to Send Credentials<br/> <br/></div>
                </li>
                <li>
                    <Typography variant={'h4'}>Click <StrongContainer text={'Agree'}/> to perform the action or <StrongContainer text={'Cancel'}/> to dismiss it</Typography>
                </li>
            </ol>

            <Typography variant={'h4'}>After clicking <StrongContainer text={'Ok'}/>, a confirmation message appears. On the message window, click <StrongContainer text={'Ok'}/> to close it.</Typography>


            <br/><br/>
            <Typography variant={'h4'}><StrongContainer text={'Editing the Active Term'}/></Typography>
            <br/>
            <Typography variant={'h4'}>You can edit the term information on the <StrongContainer text={'YOUR ACTIVE TERM'}/> tile. </Typography>

            <Typography variant={'h4'}>To edit the <StrongContainer text={'Tolerance time of attendance:'}/></Typography>

            <ol>
                <li>
                    <Typography variant={'h4'}>Click the <StrongContainer text={'Edit'}/> link besides the time. The time becomes an editable field. </Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>Edit the time.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>Click <StrongContainer text={'Save. '}/> The platform stores the tolerance time.</Typography>
                </li>
            </ol>

            <Typography variant={'h4'}>To edit the <StrongContainer text={'Minimum attendance to pass:'}/></Typography>

            <ol>
                <li>
                    <Typography variant={'h4'}>Click the <StrongContainer text={'Edit'}/> link besides the percentage. The percentage becomes an editable field. </Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>Edit the percentage.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>Click <StrongContainer text={'Save. '}/>  The platform stores the percentage of minimum attendance.</Typography>
                </li>
            </ol>

            <br/>
            <Typography variant={'h4'}><StrongContainer text={'Closing a Term'}/></Typography>
            <br/>
            <Typography variant={'h4'}>The<StrongContainer text={' CLOSE TERM'}/> tile enables you to end the current term and archive all its data. You must close the current term before configuring a new one. <StrongContainer text={'Warning: '}/> Close the term only when you are completely sure that all of the active courses have ended.</Typography>

            <Typography variant={'h4'}>To close a term:</Typography>

            <ol>
                <li>
                    <Typography variant={'h4'}>Click<StrongContainer text={' Close term.'}/> The following pop-up window appears.</Typography>
                    <FigureContainer figure={figure19} style={{maxWidth: '400px'}}></FigureContainer>
                    <div style={{textAlign:'center'}}>
                        <StrongContainer text={'Figure 19. '}/> 
                        Warning Message Before Closing a Term<br/> <br/></div>
                </li>
                <li>
                    <Typography variant={'h4'}>Click <StrongContainer text={'Agree'}/> to perform the action or <StrongContainer text={'Cancel'}/> to dismiss it</Typography>
                </li>
            </ol>

            <Typography variant={'h4'}>After clicking <StrongContainer text={'Ok'}/>, a confirmation message appears. On the message window, click <StrongContainer text={'Ok'}/> to close it.</Typography>

            <br/><br/>
            <Typography variant={'h4'}><StrongContainer text={'Setting Up Notifications and Reminders'}/></Typography>
            <br/>
            <Typography variant={'h4'}>You can set up four types of messages in the <StrongContainer text={'Notifications and Reminders'}/> tile. This subsection explains how to set them up.</Typography>

            <Typography variant={'h4'}>To set up an <StrongContainer text={'UPCOMING CLASS'}/> reminder:</Typography>

            <ol>
                <li>
                    <Typography variant={'h4'}>On the <StrongContainer text={'hours'}/> and <StrongContainer text={'minutes'}/> fields, specify how long before class you want to send the message.</Typography>
                    <ul>
                        <li>
                            <Typography variant={'h4'}>The <StrongContainer text={'hours'}/> field only accepts numbers from 0 to 23.</Typography>
                        </li>
                        <li>
                            <Typography variant={'h4'}>The <StrongContainer text={'minutes'}/> field only accepts numbers from 0 to 59.</Typography>
                        </li>
                    </ul>
                </li>
                <li>
                    <Typography variant={'h4'}>On the <StrongContainer text={'Message 45 characters'}/> field, write the message for the reminder. Your message can a have maximum of 45 characters.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>On the bottom-right side of the <StrongContainer text={'Notifications and Reminders'}/> tile, click <StrongContainer text={'Save.'}/></Typography>
                </li>
            </ol>

            <Typography variant={'h4'}>Your message and time settings are now saved.</Typography>
            <Typography variant={'h4'}><StrongContainer text={'Note: '}/> You can only save one message at a time.</Typography>

            <br/>
            <Typography variant={'h4'}>To set up a <StrongContainer text={'MISSED CLASS (MANUAL ATTENDANCE)'}/> or a <StrongContainer text={'MISSED CLASS (AUTOMATIC ATTENDANCE)'}/> notification:</Typography>

            <ol>
                <li>
                    <Typography variant={'h4'}>On the <StrongContainer text={'hours'}/> and <StrongContainer text={'minutes'}/> fields, specify how long before class you want to send the message.</Typography>
                    <ul>
                        <li>
                            <Typography variant={'h4'}>The <StrongContainer text={'hours'}/> field only accepts numbers from 0 to 23.</Typography>
                        </li>
                        <li>
                            <Typography variant={'h4'}>The <StrongContainer text={'minutes'}/> field only accepts numbers from 0 to 59.</Typography>
                        </li>
                    </ul>
                </li>
                <li>
                    <Typography variant={'h4'}>On the <StrongContainer text={'Message 45 characters'}/> field, write the message for the reminder. Your message can a have maximum of 45 characters.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>On the bottom-right side of the <StrongContainer text={'Notifications and Reminders'}/> tile, click <StrongContainer text={'Save.'}/></Typography>
                </li>
            </ol>

            <Typography variant={'h4'}>Your message and time settings are now saved.</Typography>
            <Typography variant={'h4'}><StrongContainer text={'Note: '}/> You can only save one message at a time.</Typography>


            <br/>
            <Typography variant={'h4'}>To set up an <StrongContainer text={'ENCOURAGEMENT MESSAGE:'}/></Typography>

            <ol>
                <li>
                    <Typography variant={'h4'}>On the dropdown menu, select the day of the week when you want the message sent. You can choose any day from Monday to Sunday.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>On the <StrongContainer text={'Message 45 characters'}/> field, write the encouragement message. Your message can have a maximum of 45 characters.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>If you want to add more encouragement messages, click <StrongContainer text={'+ Add message'}/> below the last message field. A new message field appears. <StrongContainer text={'Important: '}/> You cannot leave blank message fields. To delete extra fields, click <StrongContainer text={'Delete'}/> next to the last message field.</Typography>
                </li>
                <li>
                    <Typography variant={'h4'}>On the bottom right side of the <StrongContainer text={'Notifications and Reminders'}/> tile, click <StrongContainer text={'Save.'}/></Typography>
                </li>
            </ol>

            <Typography variant={'h4'}>Your message and day settings are now saved.</Typography>            
        </Fragment>
    },
    {
        id: 3,
        question: 'My profile',
        answer:
        <Fragment>
            <Typography variant={'h4'}>At the left side of the portal, on the Navigation bar, click the <strong>My Profile</strong> tab. This opens the <strong>My Profile</strong> screen. This screen displays your personal user information. <strong>Figure 20</strong> depicts a typical <strong>My Profile</strong> screen.</Typography>
            <br/>
            <FigureContainer figure={figure20} style={{maxWidth: '400px'}}></FigureContainer>
            <div style={{textAlign:'center'}}><strong>Figure 20</strong>. My Profile Screen <br/> <br/></div>
            <br/>
            

            <Typography variant={'h4'}>The screen has the following tiles:</Typography>
            <ul>
                <Typography variant={'h4'}><strong>MY INFORMATION:</strong> This section displays the following information related to you:</Typography>
                <li>
                    <ul>
                        <li><Typography variant={'h4'}>Name</Typography></li>
                        <li><Typography variant={'h4'}>Email</Typography></li>
                        <li><Typography variant={'h4'}><strong>Phone Note:</strong> You can edit your phone number by clicking the <strong>Edit</strong> link next to the value.</Typography></li>
                    </ul>
                </li>
                <li>
                    <Typography variant={'h4'}><strong>SECURITY:</strong> This section enables you to change your password.</Typography>
                </li>
            </ul>

            
            
            <Typography variant={'h4'}><strong>Changing your Password</strong><br/><br/></Typography>
            <Typography variant={'h4'}>To change your password:<br/></Typography>
            <ol>
                <li>In the SECURITY tile, click <strong>Change my password.</strong> A pop-up window appears</li>
                <li>Type your <strong>Current password</strong> in the corresponding field.</li>
                <li>Type the <strong>New password.</strong> Be sure to comply with the guidelines specified below the password fields.</li>
                <li>Click <strong>Save</strong> to complete the task.</li>
            </ol>
            <Typography variant={'h4'}>A confirmation pop-up window now appears. Click <strong>Ok</strong> to close the window.<br/></Typography>
        </Fragment>
    },
    ]


    if(role === 'Professor')
        return (
            <Fragment>
                <Grid item xs={12}>
                    <SectionTypography>Frequently Asked Questions</SectionTypography>
                </Grid>
                {questionsProfessor.map((item, i) => <Question key={i} {...item} />)}
            </Fragment>
        );
    else {
        return(
            <Fragment>
                <Grid item xs={12}>
                    <Typography variant={'h2'} color={'primary'}><strong>Frequently Asked Questions</strong></Typography>
                </Grid>
                {questionsAdmin.map((item, i) => <Question key={i} {...item} />)}
                <Grid item xs={12}>
                    <Typography variant={'h2'} color={'primary'}><strong>Administrators Guide</strong></Typography>
                </Grid>
                {administratorsGuide.map((item, i) => <Question type='guide' key={i} {...item} />)}
            </Fragment>
        )
    }
}

export default FrequentlyQuestion;
