import React from 'react';
import {TableContainer, TableHead, withStyles} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";

export const WTableContainer = withStyles(theme => ({
        root: {
            width: '100%',
            fontFamily: theme.typography.fontFamily,
        },
    })
)(TableContainer);

export const WTableCell = withStyles(theme => ({
        root: {
            ...theme.typography.h5,
            lineHeight: '16px',
            borderBottom: 'none',
            padding: '16px 20px 16px 0px',
        },
        head: {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            fontWeight: 600,
            fontSize: 13,
            verticalAlign: 'top'
        }
    })
)(TableCell);

export const WTableHead = withStyles(theme => ({
        root: {
            borderTop: '1px solid rgba(224, 224, 224, 1)',
        }
    })
)(TableHead);

