import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getProfileInfo, getProfile } from "../../../services/profileService";

import ProfileMyInformation from "./_ProfileMyInformation";
import ProfileSecurity from "./_ProfileSecurity";
import { useQuery } from '@apollo/react-hooks';
import { Modal } from '@material-ui/core';
import SuccessChange from "./SuccessChange";
import ChangePassword from './ChangePassword';
import ChangePhone from "./ChangePhone";

const useStyles = makeStyles({
  root: {
    paddingTop: 26
  },

  contentCardSection: {
    paddingLeft: 3,
  },

  profileContainer: {
    marginTop: 32,
  },

  securityContainer: {
    marginTop: 18,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default function MyProfile({profile, setProfile}) {
  const classes = useStyles();
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openChangePhone, setOpenChangePhone] = useState(false);
  const [typeSuccess, setTypeSuccess] = useState('');
  const handleClose = () => {
    setOpenChangePassword(false);
  }


  const showSuccess = (type, profile = null) => {
    setTypeSuccess(type);
    setOpenChangePassword(false);
    if(type === 'phone') {
      setProfile(profile);
    }
    setOpenChangePhone(false);
    setOpenSuccess(true);

  }

  return (
    <Grid className={classes.root} container direction={"column"}>
      <Grid item>
        <Typography variant={"h1"} color={'primary'} className={classes.header}>My Profile</Typography>
      </Grid>

      <Grid container item className={classes.contentCardSection} direction={"column"}>
        <Grid item className={classes.profileContainer}>
          <ProfileMyInformation  onEditPhone={e=> setOpenChangePhone(true)} name={profile.name} email={profile.email} phone={profile.phone} />
        </Grid>

        <Grid item className={classes.securityContainer}>
          <ProfileSecurity onChangePassword={e => setOpenChangePassword(true)} />
        </Grid>
      </Grid>

      <Modal
        disableBackdropClick
        aria-labelledby="Change password"
        aria-describedby="Modal to change password"
        open={openChangePassword}
        onClose={handleClose}
        className={classes.modal}
      >
        <ChangePassword onSuccess={e=> showSuccess('password')} onClose={e=> setOpenChangePassword(false)}/>
      </Modal>

      <Modal
        disableBackdropClick
        aria-labelledby="Change phone"
        aria-describedby="Modal to change phone"
        open={openChangePhone}
        onClose={handleClose}
        className={classes.modal}
      >
        <ChangePhone profile={profile} onSuccess={profile => showSuccess('phone', profile)} onClose={e=> setOpenChangePhone(false)}/>
      </Modal>
      <Modal
        disableBackdropClick
        aria-labelledby="Succes"
        aria-describedby="Modal to success"
        open={openSuccess}
        onClose={handleClose}
        className={classes.modal}
      >
        <SuccessChange type={typeSuccess} onClose={e=> setOpenSuccess(false)}/>
      </Modal>
    </Grid>
  )
};
