import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Icon } from "@material-ui/core";
import { ErrorTitleTypography, ErrorTypography, SubtitleTypography } from './typography';
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";
import { BlueButton } from "../common/buttons";
import { forwardRef } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding:20,
        height: 250,
        width: 400
    },
    typography: {
        display: 'inline',
        textAlign: 'left'
    },
    errorsList: {
        paddingTop: 15
    },
    subtitle: {
        paddingBottom: 10,
        textAlign: 'left'
    },
    icon: {
        cursor: 'pointer'
    }
}));

const ErrorComponent = forwardRef((props, ref) => {
    const classes = useStyles();
    const onClose = () => {
        props.onClose();
    };
    return(
        <div className={classes.root}>
            <Grid container justify="space-between">
                <Grid item xs={3}>
                    <ErrorTitleTypography>Error</ErrorTitleTypography>
                </Grid>
                <Grid>
                    <Icon className={classes.icon} onClick={onClose}>close</Icon>
                </Grid>
            </Grid>
            <label>
                <ErrorIcon /> <ErrorTypography className={classes.typography}>{props.title}</ErrorTypography>
            </label>
            <div className={classes.errorsList}>
                <SubtitleTypography className={classes.subtitle}>{props.desc}</SubtitleTypography>
            </div>
            <Grid item container direction="row-reverse">
                <Grid item xs={5}>
                    <BlueButton onClick={onClose}>Ok</BlueButton>
                </Grid>
            </Grid>
        </div>
    )
});

export default ErrorComponent;