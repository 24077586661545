import React, { useState } from "react";
import {
	Table,
	TableBody,
	TableHead,
	TableRow,
    TableCell,
    TableContainer
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Icon } from "@material-ui/core";
import { TitleTableTypography, InstructionsTypography } from "../common/typography";
import { BlueButton } from "../common/buttons";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: "20px 20px",
        width: "80%",
        "& .MuiTableCell-root": {
            fontFamily: 'Avenir-Roman',
            fontSize: 14,
        },
        overflow: 'auto',
        maxHeight: "80%"

    },
    icon: {
        cursor: 'pointer'
    },
    tableRoot: {
        fontFamily: 'Avenir-Roman',
        overflow: 'auto'
    },
    cell: {
        padding: '0px 10px'
    },
    head: {
        color: '#959595'
    }
}));
export const RowParser = row => {
	return row.split(",");
};

const CSVTable = React.forwardRef((props, ref) => {
    const classes = useStyles();
    
	const rowParser = row => {
		return RowParser(row);
    };

    const [data] = useState(props.data);
    const [header] = useState(props.header);
     
	const rows = [...data.split("\n")];
    const contentRows = header ? rows.slice(1, rows.length) : rows;
    
    const onClose = () => {
        props.onClose();
    };

    return (
        <div className={classes.root}>
            <Grid container justify="space-between">
                <Grid item xs={3}>
                    <TitleTableTypography>Your file</TitleTableTypography>
                </Grid>
                <Grid>
                    <Icon className={classes.icon} onClick={onClose}>close</Icon>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <InstructionsTypography>This is the information that you will upload to your portal. </InstructionsTypography>
            </Grid>
            <TableContainer>
                <Table className={classes.tableRoot}>
                    {header && (
                        <TableHead>
                            <TableRow > 
                                {rowParser(rows[0]).map((value, index) => (
                                    <TableCell className={classes.head} key={index}><p>{value}</p></TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {contentRows.map((row, index) => (
                            <TableRow key={index} >
                                {rowParser(row).map((value, index) => (
                                    <TableCell className={classes.cell} key={index}><p>{value}</p></TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container item xs={12} direction="row-reverse">
                <BlueButton onClick={onClose}>Ok</BlueButton>
            </Grid>
        </div>
    );

});

export default CSVTable;