import React from 'react';
import {Grid} from "@material-ui/core";
import SchoolCard from "./_SchoolCard";

const SchoolsGrid = ({schools, onSeeAdmins, onDelete}) => {
    const items = schools.map(school => (
        <Grid item xs={12} md={6} key={school.schoolCode}>
            <SchoolCard {...school}
                        onSeeAdmins={() => onSeeAdmins(school)}
                        onDelete={() => onDelete(school)}/>
        </Grid>
    ));

    return (
        <Grid container spacing={4}>
            {items}
        </Grid>
    );
};

export default SchoolsGrid;