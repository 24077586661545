import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Icon, Typography, IconButton } from '@material-ui/core';
import { PasswordTextField } from "../../common/inputs";
import { ReactComponent as ErrorIcon } from "../../../assets/error.svg";
import {ReactComponent as PasswordIcon} from '../../../assets/show_password.svg';
import { validateRegex } from "../../../utils/validators";
import { BlueButton, NoBorderButton } from "../../common/buttons";
import AuthService from "../../../services/auth";
import { ReactComponent as ChangeIcon } from "../../../assets/change.svg";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: "20px 20px",
        height: 600,
        width: 282,
        "& .MuiInputLabel-formControl": {
            top: -6,
            left: -4
        }
    },
    container: {
        textAlign: 'center',
        height: "100%"
    },
    icon: {
        cursor: 'pointer',
        color: theme.palette.primary.main
    },
    title: {
        fontWeight: 900 
    },
    input: {
        width:'90%'
    },
    button: {
        minWidth: 'unset',
        width: '100%'
    },
    disabledButton: {
        opacity: "40%"
    },
    changeIcon: {
        height:80,
        width:80
    },
    inputContainer: {
        paddingBottom: '8%'
    }
}));

const ChangePassword =  React.forwardRef((props, ref) => {
    const [disabledText, setDisabledText] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const [form, setForm] = useState({
        oldPassword: { value : '', errorPassword: false},
        newPassword: {
            value: '',
            missingValue: {
                minLength: false,
                upperCase: false,
                number: false
            },
            errorPassword: false,
            differentError: false
        },
    })
    const classes = useStyles();

    const setOldPassword = async (event) => {
        setForm({...form, oldPassword: {value: event.target.value, errorPassword: false, differentError: false}});
        if(event.target.value && form.newPassword.value !== '' && !form.newPassword.errorPassword) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true)
        }
    };

    const handlePassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    }

    const onClose = () => {
        props.onClose();
    };

    const setNewPassword = async (event) => {
        let val = event.target.value;
        let isValid = validateRegex(val)
        let error = checkPasswordValidation(isValid)
        setForm({...form,
            newPassword: {
                value: val,
                missingValue: isValid,
                errorPassword: error,
                differentError: false
            }
        });
        if(!error && form.oldPassword.value !== '' && !form.oldPassword.errorPassword) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true)
        }
    };

    const checkPasswordValidation = obj => {
        for(const prop in obj) {
            if(obj[prop]) return true;
        }
        return false;
    };

    const changePassword = async () => {
        setDisabledText(true);
        setDisabledButton(true);
        const change = await AuthService.changePassword(form.oldPassword.value, form.newPassword.value);
        if(change.code === 'NotAuthorizedException') {
           setForm({...form, oldPassword: { value: form.oldPassword.value, errorPassword: true}});
        }
        else if(change.code === 'success') {
            props.onSuccess();
        } else {
            setForm({...form,
                 newPassword: {
                    value: form.newPassword.value,
                    missingValue: form.newPassword.missingValue,
                    errorPassword: form.newPassword.errorPassword,
                    differentError: true,
                    message: change.message
                 }
            });
        }
        setDisabledButton(false);
        setDisabledText(false);
    };

    return(
        <div className={classes.root}>
            <Grid container justify="space-between" direction="row-reverse">
                <Grid>
                    <Icon className={classes.icon} onClick={onClose}>close</Icon>
                </Grid>
            </Grid>
            <Grid container justify="center" className={classes.container}>
                <Grid item xs={12}>
                    <ChangeIcon className={classes.changeIcon}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.title}  variant={'h3'} color={'primary'}>Change your password</Typography>
                </Grid>
                <Grid item xs={12} className={classes.inputContainer}>
                    <PasswordTextField
                        className={classes.input}
                        disabled={disabledText}
                        label="Old Password"
                        placeholder="Old Password"
                        variant="outlined"
                        onChange={setOldPassword}
                        type={showOldPassword? 'text': 'password'}
                        error={form.oldPassword.errorPassword}
                        helperText={form.oldPassword.errorPassword ? 
                            <label>
                            <ErrorIcon /> <span>The old password is incorrect.</span>
                            </label> : null
                        }
                        InputProps={{
                            style: {
                                height: "38px",
                                fontFamily: "Avenir-medium",
                                fontSize: 14
                            }
                        }}
                    /> 
                    <IconButton disabled={disabledText} onClick={handlePassword} className="overlay-icon" aria-label="password">
                        <PasswordIcon></PasswordIcon>
                    </IconButton>
                </Grid>
                <Grid item xs={12} className={classes.inputContainer}>
                    <PasswordTextField
                        className={classes.input}
                        disabled={disabledText}
                        label="New Password"
                        placeholder="New Password"
                        variant="outlined"
                        onChange={setNewPassword}
                        type={showNewPassword? 'text': 'password'}
                        error={form.newPassword.errorPassword || form.newPassword.differentError}
                        helperText={form.newPassword.errorPassword ? 
                            <label>
                            <ErrorIcon /> <span>Your new password is missing something.</span>
                            </label> :  form.newPassword.differentError ?
                            <label>
                            <ErrorIcon /> <span>{form.newPassword.message}</span>
                            </label> : null
                        }
                        InputProps={{
                            style: {
                                height: "38px",
                                fontFamily: "Avenir-medium",
                                fontSize: 14
                            }
                        }}
                    /> 
                    <IconButton disabled={disabledText} onClick={handleNewPassword} className="overlay-icon" aria-label="password">
                        <PasswordIcon></PasswordIcon>
                    </IconButton>
                </Grid>
                <Grid item xs={12} className="message-container padding-message">
                    <label className={"message activate"}>Your password must have at least:</label><br/>
                    <div className={ form.newPassword.missingValue.minLength? 'error-description': 'message activate'} style={{margin: '0px'}}>* 6 characters.</div>
                    <div className={form.newPassword.missingValue.upperCase? 'error-description': 'message activate'} style={{margin: '0px'}}>* 1 upper case letter</div>
                    <div className={form.newPassword.missingValue.number? 'error-description': 'message activate'} style={{margin: '0px'}}>* 1 number</div>
                </Grid>
                <Grid item xs={12}>
                    <div className="message activate">Do not use sequential characters (3333, 1234, abcdf).</div>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={6}>
                    <NoBorderButton onClick={e => props.onClose()} className={classes.button}>Cancel</NoBorderButton> 
                    </Grid>
                    <Grid item xs={6}>
                    <BlueButton 
                    disabled={disabledButton}
                    onClick={changePassword}
                    className={classes.button}>Save</BlueButton>
                    </Grid>
                </Grid>
            </Grid>
    </div>
    )
});

export default ChangePassword;