import React, {useState, useEffect} from 'react';
import './Login.css'
import ForgotPassword from './ForgotPassword'
import SignIn from './SignIn'
import CreatePassword from './CreatePassword';
import ActivateAccount from './ActivateAccount';
import  SuccessPassword  from "./SuccessPassword";
import logo from '../../assets/wise_logo.svg'

function Login(props) {

    const [mode, setMode] = useState("login");
    const [user, setUser] = useState(null);

    useEffect(() => {
        if(props.isAuthenticated) {
            props.history.push('/role-redirect');
        }
    });

    
    const handleChange = async (value, user) => {
        const newMode = value;
        const newUser = user;
        setMode(newMode);
        setUser(newUser);
        
    }
    
    return (
        <div className="background">
            <div className="logo" style={{marginTop: '-5%'}}>
                <img alt="Wise Logo" src={logo} height="200" width="400"/>
            </div>
            {{
                "login": <SignIn {...props} onChangeMode={handleChange}/>,
                "create-password": <CreatePassword {...props} onChangeMode={handleChange} user={user}/>,
                "forgot-password": <ForgotPassword {...props} onChangeMode={handleChange} />,
                "activate": <ActivateAccount {...props} onChangeMode={handleChange} user={user} />,
                "success-password": <SuccessPassword {...props} onChangeMode={handleChange} />
            }[mode]}
        </div>
    )
}

export default Login;