import gql from 'graphql-tag';

const getCoursesSummary = () => {

    return {
        coursesWithLowAttendance: 8,
        activeCourses: 8,
        percentageCoursesAtRisk: 6.4,
        maxAttendance: 200000,
        recordedAttendance: 120345
    }
};

const getCoursesGraphinfo = (typeGraph, role) => {
    let data = typeGraph === 'monthly' ? [
        {
            label: 'All school students attendance',
            values: [56, 62, 78, 32, 54, 76, 23, 43, 73, 83, 32, 58]
        },
        {
                label: 'Your students attendance',
                values: [63, 24, 57, 64, 83, 42, 53, 14, 56, 83, 91, 46]
        }] :[
        {
            label: 'All school students attendance',
            values: [56, 62, 78, 32, 54, 76, 23, 43, 73, 83, 32, 58, 56, 62, 78, 32, 54, 76, 23, 43, 73, 83, 32, 58]
        },
        {
            label: 'Your students attendance',
            values: [63, 24, 57, 64, 83, 42, 53, 14, 56, 83, 91, 46, 63, 24, 57, 64, 83, 42, 53, 14, 56, 83, 91, 46]
        }];
    
        const labels = typeGraph === 'monthly' ?
        ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']:
        ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8', 'Week 9', 'Week 10', 'Week 11', 'Week 12', 'Week 13', 'Week 14', 'Week 15', 'Week 16', 'Week 17', 'Week 18', 'Week 19', 'Week 20', 'Week 21', 'Week 22', 'Week 23', 'Week 24'];

       if(role === 'Admin') {
           data.pop();
       }
    return {
        labels,
       data
    }
}

const TOGGLE_COURSE_ATTENDANCE = gql`
mutation toggleClassAttendance($input: UpdateClassInput!){
    toggleClassAttendance(input: $input) {
        id
        name
        graded
    }
}`;



// studentsTotalRecordedAttendance
// totalCourseEventsToDate
// totalCourseEvents
const getProfCourses = gql `
query Query{
    listCourses{
        courses{
            id
            name
            graded
            image
            schedule
            courseInProgress
            courseAttendanceCode
            courseAttendancePercentage
            courseAtRiskPercentage
            studentsEnrolled
            studentsAtRisk
            atRisk
            
        }
    }
}
`

const getAdminChartsInfo = gql `
query Query{
    globalAttendancePerWeek{
        x
        y
    }
    globalAttendancePerMonth{
        x
        y
    }
}`

const GET_COURSE_ATTENDANCE_CHART = gql`
query Query($id: ID!){
    courseAttendancePerWeek(id: $id){
        x
        y
    }
    courseAttendancePerMonth(id: $id){
        x
        y
    }
}
`

const getAdminCirclesInfo = gql `
query Query{
    getOverallAttendance {
        coursesTotal
        coursesAtRisk
        coursesAtRiskPercentage
        totalCourseEvents
        totalCourseEventsToDate
        studentsTotalRecordedAttendance
    }
}
`

const getOngoingCourse = gql`
    query {
        getAttendanceCodeCard {
            className
            attendanceCode
            schedule {
                day
                endTime
                startTime
            }
            facility {
                name
            }
            graded
        }
    }
`;

const GET_COURSE_DETAIL_BANNER = gql`
query Query($id: ID!) {
    courseDetailBanner(id: $id){
        id
        name
        graded
        schedule
        professorName
        maxAttendancesCount
        recordedAttendancesCount
        courseAttendancePercentage
        courseAtRiskPercentage
        studentsEnrolled
        studentsAtRisk
        atRisk
        progressBar{
            courseEventsToDateCount
            maxCourseEventsCount
            courseProgressPercentage
            courseEventsPending
        }
    }
}
`

const GET_PROFESSOR_STUDENTS_TABLE = gql`
query Query ($id: ID!, $nextToken: String!){
    professorStudentsTable(id: $id, nextToken: $nextToken){
        students{
            name
            email
            gradedAttendancesCount
            generalAttendancePercentage
            atRisk
            attendanceRecords
        }
        totalStudentsCount
        tableHeaders
        nextToken
    }
}
`

const GET_ADMIN_STUDENTS_TABLE = gql`
query Query ($id: ID!, $nextToken: String!){
    courseStudentsTable(id: $id, nextToken: $nextToken){
        students{
            name
            email
            gradedAttendancesCount
            generalAttendancePercentage
            atRisk
            attendanceRecords
        }
        totalStudentsCount
        tableHeaders
        nextToken
    }
}
`

const professorStudentsTableCSV = gql`
mutation ProfessorStudentsTableCSV {
    professorStudentsTableCSV
}`

const getCourseDetailsCSV = gql`
mutation ($id: ID!){
    courseDetailsCSV(id: $id)
}
`

export {
    getCoursesSummary,
    getCoursesGraphinfo,
    getProfCourses,
    getOngoingCourse,
    getAdminCirclesInfo,
    getAdminChartsInfo,
    TOGGLE_COURSE_ATTENDANCE,
    GET_COURSE_DETAIL_BANNER,
    GET_COURSE_ATTENDANCE_CHART,
    GET_PROFESSOR_STUDENTS_TABLE,
    GET_ADMIN_STUDENTS_TABLE,
    professorStudentsTableCSV,
    getCourseDetailsCSV
}