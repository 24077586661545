import { Grid, makeStyles } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { SectionTypography } from '../../common/typography'
import ActivePeriod from './ActivePeriod'
import ClosePeriod from './ClosePeriod'
import UploadedInformation from './UploadedInformation'
import { GET_TEMPLATE_LINKS } from  '../../../services/school';
import { useQuery } from '@apollo/react-hooks';

const useStyles = makeStyles(theme => ({
        container: {
            marginTop: 15
        }
    })
);

function CurrentPeriod({school}) {
    const classes = useStyles();
    const [fileLinks, setFileLinks] = useState({});
    const [isLoading, setLoading] = useState(true);

    useQuery(GET_TEMPLATE_LINKS, {
        onError: err => console.log(err),
        onCompleted: data => {
            setFileLinks(data.getOnBoardingFileLinks);
            setLoading(false);
        }
    });

    return (
        !isLoading && 
        <Fragment>
            <Grid item xs={12}>
                <SectionTypography>Current term</SectionTypography>
            </Grid>
            <Grid item container spacing={2} className={classes.container}>
                <Grid item container spacing={2} xs={12} sm={6} direction={'column'}>
                  <Grid item xs>
                    <ActivePeriod {...school}/>
                  </Grid>
                  <Grid item>
                    <ClosePeriod schoolCode={school.schoolCode} semester={school.semester} />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <UploadedInformation uploadedInfo={[
                        { name: 'Classroom locations', templateUrl: fileLinks.facilityCSV, uploadId: 'facility', inputValue: '' },
                        { name: 'Students', templateUrl: fileLinks.studentCSV, uploadId: 'student', inputValue: '' },
                        { name: 'Professors', templateUrl: fileLinks.professorCSV, uploadId: 'professor', inputValue: '' },
                        { name: 'Courses', templateUrl: fileLinks.classCSV, uploadId: 'class', inputValue: '' },
                        { name: 'Wise Platform Administrators', templateUrl: fileLinks.adminCSV, uploadId: 'admin', inputValue: '' },
                    ]} {...school} />
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default CurrentPeriod;