import React, { useState } from 'react'
import {  Grid, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { NoBorderButton } from '../../common/buttons';
import { ReactComponent as UploadIcon } from "../../../assets/upload.svg";
import { ReactComponent as DownloadTemplateIcon } from '../../../assets/download_template.svg';
import { SubtitleTypography } from '../../common/typography';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up("md")]: {
            height: "25%",
            width: "33%",
            margin: "10% 10%"
        },
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            width: "auto",
            margin: "10% 10%"
        }
    },
    input: {
        display: "none"
    },
    container: {
        paddingTop: 21
    },
    containerUpload: {
        padding: "10% 10%",
        width: 500
    },
    button: {
        minWidth: "unset",
        cursor: 'pointer'
    },
    warningContainer: {
        backgroundColor: '#e4f4fd'
    },
    loading: {
        opacity: "35%"
    },
    notLoading: {
        opacity: "unset"
    }
  }));

export default function UploadBefore(props) {
    const classes = useStyles();
    const onChange = e => {
        props.onChange(e);
    }

    const downloadTemplate =  name => {
        props.onDownloadTemplate(name);
    }
    return(           
        <Grid className={classes.containerUpload + " "+ (props.loading ? classes.loading : null)} container justify="center" item xs={12} spacing={2}>
            <Grid item xs={1}>
                <label htmlFor="button-file">
                    <UploadIcon className={classes.button}></UploadIcon>
                </label>
            </Grid>
            <Grid item xs={2} style={{padding: 0}}>
                <label htmlFor="button-file">
                    <NoBorderButton disabled={props.loading} className={classes.button} component="span">Upload</NoBorderButton>
                </label>
                <input accept=".csv" disabled={props.loading} onClick={e => e.target.value=null} id="button-file" className={classes.input} hidden type="file" onChange={ e => onChange(e.target.files[0])}/>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={1}>
                <DownloadTemplateIcon className={classes.button}  onClick={e => downloadTemplate(props.type)} />
            </Grid>
            <Grid item xs={2}>
                <Typography style={{cursor: 'pointer'}} onClick={e => downloadTemplate(props.type)} variant={'h5'} color={'secondary'}><strong>Download Template</strong> </Typography>
            </Grid>
            
            {
                props.warning ?
                <Grid item xs={12} className={classes.warningContainer}>
                    <SubtitleTypography>Wise keeps the {props.name} from previous school term.</SubtitleTypography>
                    <SubtitleTypography> Only upload a CSV if there have been changes.</SubtitleTypography>
                </Grid> : null
            }
        </Grid>
    );
};  