import React from 'react';
import {Grid, makeStyles} from "@material-ui/core";
import {TitleTypography, LabelTypography} from "../../common/typography";
import {GrayTextField} from "../../common/text-fields";
import {ReactComponent as ErrorIcon} from "../../../assets/error.svg";

const useStyles = makeStyles(theme => ({
        textMessage: {
            borderRadius: 5,
        }
    })
);

function NotificationBase({onChange, title, subTitle, id, name, hours, minutes, msg, validate, minError, hoursError, msgError}) {
    const classes = useStyles();

    function handleMinutesChange(e) {
        if (e.target.value > 59) {
            e.target.value = 59;
        }else if(e.target.value < 0) {
            e.target.value = 0;
        }
        onChange(e);
    }

    function handleHoursChange(e) {
      if (e.target.value > 23) {
          e.target.value = 23;
      }else if(e.target.value < 0) {
          e.target.value = 0;
      }
      onChange(e);
  }

    return (
        <Grid container justify="space-between" spacing={2}>
            <Grid item xs={12}><TitleTypography>{title}</TitleTypography></Grid>

            <Grid direction="row" container item xs={12} alignItems="flex-end">
                <Grid container item xs={3} alignItems="flex-end">
                    <LabelTypography>{subTitle}</LabelTypography>
                </Grid>
                <Grid container alignItems="center" justify="flex-start" spacing={1} item xs={9}>
                    <GrayTextField
                        id={`hours-${id}`}
                        size="small"
                        label="hours"
                        type="number"
                        variant="outlined"
                        inputProps={{min: 0, max: 23}}
                        name={`${name}Hours`}
                        value={hours}
                        onChange={handleHoursChange}
                        onBlur={validate}
                        error={!!hoursError}
                    />
                    <Grid item>
                        <LabelTypography>:</LabelTypography>
                    </Grid>
                    <GrayTextField
                        id={`min-${id}`}
                        size="small"
                        label="min."
                        type="number"
                        variant="outlined"
                        inputProps={{min: 0, max: 60}}
                        name={`${name}Min`}
                        value={minutes}
                        onChange={handleMinutesChange}
                        onBlur={validate}
                        error={!!minError}
                    />
                    <Grid item>
                        {hoursError && 
                            <label>
                                <ErrorIcon/> <span>{hoursError}</span>
                            </label>
                        }
                        {minError && 
                            <label>
                                <ErrorIcon/> <span>{minError}</span>
                            </label>
                        }
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={11}>
                <GrayTextField
                    id={`text-area-${id}`}
                    fullWidth
                    label="Message 140 characters"
                    multiline
                    rows="1"
                    variant="outlined"
                    inputProps={{maxLength: 140}}
                    className={classes.textMessage}
                    name={`${name}Msg`}
                    value={msg}
                    onChange={onChange}
                    onBlur={validate}
                    error={!!msgError}
                    helperText={msgError ?
                        <label>
                            <ErrorIcon/> <span>{msgError}</span>
                        </label> :
                        null
                    }
                />
            </Grid>
        </Grid>
    );
}

export default NotificationBase;