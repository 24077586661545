import { Auth } from 'aws-amplify';
const ROLES = ["Professor", "Admin", "SuperAdmin"];

async function authSignIn(username, password) {
    try {
        const user = await Auth.signIn(username, password);
        if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
           return user;
        }
        let roleUser = user.signInUserSession.accessToken.payload["cognito:groups"][0];
        let index = ROLES.findIndex(role =>  role === roleUser);
        if(index >=0) {
            saveSessionInStorage(user.signInUserSession);
            return user;
        } else {
            Auth.signOut();
            cleanSession();
            return {code: 'NotAuthorizedException'};
        }

    } catch (err) {
        console.log(err);
        return err;
    }
}

async function isUserAuthenticated() {
    try {
        let logged = await isAuthenticated();
        if(!logged) {
         return {
             isAuthenticated: false,
             role: null,
             schoolCode: null
         };
        } 
        return {
            isAuthenticated: true,
            role: getRole(),
            schoolCode: getSchoolCode(),
            idToken: getIdToken()
        };
      }
      catch(e) {
        return {
            isAuthenticated: false,
            role: null
        };
      }
}

async function isAuthenticated() {
    try {
        const session = await Auth.currentSession();
        if(!session) {
            return false;
        }
        saveSessionInStorage(session);
        return true;
    }catch(err) {
        return false;
    }
}



async function signOut() {
    try {
        await Auth.signOut();
        cleanSession();
    } catch(err) {
        console.log(err);
    }
}

async function completeNewPassword(user, newPassword) {
    try {
        await Auth.completeNewPassword(user, newPassword);
        return true;
    } catch(err) {
        console.log(err);
        return false;
    }
}

async function requestCodeForNewPassword(email) {
    try {
        await Auth.forgotPassword(email);
        return true;
    } catch(err){
        console.log(err)
        return false;
    }
}

async function submitForgotPassword(username, code, newPassword) {
    try {
        await Auth.forgotPasswordSubmit(username, code, newPassword);
        return true;
    } catch(err){
        console.log(err)
        return false;
    }
}

async function changePassword(oldPassword, newPassword) {
    try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);
        return {code: 'success'};
    } catch(err) {
        console.log(err);
        return err;
    }
}

function saveSessionInStorage(session) {
    localStorage.setItem("session", JSON.stringify(session));
    localStorage.setItem("token", session.idToken.jwtToken);
    localStorage.setItem("schoolCode", session.idToken.payload.family_name);
    return session;
}

function cleanSession() {
    localStorage.removeItem("session");
    localStorage.removeItem("token");
    localStorage.removeItem("schoolCode");

}

function getRole() {

    let session = JSON.parse(localStorage.getItem("session"));
    return session.accessToken.payload["cognito:groups"][0] || null;
}

function getSchoolCode() {

    let session = JSON.parse(localStorage.getItem("session"));
    return session.idToken.payload.family_name|| null;
}

function getIdToken() {

    let session = JSON.parse(localStorage.getItem("session"));
    return session.idToken.jwtToken || "";
}

const AuthService = {
    isUserAuthenticated,
    authSignIn,
    signOut,
    completeNewPassword,
    requestCodeForNewPassword,
    submitForgotPassword,
    isAuthenticated,
    getRole,
    getIdToken,
    getSchoolCode,
    changePassword
}

export default AuthService;

