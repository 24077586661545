import React from 'react';
import {useHistory} from 'react-router-dom';

import {BlueButton} from "../../common/buttons";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import SchoolsGrid from "./youSchools/_SchoolsGrid";
import SchoolsPaginator from "./youSchools/_SchoolsPaginator";
import SchoolAdminsDialog from "./SchoolAdminsDialog";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {DELETE_SCHOOL, GET_SCHOOLS} from "../../../services/school";
import DeleteSchoolDialog from './DeleteSchoolDialog'
const PAGE_SIZE = 6;

const useStyles = makeStyles({
    header: {
        paddingBottom: 36,
        marginTop: 26
    },
});

const YourSchools = () => {
    const history = useHistory();
    const classes = useStyles();

    const [schoolForAdminsDialog, showAdminsDialog] = React.useState({});
    const [deleteSchoolDialog, showDeleteSchoolDialog] = React.useState({});

    const [page, setPage] = React.useState(0);
    const [getSchools, {error, data, loading}] = useLazyQuery(GET_SCHOOLS);

    const refreshList = () => {
        const nextToken = page === 0 ? '' : data && data.listSchools.nextToken;
        console.log('REFRESHING LIST')
        getSchools({
            variables: {
                limit: PAGE_SIZE,
                nextToken: nextToken
            },
            fetchPolicy: 'cache-and-network'
        });
    };

    React.useEffect(() => {
        refreshList();
    }, [page]);

    const [deleteSchool] = useMutation(DELETE_SCHOOL, {
        variables: {
            input:{
                schoolCode: deleteSchoolDialog.schoolCode
            }
        },
        onCompleted: (data) => {
            window.location.href = '/'
            showDeleteSchoolDialog({})
        },
        onError: (err) => {
            console.log('ERROR', err)
            refreshList();
            showDeleteSchoolDialog({})
        }
    });

    const handleDeleteSchool = (school) => {
        showDeleteSchoolDialog(school)
    };

    const handleAddSchool = () => {
        history.push('/schools/create');
    };

    if (loading) return <p>Loading ...</p>;
    if (error) return <div>{error.toString()}</div>;

    if (!data) return null;

    return (
        <Grid item container direction={'column'} xs>

            <Grid item container justify={'space-between'} className={classes.header}>
                <Grid item>
                    <Typography variant={'h1'} color={'primary'}>Your Schools</Typography>
                </Grid>
                <Grid item>
                    <BlueButton onClick={handleAddSchool}>Add School</BlueButton>
                </Grid>
            </Grid>

            <Grid item xs>
                <SchoolsGrid schools={data.listSchools.items}
                             onSeeAdmins={school => showAdminsDialog(school)}
                             onDelete={handleDeleteSchool}
                />
            </Grid>

            <Grid item container justify={"flex-end"} style={{marginTop: 48}}>
                <Grid item>
                    <SchoolsPaginator count={data.listSchools.total}
                                      page={page}
                                      itemsInPage={data.listSchools.items.length}
                                      toStart={page !== 0}
                                      onStart={() => setPage(0)}
                                      isNextAvailable={(page + 1) * PAGE_SIZE < data.listSchools.total}
                                      onNext={() => setPage(page + 1)}/>
                </Grid>
            </Grid>

            <SchoolAdminsDialog schoolCode={schoolForAdminsDialog.schoolCode}
                                schoolName={schoolForAdminsDialog.name}
                                onClose={() => showAdminsDialog({})}/>
            <DeleteSchoolDialog schoolName={deleteSchoolDialog.name} onOk={deleteSchool} onCancel={() => showDeleteSchoolDialog({})} onClose={() => showDeleteSchoolDialog({})} showDialog={deleteSchoolDialog}></DeleteSchoolDialog>
        </Grid>
    );
};

export default YourSchools;
