import { TextField, withStyles, Select } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

// const themeForDatePicker = createMuiTheme({
//     palette: {
//         primary: {500: "#37b5ff"}
//     }
// })

const commonProps = {
    "& .MuiOutlinedInput-root": {
        height: 28,
        color: '#959595',
        fontFamily: 'Avenir Roman',
        fontSize: 16,
        borderRadius: 8,

        textAlign: 'center',
        borderColor: '#E4E4EB'
    },
    "& .MuiInputBase-input": {
        fontSize: 16,
        fontFamily: 'Avenir-Roman',
        textAlign: 'center',
        fontWeight: 'normal'
    }
};

const WiseCenterTextField = withStyles({
    root: {
        width: "80%", 
        ...commonProps,
        "& .MuiOutlinedInput-root": {
            height: 28,
            color: '#959595',
            fontFamily: 'Avenir Roman',
            fontSize: 16,
            borderRadius: 8,
    
            textAlign: 'center',
            borderColor: '#E4E4EB'
        },
        "& .MuiInputBase-input": {
            fontSize: 16,
            fontFamily: 'Avenir-Roman',
            textAlign: 'center',
            fontWeight: 'normal'
        }        
    }
})(TextField);

const WiseRightTextField = withStyles({
    root: {
        width: "80%", 
        ...commonProps,
        "& .MuiInputBase-input": {
            fontSize: 16,
            fontFamily: 'Avenir-Roman',
            fontWeight: 'normal',
            textAlign: 'right'
        }
    }
})(TextField);

const PasswordTextField = withStyles({
    root: {
        '& .MuiTextField-root': {
            width: "90%"
        },
  
        '& .MuiFormLabel-root': {
          fontSize: 14,
          color: "#c3c3c3"
          },
          '& .MuiFormHelperText-root': {
              color: "#f0b000",
          },
          '& .MuiOutlinedInput-input:-webkit-autofill': {
              height: 0
          },
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: "#f0b000"
          },
    }
})(TextField);


const WiseDatePicker = withStyles(theme => ({
    root: {
        width: "80%",
        ...commonProps,
        "& .MuiInputAdornment-root  .MuiIconButton-root": {
            marginRight: -50,
            paddingLeft: 14
        },
        "& .MuiSvgIcon-root": {
            color: "#37b5ff"
        },
        "& .MuiFormHelperText-root": {
            color: theme.palette.warning.main
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.warning.main
        }
    },
        
}))(KeyboardDatePicker);

const WiseSelect = withStyles({
    root: {
        fontSize: 12,
        color: '#37b5ff',
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: '#37b5ff'
        }
    }
})(Select);

export {WiseCenterTextField, WiseSelect ,WiseRightTextField ,WiseDatePicker, PasswordTextField };
