import { useState } from 'react';

function useDialog(initialVisible, onCloseCallback, onOkCallBack, onCancelCallback) {
    const [visible, setVisible] = useState(initialVisible);
    const onClose = () => {
        if (onCloseCallback) {
            onCloseCallback();
        }
        setVisible(false)
    };
    const onOk = () => {
        if (onOkCallBack) {
            onOkCallBack();
        }
        onClose();
    };
    const onCancel = () => {
        if (onCancelCallback) {
            onCancelCallback();
        }
        onClose();
    };
    const toggle = () => setVisible(!visible);

    return {
        visible,
        toggle,
        onOk,
        onCancel,
        onClose
    }
}

export default useDialog;