import React, { useEffect, useState }  from 'react';
import {  makeStyles, createStyles } from "@material-ui/core/styles";
import OnboardingProvider from "../../context/onboarding-context";
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { INIT_INFO } from '../../services/school';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 1024,
      minHeight: 768,
      backgroundColor: '#f7f7f7'
    }

  })
);

export default function OnboardingRoute(route) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    let history = useHistory();
    const [getInfo, {data}] = useLazyQuery(INIT_INFO, {
      variables: {schoolCode: route.appProps.schoolCode},
      onCompleted: data => {checkSemester(data)},
      fetchPolicy: 'network-only'
    }
    );
    
    useEffect(() => { 
      if(!route.appProps.school) {
        getInfo();
      } 
      setIsLoading(false);

    }, [data]);

 

    const checkSemester = data => {
      const school = data.getSchool;
      const profile = data.getUser;
      route.appProps.setProfile(profile);
      route.appProps.setSchool(school);

    };
    
    return(
      !isLoading &&
        <div className={classes.root}>
           <Route
             path={route.path}
             render={props => (
              <OnboardingProvider>
                <route.component {...props} routes={route.routes} {...route} />
             </OnboardingProvider>
             )}
           ></Route>
            {
              route.redirect ?
              <Redirect exact from={route.path} to={route.redirect} /> : null
            }
        </div>
    )
}