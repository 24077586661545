import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles(theme => ({

    item: {
        marginTop: 3
    },

    card: {
        padding: 16,
        height: '100%'
    }

}));

const ProfessorInformationCard = (props) => {
    const classes = useStyles();
    const professor = props.studentData
    return (
        <Card classes={{root: classes.card}}>
            <Grid container direction={'column'} spacing={2}>
                <Grid item>
                    <Typography variant={'h2'} color={'primary'}>
                        <strong>
                            Professor Information
                        </strong>
                    </Typography>
                </Grid>

                <Grid item container spacing={2}>

                    <Grid item container direction={'column'} xs={6} spacing={2}>
                        <Grid item container direction={'column'}>
                            <Grid item>
                                <Typography variant={'h5'} color={'textSecondary'}>
                                    <strong>Name:</strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'h5'} color={'textSecondary'}
                                            className={classes.item}>{professor.name}</Typography>
                            </Grid>
                        </Grid>

                        <Grid item container direction={'column'}>
                            <Grid item>
                                <Typography variant={'h5'} color={'textSecondary'}>
                                    <strong>Email:</strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'h5'} color={'textSecondary'} className={classes.item}>{professor.email}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default ProfessorInformationCard;