function getMenuOptions(role) {
    if (role === 'Admin')
        return [
            {name: "Courses", link: "/"},
            {name: "Professors", link: "/professors"},
            {name: "Students", link: "/students"},
            {name: "School Settings", link: "/settings"},
            {name: "My Profile", link: "/my-profile"}
        ];
    else if (role === 'Professor') {
        return [
            {name: "My Courses", link: "/"},
            {name: "My Students", link: "/students"},
            {name: "My Profile", link: "/my-profile"}
        ];
    } else if (role === 'SuperAdmin') {
        return [
            {name: "Your Schools", link: "/schools"},
            // { name: "My Profile", link: "/my-profile"}
        ];
    }
}

export default getMenuOptions;