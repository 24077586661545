import {TextField, withStyles} from "@material-ui/core";

const GrayTextField = withStyles({
    root: {
        '& .MuiFormControl-root': {
            border: '1px solid rgb(228, 228, 235)',
            borderRadius: 5
        },
        '& label.Mui-focused': {
            color: '#959595',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#e4e4eb',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#e4e4eb',
            },
            '&:hover fieldset': {
                borderColor: '#e4e4eb',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#e4e4eb',
            },
        },
        '& .MuiFormLabel-root': {

            color: 'rgb(149, 149, 149)',
            fontSize: '13px',
            fontFamily: 'Avenir-Roman',
            fontWeight: 'normal',
            letterSpacing: 0,
            lineHeight: '18px',
        }
    },
})(TextField);

export { GrayTextField }