import React, {createContext} from "react";

export const AttendanceCodeContext = createContext({
    isVisible: false,
    setVisibility: Function
});

const AttendanceCodeContextProvider = ({children}) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const setVisibility = (value) => {
        setIsVisible(value);
    };
    return (
        <AttendanceCodeContext.Provider value={{isVisible, setVisibility}}>
            {children}
        </AttendanceCodeContext.Provider>
    );
};

export default AttendanceCodeContextProvider;