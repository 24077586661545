import React from 'react';
import v8n from 'v8n';
import {useHistory} from 'react-router-dom';
import {useMutation} from "@apollo/react-hooks";
import AddSchoolLayout from './addSchool/_AddSchoolLayout';
import {v8nResultParser} from "../../../services/validationService";
import CautionDialog from "./addSchool/_CautionDialog";
import CongratulationsDialog from "./addSchool/_CongratulationsDialog";
import {Step1Actions, Step1Inputs, Step2Actions, Step2Inputs} from "./addSchool/_AddSchoolStepsContent";
import {CREATE_SCHOOL} from '../../../services/school';
import {regexEmail} from '../../../utils/validators';


const schoolNameRules = v8n()
    .not.empty()
    .maxLength(50)
    .minLength(2);

const schoolAdminNameRules = v8n().not.empty();
const schoolTimeZoneRules = v8n().not.empty();
const schoolAdminEmailRules = v8n().pattern(regexEmail);

const useField = (initValue, validationRules, customMessages) => {
    const [schoolName, setSchoolName] = React.useState('');
    const [errorMsg, setErrorMsg] = React.useState('');
    const [isDirty, setIsDirty] = React.useState(false);
    const [invalidate, setForceValidation] = React.useState(1);

    React.useEffect(() => {
        if (!isDirty) {
            setIsDirty(true);
            return;
        }

        const validationErrors = validationRules.testAll(schoolName);
        const errosMsgs = v8nResultParser(validationErrors, customMessages);

        setErrorMsg(errosMsgs.length ? errosMsgs[0] : '');
    }, [schoolName, invalidate]);

    const forceValidation = () => {
        setForceValidation(invalidate + 1);
    };

    return [schoolName, setSchoolName, forceValidation, errorMsg];
};

const AddSchool = ({}) => {
    const [step, goToStep] = React.useState(0);
    const [schoolTimeZone, setSchoolTimeZone, schoolTimeZoneForceValidation, schoolTimeZoneError] = useField('', schoolTimeZoneRules);
    const [schoolName, setSchoolName, schoolForceValidation, schoolNameError] = useField('', schoolNameRules);
    const [adminName, setAdminName, adminNameForceValidation, adminNameError] = useField('', schoolAdminNameRules);
    const [adminEmail, setAdminEmail, adminEmailForceValidation, adminEmailError] = useField('', schoolAdminEmailRules, {'pattern': 'The field should be a valid email'});

    const onStep1Next = () => {
        schoolForceValidation();
        schoolTimeZoneForceValidation();
        if (schoolNameRules.test(schoolName) && schoolTimeZoneRules.test(schoolTimeZone)) {
            goToStep(1);
        }
    };

    const onAddSchool = () => {
        adminNameForceValidation();
        adminEmailForceValidation();

        if (schoolAdminNameRules.test(adminName) && schoolAdminEmailRules.test(adminEmail)) {
            setCreationWarningOpen(true);
        }
    };

    let controls, actions, headerText;
    switch (step) {
        case 0:
            controls = <Step1Inputs value={schoolName}
                                    onChange={e => setSchoolName(e.target.value)}
                                    onChangeTimeZone={e => setSchoolTimeZone(e.target.value)}
                                    error={schoolNameError}
                                    timeZoneValue={schoolTimeZone}
                                    timeZoneError={schoolTimeZoneError}/>;
            actions = <Step1Actions onNext={onStep1Next}/>;
            headerText = 'Add school info';
            break;

        case 1:
            controls = <Step2Inputs email={adminEmail} emailError={adminEmailError}
                                    onEmailChange={e => setAdminEmail(e.target.value)}
                                    name={adminName} nameError={adminNameError}
                                    onNameChange={e => setAdminName(e.target.value)}/>;
            actions = <Step2Actions onAddSchool={onAddSchool}/>;
            headerText = 'Assign school admins';
            break;
    }

    const [creationWarningOpen, setCreationWarningOpen] = React.useState(false);
    const [congratulationsOpen, setCongratulationsOpen] = React.useState(false);

    const history = useHistory();

    const goToSchoolList = () => {
        history.push('/schools');
    };

    const [saveSchool, {data}] = useMutation(CREATE_SCHOOL, {
        onCompleted: () => setCongratulationsOpen(true)
    });

    const createSchool = () => {
        saveSchool({
            variables: {
                input: {
                    name: schoolName,
                    adminEmail,
                    adminName,
                    timezone: schoolTimeZone
                }
            },
            onerror: (error) => console.log(error),
        },
    );

        setCreationWarningOpen(false);
    };

    const onCancelConfirm = () => {
        setCreationWarningOpen(false);
    };

    return (
        <>
            <AddSchoolLayout
                step={step}
                headerText={headerText}
                controlsComponent={controls}
                actionsComponent={actions}
            />

            <CautionDialog
                open={creationWarningOpen}
                onSuccess={createSchool}
                onCancel={onCancelConfirm}
            />

            <CongratulationsDialog
                open={congratulationsOpen}
                schoolId={data && data.createSchool.schoolCode}
                schoolName={schoolName}
                goToSchools={goToSchoolList}
            />
        </>
    );
};

export default AddSchool;
