const daysOfWeek = {
    Mo: 'Mon',
    Tu: 'Tue',
    We: 'Wed',
    Th: 'Thu',
    Fr: 'Fri',
    Sa: 'Sat',
    Su: 'Sun'
};

export {
    daysOfWeek
}