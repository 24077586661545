import React, {useEffect, useState} from 'react';
import {Card, Grid, makeStyles, createStyles, Paper, Typography} from "@material-ui/core";
import AttendanceGraphic from "../../graphics/Attendance";
import { useQuery } from "@apollo/react-hooks";
import {isEmpty} from 'lodash'
import moment from 'moment';
import { getAdminChartsInfo, GET_COURSE_ATTENDANCE_CHART } from "../../../services/courses";


const useStyles = makeStyles(theme => ({
    card: {
        padding: '12px 15px',
        height: '100%'
    },
    dataContainer: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        marginBottom: 35,
        padding: 15
    },
}));

const LoadingContainer = (props) => {
    return(<Paper className={props.class}>
        <Grid container alignItems='center'>
            <Typography variant={'h2'} color={'primary'}><strong>Loading...</strong></Typography>
        </Grid>
    </Paper>)
}

const AttendanceChart = (props) => {
    const classes = useStyles();
    const school = props.school
    const courseId= props.courseId

    const tooltip = '% attendance percentage';
    const [typeChart, setTypeChart] = useState('monthly');

    const [adminCoursesGraphInfo, setAdminCoursesGraphInfo] = useState(null);
    const [courseGraphInfo, setCourseGraphInfo] = useState(null);

    useQuery(getAdminChartsInfo, {
        onError: (err) => {
            console.log(err);
        },
        onCompleted: (data) => {
            setAdminCoursesGraphInfo(data)
        }
    });

    useQuery(GET_COURSE_ATTENDANCE_CHART, {
        variables: {
            id: courseId
        },
        onError: (err) => {
            console.log(err);
        },
        onCompleted: (data) => {
            setCourseGraphInfo(data)
        }
    });

    const prepareLabels = (data) => {
        if(isEmpty(school)) return
        const resultLabels = []
        if (typeChart === 'weekly'){
            data.forEach((_, index) => resultLabels.push(`Week ${index+1}`))
            return resultLabels
        }
        let date = moment(school.semesterStartDate.split('T')[0]).startOf('month')
        data.forEach(() => {
            resultLabels.push(moment(date).format('MMMM'))
            date = moment(date).clone().add(1, 'month')
        })
        return resultLabels
    }

    const preparePointsData = (pointsData) => {
        return pointsData.map(item => item.y)
    }

    const formatDataForChart = (adminData, courseData) => {
        if(!adminData) return

        const data = []

        // format data for students
        const courseLabel = 'Course students attendance'
        const courseCandidateData  = typeChart === 'weekly' ? courseData.courseAttendancePerWeek : courseData.courseAttendancePerMonth;
        const courseValues = preparePointsData(courseCandidateData)
        courseValues.shift()
        data.push({label: courseLabel, values: courseValues})

        // format data for admins
        const adminLabel = 'All school students attendance';
        const adminCandidateData  = typeChart === 'weekly' ? adminData.globalAttendancePerWeek : adminData.globalAttendancePerMonth;
        const adminValues = preparePointsData(adminCandidateData)
        adminValues.shift()
        const labels = prepareLabels(adminValues)
        data.push({label: adminLabel, values: adminValues})


        return {
            labels,
            data
        }
    }

    let chartData = null
    if (adminCoursesGraphInfo && courseGraphInfo){
        chartData = formatDataForChart(adminCoursesGraphInfo, courseGraphInfo)
    }

    return (
        !isEmpty(adminCoursesGraphInfo) && !isEmpty(courseGraphInfo) ? <Card classes={{root: classes.card}}>
            <Grid container spacing={1} direction={'column'}>
                <Grid container item xs={12}>
                    <AttendanceGraphic onChangePeriod={val => setTypeChart(val)}
                                       tooltipText={tooltip}
                                       title='Attendance over the active period'
                                       labels={chartData.labels}
                                       data={chartData.data}/>
                </Grid>
            </Grid>
        </Card> : <LoadingContainer class={classes.dataContainer}></LoadingContainer>
    );
};

export default AttendanceChart;