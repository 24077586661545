import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import {daysOfWeek} from "../../../utils/dictionaries";

const useStyles = makeStyles(theme => ({

    item: {
        marginTop: 3
    },

    card: {
        padding: 16,
        height: '100%'
    }

}));

const CourseInformationCard = ({course}) => {
    const classes = useStyles();

    const [schedule, setSchedule] = React.useState([]);

    React.useEffect(() => {

        if (course.schedule) {
            const correctJson = course.schedule.replace(/=/g, ':').replace(/\s/g, '').replace(/(['":])?(day|startTime|endTime)(['"])?:/g, '"$2":').replace(/:?(Mo|Tu|We|Th|Fr|Sa|Su),/g, ':"$1",').replace(/['"]?([0-9]{1,2}:[0-9][0-9])/g, '"$1"');
            const newSchedules = JSON.parse(correctJson);
            setSchedule(newSchedules);
        }

    }, [course]);
    return (
        <Card classes={{root: classes.card}}>
            <Grid container direction={'column'} spacing={2}>
                <Grid item>
                    <Typography variant={'h2'} color={'primary'}>
                        <strong>
                            Course information
                        </strong>
                    </Typography>
                </Grid>

                <Grid item container spacing={2}>

                    <Grid item container direction={'column'} xs={6} spacing={2}>
                        <Grid item container direction={'column'}>
                            <Grid item>
                                <Typography variant={'h5'} color={'textSecondary'}>
                                    <strong>Course ID:</strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'h5'} color={'textSecondary'}
                                            className={classes.item}>{course.id}</Typography>
                            </Grid>
                        </Grid>

                        <Grid item container direction={'column'}>
                            <Grid item>
                                <Typography variant={'h5'} color={'textSecondary'}>
                                    <strong>Professor:</strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'h5'} color={'textSecondary'} className={classes.item}>{course.professorName}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container xs={6} direction={'column'}>
                        <Grid item>
                            <Typography variant={'h5'}
                                        color={'textSecondary'}><strong>Schedule:</strong></Typography>
                        </Grid>
                        {schedule.map((item, i) => (
                            <Grid item container key={i}>
                                <Grid item xs={2}>
                                    <Typography variant={'h5'} color={'textSecondary'}
                                                className={classes.item}>{daysOfWeek[item.day]}</Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography variant={'h5'} color={'textSecondary'}
                                                className={classes.item}>{item.startTime} - {item.endTime}</Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default CourseInformationCard;