import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles(theme => ({

    item: {
        marginTop: 3
    },

    card: {
        padding: 16,
        height: '100%'
    }

}));

const StudentInformationCard = (props) => {
    const classes = useStyles();
    const student = props.studentData
    return (
        <Card classes={{root: classes.card}}>
            <Grid container direction={'column'} spacing={2}>
                <Grid item>
                    <Typography variant={'h2'} color={'primary'}>
                        <strong>
                            Student Information
                        </strong>
                    </Typography>
                </Grid>

                <Grid item container spacing={2}>

                    <Grid item container direction={'column'} xs={6} spacing={2}>
                        <Grid item container direction={'column'}>
                            <Grid item>
                                <Typography variant={'h5'} color={'textSecondary'}>
                                    <strong>Name:</strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'h5'} color={'textSecondary'}
                                            className={classes.item}>{student.name}</Typography>
                            </Grid>
                        </Grid>

                        <Grid item container direction={'column'}>
                            <Grid item>
                                <Typography variant={'h5'} color={'textSecondary'}>
                                    <strong>Email:</strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'h5'} color={'textSecondary'} className={classes.item}>{student.email}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container xs={6} direction={'column'}>
                        <Grid item>
                            <Typography variant={'h5'}
                                        color={'textSecondary'}><strong>Nickname:</strong></Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'} color={'textSecondary'} className={classes.item}>
                                {student.nickname}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default StudentInformationCard;