import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  title: {
    opacity: 0.6,
  }
});

export const ProfileNamedEntry = ({ name, children }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={3}><Typography className={classes.title} variant={"h5"}>{name}</Typography> </Grid>
      <Grid item xs={9}>{children}</Grid>
    </Grid>
  )
};
