import React from 'react';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
    circle: {
        ...theme.typography.h1,
        height: 40,
        width: 40,
        textAlign: 'center',
        padding: 4,
        color: theme.palette.primary.main
    },

    selected: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.background.default,
        borderRadius: 20,
    }
}));

const Circle = ({number, selected}) => {
    const classes = useStyles();

    return (
        <div className={classnames(classes.circle, {[classes.selected]: selected})}>
            {number}
        </div>
    );
};

const AddSchoolsSteps = ({step}) => {
    return (
        <Grid container>
            <Grid item>
                <Circle number={1} selected={step === 0}/>
            </Grid>
            <Grid item>
                <Circle number={2} selected={step === 1}/>
            </Grid>
        </Grid>
    );
};

export default AddSchoolsSteps;
