import MomentUtils from '@date-io/moment';
import { Grid, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState, useEffect, useCallback } from 'react';
import { ReactComponent as ErrorIcon } from "../../../assets/error.svg";
import useForm from '../../../utils/hooks/useForm';
import { maxLengthValidator, minLengthValidator, numberLessThanValidator, onlyNumbersIntValidator, requiredFieldValidator } from '../../../utils/validators';
import { FlatBlueButton } from '../../common/buttons';
import { LabelTypography } from '../../common/typography';
import { UPDATE_AT_RISK_PERCENTAGE, UPDATE_ATTENDANCE_ASSERTION_THRESHOLD, UPDATE_MIN_TIME_SPENT } from "../../../services/school";
import { useMutation } from '@apollo/react-hooks';

const useStyles = makeStyles(theme => ({

    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    input: {
        fontFamily: 'Avenir-Medium',
        fontSize: 13,
        fontWeight: 'normal',
        letterSpacing: 0
    }
}))


const validationRules = {
    'name': [
        requiredFieldValidator('Name is required'),
        maxLengthValidator(100, 'Name length exceeded 100 chars'),
        minLengthValidator(2, 'Name must have at least 10 chars'),
    ],
    'toleranceMinutesForAttendance': [
        onlyNumbersIntValidator('Incorrect value'),
        requiredFieldValidator('Tolerance is required'),
        numberLessThanValidator(60, null, 'Tolerance must be less than 60')
    ],
    'minAttendancePercent': [
        onlyNumbersIntValidator('Incorrect value'),
        requiredFieldValidator('Attendance is required'),
        numberLessThanValidator(101, null, 'Attendance could be maximum 100%')
    ],
    'minTime': [
        onlyNumbersIntValidator('Incorrect value'),
        requiredFieldValidator('Time spent is required'),
        numberLessThanValidator(101, null, 'Time spent could be maximum 100%')
    ]
};

function ErrorLabel({ children }) {
    return (
        <Grid container direction="row" wrap='nowrap' spacing={1} alignItems="center">
            <Grid item>
                <ErrorIcon />
            </Grid>
            <Grid item>
                {children}
            </Grid>
        </Grid>
    );
}

function ActivePeriodForm({ period, schoolCode }) {
    const [editTolerance, setEditTolerance] = useState(false);
    const [editMinAttendance, setEditMinAttendance] = useState(false);
    const [editMinTime, setEditMinTime] = useState(false);
    const classes = useStyles();

    function submitTolerance(e){
        updateAttendanceAssertionThreshold().then(() => {
            setEditTolerance(false);
        });
        handleOnSubmit(e);
    }

    function submitMinAttendance(e){
        updateAtRiskPercentage().then(() => {
            setEditMinAttendance(false);
        });
        handleOnSubmit(e);
    }

    function submitMinTimeSpent(e){
        updateMinTimeSpent().then(() => {;
            setEditMinTime(false);
        });
        handleOnSubmit(e);
    }

    const { errors,
        handleInputChange,
        handleOnSubmit,
        hasErrors,
        inputsState: inputs,
        triggerFieldValidation: validate } = useForm(period, () => {}, validationRules);

    const [updateAtRiskPercentage] = useMutation(UPDATE_AT_RISK_PERCENTAGE, {
        variables: {
            input: {
                schoolCode: schoolCode,
                atRiskPercentage: inputs.minAttendancePercent
            }
        },
        onCompleted: (data) => console.log('Success updating at risk percentage')
    });

    const [updateAttendanceAssertionThreshold] = useMutation(UPDATE_ATTENDANCE_ASSERTION_THRESHOLD, {
        variables: {
            input: {
                schoolCode: schoolCode,
                attendanceAssertionTimeThreshold: inputs.toleranceMinutesForAttendance
            }
        },
        onCompleted: (data) => console.log('Success updating attendance assertion time threshold')
    });

    const [updateMinTimeSpent] = useMutation(UPDATE_MIN_TIME_SPENT, {
        variables: {
            input: {
                schoolCode: schoolCode,
                minTimeSpentInClass: inputs.minTime
            }
        },
        onCompleted: (data) => console.log('Success updating min time spent')
    });

    function handleOnChange(e){
        if(e.target.value < 0){
            e.target.value = 0;
        }else if(e.target.value > Number(e.target.max)){
            e.target.value = e.target.max;
        }
        handleInputChange(e);
        validate(e);
    }

    return (
        <form className={classes.root}>
            <Grid container spacing={2}>
                <Grid container item xs={12}>
                    <Grid item xs={12} sm={4}>
                        <LabelTypography> Name</LabelTypography>
                    </Grid>
                    <Grid item sm={1}></Grid>
                    <Grid item xs={12} sm={7}>
                        <TextField
                            disabled={true}
                            InputProps={{ disableUnderline: true, className: classes.input }}
                            value={inputs.name}
                            name="name"
                            error={!!errors.name}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={12}>
                        {errors.name &&
                            <ErrorLabel>
                                {errors.name}
                            </ErrorLabel>
                        }
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12} sm={4}>
                        <LabelTypography>Dates</LabelTypography>
                    </Grid>
                    <Grid container item xs={12} sm={8}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid item xs={12}>
                                <DatePicker
                                    variant="inline"
                                    autoOk
                                    className={classes.input}
                                    InputProps={{
                                        disableUnderline: true,
                                        className: classes.input,
                                        startAdornment: <InputAdornment disableTypography position="start"><TodayIcon />&nbsp;Start&nbsp;&nbsp;</InputAdornment>
                                    }}
                                    disabled={true} format="MM/DD/YYYY"
                                    value={inputs.startDate}
                                    onChange={(date) => handleInputChange(date, 'startDate')}
                                    onClose={() => validate(null, 'startDate')}
                                    fullWidth
                                    error={!!errors.startDate}
                                />
                            </Grid>
                            <Grid container item xs={12} sm={3}>
                                {errors.startDate &&
                                    <ErrorLabel>
                                        {errors.startDate}
                                    </ErrorLabel>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    variant="inline"
                                    autoOk
                                    InputProps={{
                                        disableUnderline: true,
                                        className: classes.input,
                                        startAdornment: <InputAdornment disableTypography position="start"><TodayIcon />&nbsp;End&nbsp;&nbsp;</InputAdornment>
                                    }}
                                    disabled={true}
                                    format="MM/DD/YYYY"
                                    value={inputs.endDate}
                                    fullWidth
                                    onChange={(date) => handleInputChange(date, 'endDate')}
                                    error={!!errors.endDate}
                                />
                            </Grid>
                            <Grid container item xs={12} sm={3}>
                                {errors.endDate &&
                                    <ErrorLabel>
                                        {errors.endDate}
                                    </ErrorLabel>
                                }
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" item xs={12}>
                    <Grid item xs={12} sm={4}>
                        <LabelTypography>
                            Amount of time students have after class starts to mark attendance
                        </LabelTypography>
                    </Grid>
                    <Grid item sm={1}></Grid>
                    <Grid container justify="flex-start" alignItems="center" item xs={8} sm={7} spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                disabled={!editTolerance}
                                className={classes.input}
                                inputProps={{ style: { textAlign: 'right' }, min: 0, max: 59 }}
                                InputProps={{
                                    disableUnderline: !editTolerance,
                                    className: classes.input,
                                    endAdornment: <InputAdornment disableTypography classes={{ typography: classes.adornment }} position="end">Minutes</InputAdornment>
                                }}
                                value={inputs.toleranceMinutesForAttendance}
                                name="toleranceMinutesForAttendance"
                                onChange={handleOnChange}
                                fullWidth
                                onBlur={validate}
                                type="number"
                                error={!!errors.toleranceMinutesForAttendance} />
                        </Grid>
                        <Grid item xs={6}>
                            {!editTolerance && <FlatBlueButton onClick={() => setEditTolerance(true)}>Edit</FlatBlueButton>}
                            {editTolerance && <FlatBlueButton disabled={!!errors.toleranceMinutesForAttendance} onClick={submitTolerance}>Save</FlatBlueButton>}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={12}>
                        {errors.toleranceMinutesForAttendance &&
                            <ErrorLabel>
                                {errors.toleranceMinutesForAttendance}
                            </ErrorLabel>
                        }
                    </Grid>
                </Grid>
                <Grid container alignItems="center" item xs={12}>
                    <Grid item xs={12} sm={4}>
                        <LabelTypography>
                            Minimum attendance to pass
                        </LabelTypography>
                    </Grid>
                    <Grid item sm={1}></Grid>
                    <Grid container justify="flex-start" alignItems="center" item xs={8} sm={7} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                disabled={!editMinAttendance}
                                inputProps={{ style: { textAlign: 'right' }, min: 0, max: 100 }}
                                InputProps={{
                                    disableUnderline: !editMinAttendance,
                                    className: classes.input,
                                    endAdornment: <InputAdornment disableTypography position="end">%</InputAdornment>
                                }}
                                value={inputs.minAttendancePercent}
                                name="minAttendancePercent"
                                fullWidth
                                onChange={handleOnChange}
                                onBlur={validate}
                                type="number"
                                error={!!errors.minAttendancePercent} />
                        </Grid>
                        <Grid item xs={6}>
                            {!editMinAttendance && <FlatBlueButton onClick={() => setEditMinAttendance(true)}>Edit</FlatBlueButton>}
                            {editMinAttendance && <FlatBlueButton disabled={!!errors.minAttendancePercent} onClick={submitMinAttendance}>Save</FlatBlueButton>}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={12}>
                        {errors.minAttendancePercent &&
                            <ErrorLabel>
                                {errors.minAttendancePercent}
                            </ErrorLabel>
                        }
                    </Grid>
                </Grid>
                <Grid container alignItems="center" item xs={12}>
                    <Grid item xs={12} sm={4}>
                        <LabelTypography>
                            Minimum time spent in class
                        </LabelTypography>
                    </Grid>
                    <Grid item sm={1}></Grid>
                    <Grid container justify="flex-start" alignItems="center" item xs={8} sm={7} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                disabled={!editMinTime}
                                inputProps={{ style: { textAlign: 'right' }, min: 0, max: 100 }}
                                InputProps={{
                                    disableUnderline: !editMinTime,
                                    className: classes.input,
                                    endAdornment: <InputAdornment disableTypography position="end">%</InputAdornment>
                                }}
                                value={inputs.minTime}
                                name="minTime"
                                fullWidth
                                onChange={handleOnChange}
                                onBlur={validate}
                                type="number"
                                error={!!errors.minTime} />
                        </Grid>
                        <Grid item xs={6}>
                            {!editMinTime && <FlatBlueButton onClick={() => setEditMinTime(true)}>Edit</FlatBlueButton>}
                            {editMinTime && <FlatBlueButton disabled={!!errors.minTime} onClick={submitMinTimeSpent}>Save</FlatBlueButton>}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={12}>
                        {errors.minTime &&
                            <ErrorLabel>
                                {errors.minTime}
                            </ErrorLabel>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}

export default ActivePeriodForm;
