import React, { useContext, useEffect } from 'react';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { BlueButton, WhiteButton } from '../../../common/buttons';
import SetupContainer from './SetupContainer';
import { OnboardingContext } from "../../../../context/onboarding-context";
import { GET_SCHOOL, CREATE_SEMESTER } from "../../../../services/school";
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';

const useStyles = makeStyles((theme) => 
    createStyles({
        root: {
            padding: 100,
            textAlign: 'center'
        },
        container: {
            backgroundColor: 'white',
            minHeight: 530,
            display: 'inline-block',
            minWidth: 800,
            width: "80%",
            height: "63%",
            overflow: 'scroll'
        },
        buttonContainer: {
            minWidth: 800,
            display: 'inline-block',
            width: "80%",
            height: "63%",     
        },
    })
);


export default function SchoolSetup(props) {
    const classes = useStyles();
    const {setupStep, updateInfoTerm, schoolName, schoolId ,setNewUploadMode, setSetupStep, setPercentageAndTolerance, setNewSchoolId,
        image, termInfo, setSemesterId , setOnboardingStep, uploadSteps,setUploadStepsObject ,setNewSchoolName} = useContext(OnboardingContext);
    useEffect(() => {
        const school = props.appProps.school;
        if(school && school.semester) {
            checkUploadStep(school);
        } else {
            requestSchool();
        }  
    }, []);

    const [saveInfo] = useMutation(CREATE_SEMESTER,
        { 
            variables: {
                inputSchool: {
                    schoolCode: schoolId,
                    atRiskPercentage: termInfo.minimumPercentage,
                    attendanceAssertionTimeThreshold: termInfo.tolerance
                },
                inputSemester: {
                    schoolCode: schoolId,
                    name: termInfo.name,
                    startDate: termInfo.start,
                    endDate: termInfo.end,
                } 
            },
            
            onCompleted: (data) => finish(data)
        }
        );


    const [requestSchool, {loading}] = useLazyQuery(GET_SCHOOL, {
        variables: {schoolCode: props.appProps.schoolCode},
        onCompleted: (data) => saveData(data.getSchool),
        fetchPolicy: 'no-cache',
        onError: err => console.log(err)
    })

    if(loading) {
        return "Loading..."
    }

    const checkUploadStep = school => {
        if(school.semester) {
            setSemesterId(school.semester);
            props.history.push('/school-setup/upload')
            setNewSchoolName(school.name);
            setNewSchoolId(school.schoolCode);
            updateInfoTerm({
                start: moment(school.semesterStartDate).format('YYYY-MM-DD'),
                name: school.semesterName,
                end: moment(school.semesterEndDate).format('YYYY-MM-DD'),
                minimumPercentage: school.atRiskPercentage,
                tolerance: school.attendanceAssertionTimeThreshold  
            });
            if(!school.facilitiesFileUploaded) {
                setSetupStep(5);
            } else if(!school.studentsFileUploaded) {
                setUploadStepsObject({...uploadSteps, facilities: true});
                setSetupStep(6);
            } else if(!school.professorsFileUploaded) {
                setUploadStepsObject({...uploadSteps, facilities: true, students: true});
                setSetupStep(7);
            } else if(!school.classesFileUploaded) {
                setUploadStepsObject({...uploadSteps, facilities: true, students: true, professors: true});
                setSetupStep(8);
            } else {
                setUploadStepsObject({...uploadSteps, facilities: true, students: true, professors: true, courses: true});
                setSetupStep(9);
            }
        }
    };

    const finish = (data) => {
        setSemesterId(data.createSemester.semester);
        setSetupStep(5);
    }

    const saveData = school => {
        setNewSchoolName(school.name);
        setPercentageAndTolerance(school.atRiskPercentage, school.attendanceAssertionTimeThreshold);
        setNewSchoolId(school.schoolCode);
        checkUploadStep(school);
    };


    const changeStep = (newVal) => {
        if(newVal === 0) {
            setOnboardingStep('introduction');
            props.history.push('/introduction');
        } else if (newVal < 5) {
            setSetupStep(newVal);
        } else if(newVal === 5) {
            saveInfo();
        }
        else if(newVal > 5 && newVal < 10) {
            setSetupStep(newVal);
            setNewUploadMode('before');
        }
        else {
            props.appProps.setSemester(true);
            props.history.push('/');
        }
    };

    const isDisabled = () => {
        switch(setupStep){
            case 2:
                return termInfo.name === '' || termInfo.start === '' || termInfo.end === '' || checkDates(termInfo.start, termInfo.end);
            case 3:
                return termInfo.minimumPercentage <= 0 || termInfo.minimumPercentage > 100;
            case 4: 
                return termInfo.tolerance <= 0;
            case 5:
                return !uploadSteps.facilities;
            case 6:
                return !uploadSteps.students;
            case 7:
                return !uploadSteps.professors;
            case 8:
                return !uploadSteps.courses;
            case 9:
                return !uploadSteps.administrators;
            default:
                return false;
        }
    }
    
    const checkDates = (start, end) => {
        const today = moment();
        return start === 'Invalid date' || end === 'Invalid date' || moment(start).isAfter(end);
    }

    return(
        schoolName && schoolId && 
        <div className={classes.root}>
            <div className={classes.container}>
                <SetupContainer schoolName={schoolName} schoolId={schoolId} image={image} {...props} />
            </div>
            <div className={classes.buttonContainer}>
                <Grid container direction='row-reverse'>
                    <Grid item xs={3}>
                        <BlueButton disabled={isDisabled()} onClick={ e => changeStep(setupStep+1)}>Next</BlueButton>
                    </Grid>
                    {
                    setupStep !== 5 &&
                    <Grid item xs={3}>
                        <WhiteButton onClick={ e => changeStep(setupStep-1)}>Back</WhiteButton>
                    </Grid>
                    }
                    
                </Grid>
            </div>
        </div>
    )
}