import React from 'react'
import {  Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { NoBorderButton } from '../../common/buttons';
import { ReactComponent as UploadErrorIcon } from "../../../assets/upload_error.svg";
import { ErrorTypography } from '../../common/typography';

const useStyles = makeStyles(theme => ({
    root: {
    //   display: 'flex',
    //   flexWrap: 'wrap',
        [theme.breakpoints.up("md")]: {
            height: "25%",
            width: "33%",
            margin: "10% 10%"
        },
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            width: "auto",
            margin: "10% 10%"
        }
    },
    input: {
        display: "none"
    },
    container: {
        padding: "21px"
    },
    containerUpload: {
        padding: "10% 10%"
    },
    buttonPreview: {
        color: "#959595",
        width: "100%",
        minWidth: "unset"
    },
    errorTypography: {
        textAlign: 'center',
        cursor: 'pointer'
    },
    button: {
        width: "100%",
        minWidth: "unset"
    }
  }));

export default function UploadError(props) {
    const classes = useStyles();

    const onChange = e => {
        props.onChange(e);
    }

    const openPreview = () => {
        props.onOpenPreview();
    };

    const openErrors = () => {
        props.onOpenErrors();
    }
    
    return(           
        <Grid className={classes.containerUpload} container item xs={12}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
                <label htmlFor="button-file">
                    <UploadErrorIcon></UploadErrorIcon>
                </label>
            </Grid>
            <Grid container item xs={8}>
                <Grid item xs={6}>
                    <label htmlFor="button-file">
                        <NoBorderButton className={classes.button} component="span">Upload</NoBorderButton>
                    </label>
                    <input accept=".csv" id="button-file" className={classes.input} hidden type="file" onClick={e => e.target.value = null} onChange={ e => onChange(e.target.files[0])}/>
                </Grid>
                <Grid item xs={6}>
                        <NoBorderButton onClick={openPreview} className={classes.buttonPreview} component="span">Preview</NoBorderButton>
                </Grid>
                <Grid item xs={12}>
                    <ErrorTypography onClick={openErrors} className={classes.errorTypography}>Missing items</ErrorTypography>
                </Grid> 
            </Grid>
        </Grid>
    );
};  