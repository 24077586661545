import React, {useEffect, useState} from 'react';
import { Grid, makeStyles, Paper, TableBody, Typography, Table, TableRow, TableSortLabel} from "@material-ui/core";
import { WhiteButton } from "../../common/buttons";
import { WTableContainer, WTableHead, WTableCell } from '../../graphics/WiseTable';
import {LIST_STUDENT_CLASSES, listStudentClassesCSV} from '../../../services/students'
import { useLazyQuery } from "@apollo/react-hooks";
import {orderBy} from 'lodash'
import DownloadButton from '../../common/DownloadButton'

const useStyles = makeStyles(theme => ({

    item: {
        marginTop: 3
    },
    card: {
        padding: 16,
        height: '100%'
    },
    dataContainer: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        marginBottom: 35,
        padding: 15
    },
    lowAttendance: {
        color: theme.palette.warning.main
    },
    buttonContainer: {
        textAlign: 'right'
    },
    link: {
        color: theme.palette.secondary.main,
        cursor: 'pointer'
    },
    title: {
        fontWeight: 900
    }

}));

function createData(name, courseId, professor, attendanceConfiguration, percentage, lowAttendance) {
    return {name, courseId, professor, attendanceConfiguration, percentage, lowAttendance};
}


let dummyRows = new Array(2);
  for(let i= 0; i<dummyRows.length; i++) {
    dummyRows[i] = createData('Math 101', "MA12323", "Judith Smith", "Manual", 80, true);
}


const StudentCoursesTable = (props) => {

    const onLinkClick = (student) => {
        props.onNameClick(student);
    };
    const [page, setPage] = React.useState(0);
    const [getStudentsClasses, studentsClassesResult] = useLazyQuery(LIST_STUDENT_CLASSES);
    const studentsClasses = studentsClassesResult && studentsClassesResult.data && studentsClassesResult.data.listStudentClasses
    const mutationParams={
        variables: {
            email: props.userEmail
        }
    }
    // multi filters
    const [nameFilter, setNameFilter] = useState('desc')
    const [courseIdFilter, setCourseIdFilter] = useState('desc')
    const [professorFilter, setProfessorFilter] = useState('desc')
    const [attendanceConfigurationFilter, setAttendanceConfigurationFilter] = useState('desc')
    const [generalAttendancePercentageFilter, setGeneralAttendancePercentageFilter] = useState('desc')
    const [atRiskFilter, setAtRiskFilter] = useState('desc')

    // single filter
    const [defaultFilterKey, setDefaultFilterKey] = useState('name')
    const [defaultFilterValue, setDefaultFilterValue] = useState('asc')

    const {courses, nextToken, totalCoursesCount} = studentsClasses ? studentsClasses : {}
    const refreshList = () => {
        const nextToken = page === 0 ? '' : studentsClassesResult && studentsClasses.nextToken;
        getStudentsClasses({
            variables: {
                email: props.userEmail,
                // limit: PAGE_SIZE
                token: nextToken
            }
        });
    };

    const selectDirection = (value) => {
        const res = value === 'asc' ? 'desc' : 'asc'
        setDefaultFilterValue(res)
        return res
    }

    const toggleSortDirection = (key) => {
        setDefaultFilterKey(key)
        switch(key) {
            case 'name':
                setNameFilter(selectDirection(nameFilter))
                break;
            case 'courseId':
                setCourseIdFilter(selectDirection(courseIdFilter))
                break;
            case 'professor':
                setProfessorFilter(selectDirection(professorFilter))
                break;
            case 'attendanceConfiguration':
                setAttendanceConfigurationFilter(selectDirection(attendanceConfigurationFilter))
                break;
            case 'generalAttendancePercentage':
                setGeneralAttendancePercentageFilter(selectDirection(generalAttendancePercentageFilter))
                break;
            case 'atRisk':
                setAtRiskFilter(selectDirection(atRiskFilter))
                break;
        }
    }

    const orderCourses = (courses) => {
        if (!courses) return;

        //singlefilter
        return orderBy(courses, [defaultFilterKey], [defaultFilterValue])

        // multifilter
        // return orderBy(students,
        //     ['name', 'email', 'classEnrolled', 'attendancePercentage', 'absences'],
        //     [nameFilter, emailFilter, classEnrolledFilter, attendancePercentageFilter, absencesFilter]
        // );
    }

    const isFilterSelected = (key) => {
        return key === defaultFilterKey
    }

    React.useEffect(() => {
        refreshList();
    }, [page])

    const classes = useStyles();
    return(
        <Paper elevation={3} className={classes.dataContainer}>

        <Grid container>
        <Grid item xs={12}>
            <Typography className={classes.title} variant={'h2'} color={'primary'}>Courses</Typography>
        </Grid>
    </Grid>
    <Grid container justify="space-between" alignItems='center'>
        <Grid item xs={3}>
            <Typography variant={'h5'}>{totalCoursesCount} Courses</Typography>
        </Grid>
        <Grid item xs={3} className={classes.buttonContainer}>
            <DownloadButton mutation={listStudentClassesCSV} mutationParams={mutationParams} lookupKey={'listStudentClassesCSV'}></DownloadButton>
        </Grid>
    </Grid>
        <WTableContainer>
            <Table>
                <WTableHead>
                    <TableRow>
                        <WTableCell onClick={() => toggleSortDirection('name')}>
                            <TableSortLabel active={true}
                                            direction={nameFilter}
                                            style={{color: isFilterSelected('name') ?'#177acc' : '#757373'}}>
                                Course Name
                            </TableSortLabel>
                        </WTableCell>
                        <WTableCell onClick={() => toggleSortDirection('courseId')}>
                            <TableSortLabel active={true}
                                            direction={courseIdFilter}
                                            style={{color: isFilterSelected('courseId') ?'#177acc' : '#757373'}}>
                                Course ID
                            </TableSortLabel>
                        </WTableCell>
                        <WTableCell onClick={() => toggleSortDirection('professor')}>
                            <TableSortLabel active={true}
                                            direction={professorFilter}
                                            style={{color: isFilterSelected('professor') ?'#177acc' : '#757373'}}>
                                Professor
                            </TableSortLabel>
                        </WTableCell>
                        <WTableCell onClick={() => toggleSortDirection('attendanceConfiguration')}>
                            <TableSortLabel active={true}
                                            direction={attendanceConfigurationFilter}
                                            style={{color: isFilterSelected('attendanceConfiguration') ?'#177acc' : '#757373'}}>
                                Attendance configuration
                            </TableSortLabel>
                        </WTableCell>
                        <WTableCell onClick={() => toggleSortDirection('generalAttendancePercentage')}>
                            <TableSortLabel active={true}
                                            direction={generalAttendancePercentageFilter}
                                            style={{color: isFilterSelected('generalAttendancePercentage') ?'#177acc' : '#757373'}}>
                                Attendance %
                            </TableSortLabel>
                        </WTableCell>
                        <WTableCell onClick={() => toggleSortDirection('atRisk')}>
                            <TableSortLabel active={true}
                                            direction={atRiskFilter}
                                            style={{color: isFilterSelected('atRisk') ?'#177acc' : '#757373'}}>
                                Low Attendance
                            </TableSortLabel>
                        </WTableCell>
                    </TableRow>
                </WTableHead>
                <TableBody>
                    {
                        orderCourses(courses) && orderCourses(courses).map((r, index) => {
                            return <TableRow key={index}>
                                <WTableCell onClick={e => onLinkClick(r)} className={classes.link}>{r.name}</WTableCell>
                                <WTableCell>{r.courseId}</WTableCell>
                                <WTableCell>{r.professor}</WTableCell>
                                <WTableCell align='center'>{r.attendanceConfiguration}</WTableCell>
                                <WTableCell align='center'>{r.generalAttendancePercentage}</WTableCell>
                                <WTableCell align='center' className={r.atRisk ? classes.lowAttendance : null}>{r.atRisk ? 'Yes': 'No'}</WTableCell>
                            </TableRow>;
                        })
                    }
                </TableBody>
            </Table>
        </WTableContainer>
    </Paper>
    )
};

export default StudentCoursesTable
