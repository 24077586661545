import React, {createContext, useContext} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {Card, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {BlueButton} from "../common/buttons";
import CardContent from "@material-ui/core/CardContent";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {getOngoingCourse} from "../../services/courses";
import {useQuery} from "@apollo/react-hooks";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {AttendanceCodeContext} from "./AttendanceCodeContextProvider";
import { eq } from "lodash";
import moment from 'moment'

const ACTIVE_COURSE_CHECK_INTERVAL = 5 * 60 * 1000; // 5 minutes

const useStyles = makeStyles(theme => createStyles({
    button: {
        fontSize: 12,
        fontWeight: 900,
        height: 26,
        width: 143,
        margin: 0,
        lineHeight: 0,
        padding: '5px 0 4px 0',
        letterSpacing: 0,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
    },

    buttonContainer: {
        textAlign: 'center'
    },

    paper: {
        minHeight: 177,
        width: 287,
        position: 'absolute',
        right: 0,
        top: 43,
        padding: '16px 12px'
    },

    cardContent: {
        padding: '0 !important'
    },

    titleContainer: {
        paddingBottom: 8
    },

    title: {
        fontWeight: 900
    },

    time: {
        paddingBottom: 12
    },

    codeTitle: {
        paddingBottom: 4
    },

    codeContainer: {
        borderRadius: 5,
        backgroundColor: 'rgb(243, 243, 243)',
        height: 46
    },

    code: {
        fontSize: 40,
        letterSpacing: 18,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: '100%',
        paddingTop: 3
    },

    closeBtn: {
        position: 'absolute',
        right: 0,
        top: -3
    }
}));

export default function AttendanceCode() {
    const classes = useStyles();
    const MINUTES = 1;
    const ONE_MINUTE_IN_SECONDS = 60
    const [activeCourse, setActiveCourse] = React.useState(null);
    const [displayComponent, setDisplayComponent] = React.useState(false);

    const context = useContext(AttendanceCodeContext);

    const {loading, error, data} = useQuery(getOngoingCourse, {
        pollInterval: 1000 * ONE_MINUTE_IN_SECONDS * MINUTES,
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            updateActiveCourse(data);
        },
        onError: (res) => {
            setActiveCourse(null)
        }
    });

    const updateActiveCourse = (data) => {
        if (!data) return;
        if (eq(data.getAttendanceCodeCard, activeCourse)) return;
        const {getAttendanceCodeCard} = data;
        setActiveCourse(getAttendanceCodeCard);
        setDisplayComponent(getAttendanceCodeCard)
    };

    const toggleAttendanceCode = () => {
        context.setVisibility(!context.isVisible);
    };

    const handleClose = (e) => {
        if (!e.defaultPrevented) {
            context.setVisibility(false)
        }
    };

    // console.log('AC', activeCourse, 'DC', displayComponent)
    if (!activeCourse || !displayComponent)
        return null;
    // console.log('ACTIVE', activeCourse)

    return (
            <Grid container direction={'row'} justify={'center'} alignItems={'center'} spacing={1}>
                <Grid item>
                    <Typography variant={'caption'} color={'textPrimary'}>
                        Current class: <strong>{activeCourse.className}</strong>
                    </Typography>
                </Grid>
                <Grid item>
                    <ClickAwayListener onClickAway={handleClose}>
                        <div style={{position: 'relative'}}>
                            <BlueButton className={classes.button} onClick={toggleAttendanceCode} disabled={!activeCourse.graded}>
                                {activeCourse.graded ? 'Show attendance code' : 'Nongraded Attendance'}
                            </BlueButton>

                            {
                                context.isVisible &&
                                <Card classes={{root: classes.paper}}>
                                    <CardContent classes={{root: classes.cardContent}}>

                                        <IconButton color="secondary" component="span" className={classes.closeBtn}
                                                    onClick={handleClose}>
                                            <CloseIcon/>
                                        </IconButton>

                                        <Grid container direction={'column'}>
                                            <Grid item className={classes.titleContainer}>
                                                <Typography variant={'h3'} color={'primary'} className={classes.title}>
                                                    {activeCourse.className}
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.location}>
                                                <Typography variant={'caption'}>
                                                    {activeCourse.facility.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.time}>
                                                <Typography
                                                    variant={'caption'}>{activeCourse.schedule.day} {activeCourse.schedule.startTime} - {activeCourse.schedule.endTime}</Typography>
                                            </Grid>
                                            <Grid item container direction={'column'}>
                                                <Grid item className={classes.codeTitle}>
                                                    <Typography variant={'caption'}>ATTENDANCE CODE</Typography>
                                                </Grid>
                                                <Grid item className={classes.codeContainer}>
                                                    <Typography className={classes.code} variant={'h1'}
                                                                color={'secondary'}>
                                                        {activeCourse.attendanceCode}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            }
                        </div>
                    </ClickAwayListener>
                </Grid>
            </Grid>
    )
}