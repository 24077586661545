import React, {useEffect, useState} from 'react';
import {Card, TableBody, TableSortLabel} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {WTableCell, WTableContainer, WTableHead} from "../../graphics/WiseTable";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import {makeStyles} from "@material-ui/core/styles";
import WPaginator from "../../graphics/WisePaginator";
import DownloadButton from '../../common/DownloadButton'
import {downloadProfessorsCSV} from '../../../services/professors'
import {orderBy} from 'lodash'

const useStyles = makeStyles(theme => ({
    card: {
        padding: '27px 16px'
    },
    button: {
        margin: 0
    },
    link: {
        color: theme.palette.secondary.main,
        cursor: 'pointer'
    },
}));

const ProfessorsTableCard = ({
                                 professors,
                                 onProfessorClick,
                                 page,
                                 pageSize,
                                 onPageChange,
                                 total
                             }) => {
    const classes = useStyles();

    // multi filters
    const [nameFilter, setNameFilter] = useState('desc')
    const [totalCourseFilter, setTotalCourseFilter] = useState('desc')
    const [totalPercentageFilter, setTotalPercentageFilter] = useState('desc')
    const [totalStudentsFilter, setTotalStudentsFilter] = useState('desc')
    const [totalLowAttendanceStudentsFilter, setTotalLowAttendanceStudentsFilter] = useState('desc')
    const [lowAttendanceStudentsPercentageFilter, setLowAttendanceStudentsPercentageFilter] = useState('desc')

    // single filter
    const [defaultFilterKey, setDefaultFilterKey] = useState('name')
    const [defaultFilterValue, setDefaultFilterValue] = useState('asc')


    const selectDirection = (value) => {
        const res = value === 'asc' ? 'desc' : 'asc'
        setDefaultFilterValue(res)
        return res
    }

    const toggleSortDirection = (key) => {
        setDefaultFilterKey(key)
        switch(key) {
            case 'name':
                setNameFilter(selectDirection(nameFilter))
                break;
            case 'summary.totalCourse':
                setTotalCourseFilter(selectDirection(totalCourseFilter))
                break;
            case 'summary.totalPercentage':
                setTotalPercentageFilter(selectDirection(totalPercentageFilter))
                break;
            case 'summary.totalStudents':
                setTotalStudentsFilter(selectDirection(totalStudentsFilter))
                break;
            case 'summary.totalLowAttendanceStudents':
                setTotalLowAttendanceStudentsFilter(selectDirection(totalLowAttendanceStudentsFilter))
                break;
            case 'summary.lowAttendanceStudentsPercentage':
                setLowAttendanceStudentsPercentageFilter(selectDirection(lowAttendanceStudentsPercentageFilter))
                break;
        }
    }

    const orderProfessors = (professors) => {
        if (!professors) return;

        //singlefilter
        return orderBy(professors, [defaultFilterKey], [defaultFilterValue])

        // multifilter
        // return orderBy(students,
        //     ['name', 'email', 'classEnrolled', 'attendancePercentage', 'absences'],
        //     [nameFilter, emailFilter, classEnrolledFilter, attendancePercentageFilter, absencesFilter]
        // );
    }

    const isFilterSelected = (key) => {
        return key === defaultFilterKey
    }


    const rows = orderProfessors(professors) && orderProfessors(professors).map(prof => (
        <TableRow key={prof.name}>
            <WTableCell>
                <Typography variant={'h4'}
                            onClick={() => onProfessorClick(prof)}
                            className={classes.link}>
                    {prof.name}
                </Typography>
            </WTableCell>
            <WTableCell align={'center'}>{prof.summary.totalCourse}</WTableCell>
            <WTableCell align={'center'}>{prof.summary.totalPercentage}%</WTableCell>
            <WTableCell align={'center'}>{prof.summary.totalStudents}</WTableCell>
            <WTableCell align={'center'}>{prof.summary.totalLowAttendanceStudents}</WTableCell>
            <WTableCell align={'center'}>{prof.summary.lowAttendanceStudentsPercentage}%</WTableCell>
        </TableRow>
    ));
    return (
        <Card classes={{root: classes.card}}>
            <Grid container spacing={2}>

                <Grid item container xs={12} justify={'space-between'} alignItems={"center"}>
                    <Grid item>
                        <Typography variant={'h5'}>{total} Professors</Typography>
                    </Grid>
                    <Grid item>

                        <DownloadButton mutation={downloadProfessorsCSV} mutationParams={null} lookupKey={'professorsCSV'}></DownloadButton>
                    </Grid>
                </Grid>

                <Grid item xs={12}>

                    <WTableContainer>
                        <Table>
                            <WTableHead>
                                <TableRow>
                                    <WTableCell onClick={() => toggleSortDirection('name')}>
                                        <TableSortLabel active={true}
                                                direction={nameFilter}
                                                style={{color: isFilterSelected('name') ?'#177acc' : '#757373'}}>
                                            Professor Name
                                        </TableSortLabel>
                                    </WTableCell>
                                    <WTableCell onClick={() => toggleSortDirection('summary.totalCourse')}>
                                        <TableSortLabel active={true}
                                                direction={totalCourseFilter}
                                                style={{color: isFilterSelected('summary.totalCourse') ?'#177acc' : '#757373'}}>
                                            Couses imparting
                                        </TableSortLabel>
                                    </WTableCell>
                                    <WTableCell onClick={() => toggleSortDirection('summary.totalPercentage')}>
                                        <TableSortLabel active={true}
                                                direction={totalPercentageFilter}
                                                style={{color: isFilterSelected('summary.totalPercentage') ?'#177acc' : '#757373'}}>
                                            Attendance %
                                        </TableSortLabel>
                                    </WTableCell>
                                    <WTableCell onClick={() => toggleSortDirection('summary.totalStudents')}>
                                        <TableSortLabel active={true}
                                                direction={totalStudentsFilter}
                                                style={{color: isFilterSelected('summary.totalStudents') ?'#177acc' : '#757373'}}>
                                            Enrolled students
                                        </TableSortLabel>
                                    </WTableCell>
                                    <WTableCell onClick={() => toggleSortDirection('summary.totalLowAttendanceStudents')}>
                                        <TableSortLabel active={true}
                                                direction={totalLowAttendanceStudentsFilter}
                                                style={{color: isFilterSelected('summary.totalLowAttendanceStudents') ?'#177acc' : '#757373'}}>
                                            Students with Low attendance
                                        </TableSortLabel>
                                    </WTableCell>
                                    <WTableCell onClick={() => toggleSortDirection('summary.lowAttendanceStudentsPercentage')}>
                                        <TableSortLabel active={true}
                                                direction={lowAttendanceStudentsPercentageFilter}
                                                style={{color: isFilterSelected('summary.lowAttendanceStudentsPercentage') ?'#177acc' : '#757373'}}>
                                            Students with low attendance (%)
                                        </TableSortLabel>
                                    </WTableCell>
                                </TableRow>
                            </WTableHead>
                            <TableBody>
                                {rows}
                            </TableBody>
                        </Table>
                    </WTableContainer>

                </Grid>
                <Grid item container justify={'flex-end'}>
                    <Grid item>
                        <WPaginator total={total}
                                    page={page} i
                                    itemsInPage={professors.length}
                                    onNext={() => onPageChange(page + 1)}
                                    onStart={() => onPageChange(0)}
                                    startDisabled={page === 0}
                                    nextDisabled={!((page + 1) * pageSize < total)}/>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default ProfessorsTableCard;