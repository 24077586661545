import React, { useContext } from 'react';
import { Grid, makeStyles, createStyles } from '@material-ui/core';
import { TitleTableTypography, SubtitleOnboardingTypography, SubtitleTypography } from '../../../common/typography';
import { WiseRightTextField } from '../../../common/inputs';
import { OnboardingContext } from "../../../../context/onboarding-context";

const useStyles = makeStyles((theme) => 
    createStyles({
        input:{
            textAlign:'right'
        },
        infoContainer: {
            textAlign: 'center',
            padding: '20px 70px'
        },
        title: {
            fontWeight: 900,
            paddingTop:10,
            color: '#585757'
        },
        minutes: {
            color: '#959595',
            textAlign: 'left',
            paddingLeft: 10
        },
        smallText: {
            width: '50%',
        },
        centerAdornment: {
            marginRight: "40%" // or your relevant measure
        },
        textContainer: {
            textAlign: 'right'
        }
    })
);

export default function AttendanceTime() {
    const classes = useStyles();
    const {termInfo, setPercentageAndTolerance} = useContext(OnboardingContext);

    const changeTolerance = e => {
        let { value, min, max } = e.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setPercentageAndTolerance(termInfo.minimumPercentage, value);
    };

    return(
        <Grid item container xs={12}>
        <Grid item xs={12}>
            <TitleTableTypography>Tolerance time <br/> to mark attendance</TitleTableTypography>
        </Grid>
        <Grid className={classes.infoContainer} justify="space-between" alignItems="flex-start" item container xs={12}>
            <Grid item xs={12}>
                <SubtitleOnboardingTypography>Tolerated tardiness up to:</SubtitleOnboardingTypography>
            </Grid>
            <Grid item xs={8} className={classes.textContainer}>
                <WiseRightTextField 
                type="number"
                min="1"
                max="59" 
                step="10"
                inputProps={{
                    min:"0",
                    max: "59",
                    step: "1",
                }}
                value={termInfo.tolerance}
                className={classes.smallText}
                onChange={changeTolerance}
                variant="outlined"
                />
            </Grid>
            <Grid item xs={4}>
                <SubtitleOnboardingTypography className={classes.minutes}>minutes</SubtitleOnboardingTypography>
            </Grid>
            <Grid item xs={12}>
                <SubtitleTypography>From the beginning of the class</SubtitleTypography>
            </Grid>
        </Grid>
    </Grid>
    )
}