import React from 'react';
import {Card, RadioGroup, Row} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {BlueButton} from "../../common/buttons";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TOGGLE_COURSE_ATTENDANCE, getProfCourses } from "../../../services/courses";
import { useMutation } from '@apollo/react-hooks';
import {SuccessDialog} from '../../common/dialog'
import { ReactComponent as NonVisibleAttendance } from "../../../assets/automatic-attendance-non-visible.svg";
import { ReactComponent as VisibleAttendance } from "../../../assets/graded-attendance-visible.svg";
import { ReactComponent as AttendanceCode } from "../../../assets/graded-attendance-code.svg";

const useStyles = makeStyles(theme => ({

    card: {
        padding: '12px 15px 20px 15px',
        height: '100%'
    },

    button: {
        height: 32,
        minWidth: 95,
        margin: 0,
        padding: 0
    },

    labelContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'start'
    }
}));


const InputRadioButton = ({title, linkText, value, disabled, onClick}) => {
    const classes = useStyles();

    return (
        <FormControlLabel disabled={disabled} control={<Radio color="secondary" size={'small'} value={value}/>} label={
            <div className={classes.labelContainer}>
                <Typography variant={'caption'}>{title}</Typography>
                <Link component={'button'} color={'secondary'}>
                    <Typography onClick={onClick} variant={'h5'}>{linkText}</Typography>
                </Link>
            </div>
        }>
        </FormControlLabel>
    )
};

const isUserAdmin = (role) => role && role === 'Admin'

const AttendanceConfigurationCard = (props) => {
    const classes = useStyles();
    const { role } = props
    const [value, setValue] = React.useState(props.course.graded);
    const [displayFirst, setDisplayFirst] = React.useState(false);
    const [displaySecond, setDisplaySecond] = React.useState(false);
    const [displayThird, setDisplayThird] = React.useState(false);
    const [updateCourseAttendance] = useMutation(TOGGLE_COURSE_ATTENDANCE, {
        variables: {
            input: {
                id: props.course.id,
                schoolCode: props.schoolCode,
                graded: value == 'true' ? true : false
            }
        },
        update(cache, { data: { toggleClassAttendance } }) {
            const cach = cache.readQuery({ query: getProfCourses });
            const newListCourses = cach.listCourses.courses.filter(course => course.id !== toggleClassAttendance.id)
            const selectedCourse = cach.listCourses.courses.find(course => course.id === toggleClassAttendance.id)
            newListCourses.push({...selectedCourse, ...toggleClassAttendance})
            const objectToWrite = {
                courses: newListCourses,
                __typename: "CourseConnection"
            }
            cache.writeQuery({
              query: getProfCourses,
              data: { listCourses: objectToWrite },
            });
          },
        onCompleted: (data) => {
            setDisplayThird(true)
        }
    });

    return (
        <Card classes={{root: classes.card}}>
            <RadioGroup name="config" value={String(value)} onChange={e => {setValue(e.target.value)}}>
            {displayFirst && <SuccessDialog title={'Graded Attendance (Manual)'}
                showDialog={displayFirst}
                onClose={() => setDisplayFirst(false)}
                onOk={() => setDisplayFirst(false)}>
                <Grid container item xs={12}>
                    <Grid item xs={5}>
                        <Grid item xs={12}>
                            <VisibleAttendance></VisibleAttendance>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '26px'}}>
                            <p><strong>Professors</strong> will see each of the students attendance and absences</p>
                        </Grid>
                        
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid item xs={12}>
                            <AttendanceCode style={{marginLeft: '40px'}}></AttendanceCode>
                        </Grid>
                        <Grid item xs={12}>
                            <p>The students input their attendance <strong>code manually</strong> each class</p>
                        </Grid>
                    </Grid>
                </Grid>
            </SuccessDialog>}
            {displaySecond && <SuccessDialog title={'Nongraded attendance (Automatic)'}
                showDialog={displaySecond}
                onClose={() => setDisplaySecond(false)}
                onOk={() => setDisplaySecond(false)}>
                <Grid container item xs={12}>
                    <Grid item xs={5}>
                        <Grid item xs={12}>
                            <NonVisibleAttendance></NonVisibleAttendance>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '26px'}}>
                            <p><strong>Professors</strong> will see the overall course attendance and <strong>only students at risk</strong></p>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid item xs={12}>
                            <AttendanceCode style={{marginLeft: '40px'}}></AttendanceCode>
                        </Grid>
                        <Grid item xs={12}>
                            <p>The app <strong>tracks</strong> students location and marks the class attendance <strong>automatically</strong></p>
                        </Grid>  
                    </Grid>
                </Grid>
            </SuccessDialog>}
            {displayThird && <SuccessDialog title={'Your attendance configuration has been saved'}
                showDialog={displayThird}
                onClose={() => setDisplayThird(false)}
                onOk={() => setDisplayThird(false)}>
                <Grid container item xs={12}>
                    <Grid item>
                        <p>It can take up to 24 hours for the new configuration to be visible for students</p>
                    </Grid>
                </Grid>
            </SuccessDialog>}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'h2'} color={'primary'}><strong>Attendance
                            configuration</strong></Typography>
                    </Grid>
                    <Grid item container direction={'column'} xs={8}>
                        <Grid item container>
                            <InputRadioButton disabled={isUserAdmin(role)} title={'Graded Attendance (manual)'} linkText={'What does it mean?'} onClick={() => setDisplayFirst(!displayFirst)} value={'true'}/>
                        </Grid>

                        <Grid item container style={{marginTop: 12}}>
                            <InputRadioButton disabled={isUserAdmin(role)} title={'Nongraded (automatic)'} linkText={'What does it mean?'} onClick={ () => setDisplaySecond(!displaySecond)} value={'false'}/>
                        </Grid>
                    </Grid>

                    {!isUserAdmin(role) && <Grid item container alignItems={'flex-end'} justify={'flex-end'} xs={4}>
                        <BlueButton className={classes.button} onClick={updateCourseAttendance}>Save</BlueButton>
                    </Grid>}
                </Grid>
            </RadioGroup>
        </Card>
    );
};

export default AttendanceConfigurationCard;