import React, {useEffect, useState} from 'react';
import {createStyles, Grid, makeStyles, Paper, TableBody, Typography} from "@material-ui/core";
import CircleInfo from '../../common/CircleInfo';
import {GET_TOTAL_STUDENTS_BANNER} from '../../../services/students'
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {isEmpty, orderBy, sortBy} from 'lodash';
import ProfessorStudentsTable from './ProfessorStudentsTable'
import AdminStudentsTable from './AdminStudentsTable'

const PAGE_SIZE = 10

const LoadingContainer = (props) => {
    return(<Paper className={props.class}>
        <Grid container alignItems='center'>
            <Typography variant={'h2'} color={'primary'}><strong>Loading...</strong></Typography>
        </Grid>
    </Paper>)
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            maxWidth: 1400,
            marginTop: 26
        },
        dataContainer: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: 5,
            marginBottom: 35,
            padding: 15
        },
        borders: {
            borderLeftWidth: 2,
            borderLeftColor: '#E8E8E8',
            borderStyle: 'solid',
            borderBottom: 0,
            borderTop: 0,
            borderRight: 0,
            textAlign: 'center',
            paddingTop: 15,
            height: 110
        },
        percentageAtRiskAdmin: {
            color: theme.palette.primary.main
        },
        percentageAtRiskProf: {
            color: theme.palette.warning.main
        },
        overallPercentage: {
            color: theme.palette.info.main,
            fontWeight: 900
        },
        warningLabel: {
            color: theme.palette.warning.main
        },
        buttonContainer: {
            textAlign: 'right'
        },
        link: {
            color: theme.palette.secondary.main,
            cursor: 'pointer'
        }
    })
);

export default function Students({role, school}) {
    const classes = useStyles();
    const [blueData, setBlueData] = useState({})
    const [lightBlueData, setLightBlueData] = useState({})
    const [yellowData, setYellowData] = useState({})
    const [rightBannerData, setRightBannerData] = useState({})

    const adminStudentsBannersResult = useQuery(GET_TOTAL_STUDENTS_BANNER, {
        onCompleted: () => {
            const {totalStudentsBanner} = adminStudentsBannersResult.data
            setBlueData({number: totalStudentsBanner.numberOfStudentsEnrolled, text: 'Students are enrolled'})
            setLightBlueData({number: totalStudentsBanner.numberOfClasses, text1: 'Across', text2: 'courses'})
            setYellowData({number: totalStudentsBanner.numberOfStudentsAtRisk, text1: '', text2: 'Students with low attendance'})
            setRightBannerData({percentageOfStudentsAtRisk: totalStudentsBanner.percentageOfStudentsAtRisk})
        }
    });

    return (
        <div className={classes.root}>
            {
                isEmpty(blueData) && isEmpty(lightBlueData) && isEmpty(yellowData) && isEmpty(rightBannerData) ?
                <LoadingContainer class={classes.dataContainer}></LoadingContainer>
                :
              (<Paper elevation={5} className={classes.dataContainer}>
                <Grid container alignItems='center'>
                    {/* CIRCULOS */}
                    <Grid item xs={6}>
                        <CircleInfo lightBlueData={lightBlueData} yellowData={yellowData} blueData={blueData}/>
                    </Grid>
                    <Grid item xs={6} className={classes.borders}>
                        <Typography variant={'h1'} className={classes.percentageAtRiskAdmin}>{rightBannerData.percentageOfStudentsAtRisk}%</Typography>
                        <Typography variant={'caption'}>Of students are at-risk of <br/> failing based on
                            attendance</Typography>
                    </Grid>
                </Grid>
            </Paper>)}
        </div>
    )
}