import React, { useContext, useState } from 'react';
import { Grid, makeStyles, createStyles } from '@material-ui/core';
import { TitleTableTypography } from '../../../common/typography';
import UploadCSV from '../../../csv_files/UploadCSV';
import { OnboardingContext } from "../../../../context/onboarding-context";
import { GET_TEMPLATE_LINKS } from '../../../../services/school';
import { useQuery } from '@apollo/react-hooks';

const useStyles = makeStyles((theme) => 
    createStyles({
        input:{
            textAlign:'right'
        },
        infoContainer: {
            textAlign: 'center',
        },
        title: {
            fontWeight: 900,
            paddingTop:10,
            color: '#585757'
        },
        centerAdornment: {
            marginRight: "40%" // or your relevant measure
          },
    })
);



export default function UploadDataSchool(props) {
    const classes = useStyles();
    const [onboardingLinks, setOnboardingLinks] = useState({});
    const {setupStep, termInfo, schoolId, semesterCode} = useContext(OnboardingContext);
    const schoolNameModified = schoolId.split(" ").join("-");
    const termNameModified = semesterCode.split(" ").join("-");
    useQuery(GET_TEMPLATE_LINKS, {
        onCompleted: (data) => getLinks(data)
    });

    const getLinks = ({getOnBoardingFileLinks}) => {
        setOnboardingLinks({
            facilities: getOnBoardingFileLinks.facilityCSV,
            students: getOnBoardingFileLinks.studentCSV,
            professors: getOnBoardingFileLinks.professorCSV,
            courses: getOnBoardingFileLinks.classCSV,
            administrators: getOnBoardingFileLinks.adminCSV
        });
    };
    const infoData = {
        5: {
            name: 'classroom locations',
            fileName: `${schoolNameModified}_${termNameModified}_facility.csv`,
            warning: true,
            type: 'facilities'     
        },
        6: {
            name: 'students',
            fileName: `${schoolNameModified}_${termNameModified}_student.csv`,
            warning: false,
            type: 'students'     
        },
        7: {
            name: 'professors',
            fileName: `${schoolNameModified}_${termNameModified}_professor.csv`,
            warning: false,
            type: 'professors'     
        },
        8: {
            name: 'courses',
            fileName: `${schoolNameModified}_${termNameModified}_class.csv`,
            warning: false,
            type: 'courses'     
        },
        9: {
            name: 'administrators',
            fileName: `${schoolNameModified}_${termNameModified}_admin.csv`,
            warning: true,
            type: 'administrators'     
        }
    };
 
    return(
        <Grid justify="center" item container xs={12}>
            <Grid item xs={12}>
                <TitleTableTypography>Upload your {infoData[setupStep].name}</TitleTableTypography>
            </Grid>
            <Grid item container justify='center' alignItems='center' xs={9} className={classes.infoContainer}>
                <UploadCSV onboardingLinks={onboardingLinks} schoolId={props.schoolId} {...infoData[setupStep]}></UploadCSV>
            </Grid>
    </Grid>
    )
}