import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useHistory} from 'react-router-dom';
import {useMutation} from "@apollo/react-hooks";
import {SuccessDialog, WarningDialog} from './dialog'
import {WhiteButton} from './buttons'

const useStyles = makeStyles(theme => ({
    title: {
        marginLeft: 16,
        marginTop: 35
        
    }
}));

const AlertModal = (props) => {
  if (props.downloadData === false) return null;
  return (
    props.downloadData ? <SuccessDialog title={'Download CSV'}
    showDialog={props.showDialog}
    onClose={() => props.dismiss()}
    onOk={() => props.dismiss()}>
  A CSV file with the data will be sent to your email when ready
  </SuccessDialog> : 
  <WarningDialog
    showDialog={props.showDialog}
    onCancel={() => props.dismiss()}
    onClose={() => props.dismiss()}
    onOk={() => props.dismiss()}
  >
    It was not possible to process your request. Please try again later.
  </WarningDialog>
  )
}

const DownloadButton = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const lookupKey = props.lookupKey
    const [showDialog, setShowDialog] = React.useState(false);
    const [downloadData, setDownloadData] = React.useState(false);
    // const [updateCourseAttendance] = useMutation(TOGGLE_COURSE_ATTENDANCE, {
    //     variables: {
    //         input: {
    //             id: props.course.id,
    //             schoolCode: props.schoolCode,
    //             graded: value == 'true' ? true : false
    //         }
    //     },
    //     update(cache, { data: { toggleClassAttendance } }) {
    //         const cach = cache.readQuery({ query: getProfCourses });
    //         const newListCourses = cach.listCourses.courses.filter(course => course.id !== toggleClassAttendance.id)
    //         const selectedCourse = cach.listCourses.courses.find(course => course.id === toggleClassAttendance.id)
    //         newListCourses.push({...selectedCourse, ...toggleClassAttendance})
    //         const objectToWrite = {
    //             courses: newListCourses,
    //             __typename: "CourseConnection"
    //         }
    //         cache.writeQuery({
    //           query: getProfCourses,
    //           data: { listCourses: objectToWrite },
    //         });
    //       },
    //     onCompleted: (data) => console.log(data)
    // });
    const [triggerDownload] = useMutation(props.mutation, {
      ...props.mutationParams,
      onError: (err) => {
          console.log('err', err)
          setDownloadData(err[lookupKey])
      },
      onCompleted: (data) => {
          setDownloadData(data[lookupKey])
      }
    })
    const displayAndExecute = () => {
      setShowDialog(true)
      triggerDownload()
    }
    const hideAndClear = () => {
      setShowDialog(false)
      setDownloadData(false)
    }
    return (
      <React.Fragment>
        <AlertModal showDialog={showDialog} setShowDialog={setShowDialog} downloadData={downloadData} dismiss={hideAndClear}></AlertModal>
        <WhiteButton variant={'outlined'} size={'small'} className={classes.button}
          onClick={() => displayAndExecute()}>
          Download data
        </WhiteButton>
      </React.Fragment>
    );
};

export default DownloadButton;