import React, {useEffect, useState} from 'react';
import {Card, TableBody} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {WTableCell, WTableContainer, WTableHead} from "../../graphics/WiseTable";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import WPaginator from "../../graphics/WisePaginator";
import {IOSSwitch} from "../../common/buttons";
import {GET_PROFESSOR_STUDENTS_TABLE, professorStudentsTableCSV, getCourseDetailsCSV} from '../../../services/courses'
import { useLazyQuery } from "@apollo/react-hooks";
import {orderBy} from 'lodash'
import {useHistory} from "react-router-dom";
import DownloadButton from '../../common/DownloadButton'

const PAGE_SIZE = 10

const useStyles = makeStyles({
    card: {
        padding: '24px 15px'
    },
    link: {
        color: '#37b5ff',
        cursor: 'pointer'
    },
    dataContainer: {
        borderRadius: 5,
        marginBottom: 35,
        padding: 15,
        maxWidth: '1230px'
    },
});

const ProfessorCourseStudentsCard = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const onEmailClick = (email) => {
        window.open(`mailto:${email}`, "_blank");
    };

    const onNameClick = (student) => {
        history.push(`/students/${encodeURIComponent(student.email)}`);
    };

    const [page, setPage] = React.useState(0);
    const [getProfessorStudents, professorStudentsResult] = useLazyQuery(GET_PROFESSOR_STUDENTS_TABLE);
    const mutationParams= {
        variables: {
            id: props.course.id
        }
    }
    const data = professorStudentsResult && professorStudentsResult.data && professorStudentsResult.data.professorStudentsTable
    const students = data && data.students
    let filteredStudents = students || [];
    const tableHeaders = data && data.tableHeaders
    const parsedTableHeaders = tableHeaders && JSON.parse(tableHeaders)
    const [lowAttendanceFilter, setLowAttendanceFilter] = React.useState(false);

    const refreshList = () => {
        const nextToken = page === 0 ? '' : data && data.nextToken;
        getProfessorStudents({
            variables: {
                id: props.course.id,
                limit: PAGE_SIZE,
                nextToken: nextToken
            }
        });
    };


    // multi filters
    const [nameFilter, setNameFilter] = useState('desc')
    const [emailFilter, setEmailFilter] = useState('desc')
    const [gradedAttendancesCountFilter, setGradedAttendancesCountFilter] = useState('desc')
    const [generalAttendancePercentageFilter, setGeneralAttendancePercentageFilter] = useState('desc')
    const [atRiskFilter, setAtRiskFilter] = useState('desc')
    const [dateFilter, setDateFilter] = useState({})

    // single filter
    const [defaultFilterKey, setDefaultFilterKey] = useState('name')
    const [defaultFilterValue, setDefaultFilterValue] = useState('asc')


    React.useEffect(() => {
        refreshList()
    }, [page])

    const staticTableHeaders = () => {
        if(!parsedTableHeaders || !tableHeaders) return []
        const obj = [
            {
                title: 'Name',
                id: 'name',
                filter: nameFilter
            },
            {
                title: 'Email',
                id: 'email',
                filter: emailFilter
            },
            {
                title: 'Graded attendance',
                id: 'gradedAttendancesCount',
                filter: gradedAttendancesCountFilter
            },
            {
                title: 'Attendance %',
                id: 'generalAttendancePercentage',
                filter: generalAttendancePercentageFilter
            },
            {
                title: 'Low Attendance',
                id: 'atRisk',
                filter: atRiskFilter
            }
        ]
        return obj
    }

    const dynamicTableHeaders = () => {
        if(!parsedTableHeaders || !tableHeaders) return []
        return parsedTableHeaders
    }


    const selectDirection = (value) => {
        const res = value === 'asc' ? 'desc' : 'asc'
        setDefaultFilterValue(res)
        return res
    }

    const toggleSortDirection = (key) => {
        setDefaultFilterKey(key)
        switch(key) {
            case 'name':
                setNameFilter(selectDirection(nameFilter))
                break;
            case 'email':
                setEmailFilter(selectDirection(emailFilter))
                break;
            case 'gradedAttendancesCount':
                setGradedAttendancesCountFilter(selectDirection(gradedAttendancesCountFilter))
                break;
            case 'generalAttendancePercentage':
                setGeneralAttendancePercentageFilter(selectDirection(generalAttendancePercentageFilter))
                break;
            case 'atRisk':
                setAtRiskFilter(selectDirection(atRiskFilter))
                break;
        }
    }

    const modifyDateFilter = (key, value) => {
        return {
            ...dateFilter,
            [key]: value
        }
    }

    const toggleDateSortDirection = (key, lookupHash) => {
        setDefaultFilterKey(`attendanceRecords.${key}`)
        setDateFilter(lookupHash)
        const currentValue = lookupHash[key]
        const newValue = selectDirection(currentValue)
        setDateFilter(modifyDateFilter(key, newValue))
    }

    const isFilterSelected = (key) => {
        return key === defaultFilterKey
    }

    const orderStudents = (students) => {
        if (!students) return;

        //singlefilter
        const res = orderBy(students, [defaultFilterKey], [defaultFilterValue])
        // console.log('ORDERED STUDENTS', res)
        // console.log('DEF FILTER', defaultFilterKey)
        // console.log('FILTER VAL', defaultFilterValue)
        return res
    }

    const reformatStudents = (students) => {
        const res = students.map(student => {
            const attendanceRecords = student.attendanceRecords && JSON.parse(student.attendanceRecords)
            return {
                ...student,
                attendanceRecords
            }
        })
        // console.log('RES', res)
        return res
    }
    if(filteredStudents){
        // console.log('FILTERED DEFINED')
        filteredStudents = reformatStudents(filteredStudents)
    }
    filteredStudents = orderStudents(filteredStudents)

    if(lowAttendanceFilter){
        filteredStudents = filteredStudents.filter(student => student.atRisk)
    }

    const definedTableHeaders = staticTableHeaders()
    const undefinedTableHeaders = dynamicTableHeaders()

    const getCellFill = (value) => {
        switch (value) {
            case true:
                return '✓'
            case false:
                return 'x'
            default:
                return 'Not graded'
        }
    }

    const getCellColor = (value) => {
        switch (value) {
            case true:
                return 'green'
            case false:
                return 'red'
            default:
                return '#757373'
        }
    }

    return (
        <Card classes={{root: classes.dataContainer}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h2'} color={'primary'}><strong>Students</strong></Typography>
                </Grid>

                <Grid item xs={12} container alignItems={'center'}>
                    <Grid item xs={6}>
                        {data && <Typography variant={'h5'}>{data.totalStudentsCount} students enrolled</Typography>}
                    </Grid>
                    <Grid item xs={6} container justify={'flex-end'} alignItems={'center'}>
                        <Typography variant={'h5'}> Low attendance</Typography>
                        <IOSSwitch onClick={() => setLowAttendanceFilter(!lowAttendanceFilter)}/>
                        <DownloadButton mutation={getCourseDetailsCSV} mutationParams={mutationParams} lookupKey={'courseDetailsCSV'}></DownloadButton>
                    </Grid>
                </Grid>

                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <WTableContainer>
                            <Table>
                                <WTableHead>
                                    <TableRow>
                                        {definedTableHeaders.map(header => {
                                            return (<WTableCell key={header.id} onClick={() => toggleSortDirection(header.id)}>
                                            <TableSortLabel active={true}
                                                            direction={header.filter}
                                                            style={{color: isFilterSelected(header.id) ?'#177acc' : '#757373'}}>
                                                {header.title}
                                            </TableSortLabel>
                                        </WTableCell>)
                                        })}
                                        {undefinedTableHeaders.map(header => {
                                            return (<WTableCell key={header.id} onClick={() => toggleDateSortDirection(header.id, dateFilter)}>
                                            <TableSortLabel active={true}
                                                            direction={dateFilter[header.id] ? dateFilter[header.id] : 'desc' }
                                                            style={{color: isFilterSelected(`attendanceRecords.${header.id}`) ?'#177acc' : '#757373'}}>
                                                {header.title}
                                            </TableSortLabel>
                                        </WTableCell>)
                                        })}
                                    </TableRow>
                                </WTableHead>
                                <TableBody>
                                    {
                                        (filteredStudents || []).map((r, index) => {
                                            return (<TableRow key={index}>
                                                <WTableCell onClick={e => onNameClick(r)} className={classes.link}>{r.name}</WTableCell>
                                                <WTableCell onClick={e => onEmailClick(r.email)} className={classes.link}>{r.email}</WTableCell>
                                                <WTableCell style={{textAlign: 'center'}}>{r.gradedAttendancesCount}</WTableCell>
                                                <WTableCell style={{textAlign: 'center'}}>{r.generalAttendancePercentage}%</WTableCell>
                                                <WTableCell style={{textAlign: 'center'}}>{r.atRisk ? 'yes' : 'no'}</WTableCell>
                                                {Object.keys(r.attendanceRecords).map((key) => {
                                                    const found = r.attendanceRecords[key]
                                                    return (<WTableCell key={key} style={{color: getCellColor(found), textAlign: 'center'}}>
                                                        {getCellFill(found)}
                                                        </WTableCell>)
                                                })}
                                            </TableRow>)
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </WTableContainer>
                    </Grid>

                    <Grid item container justify={'flex-end'}>
                        <Grid item>
                            {data && <WPaginator total={data.totalStudentsCount}
                                    page={page} i
                                    itemsInPage={filteredStudents.length}
                                    onNext={() => setPage(page + 1)}
                                    onStart={() => setPage(0)}
                                    startDisabled={page === 0}
                                    nextDisabled={!((page + 1) * PAGE_SIZE < data.totalStudentsCount)}/>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default ProfessorCourseStudentsCard;