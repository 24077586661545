import React from 'react'
import {  Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { NoBorderButton } from '../../common/buttons';
import { ReactComponent as UploadSuccessIcon } from "../../../assets/upload_success.svg";
import { SubtitleTypography } from '../../common/typography';

const useStyles = makeStyles(theme => ({
    root: {
    //   display: 'flex',
    //   flexWrap: 'wrap',
        [theme.breakpoints.up("md")]: {
            height: "25%",
            width: "33%",
            margin: "10% 10%"
        },
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            width: "auto",
            margin: "10% 10%"
        }
    },
    input: {
        display: "none"
    },
    container: {
        padding: "21px"
    },
    containerUpload: {
        paddingTop: "10% !important"
    }
  }));

export default function UploadSuccessfull(props) {
    const classes = useStyles();

    const onChange = e => {
        props.onChange(e);
    }

    return(           
        <Grid className={classes.containerUpload} container item xs={12} >
            <Grid item xs={4}>
                <label htmlFor="button-file">
                    <UploadSuccessIcon></UploadSuccessIcon>
                </label>
            </Grid>
            <Grid item xs={7}>
                <div>
                    <SubtitleTypography>Upload Successfull</SubtitleTypography>
                </div>
                <div>
                    <NoBorderButton onClick={onChange}>Preview file</NoBorderButton>
                </div>
            </Grid>
        </Grid>
    );
};  