import React from 'react';
import { ReactComponent as SuccessIcon } from "../../assets/check_mark.svg";
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiButton-contained': {
            fontFamily: 'Avenir-Medium',
            fontSize: 14,
            backgroundColor: "#37B5FF",
            textTransform: "none",
            color: "white",
            fontWeight: 900,
            width: "35%"
        }
    }
    }));

function SuccessPassword(props) {
    const classes = useStyles();

    const goToLogin = () => {
        props.onChangeMode("login");
    }
    return(
        <div className={"login-container-success " + classes.root}>
                <div className="title">Success</div>
                <div className="message bold">Your password was created succesfully</div>
                <SuccessIcon className="success-icon"/>
                <div className="message">Please login to the platform</div>
                <div className="message">using your new password</div>
                <div className="button-ok-container" style={{flex: 1}}>
                    <Button onClick={goToLogin} variant="contained" style={{width: '23%'}}>Ok</Button>
                </div>
        </div>
    )
}

export default SuccessPassword;
