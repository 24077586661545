import gql from 'graphql-tag';

const GET_SCHOOL = gql`
query GetSchool($schoolCode: ID!){  
    getSchool(schoolCode: $schoolCode) {
        schoolCode
        name
        timezone
        semester
        semesterName
        adminsFileUploaded
        facilitiesFileUploaded
        professorsFileUploaded
        studentsFileUploaded
        classesFileUploaded
        semesterStartDate 
        semesterEndDate 
        atRiskPercentage 
        attendanceAssertionTimeThreshold 
        contactHelpEmail 
        upcomingClassNotificationMsg 
        upcomingClassNotificationTime 
        missedClassAlertAutoMsg 
        missedClassAlertAutoTime 
        missedClassAlertManualMsg 
        missedClassAlertManualTime 
        atRiskMessagesPool 
        atRiskMessagesTime 
        minTimeSpentInClass
    }
}`;

const INIT_INFO = gql`
query GetSchool($schoolCode: ID!){  
    getSchool(schoolCode: $schoolCode) {
        schoolCode
        name
        timezone
        semester
        semesterName
        adminsFileUploaded
        facilitiesFileUploaded
        professorsFileUploaded
        studentsFileUploaded
        classesFileUploaded
        semesterStartDate 
        semesterEndDate 
        atRiskPercentage 
        attendanceAssertionTimeThreshold 
        contactHelpEmail 
        upcomingClassNotificationMsg 
        upcomingClassNotificationTime 
        missedClassAlertAutoMsg 
        missedClassAlertAutoTime 
        missedClassAlertManualMsg 
        missedClassAlertManualTime 
        atRiskMessagesPool 
        atRiskMessagesTime 
    }
    getUser {
        email 
        schoolCode 
        name 
        nickname 
        code 
        phone 
        semester 
        role 
        academic_rank 
      }
}
`

const CREATE_SEMESTER = gql`
mutation UpdateSchool($inputSchool: UpdateSchoolInput!, $inputSemester: CreateSemesterInput!){  
    updateSchool(input: $inputSchool) {
        schoolCode
        atRiskPercentage 
        attendanceAssertionTimeThreshold         
    },
    createSemester(input: $inputSemester) {
        schoolCode
        semester
        name
        startDate
        endDate
    }
}`;

const UPDATE_SCHOOL_INFO = gql`
mutation UpdateSchool($input: UpdateSchoolInput!){  
    updateSchool(input: $input) {
        schoolCode
        name
    }
}`;

const UPDATE_SCHOOL = gql`
mutation Mutation($input: UpdateSchoolInput!) {
    updateSchool(input: $input) {
     schoolCode
    studentsFileUploaded
	professorsFileUploaded
	adminsFileUploaded
	facilitiesFileUploaded
	classesFileUploaded
    }
}
`;

const GET_SIGNED_URL = gql`
mutation Mutation($input: S3Input!) {
    signURL(input: $input) {
        signedRequestUrl
    }
}
`;

const GET_TEMPLATE_LINKS = gql`
query Query {
    getOnBoardingFileLinks{
        howToTemplates
          facilityCSV
          classCSV
          studentCSV
          adminCSV
          professorCSV
    }
}
`;

const UPDATE_SCHOOL_NOTIFICATIONS = gql`
mutation UpdateSchool($input: UpdateSchoolInput!){  
    updateSchool(input: $input) {
        schoolCode
        upcomingClassNotificationMsg
        upcomingClassNotificationTime
        missedClassAlertAutoMsg
        missedClassAlertAutoTime
        missedClassAlertManualMsg
        missedClassAlertManualTime
        atRiskMessagesPool
        atRiskMessagesTime
    }
}`;


const UPDATE_AT_RISK_PERCENTAGE = gql`
mutation UpdateSchool($input: UpdateSchoolInput!){
    updateSchool(input: $input) {
        schoolCode
        atRiskPercentage
    }
}`;

const UPDATE_ATTENDANCE_ASSERTION_THRESHOLD = gql`
mutation UpdateSchool($input: UpdateSchoolInput!){
    updateSchool(input: $input) {
        schoolCode
        attendanceAssertionTimeThreshold
    }
}`;

const UPDATE_MIN_TIME_SPENT = gql`
mutation UpdateSchool($input: UpdateSchoolInput!){
    updateSchool(input: $input) {
        schoolCode
        minTimeSpentInClass
    }
}`;

const CLOSE_SEMESTER = gql`
mutation CloseSemester($input: CloseSemesterInput!){
    closeSemester(input: $input) {
        schoolCode
    }
}`;

const CREATE_SCHOOL = gql`
    mutation CreateSchool($input: CreateSchoolInput!) {
        createSchool(input: $input) {
            schoolCode
        }
    }
`;

const GET_SCHOOL_ADMINS = gql`
    query GetSchoolAdmins ($filter: UserFilterInput) {
        listUsers(filter: $filter) {
            items {
                name
                email
            }
        }
    }
`;

const GET_SCHOOLS = gql`
    query GetSchools($input: SchoolFilterInput, $limit: Int, $nextToken: String! ) {
        listSchools(filter: $input, limit: $limit, nextToken: $nextToken) {
            items {
                schoolCode
                name
                createdAt
            }
            nextToken
            total
        }
    }
`;

const DELETE_SCHOOL = gql`
    mutation DeleteSchool($input: DeleteSchoolInput!) {
        deleteSchool(input: $input) {
            schoolCode
        }
    }
`;

const ACTIVATE_ACCOUNTS = gql`
    mutation ActivateAccounts($input: PopulateCognitoInput!) {
        populateCognito(input: $input) {
            msg
            numUsers
        }
    }
`;

export {
    GET_SCHOOL,
    UPDATE_SCHOOL_INFO,
    UPDATE_SCHOOL_NOTIFICATIONS,
    INIT_INFO,
    CLOSE_SEMESTER,
    CREATE_SEMESTER,
    CREATE_SCHOOL,
    DELETE_SCHOOL,
    UPDATE_SCHOOL,
    GET_SIGNED_URL,
    GET_TEMPLATE_LINKS,
    GET_SCHOOL_ADMINS,
    GET_SCHOOLS,
    ACTIVATE_ACCOUNTS,
    UPDATE_AT_RISK_PERCENTAGE,
    UPDATE_ATTENDANCE_ASSERTION_THRESHOLD,
    UPDATE_MIN_TIME_SPENT
}