import React from "react";
import {Drawer} from "@material-ui/core";
import {createStyles, makeStyles} from '@material-ui/core/styles';
import logo from '../../assets/wise_logo.svg'
import ListOptions from "./ListOptions";
import Typography from "@material-ui/core/Typography";


const drawerWidth = 160;

const useStyles = makeStyles((theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            overflow: "auto",
            height: "100%",
            minHeight: 500
        },
        logoContainer: {
            width: '100%',
            textAlign: 'center',
            marginTop: 36,
            marginBottom: 47
        },
        logo: {
            backgroundImage: `url(${logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '-36px -24px',
            backgroundSize: '186px',
            width: 120,
            height: 50,
            display: 'inline-block',
        },

        gradient: {
            background: 'linear-gradient(-180deg, rgba(41, 174, 230, 0.09) 0%, rgba(40, 173, 229, 0) 100%)',
            borderRadius: 0,
            height: 42,
            width: '100%',
            position: 'absolute',
            left: 0,
            top: 0
        },

        schoolName: {
            textTransform: 'uppercase',
            marginLeft: 16,
            marginBottom: 10,
            letterSpacing: '0.46px'
        },
    }),
);


export default function Sidebar(props) {
    const classes = useStyles();
    const isSuperAdmin = () => props.role === 'SuperAdmin'
    const {school} = props
    return (
        <Drawer className={classes.drawer}
                classes={{paper: classes.drawerPaper}}
                variant="permanent"
                anchor="left">

            <div className={classes.logoContainer}>
                <div className={classes.gradient}></div>
                <span className={classes.logo} onClick={e => props.onLogoClick()}></span>
            </div>

            <div className={classes.schoolName}>
                {!isSuperAdmin() && school && <Typography variant={'caption'} color={'textSecondary'}>{school.name}</Typography>}
            </div>

            <ListOptions role={props.role} studentsAtRisk={props.studentsAtRisk}/>
        </Drawer>
    )
};