import React, { useState, Fragment } from 'react';
import {Card, CardActions, CardContent, Grid, makeStyles} from '@material-ui/core'
import {TitleTypography, LabelTypography} from '../../common/typography';
import {BlueButton, WhiteButton} from "../../common/buttons";
import NotificationBase from "./NotificationBase";
import {GrayTextField} from "../../common/text-fields";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { FlatBlueButton } from '../../common/buttons';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { UPDATE_SCHOOL_NOTIFICATIONS } from "../../../services/school";
import { useMutation } from '@apollo/react-hooks';
import useForm from '../../../utils/hooks/useForm';
import { maxLengthValidator, minLengthValidator, numberLessThanValidator, onlyNumbersIntValidator, requiredFieldValidator, numberGreaterThanValidator } from '../../../utils/validators';
import {ReactComponent as ErrorIcon} from "../../../assets/error.svg";

const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        card: {
            padding: theme.spacing(2),
            background: 'rgb(255, 255, 255)',
            boxShadow: '0px 2px 3px 0px rgba(83, 85, 86, 0.11), 0px 2px 9px 0px rgba(71, 76, 81, 0.09)',
            borderRadius: '6px'
        },
        textMessage: {
            borderRadius: 5,
        },
        separator: {
          borderColor: '#959595',
          borderStyle: 'none none dashed',
          margin: `${theme.spacing(4)}px 0`
        },
        deleteMessageButton: {
          alignSelf: 'center'
        }
    })
);

const validationRules = {
    'upcomingClassNotificationMsg': [
        maxLengthValidator(140, 'Message length exceeded 140 chars.'),
        minLengthValidator(1, 'Message can\'t be empty')
    ],
    'missedClassAlertAutoMsg': [
        maxLengthValidator(140, 'Message length exceeded 140 chars.'),
        minLengthValidator(1, 'Message can\'t be empty')
    ],
    'missedClassAlertManualMsg': [
        maxLengthValidator(140, 'Message length exceeded 140 chars.'),
        minLengthValidator(1, 'Message can\'t be empty')
    ],
    'upcomingClassNotificationMin': [
        requiredFieldValidator('Field is required.'),
        numberLessThanValidator(60, null, 'Minutes must be less than 60.'),
        numberGreaterThanValidator(-1, null, 'Minutes must be at least 0.'),
        onlyNumbersIntValidator('Minutes cannot be a fraction of time.')
    ],
    'upcomingClassNotificationHours': [
        requiredFieldValidator('Field is required.'),
        numberLessThanValidator(24, null, 'Hours must be less than 24.'),
        numberGreaterThanValidator(-1, null, 'Hours must be at least 0'),
        onlyNumbersIntValidator('Hours cannot be a fraction of time')
    ],
    'missedClassAlertAutoMin': [
        requiredFieldValidator('Field is required.'),
        numberLessThanValidator(60, null, 'Minutes must be less than 60.'),
        numberGreaterThanValidator(-1, null, 'Minutes must be at least 0.'),
        onlyNumbersIntValidator('Minutes cannot be a fraction of time.')
    ],
    'missedClassAlertAutoHours': [
        requiredFieldValidator('Field is required.'),
        numberLessThanValidator(24, null, 'Hours must be less than 24.'),
        numberGreaterThanValidator(-1, null, 'Hours must be at least 0.'),
        onlyNumbersIntValidator('Hours cannot be a fraction of time.')
    ],
    'missedClassAlertManualMin': [
        requiredFieldValidator('Field is required.'),
        numberLessThanValidator(60, null, 'Minutes must be less than 60.'),
        numberGreaterThanValidator(-1, null, 'Minutes must be at least 0.'),
        onlyNumbersIntValidator('Minutes cannot be a fraction of time.')
    ],
    'missedClassAlertManualHours': [
        requiredFieldValidator('Field is required.'),
        numberLessThanValidator(24, null, 'Hours must be less than 24.'),
        numberGreaterThanValidator(-1, null, 'Hours must be at least 0.'),
        onlyNumbersIntValidator('Hours cannot be a fraction of time.')
    ]
};

const validWeekDays = {
    'Mon 10:00': 'Mondays',
    'Tue 10:00': 'Tuesdays',
    'Wed 10:00': 'Wednesdays',
    'Thu 10:00': 'Thursdays',
    'Fri 10:00': 'Fridays',
    'Sat 10:00': 'Saturdays',
    'Sun 10:00': 'Sundays'
}

export default function Reminder({school}) {
    const classes = useStyles();

    const {
        upcomingClassNotificationTime: upcomingTime,
        missedClassAlertAutoTime: autoTime,
        missedClassAlertManualTime: manualTime,
        atRiskMessagesPool,
        atRiskMessagesTime,
        ...rest
    } = school

    const [messagesList, setMessagesList] = useState(atRiskMessagesPool.split('#'));
    const [day, setDay] = useState(validWeekDays[atRiskMessagesTime] ? atRiskMessagesTime : 'Mon 10:00');
    const [isEditing, setIsEditing] = useState(false);

    const schoolFormInputs = {
        'upcomingClassNotificationHours': Math.floor(upcomingTime / 60),
        'upcomingClassNotificationMin': upcomingTime % 60,
        'missedClassAlertAutoHours': Math.floor(autoTime / 60),
        'missedClassAlertAutoMin': autoTime % 60,
        'missedClassAlertManualHours': Math.floor(manualTime / 60),
        'missedClassAlertManualMin': manualTime % 60,
        ...rest
    }

    const {
        errors,
        handleInputChange,
        handleOnSubmit,
        hasErrors,
        inputsState: inputs,
        triggerFieldValidation: validate,
        resetForm
    } = useForm(schoolFormInputs, handleFormSubmit, validationRules);

    function inputChange(e){
        setIsEditing(true);
        handleInputChange(e);
        validate(e);
    }

    function deleteMessage(key){
        const msgListCopy = Array.from(messagesList);
        msgListCopy.splice(key, 1)
        setMessagesList(msgListCopy);
        setIsEditing(true);
    }

    function addMessage(){
        const msgListCopy = Array.from(messagesList);
        setMessagesList(msgListCopy.concat(['']));
        setIsEditing(true);
    }

    function handleMessageChange(e){
        const index = e.target.name.split('-')[2];
        const msgListCopy = Array.from(messagesList);
        msgListCopy[index] = e.target.value;
        setMessagesList(msgListCopy);
        setIsEditing(true);
    }

    const changeWeekDay = event => {
        setIsEditing(true);
        setDay(event.target.value);
    };

    function handleFormSubmit(inputsState){
        let validMessages = true;
        messagesList.forEach((msg) => {
            validMessages = validMessages && msg.length <= 140 && msg.trim().length > 0;
        });
        if(validMessages){
            updateSchool().then(() => {
                setIsEditing(false);
            })
        }
    }

    function cancelEdit(){
        setIsEditing(false);
        resetForm();
        setDay(validWeekDays[atRiskMessagesTime] ? atRiskMessagesTime : 'Mon 10:00');
        setMessagesList(atRiskMessagesPool.split('#'));
    }

    const [updateSchool] = useMutation(UPDATE_SCHOOL_NOTIFICATIONS, {
        variables: {
            input: {
                schoolCode: school.schoolCode,
                upcomingClassNotificationMsg: inputs.upcomingClassNotificationMsg,
                upcomingClassNotificationTime: (inputs.upcomingClassNotificationHours * 60) + inputs.upcomingClassNotificationMin,
                missedClassAlertAutoMsg: inputs.missedClassAlertAutoMsg,
                missedClassAlertAutoTime: (inputs.missedClassAlertAutoHours * 60) + inputs.missedClassAlertAutoMin,
                missedClassAlertManualMsg: inputs.missedClassAlertManualMsg,
                missedClassAlertManualTime: (inputs.missedClassAlertManualHours * 60) + inputs.missedClassAlertManualMin,
                atRiskMessagesPool: messagesList.join('#'),
                atRiskMessagesTime: day,
            }
        },
        onCompleted: (data) => console.log('Success updating school')
    });

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <form onSubmit={handleOnSubmit}>
                    <CardContent>
                        <Grid container>
                            <NotificationBase
                                classes={classes}
                                title="UPCOMING CLASS"
                                subTitle="Time before class"
                                id="upcoming-class"
                                name="upcomingClassNotification"
                                msg={inputs.upcomingClassNotificationMsg}
                                minutes={inputs.upcomingClassNotificationMin}
                                hours={inputs.upcomingClassNotificationHours}
                                onChange={inputChange}
                                validate={validate}
                                minError={errors.upcomingClassNotificationMin}
                                hoursError={errors.upcomingClassNotificationHours}
                                msgError={errors.upcomingClassNotificationMsg}/>
                            
                            <Grid item xs={12}>
                              <hr className={classes.separator}/>
                            </Grid>

                            <NotificationBase
                                classes={classes}
                                title="MISSED CLASS (MANUAL ATTENDANCE)"
                                subTitle="Time after class"
                                id="automatic-attendance"
                                name="missedClassAlertManual"
                                msg={inputs.missedClassAlertManualMsg}
                                minutes={inputs.missedClassAlertManualMin}
                                hours={inputs.missedClassAlertManualHours}
                                onChange={inputChange}
                                validate={validate}
                                minError={errors.missedClassAlertManualMin}
                                hoursError={errors.missedClassAlertManualHours}
                                msgError={errors.missedClassAlertManualMsg}/>

                            <Grid item xs={12}>
                              <hr className={classes.separator}/>
                            </Grid>

                            <NotificationBase
                                classes={classes}
                                title="MISSED CLASS (AUTOMATIC ATTENDANCE)"
                                subTitle="Time after class"
                                id="manual-attendance"
                                name="missedClassAlertAuto"
                                msg={inputs.missedClassAlertAutoMsg}
                                minutes={inputs.missedClassAlertAutoMin}
                                hours={inputs.missedClassAlertAutoHours}
                                onChange={inputChange}
                                validate={validate}
                                minError={errors.missedClassAlertAutoMin}
                                hoursError={errors.missedClassAlertAutoHours}
                                msgError={errors.missedClassAlertAutoMsg}/>

                            <Grid item xs={12}>
                              <hr className={classes.separator}/>
                            </Grid>

                            <Grid container justify="space-between" item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TitleTypography>ENCOURAGEMENT MESSAGE</TitleTypography>
                                </Grid>
                                <Grid container item xs={8} alignItems="center">
                                    <LabelTypography>Weekly on &nbsp;</LabelTypography>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                      <Select
                                        id="weekday"
                                        value={day}
                                        onChange={changeWeekDay}
                                        defaultValue={{label: 'Mondays', value: 'Mon 10:00'}}
                                      >
                                          <MenuItem value='Mon 10:00'>Mondays</MenuItem>
                                          <MenuItem value='Tue 10:00'>Tuesdays</MenuItem>
                                          <MenuItem value='Wed 10:00'>Wednesdays</MenuItem>
                                          <MenuItem value='Thu 10:00'>Thursdays</MenuItem>
                                          <MenuItem value='Fri 10:00'>Fridays</MenuItem>
                                          <MenuItem value='Sat 10:00'>Saturdays</MenuItem>
                                          <MenuItem value='Sun 10:00'>Sundays</MenuItem>
                                      </Select>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={12} alignItems="flex-end">
                                    <LabelTypography>One of the following messages will be sent</LabelTypography>
                                </Grid>
                                {messagesList.map((message, i) => (
                                    <Fragment key={i}>
                                        <Grid item xs={11}>
                                            <GrayTextField
                                                id={`encourage-message-${i}`}
                                                name={`encourage-message-${i}`}
                                                fullWidth
                                                label="Message 140 characters"
                                                multiline
                                                rows="1"
                                                variant="outlined"
                                                inputProps={{maxLength: 140}}
                                                className={classes.textMessage}
                                                value={message}
                                                onChange={handleMessageChange}
                                                error={message.length > 140 || message.trim().length === 0}
                                                helperText={message.length > 140 ?
                                                    <label>
                                                        <ErrorIcon/> <span>Message length exceeded 140 chars.</span>
                                                    </label> :
                                                    message.trim().length === 0 ?
                                                        <label>
                                                            <ErrorIcon/> <span>Message can't be empty.</span>
                                                        </label> : null
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={1} className={classes.deleteMessageButton}>
                                              { i > 0 && <FlatBlueButton onClick={() => deleteMessage(i)}><DeleteOutlineIcon /> Delete</FlatBlueButton>}
                                        </Grid>
                                    </Fragment>
                                ))}
                                <FlatBlueButton onClick={() => addMessage()}>+ Add message</FlatBlueButton>
                            </Grid>

                            <Grid item xs={12}>
                              <hr className={classes.separator}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container justify="flex-end" spacing={4}>
                            <Grid item>
                                {isEditing &&
                                    <WhiteButton variant="outlined" color="primary" onClick={() => cancelEdit()} >Cancel</WhiteButton>
                                }
                            </Grid>
                            <Grid item>
                                <BlueButton disabled={hasErrors || !isEditing} color="primary" type="submit">Save</BlueButton>
                            </Grid>
                        </Grid>
                    </CardActions>
                </form>
            </Card>
        </div>
    );

}