import React, { useContext } from 'react';
import { makeStyles, createStyles, Grid } from "@material-ui/core";
import { OnboardingContext } from "../../../../context/onboarding-context";
import { Route, Switch } from 'react-router-dom';
import logo_white from "../../../../assets/logo_wise_white.png";
const useStyles = makeStyles((theme) => 
    createStyles({
        image:{
            height: "100%",
            width:"100%",
            margin: 0
        },
        stepContainer: {
            minHeight: 530
        },
        shape: {
            width: 552,
            height: 100,
            background: "#37b5ff",
            borderRadius: "84% / 100%",
            borderTopLeftRadius: 0,
            paddingTop: "5%",
            borderTopRightRadius: 0
        },
        outlinedCircle: {
            height: 40,
            width: 40,
            color: '#177acc',
            fontSize: 26,
            fontWeight: 900,
            display: 'inline-block',
            cursor: 'pointer'
        },
        filledCircle: {
            height: 40,
            width: 40,
            fontSize: 26,
            fontWeight: 900,
            paddingTop: 10,
            marginTop: -10,
            color: 'white',
            borderRadius: "50%",
            backgroundColor: '#37b5ff',
            display: 'inline-block',
            cursor: 'pointer'
        },
    })
);

export default function SetupContainer(props) {

    const classes = useStyles();
    const {setupStep} = useContext(OnboardingContext);
    const routes = props.routes;
    const schoolId = props.schoolId;
    return(
        <Grid container item xs={12} className={classes.stepContainer}>
            <Grid item xs={5}>
                <img alt="First" className={classes.image} src={props.image}></img>
            </Grid>
            <Grid container item xs={7} className={classes.stepContainer}>
                <Grid item xs={12} className={classes.shape}>
                    <img alt="Wise Logo" height="50"  width="100" src={logo_white} className={classes.logo}></img>
                </Grid>
                <Switch>
                    {
                    routes.map((route, i) => (
                        <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={props => (
                          <route.component schoolId={schoolId} {...props} routes={route.routes} />
                        )}
                      />
                    ))}
                </Switch>
                <Grid item xs={12}>
                    <Grid justify="center" item container xs={12} className={classes.steps} spacing={7}>
                        <Grid item xs={1} style={{verticalAlign: 'middle'}}>
                            <div className={setupStep === 1? classes.filledCircle  : classes.outlinedCircle}>1</div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className={setupStep > 1 && setupStep < 5 ? classes.filledCircle : classes.outlinedCircle}>2</div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className={setupStep > 4? classes.filledCircle : classes.outlinedCircle}>3</div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}