import { useState, useCallback } from "react"


const useForm = (initialState, onSubmitCallback, validationRules = {}) => {

    const [inputsState, setInputsState] = useState(initialState)
    const [errors, setErrors] = useState({});
    const [hasErrors, setHasErrors] = useState(false);
    const [isDirty, setDirty] = useState(false);

    // Utility function to validate a field to all given rules
    const validateInput = useCallback((field, state) => {
        for (const rule in validationRules[field]) {
            // validation rules must be functions which receive the field's name to check and an input state object
            // and returns null or an error message
            // for example a required rule could look like
            // (field, state) => !!state[field] ? return null: `${field} is required` 
            const errorMessage = validationRules[field][rule](field, state);
            if (!!errorMessage) {
                return errorMessage;
            }
        }
        return null;
    }, [validationRules])

    // On submit handler to use on submit event
    const handleOnSubmit = useCallback(event => {
        if (event && event['preventDefault'] !== undefined) {
            event.preventDefault();
        }
        const newErrors = {};
        let submitErrors = false;
        //Validate each field onSubmit
        for (const field in inputsState) {
            const error = validateInput(field, inputsState);
            if (!!error) {
                newErrors[field] = error;
                submitErrors = true;
            }
        }
        // If validation passes onSubmitCallback is called to trigger user code. State is passed to the function.
        // Otherwise set validation errors and set state to error
        if (!submitErrors) {
            setDirty(false);
            onSubmitCallback(inputsState)
        } else {
            setErrors(newErrors)
            setHasErrors(true)
        }
    }, [inputsState, onSubmitCallback, validateInput])

    const handleInputChange = useCallback((event, name = '') => {
        // Set state to dirty on user change to disable button if error exists
        setDirty(true);
        // Handle the usage of datepicker, which doesn't return an event, but a date value
        if (event['target'] !== undefined && event['persist'] !== undefined) {
            event.persist()
            setInputsState(inputs => ({ ...inputs, [event.target.name]: event.target.value }))
        } else {
            setInputsState(inputs => ({ ...inputs, [name]: event }))
        }
    }, []);

    // function to use to trigger validation state changes on each field
    // Likewise, it handles the datepicker case
    const triggerFieldValidation = useCallback((e, fieldName = '', testValue = '') => {
        const field = (e) ? e.target.name : fieldName;
        const value = (e) ? e.target.value: testValue;
        let state = {};
        state[field] = value;
        const errorMessage = validateInput(field, state);
        if (!!errorMessage) {
            setErrors({ ...errors, [field]: errorMessage })
            setHasErrors(true)
            return
        }
        const updatedErrors = { ...errors };
        delete updatedErrors[field];

        if (Object.entries(updatedErrors).length === 0) {
            setHasErrors(false)
        }
        setErrors({ ...updatedErrors });
    }, [errors, validateInput]);

    // function to reset form to its initial state for Cancel buttons
    const resetForm = () => {
        setInputsState(initialState);
        setErrors({});
        setHasErrors(false);
    }

    // expose state of inputs, validation error messages per field, if form has errors, if form is dirty,
    // and handlers for different events
    return {
        inputsState,
        errors,
        hasErrors,
        isDirty,
        handleOnSubmit,
        handleInputChange,
        triggerFieldValidation,
        resetForm
    }
}

export default useForm;