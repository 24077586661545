import React from 'react';
import {Card, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(theme => ({
    card: {
        padding: '12px 15px',
        height: '100%'
    },

    determinate: {
        backgroundColor: theme.palette.common.white,
        height: 24,
        borderRadius: 4,
        border: '1px solid #e4e4eb',
        boxShadow: 'inset 0px 0.5px 4px 0px rgba(96, 97, 112, 0.32)'
    },
    barTop: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 7,
        padding: '0 4px'
    },

    barBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 8,
        padding: '0 4px'
    },
    dataContainer: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        marginBottom: 35,
        padding: 15
    },
}));

const LoadingContainer = (props) => {
    return(<Paper className={props.class}>
        <Grid container alignItems='center'>
            <Typography variant={'h2'} color={'primary'}><strong>Loading...</strong></Typography>
        </Grid>
    </Paper>)
}

const CourseProgress = (props) => {
    const classes = useStyles();
    const data = props && props.data

    const [progressData, setProgressData] = React.useState(null);

    React.useEffect(() => {
        setProgressData(data);
    }, [data]);

    return (
        progressData ?
        <Card classes={{root: classes.card}}>
            <Grid container spacing={1} direction={'column'} style={{height: '100%'}}>
                <Grid item>
                    <Typography variant={'h2'} color={'primary'}><strong>Course Progress</strong></Typography>
                </Grid>

                <Grid container item justify={'center'} alignItems={'center'} xs>
                    <Grid item xs={10}>
                        <div className={classes.barTop}>
                            <Typography variant={'caption'}>{`${progressData.courseEventsToDateCount}/${progressData.maxCourseEventsCount}`} classes</Typography>
                        </div>

                        <LinearProgress
                            classes={{
                                barColorSecondary: classes.barSecondary,
                                determinate: classes.determinate
                            }}
                            variant="determinate" value={progressData.courseProgressPercentage} color={'secondary'}/>

                        <div className={classes.barBottom}>
                            <Typography variant={'caption'}>{progressData.courseProgressPercentage}% course complete</Typography>
                            <Typography variant={'caption'}>{progressData.courseEventsPending} classes to go</Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Card> : <LoadingContainer class={classes.dataContainer}></LoadingContainer>
    );
};

export default CourseProgress;