function formatErrors(ajvErrors) {
    let errorsObject = {}
    for (const err of ajvErrors) {
        const numberPattern = /\d+/g;
        const num = parseInt(err.dataPath.match(numberPattern)) + 2;
        const field = err.keyword === 'required' ? err.params.missingProperty : err.dataPath.split('/')[2];
        const message = err.keyword === 'required' ? 'Property is required.' : err.message;
        if(Object.keys(errorsObject).indexOf(err.schemaPath) < 0 ) {
            errorsObject[err.schemaPath] = {
                lines: [num],
                messages: [message],
                field,
                keyword: err.keyword
            }
        } else {
            errorsObject[err.schemaPath].lines.push(num);
            errorsObject[err.schemaPath].messages.push(message);
        }  
    }
    return showErrors(errorsObject)
}

function showErrors(errorsObject) {
    let formatedErrros = [];
    for(const prop in errorsObject) {
        const typeError = errorsObject[prop]
        const lines = typeError.lines.join(', ');
        const field = typeError.field;
        const isSingular = typeError.lines > 1 ? 'Line': 'Lines'
        const set = new Set(typeError.messages);
        for(const message of set) {
                formatedErrros.push(`${isSingular} ${lines}: ${field}: ${message}`);
        }
    }

    return formatedErrros;
}

module.exports = {
    formatErrors
}