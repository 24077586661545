import React, {useEffect, useState} from 'react';
import { Grid, makeStyles, Breadcrumbs, Link, Typography, Icon, Paper } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import StudentInformationCard from './_StudentInformationCard';
import AttendancePercentCard from "./_AttendancePercentCard";
import StudentCoursesTable from "./_StudentCoursesTable";
import { useHistory } from 'react-router-dom';
import { GET_STUDENT_INFO } from '../../../services/students'
import { useQuery } from "@apollo/react-hooks";
import {isEmpty} from 'lodash'

const PAGE_SIZE = 10

const LoadingContainer = (props) => {
    return(<Paper className={props.class}>
        <Grid container alignItems='center'>
            <Typography variant={'h2'} color={'primary'}><strong>Loading...</strong></Typography>
        </Grid>
    </Paper>)
}

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
    },

    endLink: {
        color: theme.palette.info.gray
    },
    dataContainer: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        marginBottom: 35,
        padding: 15
    },
    breadcrumbSeparator: {
        height: 10,
        width: 10,
        color: theme.palette.info.gray,
        marginBottom: 2
    },
    titleContainer: {
        display: 'flex'
    },
    title: {
        padding: '33px 0 12px 16px'
    },
    tableContainer: {
        marginTop: 30
    },
    warningAttendance: {
        color: theme.palette.common.white,
        height: 22,
        width: 132,
        margin: '38px 0 12px 16px',
        borderRadius: 5,
        textAlign: 'center',
        fontWeight: 900,
        backgroundColor: theme.palette.warning.main,

    },
    warningDescription: {
        fontWeight: 900
    },
    warningIcon: {
        color: theme.palette.common.white,
        fontSize: 13,
        lineHeight: '18px',
    },
}));

export default function StudentDetail(props) {
    const school = props.school
    const classes = useStyles();
    const history = useHistory();
    const location = decodeURIComponent(history && history.location.pathname)
    const userEmail = location.split('/')[2]
    const [studentInfo, setStudentInfo] = useState({})
    const separator = <ArrowForwardIosIcon color={'disabled'} className={classes.breadcrumbSeparator}/>;

    const goToCourseDetail = (student) => {
        history.push(`/courses/${encodeURIComponent(student.courseId)}`);
    };

    const studentInfoResult = useQuery(GET_STUDENT_INFO, {
        variables: {
            email: userEmail
        },
        onCompleted: () => {
            const studentData = studentInfoResult && studentInfoResult.data && studentInfoResult.data.getStudentInfo
            setStudentInfo(studentData)
        }
    });

    return(
        <Grid container direction='column'>
            <Grid item>
                <Breadcrumbs separator={separator} aria-label="breadcrumb">
                    <Link to="/students" className={classes.link}>
                        <Typography variant={'body2'} color={'secondary'}>
                            All Students
                        </Typography>
                    </Link>
                    <Typography variant={'body2'} className={classes.endLink}>Student details</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item className={classes.titleContainer}>
            {!isEmpty(studentInfo) && <Typography variant={'h1'} color={'primary'} className={classes.title}>{studentInfo.student.name}</Typography>}
                {!isEmpty(studentInfo) && studentInfo.semesterProgress.attendancePercentage <= school.atRiskPercentage && <div className={classes.warningAttendance}>
                    <Icon className={classes.warningIcon}>warning</Icon> 
                    <Typography variant={'caption'}>
                        <strong>Low attendance</strong>
                        </Typography>
                </div>}
            </Grid>
            {isEmpty(studentInfo) ? <LoadingContainer class={classes.dataContainer}></LoadingContainer> : <Grid item container spacing={2}>
                <Grid item xs={6}>
                    <StudentInformationCard studentData={studentInfo.student}/>
                </Grid>

                <Grid item xs={6}>
                    <AttendancePercentCard semesterData={studentInfo.semesterProgress} school={school} role={props.role}></AttendancePercentCard>
                </Grid>
            </Grid> }
            <Grid item className={classes.tableContainer}>
                <StudentCoursesTable
                 userEmail={userEmail}
                 onNameClick={student=> goToCourseDetail(student)} 
                />
            </Grid>
        </Grid>
    )
}