import React, { useContext } from 'react';
import { Grid, makeStyles, createStyles } from '@material-ui/core';
import { TitleTableTypography, InformationTypography } from '../../../common/typography';
import { OnboardingContext } from "../../../../context/onboarding-context";

const useStyles = makeStyles((theme) => 
    createStyles({
        infoContainer: {
            textAlign: 'left',
            padding: '20px 70px'
        },
        title: {
            fontWeight: 900,
            paddingTop:10,
            color: '#585757'
        }
    })
);

export default function SchoolInformation(props) {
    const {schoolName, schoolId} = useContext(OnboardingContext)
    const classes = useStyles();
    return(
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <TitleTableTypography>Review your school information</TitleTableTypography>
            </Grid>
            <Grid className={classes.infoContainer} justify="space-between" alignItems="flex-start" item container xs={12}>
                <Grid item xs={12}>
                    <InformationTypography className={classes.title}>School Name</InformationTypography>
                </Grid>
                <Grid item xs={12}>
                    <InformationTypography>{schoolName}</InformationTypography>
                </Grid>
                <Grid item xs={12}>
                    <InformationTypography className={classes.title}>School ID</InformationTypography>
                </Grid>
                <Grid item xs={12}>
                    <InformationTypography>{schoolId}</InformationTypography>
                </Grid>
            </Grid>
        </Grid>
    )
}