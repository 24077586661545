import React, {useState} from 'react';
import {Container, Grid, makeStyles} from '@material-ui/core';
import CurrentPeriod from './CurrentPeriod';
import GeneralInformation from "./GeneralInformation";
import Notifications from "./Notifications";
import {BodyTypography, HeaderTypography} from "../../common/typography";
import {useQuery} from '@apollo/react-hooks';
import {GET_SCHOOL} from "../../../services/school";

const useStyles = makeStyles(theme => ({
        rootContainer: {
            padding: 0
        },
        container: {
            marginTop: '5%'
        }
    })
);

export default function SchoolSettings(props) {

    const classes = useStyles();
    const [school, setSchool] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useQuery(GET_SCHOOL, {
        variables: {schoolCode: props.schoolCode},
        onCompleted: data => {
            dataReady(data)
        },
        fetchPolicy: 'network-only'
    });

    function dataReady(data) {
        setSchool(data.getSchool);
        setIsLoading(false);
    }

    return (
        !isLoading &&
        <Container classes={{root: classes.rootContainer}}>
            <section>
                <Grid container direction="column" spacing={2} className={classes.container}>
                    <Grid item xs={12}><HeaderTypography variant="h1">School Settings</HeaderTypography></Grid>
                    <Grid item xs={12}><BodyTypography>Edit your current information</BodyTypography></Grid>
                </Grid>
            </section>
            <section>
                <Grid container spacing={2} className={classes.container}>
                    <GeneralInformation school={school}/>
                </Grid>
            </section>
            <section>
                <Grid container spacing={2} className={classes.container}>
                    <CurrentPeriod school={school}/>
                </Grid>
            </section>
            <section>
                <Grid container spacing={2} className={classes.container}>
                    <Notifications {...school}/>
                </Grid>
            </section>
        </Container>
    );
}