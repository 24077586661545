import React, {Fragment} from 'react'
import {Grid, makeStyles} from "@material-ui/core";
import {SectionTypography} from "../../common/typography";
import Reminder from "./Reminder";

const useStyles = makeStyles(theme => ({
        container: {
            marginTop: 15
        }
    })
);

function Notifications({schoolCode,
        upcomingClassNotificationMsg,
        upcomingClassNotificationTime,
        missedClassAlertAutoMsg,
        missedClassAlertAutoTime,
        missedClassAlertManualMsg,
        missedClassAlertManualTime,
        atRiskMessagesPool,
        atRiskMessagesTime
    }) {
    const classes = useStyles();
    const notificationsInput = {
        schoolCode,
        upcomingClassNotificationMsg,
        upcomingClassNotificationTime,
        missedClassAlertAutoMsg,
        missedClassAlertAutoTime,
        missedClassAlertManualMsg,
        missedClassAlertManualTime,
        atRiskMessagesPool,
        atRiskMessagesTime
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <SectionTypography>Notifications and reminders</SectionTypography>
            </Grid>
            <Grid item xs={12} className={classes.container}>
                <Reminder school={notificationsInput}/>
            </Grid>
        </Fragment>
    );
}

export default Notifications;