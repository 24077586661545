import Button from "@material-ui/core/Button";
import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';

import { ProfileNamedEntry } from "./_CardNamedEntry";

const useStyles = makeStyles(theme => ({
  root: {
    width: 400
  },

  content: {
    '&&': {
      padding: '16px 18px 22px',
    }
  },

  title: {
    marginBottom: 17,
  },

  infoRow: {
    marginTop: 15
  },
}));

const PhoneContent = ({ number, onEdit }) => {
  return (
    <Grid container alignItems={'flex-start'}>
      <Grid item xs={8}><Typography variant={"h5"}>{number}</Typography></Grid>
      <Grid item xs={4}>
        <Link component={'button'} color={'secondary'} onClick={onEdit} variant={"h5"}>Edit</Link>
      </Grid>
    </Grid>
  );
};

export const ProfileMyInformation = ({ name, email, phone, onEditPhone }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Grid container>

          <Grid item className={classes.title} xs={12}>
            <Typography variant={'h4'} color={'primary'}>MY INFORMATION</Typography>
          </Grid>

          <Grid item className={classes.infoRow} xs={12}>
            <ProfileNamedEntry name={'Name'}>{name}</ProfileNamedEntry>
          </Grid>

          <Grid item className={classes.infoRow} xs={12}>
            <ProfileNamedEntry name={'Email'}>{email}</ProfileNamedEntry>
          </Grid>

          <Grid item container className={classes.infoRow} xs={12}>
            <ProfileNamedEntry name={'Phone'}>
              <PhoneContent number={phone} onEdit={onEditPhone} />
            </ProfileNamedEntry>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProfileMyInformation;
