module.exports = {
    schemas : {
        student: {
            "type":"array",
            "items": {
            "type": "object",
            "properties": {
                "code": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "email": {
                    "type": "string",
                    "format": "email",
                    "isNotEmpty": true,
                    "isEnclosed": false,
                    "errorMessage": "Property should not be empty and be enclosed in double quotes."
               },
                "name": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "phone": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "action": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "isAction": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes.",
                        "isAction": "Property should be ADD, UPDATE or DELETE"
                    }
               },
            },
            "required": ["code", "email", "name", "phone", "action"]
            }
        },
        professor: {
            "type":"array",
            "items": {
            "type": "object",
            "properties": {
                "code": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "email": {
                    "type": "string",
                    "format": "email",
                    "isNotEmpty": true,
                    "isEnclosed": false,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty and be enclosed in double quotes.",
                        "format": "Property should have email format."
                    }
               },
                "name": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "phone":{
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "academic_rank": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "action": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "isAction": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
            },
            "required": ["code", "email", "name", "phone", "academic_rank", "action"]
            }
        },
        admin: {
            "type":"array",
            "items": {
            "type": "object",
            "properties": {
                "code": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "email": {
                    "type": "string",
                    "format": "email",
                    "isNotEmpty": true,
                    "isEnclosed": false,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty and be enclosed in double quotes.",
                        "format": "Property should have email format."
                    }
               },
                "name": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "phone": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
               "action": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "isAction": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes.",
                        "isAction": "Property should be ADD, UPDATE or DELETE"
                        }
                    },
                },
            "required": ["code", "email", "name", "phone", "action"]
            }
        },
        class_schema: {
            "type":"array",
            "items": {
            "type": "object",
            "required": ["code", "name", "facility_code", "graded", "start_date", "end_date", "schedule", "student_emails", "professor_email", "professor_phone", "action"],
            "properties": {
                "code": {
                     "type": "string",
                     "isNotEmpty": true,
                     "isEnclosed": true,
                     "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
                },
                "name": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "facility_code": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "graded": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "professor_email": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": false,
                    "errorMessage": "Property should not be empty and be enclosed in double quotes."
               },
                "professor_phone": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
               },
                "action": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "isAction": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes.",
                        "isAction": "Property should be ADD, UPDATE or DELETE"
                    }
               },
                "start_date": { 
                    "type": "string",
                    "format": "date",
                    "isNotEmpty": true,
                    "isEnclosed": false,
                    "errorMessage": {
                        "format":'Property should match format \'date\' (yyyy-mm-dd).',
                        "isNotEmpty": 'Property should not be empty and be enclosed in double quotes.'
                    },
                    "example": "219–12–28",
                },
                "end_date": {
                    "type": "string",
                    "format": "date",
                    "isNotEmpty": true,
                    "isEnclosed": false,
                    "errorMessage": {
                        "format":'Property should match format \'date\' (yyyy-mm-dd).',
                        "isNotEmpty": 'Property should not be empty and be enclosed in double quotes.'
                    },
                    "example": "219–12–28",
                },
                "schedule": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": false,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty and be enclosed in double quotes.",
                        "pattern": 'Property should match \'schedule\' format: (Mo|Tu|We|Th|Fr|Sa|Su)_00:00_23:00.',
                    },
                    "pattern": "^((Mo|Tu|We|Th|Fr|Sa|Su)_([0-1]?[0-9]|2[0-3]):[0-5][0-9]_([0-1]?[0-9]|2[0-3]):[0-5][0-9])(#(Mo|Tu|We|Th|Fr|Sa|Su)_([0-1]?[0-9]|2[0-3]):[0-5][0-9]_([0-1]?[0-9]|2[0-3]):[0-5][0-9])*$",
                },
                "student_emails": {
                    "type": "string",
                    "pattern": "^[^#]+(#[^#]+)*$",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "pattern": 'Property should match format \'email\'#\'email\'.',
                        "isEnclosed": "Property should be enclosed in double quotes."

                    }
                }
            },
            "errorMessage": {
                "properties": {
                "start_date": 'Property should match format \'date\' (yyyy-mm-dd).',
                },
            },
            }
        },
        facility: {
            "type":"array",
            "items": {
            "type": "object",
            "properties": {
                "code": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
                },
                "name": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
                },
                "lat": {
                    "type": "number",
                    "errorMessage": {
                        "type": "Property should be a number."
                    }
                },
                "lon": {
                    "type": "number",
                    "errorMessage": {
                        "type": "Property should be a number."
                    }
                },
                "rad": {
                    "type": "number",
                    "errorMessage": {
                        "type": "Property should be a number."
                    }
                },
                "floor": {
                    "type": "number",
                    "errorMessage": {
                        "type": "Property should be a number."
                    }
                },
                "type": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes."
                    }
                },
                "action": {
                    "type": "string",
                    "isNotEmpty": true,
                    "isEnclosed": true,
                    "isAction": true,
                    "errorMessage": {
                        "isNotEmpty": "Property should not be empty.",
                        "isEnclosed": "Property should be enclosed in double quotes.",
                        "isAction": "Property should be ADD, UPDATE or DELETE"
                    }
                }
            },
            "required": ["code", "name", "lat", "lon", "rad", "floor", "type", "action"]
            }
        }
    } 
};