import React, { createContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import image_5 from "../assets/onboarding/image_5.png";
import image_6 from "../assets/onboarding/image_6.png";
import facilities from "../assets/onboarding/facilities.png";
import administrators from "../assets/onboarding/administrators.png";
import courses from "../assets/onboarding/courses.png";
import professors from "../assets/onboarding/professors.png";
import students from "../assets/onboarding/students.png";
import moment from "moment";

export const OnboardingContext = createContext({
    step: '',
    setOnboardingStep: (step) => {},
    setupStep: 0,
    semesterCode: '',
    setSemesterId: (val) => {}, 
    image: null,
    schoolName: '',
    schoolId: '',
    termInfo: null,
    setStartDate: (date) => {},
    setEndDate: (date) => {},
    setTermName: (name) => {}, 
    setSetupStep: (step) => {},
    setPercentageAndTolerance: (num, num2) => {},
    uploadSteps: {},
    setUploadStepsObject: (obj) => {},
    uploadMode: '',
    setNewUploadMode: (val) => {},
    setNewSchoolName: (name) => {},
    setNewSchoolId: (id) => {},
    updateInfoTerm: (val) => {}
});

export default props => {
    const formTerm = {
        start: moment(new Date()).format('YYYY-MM-DD'),
        name: '',
        end: moment(new Date()).format('YYYY-MM-DD'),
        minimumPercentage: 0,
        tolerance: 0
    };
    const formUploadSteps = {
        facilities: false,
        students: false,
        professors: false,
        courses: false,
        administrators: false
    } 
    const [onboardingStep, setOnboardingStep] = useState('introduction');
    const [schoolName, setSchoolName] = useState('cascsew');
    const [schoolId, setSchoolId] = useState('');
    const [setupStep, setSetupStep] = useState(1);
    const [image, setImage] = useState(image_5);
    const [semesterId, setSemesterId] = useState('');
    const [termInfo, setInfoTerm] = useState(formTerm);
    const [uploadSteps, setUploadSteps] = useState(formUploadSteps);
    const [uploadMode, setUploadMode] = useState('before');
    let history = useHistory();

    const setNewStep = step => {
        setOnboardingStep(step);
        if(step === 'school-setup'){
            setNewSetupStep(1);
        }
    };

    const setNewUploadMode = val =>{
        setUploadMode(val);
    };  

    const setNewName = newVal => {
        setInfoTerm({...termInfo, name: newVal});
    };

    const setNewSchoolName = name => {
        setSchoolName(name);
    }

    const setNewSchoolId = id => {
        setSchoolId(id);
    };

    const setStartDate = date => {
        setInfoTerm({...termInfo, start: date});

    };

    const setEndDate = date => {
        setInfoTerm({...termInfo, end: date});
    };

    const setPercentageAndTolerance = (min, tol) => {
        setInfoTerm({...termInfo, minimumPercentage: min, tolerance: tol});
    };

    const initInfoTerm = (val) => {
        setInfoTerm(val);
    };
    
    const setUploadStepsObject = steps => {
        setUploadSteps(steps);
    } 

    const setNewSetupStep = step => {
        setSetupStep(step);
        switch(step) {
            case 1:
                setImage(image_5);
                history.push('/school-setup/info');
                break;
            case 2:
                setImage(image_6);
                history.push("/school-setup/term");
                break;
            case 3:
                setImage(image_6);
                history.push('/school-setup/attendance')
                break;
            case 4:
                setImage(image_6);
                history.push('/school-setup/attendance-time')
                break;
            case 5:
                history.push('/school-setup/upload')
                setImage(facilities);
                break;
            case 6:
                setImage(students);
                break;
            case 7:
                setImage(professors);
                break;
            case 8:
                setImage(courses);
                break;
            case 9:
                setImage(administrators);
                break;
            default:
                break;
        }
    };

    return(
        <OnboardingContext.Provider
            value={
                {
                    step:onboardingStep,
                    setOnboardingStep: setNewStep, 
                    setupStep: setupStep, 
                    setSetupStep: setNewSetupStep, 
                    image: image,
                    termInfo: termInfo,
                    setTermName: setNewName,
                    setStartDate,
                    setEndDate,
                    setPercentageAndTolerance,
                    schoolName,
                    schoolId,
                    setNewSchoolName: setNewSchoolName,
                    uploadSteps,
                    uploadMode,
                    setNewUploadMode,
                    setUploadStepsObject,
                    setNewSchoolId,
                    updateInfoTerm: initInfoTerm,
                    semesterCode: semesterId,
                    setSemesterId
                }
            }
        >
            {props.children}
        </OnboardingContext.Provider>
    )
};