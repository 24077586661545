import gql from 'graphql-tag';

const GET_PROFESSORS = gql`
    query ($limit: Int, $token: String){
        getProfessorsTable (limit: $limit, nextToken: $token) {
            items {
                name
                email
                summary {
                    totalCourse
                    totalPercentage
                    totalStudents
                    totalLowAttendanceStudents
                    lowAttendanceStudentsPercentage
                }
            }
            nextToken
            totalProfessors
        }
    }`;

const GET_PROFESSOR_INFO = gql`
    query ($email: String!){
        getProfessor(email: $email){
            email
            name
        }
    }
`

const downloadProfessorsCSV = gql`
    mutation {
        professorsCSV
    }
`

const GET_PROFESSORS_BANNERS = gql`
    query Query{
        professorStudentsBanner{
            numberOfClasses
            numberOfStudentsEnrolled
            numberOfMyStudentsAtRisk
            percentageOfMyStudentsAtRisk
            percentageOfOverallStudentsAtRisk
        }
    }
`

const GET_STUDENTS_TABLE = gql`
    query Query{
        myStudentsTable{
            studentsAtRiskPercentage
            students {
                name
                email
                classEnrolled
                attendancePercentage
                absences
            }
        }
    }
`

const GET_ADMIN_STUDENTS_TABLE = gql`
    query Query{
        adminStudentsTable{
            studentsEnrolled
            students {
                name
                email
                totalClassesEnrolled
                attendancePercentage
                absences
            }
        }
    }
`

const downloadProfessorsStudentsCSV = gql `
    mutation {
        professorStudentsTableCSV
    }
`

const GET_PROFESSORS_CLASSES = gql`
query ($input: ProfessorClassesTableInput!){
    professorClassesTable(input: $input){
        classes {
            name
            code
            attendanceConfig
            attendancePercentage
            enrolledStudents
            studentsWithLowAttendance
        }
	    totalCourses
	    nextToken
    }
}

`

const downloadProfessorsClassesCSV = gql`
mutation ProfessorClassesTableCSV($professorEmail: AWSEmail!) {
    professorClassesTableCSV(professorEmail: $professorEmail)
}
`

const GET_PROFESSORS_ATTENDANCE = gql `
query Query($professorEmail: AWSEmail!){
    professorAttendancePerWeek(professorEmail: $professorEmail){
        x
        y
    }
    professorAttendancePerMonth(professorEmail: $professorEmail){
        x
        y
    }
}
`



export {
    GET_PROFESSORS,
    downloadProfessorsCSV,
    GET_PROFESSORS_BANNERS,
    GET_STUDENTS_TABLE,
    downloadProfessorsStudentsCSV,
    GET_ADMIN_STUDENTS_TABLE,
    GET_PROFESSOR_INFO,
    GET_PROFESSORS_CLASSES,
    downloadProfessorsClassesCSV,
    GET_PROFESSORS_ATTENDANCE
};