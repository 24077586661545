import gql from 'graphql-tag';

const GET_STUDENT_INFO = gql`query GetStudentInfo($email: AWSEmail!){
  getStudentInfo(email: $email){
      student {
          email
          name
          nickname
      }
      semesterProgress {
          totalCourseEventsAttended
          maxCourseEventsToDate
          attendancePercentage
      }
  }
}`

const LIST_STUDENT_CLASSES = gql`query ListStudentClasses($email: AWSEmail!, $token: String){
  listStudentClasses(email: $email, nextToken: $token){
      courses {
        name
        courseId
        generalAttendancePercentage
        attendanceConfiguration
        atRisk
        professor
        professorEmail
      }
      totalCoursesCount
      nextToken
  }
}`

const listStudentClassesCSV = gql`
  mutation ListStudentClassesCSV($email: AWSEmail!) {
    listStudentClassesCSV(email: $email)
  }
`

const GET_TOTAL_STUDENTS_BANNER = gql`
  query {
    totalStudentsBanner {
      numberOfClasses
      numberOfStudentsEnrolled
      numberOfStudentsAtRisk
      percentageOfStudentsAtRisk
    }
  }
`

const adminStudentsTableCSV = gql`
  mutation {
    adminStudentsTableCSV
  }
`

const GET_STUDENTS_AT_RISK_CHART = gql`
  query Query{
    studentsAtRiskPerWeek{
            x
            y
    }
    studentsAtRiskPerMonth{
            x
            y
    }
  }
`

export {
  GET_STUDENT_INFO,
  GET_TOTAL_STUDENTS_BANNER,
  GET_STUDENTS_AT_RISK_CHART,
  LIST_STUDENT_CLASSES,
  listStudentClassesCSV,
  adminStudentsTableCSV
}