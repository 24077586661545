import React from 'react';
import { Grid } from '@material-ui/core';
import {  makeStyles, createStyles } from "@material-ui/core/styles";
import { TitleTableTypography, OnboardingTypography, InstructionsTypography, TitleTypography } from '../../../common/typography';
import logo from '../../../../assets/wise_logo.svg'
import image_1 from "../../../../assets/onboarding/image_1.png";
import image_2 from "../../../../assets/onboarding/image_2.png";
import image_3 from "../../../../assets/onboarding/image_3.png";
import image_4 from "../../../../assets/onboarding/image_4.png";
import { ReactComponent as UploadIcon } from "../../../../assets/upload.svg";
import { ReactComponent as DownloadTemplateIcon } from "../../../../assets/download_template.svg";


const useStyles = makeStyles((theme) => 
    createStyles({
        imageContainer: {
            paddingTop: 60,
        },
        titleContainer: {
            maxHeight: 75
        },
        descriptionContainer: {
            width: '95%',
            textAlign: 'center',
            paddingBottom: "30%",
            paddingTop: "10%"
        },
        image:{
            height: "100%",
            width:"100%",
            margin: 0
        },
        stepContainer: {
            minHeight: 530,
        },
        stepContainer2: {
            minHeight: 530,
            padding: 40
        },
        logo: {
            height: 150,
            width: 300
        },
        outlinedCircle: {
            height: 10,
            width: 10,
            borderRadius: "50%",
            border: "1.5px solid #37b5ff",
            display: 'inline-block',
            cursor: 'pointer'
        },
        filledCircle: {
            height: 12,
            width: 12,
            borderRadius: "50%",
            backgroundColor: '#37b5ff',
            display: 'inline-block',
            cursor: 'pointer'
        },
        icon: {
            height: 23,
            width: 20,
            cursor: 'pointer'
        },
        iconContainer: {
            display: 'flex',
            textAlign: 'left',
            paddingBottom: '5%',
        },
        iconDescription: {
            textAlign: 'left',
            display:'table-cell',
            verticalAlign: 'middle',
            cursor: 'pointer'
        },
        files: {
            paddingLeft:  20,
            height: 155
        },
        title: {
            fontSize: 24
        }
    })
);
export default function IntroductionBase(props) {
    const classes = useStyles();

    const info = {
        1: {
            title: 'Welcome to Wise',
            description: "Wise provides administrators with complete attendance data, tools and alerts about at-risk students.",
            image: image_1
        },
        2: {
            title: 'Everything at a glance',
            description: 'See all courses, professors and related information to the current school term. ',
            image: image_2
        },
        3: {
            title: 'Powerful analytics',
            description: 'See attendance for every student and class, and instantly identify challenges.',
            image: image_3
        },
        4: {
            title: 'Set your school information',
            description: 'Download the information files to  configure the Wise platform.',
            instructions1: 'Before we start, please download and enter your school’s information into the templates below. ',
            instructions2: 'You can open the files in Excel or similar program to edit them. Once you are ready you can start.',
            image: image_4
        }
    }

    const downloadClick = (type) => {
        window.open(props.onboardingLinks[type]);
    }

    return(
        props.step < 4 ? 
        <Grid container className={classes.stepContainer}>
            <Grid item xs={7}>
                <img alt="First" className={classes.image} src={info[props.step].image}></img>
            </Grid>
            <Grid container direction="column" item xs={5} className={classes.stepContainer}>
                <Grid item xs className={classes.imageContainer}>
                    <img alt="Wise Logo" src={logo} className={classes.logo}></img>
                </Grid>
                <Grid item xs className={classes.titleContainer}>
                    <TitleTableTypography>{info[props.step].title}</TitleTableTypography>
                </Grid>
                <Grid item xs className={classes.descriptionContainer}>
                    <OnboardingTypography>{info[props.step].description}</OnboardingTypography>
                </Grid>
                <Grid justify="center" item container xs className={classes.steps}>
                    <Grid item xs={1}>
                        <div onClick={e =>props.onChangeStep(1)} className={props.step === 1? classes.outlinedCircle : classes.filledCircle}></div>
                    </Grid>
                    <Grid item xs={1}>
                        <div onClick={e =>props.onChangeStep(2)} className={props.step === 2? classes.outlinedCircle : classes.filledCircle}></div>
                    </Grid>
                    <Grid item xs={1}>
                        <div onClick={e =>props.onChangeStep(3)} className={props.step === 3? classes.outlinedCircle : classes.filledCircle}></div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid> :
        <Grid container className={classes.stepContainer}>
            <Grid item xs={6}>
                <img alt="First" className={classes.image} src={info[props.step].image}></img>
            </Grid>
            <Grid alignItems="center" container item xs={6} className={classes.stepContainer2}>
                <Grid item xs={12}>
                    <TitleTableTypography className={classes.title}>{info[props.step].title}</TitleTableTypography>
                </Grid>
                <Grid item xs={12}>
                    <OnboardingTypography>{info[props.step].description}</OnboardingTypography>
                </Grid>
                <Grid item xs={12}>
                    <InstructionsTypography>{info[props.step].instructions1}</InstructionsTypography>
                </Grid>
                <Grid item container xs={12} className={classes.files}>
                    <Grid item xs={6} className={classes.iconContainer} onClick={e => downloadClick('facilityCSV')}>
                        <DownloadTemplateIcon className={classes.icon}/> <TitleTypography className={classes.iconDescription}>School Facilities</TitleTypography>
                    </Grid>
                    <Grid item xs={6} className={classes.iconContainer} onClick={e => downloadClick('studentCSV')}>
                        <DownloadTemplateIcon className={classes.icon}/> <TitleTypography className={classes.iconDescription}>Students</TitleTypography>
                    </Grid>
                    <Grid item xs={6} className={classes.iconContainer} onClick={e => downloadClick('professorCSV')}>
                        <DownloadTemplateIcon className={classes.icon}/> <TitleTypography className={classes.iconDescription}>Professors</TitleTypography>
                    </Grid>
                    <Grid item xs={6} className={classes.iconContainer} onClick={e => downloadClick('adminCSV')}>
                        <DownloadTemplateIcon className={classes.icon}/> <TitleTypography className={classes.iconDescription}>Wise platform <br/> administrators</TitleTypography>
                    </Grid>
                    <Grid item xs={6} className={classes.iconContainer} onClick={e => downloadClick('classCSV')}>
                        <DownloadTemplateIcon className={classes.icon}/> <TitleTypography className={classes.iconDescription}>Courses</TitleTypography>
                    </Grid>
                    <Grid item xs={6} className={classes.iconContainer} onClick={e => downloadClick('howToTemplates')}>
                        <DownloadTemplateIcon className={classes.icon}/> <TitleTypography className={classes.iconDescription}>How to fill your <br/> templates</TitleTypography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <InstructionsTypography>{info[props.step].instructions2}</InstructionsTypography>
                </Grid>
            </Grid>
        </Grid> 
    )
}