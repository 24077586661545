import React, { useState, useEffect } from 'react';
import {Doughnut} from 'react-chartjs-2';
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DoughnutWithText from '../../graphics/DoughnutWithText';

const options = {
    tooltips: {enabled: false},
    cutoutPercentage: 83,
    maintainAspectRatio: false
};

const useStyles = makeStyles(theme => ({

    item: {
        marginTop: 3
    },

    card: {
        padding: "23px 50px",
        minHeight: 171,
        height: '100%',
        position: 'relative'
    },

    donutContainer: {
        position: 'absolute',
        zIndex: 0,
        marginTop: -12,
        marginLeft: -8,
        height: 140,
        width: 140
    },

    chartCenter: {
        position: 'absolute',
        zIndex: 100,
        width: 126,
        height: 126,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },

    attendancePercent: {
        fontFamily: 'Avenir',
        fontSize: 28,
        color: theme.palette.text.secondary,
        lineHeight: '28px',
        display: 'flex',
        fontWeight: 900
    },

    attendancePercentCaption: {
        fontFamily: 'Avenir',
        fontSize: 13,
        color: theme.palette.text.secondary,
        lineHeight: '14px',
        letterSpacing: 0,
        display: 'flex'
    },

    attendancesConatiner: {
        position: 'absolute',
        right: 23
    },

    studenAtRiskContainer: {
        position: 'absolute',
        left: 230,
        top: 90,
        display: 'flex'
    },
    adminInfoContainer: {
        position: 'absolute',
        left: 265,
        top: 25,
        display: 'flex'
    },
    studenAtRiskPercent: {
        height: 60,
        width: 60,
        backgroundColor: '#f0b000',
        borderRadius: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: 18,
        fontFamily: 'Avenir',
        fontWeight: 900,
        lineHeight: '0px'
    },

    studentAtRiskText: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
        paddingLeft: 18
    },

    enrolledStudents: {
        lineHeight: '15px'
    }
}));

const OK_COLOR = '#37b5ff';

const ProfAttendancePercentCard = (props) => {
    const classes = useStyles();
    const summary = props && props.summary
    const school = props.school
    const {lowAttendanceStudentsPercentage, totalCourse, totalLowAttendanceStudents, totalPercentage, totalStudents} = summary
    const missing = 100 - totalPercentage
    const [color, setColor] = React.useState(OK_COLOR);

    const isLowAttendance = totalPercentage <= school.atRiskPercentage


    // doughnut data
    const [graphicData, setGraphicData] = useState({});
    useEffect(() => {
        setData();
    }, [lowAttendanceStudentsPercentage, totalCourse, totalLowAttendanceStudents, totalPercentage, totalStudents]);
    const setData = () => {
        let backgroundColor = isLowAttendance ? '#f0b000' :'#37b5ff'
        setColor(backgroundColor)
        setGraphicData({
            labels: ['Classes Attended', 'Attendance percentage'],
            datasets: [{
                data: [totalPercentage, missing],
                backgroundColor: [backgroundColor, '#e8e8e8'],
                borderWidth: 0
            }]
        })
    };

    const options ={
        cutoutPercentage: 90,
        legend: {
            display: false
        },
        responsive: false,
        elements: {
            center: {
                text1: `${totalPercentage}%`,
                text2: `Attendance`,
                text3: 'percentage',
                color1: '#848484',
                color2: '#848484',
                fontSize1: 28,
                fontSize2: 14,
                fontStyle: 'Avenir-Medium',
                sidePadding: 20,
                lineHeight: 1.6
            }
        },
        tooltips: {
            enabled: false
       },
        animation: {
            duration: 0, // general animation time
        },
    };

    return (
        <Card classes={{root: classes.card}}>
            <div className={classes.donutContainer}>
                <Doughnut data={graphicData} options={options}/>
            </div>


            <div className={classes.studenAtRiskContainer}>
                <div className={classes.studenAtRiskPercent} style={{backgroundColor: color}}>{lowAttendanceStudentsPercentage}%</div>
                <div className={classes.studentAtRiskText}>
                    <Typography variant={'h5'}>
                        <strong>Students at risk</strong>
                    </Typography>
                    <Typography variant={'h5'}>
                        {`${totalLowAttendanceStudents} of ${totalStudents} enrolled students`}
                    </Typography>
                </div>
            </div>
        </Card>
    );
};

export default ProfAttendancePercentCard;