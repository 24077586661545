import React, {useState} from 'react';
import {createStyles, Grid, makeStyles, Paper, Typography, TableBody} from "@material-ui/core";
import {WTableCell, WTableContainer, WTableHead} from '../../graphics/WiseTable';
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {useHistory} from "react-router-dom";
import {downloadProfessorsStudentsCSV, GET_ADMIN_STUDENTS_TABLE} from '../../../services/professors'
import { adminStudentsTableCSV } from '../../../services/students'
import {useLazyQuery} from "@apollo/react-hooks";
import {orderBy, sortBy} from 'lodash';
import DownloadButton from '../../common/DownloadButton'

const PAGE_SIZE = 10

const LoadingContainer = (props) => {
  return(<Paper className={props.class}>
      <Grid container alignItems='center'>
          <Typography variant={'h2'} color={'primary'}><strong>Loading...</strong></Typography>
      </Grid>
  </Paper>)
}

const useStyles = makeStyles((theme) =>
  createStyles({
      root: {
          maxWidth: 1400,
          marginTop: 26
      },
      dataContainer: {
          backgroundColor: theme.palette.background.paper,
          borderRadius: 5,
          marginBottom: 35,
          padding: 15
      },
      borders: {
          borderLeftWidth: 2,
          borderLeftColor: '#E8E8E8',
          borderStyle: 'solid',
          borderBottom: 0,
          borderTop: 0,
          borderRight: 0,
          textAlign: 'center',
          paddingTop: 15,
          height: 110
      },
      percentageAtRiskAdmin: {
          color: theme.palette.primary.main
      },
      percentageAtRiskProf: {
          color: theme.palette.warning.main
      },
      overallPercentage: {
          color: theme.palette.info.main,
          fontWeight: 900
      },
      warningLabel: {
          color: theme.palette.warning.main
      },
      buttonContainer: {
          textAlign: 'right'
      },
      link: {
          color: theme.palette.secondary.main,
          cursor: 'pointer'
      }
  })
);

export default function AdminStudentsTable() {
  const classes = useStyles();
  const history = useHistory();
  const [title, setTitle] = useState('Students');
  const [page, setPage] = React.useState(0);
  const [tableTitle, setTableTitle] = useState('All School Students');

  // multi filters
  const [nameFilter, setNameFilter] = useState('desc')
  const [emailFilter, setEmailFilter] = useState('desc')
  const [classEnrolledFilter, setClassEnrolledFilter] = useState('desc')
  const [attendancePercentageFilter, setAttendancePercentage] = useState('desc')
  const [absencesFilter, setAbsencesFilter] = useState('desc')

  // single filter
  const [defaultFilterKey, setDefaultFilterKey] = useState('name')
  const [defaultFilterValue, setDefaultFilterValue] = useState('asc')

  const [getAdminStudentsTable, adminStudentsTableResult] = useLazyQuery(GET_ADMIN_STUDENTS_TABLE);

  const adminStudentsTable = adminStudentsTableResult && adminStudentsTableResult.data  && adminStudentsTableResult.data.adminStudentsTable
  const students =  adminStudentsTable && adminStudentsTable.students
  const atRisk = ''
  
  


  const refreshList = () => {
      const choice = adminStudentsTableResult
      const deepChoice = choice && adminStudentsTableResult.data && adminStudentsTableResult.data.adminStudentsTable && adminStudentsTableResult.data.adminStudentsTable.nextToken
      const nextToken = page === 0 ? '' : deepChoice
      getAdminStudentsTable({
          variables: {
              limit: PAGE_SIZE,
              token: nextToken
          }
      });
  };

  React.useEffect(() => {
      refreshList();
  }, [page]);

  const orderStudents = (students) => {
      if (!students) return;

      //singlefilter
      return orderBy(students, [defaultFilterKey], [defaultFilterValue])

      // multifilter
      // return orderBy(students,
      //     ['name', 'email', 'classEnrolled', 'attendancePercentage', 'absences'],
      //     [nameFilter, emailFilter, classEnrolledFilter, attendancePercentageFilter, absencesFilter]
      // );
  }

  const onEmailClick = (email) => {
      window.open(`mailto:${email}`, "_blank");
  };

  const onNameClick = (student) => {
      history.push(`/students/${encodeURIComponent(student.email)}`);
  };

  const selectDirection = (value) => {
      const res = value === 'asc' ? 'desc' : 'asc'
      setDefaultFilterValue(res)
      return res
  }

  const toggleSortDirection = (key) => {
      setDefaultFilterKey(key)
      switch(key) {
          case 'name':
              setNameFilter(selectDirection(nameFilter))
              break;
          case 'email':
              setEmailFilter(selectDirection(emailFilter))
              break;
          case 'totalClassesEnrolled':
              setClassEnrolledFilter(selectDirection(classEnrolledFilter))
              break;
          case 'attendancePercentage':
              setAttendancePercentage(selectDirection(attendancePercentageFilter))
              break;
          case 'absences':
              setAbsencesFilter(selectDirection(absencesFilter))
              break;
      }
  }

  const isFilterSelected = (key) => {
      return key === defaultFilterKey
  }

  return (
          <Paper elevation={3} className={classes.dataContainer}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                      <Typography variant={'h2'} color={'primary'}><strong>{tableTitle}</strong> </Typography>
                  </Grid>

                  <Grid container justify="space-between" item alignItems='center' xs={12}>
                      <Grid item xs={3}>
                          {students && <Typography variant={'h5'}>{students.length} Students</Typography>}
                      </Grid>
                      <Grid item xs={3} className={classes.buttonContainer}>
                      <DownloadButton mutation={adminStudentsTableCSV} mutationParams={null} lookupKey={'adminStudentsTableCSV'}></DownloadButton>
                      </Grid>
                  </Grid>

                  <Grid item xs={12}>

                      <WTableContainer>
                          <Table>
                              <WTableHead>
                                  <TableRow>
                                      <WTableCell onClick={() => toggleSortDirection('name')}>
                                          <TableSortLabel active={true}
                                                          direction={nameFilter}
                                                          style={{color: isFilterSelected('name') ?'#177acc' : '#757373'}}>
                                              Name
                                          </TableSortLabel>
                                      </WTableCell>
                                      <WTableCell onClick={() => toggleSortDirection('email')}>
                                          <TableSortLabel active={true}
                                                          direction={emailFilter}
                                                          style={{color: isFilterSelected('email') ?'#177acc' : '#757373'}}>
                                              Email
                                          </TableSortLabel>
                                      </WTableCell>
                                      <WTableCell onClick={() => toggleSortDirection('totalClassesEnrolled')}>
                                          <TableSortLabel active={true}
                                                          direction={classEnrolledFilter}
                                                          style={{color: isFilterSelected('totalClassesEnrolled') ?'#177acc' : '#757373'}}>
                                              Classes Enrolled
                                          </TableSortLabel>
                                      </WTableCell>
                                      <WTableCell onClick={() => toggleSortDirection('attendancePercentage')}>
                                          <TableSortLabel active={true}
                                                          direction={attendancePercentageFilter}
                                                          style={{color: isFilterSelected('attendancePercentage') ?'#177acc' : '#757373'}}>
                                               Attendance %
                                          </TableSortLabel>
                                         </WTableCell>
                                      <WTableCell onClick={() => toggleSortDirection('absences')}>
                                          <TableSortLabel active={true}
                                                          direction={absencesFilter}
                                                          style={{color: isFilterSelected('absences') ?'#177acc' : '#757373'}}>
                                              Absences
                                          </TableSortLabel>
                                      </WTableCell>
                                  </TableRow>
                              </WTableHead>
                              <TableBody>
                                  {
                                      orderStudents(students) && orderStudents(students).map((r, index) => {
                                          return <TableRow key={index}>
                                              <WTableCell onClick={e => onNameClick(r)}
                                                          className={classes.link}>{r.name}</WTableCell>
                                              <WTableCell onClick={e => onEmailClick(r.email)}
                                                          className={classes.link}>{r.email}</WTableCell>
                                              <WTableCell align='left'>{r.totalClassesEnrolled}</WTableCell>
                                              <WTableCell align='left'>{r.attendancePercentage} %</WTableCell>
                                              <WTableCell align='left'>{r.absences}</WTableCell>
                                          </TableRow>;
                                      })
                                  }
                              </TableBody>
                          </Table>
                      </WTableContainer>
                  </Grid>

                  {/* <Grid container item justify={'flex-end'} xs={12}>
                      <Grid item>
                          <WPaginator itemsInPage={10} nextDisabled={false} page={0} startDisabled={true}
                                      total={100}/>
                      </Grid>
                  </Grid> */}
              </Grid>

          </Paper>
  )
}