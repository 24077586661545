import React, {useEffect, useState} from 'react';
import {createStyles, Grid, makeStyles, Paper, Typography} from "@material-ui/core";
import AttendanceGraphic from '../../graphics/Attendance';
import { GET_STUDENTS_AT_RISK_CHART } from '../../../services/students';
import { useQuery } from "@apollo/react-hooks";
import {isEmpty} from 'lodash'
import moment from 'moment';

const LoadingContainer = (props) => {
  return(<Paper className={props.class}>
      <Grid container alignItems='center'>
          <Typography variant={'h2'} color={'primary'}><strong>Loading...</strong></Typography>
      </Grid>
  </Paper>)
}

const useStyles = makeStyles((theme) =>
  createStyles({
      root: {
          maxWidth: 1400,
          marginTop: 26
      },
      dataContainer: {
          backgroundColor: theme.palette.background.paper,
          borderRadius: 5,
          marginBottom: 35,
          padding: 15
      },
      borders: {
          borderLeftWidth: 2,
          borderLeftColor: '#E8E8E8',
          borderStyle: 'solid',
          borderBottom: 0,
          borderTop: 0,
          borderRight: 0,
          textAlign: 'center',
          paddingTop: 15,
          height: 110
      },
      percentageAtRiskAdmin: {
          color: theme.palette.primary.main
      },
      percentageAtRiskProf: {
          color: theme.palette.warning.main
      },
      overallPercentage: {
          color: theme.palette.info.main,
          fontWeight: 900
      },
      warningLabel: {
          color: theme.palette.warning.main
      },
      buttonContainer: {
          textAlign: 'right'
      },
      link: {
          color: theme.palette.secondary.main,
          cursor: 'pointer'
      }
  })
);

export default function AdminStudentsAttendanceGraph(props) {
  const classes = useStyles();
  const tooltip = '% Students with low attendance';
  const school = props.school
  const [coursesGraphInfo, setCoursesGraphInfo] = useState(null);
  const [typeChart, setTypeChart] = useState('monthly');


  const [adminStudentsGraphInfo, setAdminStudentsGraphInfo] = useState(null);

    useQuery(GET_STUDENTS_AT_RISK_CHART, {
        onError: (err) => {
            console.log(err);
        },
        onCompleted: (data) => {
            setAdminStudentsGraphInfo(data)
        }
    });

    const prepareLabels = (data) => {
        if(isEmpty(school)) return
        const resultLabels = []
        if (typeChart === 'weekly'){
            data.forEach((_, index) => resultLabels.push(`Week ${index+1}`))
            return resultLabels
        }
        let date = moment(school.semesterStartDate.split('T')[0]).startOf('month')
        data.forEach(() => {
            resultLabels.push(moment(date).format('MMMM'))
            date = moment(date).clone().add(1, 'month')
        })
        return resultLabels
    }

    const preparePointsData = (pointsData) => {
        return pointsData.map(item => item.y)
    }

    const formatDataForChart = (adminData) => {
        if(!adminData) return

        const data = []

        // format data for admins
        const adminLabel = 'All students with low attendance';
        const adminCandidateData  = typeChart === 'weekly' ? adminData.studentsAtRiskPerWeek : adminData.studentsAtRiskPerMonth;
        const adminValues = preparePointsData(adminCandidateData)
        adminValues.shift()
        const labels = prepareLabels(adminValues)
        data.push({label: adminLabel, values: adminValues})


        return {
            labels,
            data
        }
    }

    let chartData = null
    if (adminStudentsGraphInfo){
        chartData = formatDataForChart(adminStudentsGraphInfo)
    }

  // GRAFICA DE ATTENDANCE PARA ESTUDIANTES
  return (
                adminStudentsGraphInfo ?
                  <Paper elevation={3} className={classes.dataContainer}>
                      <AttendanceGraphic
                          onChangePeriod={val => setTypeChart(val)}
                          tooltipText={tooltip}
                          title='Students with low attendance over the active term'
                          labels={chartData.labels}
                          data={chartData.data}
                      ></AttendanceGraphic>
                  </Paper> : <LoadingContainer class={classes.dataContainer}></LoadingContainer>)
}