import React, { useState } from 'react';
import { TextField, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";
import AuthService from '../../services/auth.js';
import { regexEmail } from "../../utils/validators";


const useStyles = makeStyles(theme => ({
    root: {
      '& .MultiTextField-root': {
          width: 70
      },
      '& .MuiFormLabel-root': {
        fontSize: 14,
        color: "#c3c3c3"
        },
        '& .MuiFormHelperText-root': {
            color: "#f0b000",
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: "#f0b000"
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            height: 0
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: "#29aee6"
        },
        "& .MuiButton-outlined": {
            border: "1px solid #37b5ff",
        },
        "& .MuiButton-root": {
            textTransform: "none",
            color: "#37b5ff",
            width: 116,
            height: 32
        },
        "& .MuiInputLabel-formControl": {
            top: -6,
            left: -4
        }
    },
    buttonContainer: {
        paddingRight: '15% !important'
    },
    emailError: {
        paddingLeft: '17%',
        width:"100%",
        textAlign: 'left'
    }
    }));

function ForgotPassword(props) {
    const classes = useStyles();
    const [incorrectEmail, setIncorrectEmail] = useState(false);
    const [form, setForm] = useState({
        email: {
            value: "",
            requiredValidator: false,
            formatValidator: false
        }
    });

    const handleChange = name => event => {
        let value =  event.target.value;
        setIncorrectEmail(false);
        setForm({ ...form, [name]: {
            value: value,
            formatValidator: false,
            requiredValidator: false
        }  });

      };



    const isInvalidForm = () => {
        let validEmail = !regexEmail.test(form.email.value)
        const newForm = {...form};
        newForm.email.requiredValidator = !form.email.value;
        newForm.email.formatValidator = validEmail;
        setForm(newForm);
        return form.email.requiredValidator || form.email.formatValidator
    }


    const onSubmit = async (event) => {
        event.preventDefault()
        if(isInvalidForm()) return;
        try {
            const response = await AuthService.requestCodeForNewPassword(form.email.value);
            if(!response){
                setIncorrectEmail(true);
            }
            else {
                props.onChangeMode("create-password", form.email.value);
                setIncorrectEmail(false);
            }
        } catch(err) {
            console.log(err);
        }
    };

    const cancel = () => {
        props.onChangeMode("login");
    };

    const incorrectEmailLabel =  <label className={classes.emailError}><ErrorIcon /> <span className="error-description">The email does not exist.</span></label>




    return(
        <div className="login-container-small">
                <div className="title">Forgot password</div>
                <div className="instructions">
                    <div className="message">Please insert your school email to verify your identity</div>
                    <div className="message">and create a new password.</div>
                </div>
                <form className={classes.root} onSubmit={onSubmit}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                        <TextField
                            InputProps={{
                                style: {
                                    height: "38px",
                                    fontFamily: "Avenir-medium",
                                    fontSize: 14
                                }
                            }}
                            className="input"
                            error={form.email.requiredValidator || form.email.formatValidator}
                            onChange={handleChange("email")}
                            helperText=
                            {form.email.requiredValidator? 
                            <label>
                                <ErrorIcon /> <span style={{position: 'relative', top: '-5px'}}>Please type your school email</span>
                            </label> :
                            form.email.formatValidator?
                            <label>
                                <ErrorIcon /> <span style={{position: 'relative', top: '-5px'}}>The email does not match with the format</span>
                            </label> : 
                            null}
                            id="outlined-required"
                            name="email"
                            label="School email"
                            placeholder="School email"
                            variant="outlined"
                        /> 
                        </Grid>
                        {
                            incorrectEmail ? incorrectEmailLabel : null
                        }
                        <Grid className={classes.buttonContainer} direction="row-reverse" container item xs={12}>
                            <Grid item md={3} s={4} xs={12}>
                                <Button type='submit' variant="contained" className="button-confirm">Continue</Button> 
                            </Grid>
                            <div style={{width: '0.5%'}}></div>
                            <Grid item md={3} s={4} xs={12} className='cancel-password'>
                                <Button onClick={cancel} variant="outlined">Cancel</Button>
                            </Grid>
                        </Grid>
                    </Grid>               
            </form>
        </div>
    )
}

export default ForgotPassword;