import React, { useState } from 'react';
import { Grid, makeStyles, Icon, Typography } from '@material-ui/core';
import { NoBorderButton, BlueButton } from '../../common/buttons';
import { ReactComponent as ChangeIcon } from "../../../assets/change.svg";
import { PasswordTextField } from '../../common/inputs';
import { regexPhone, regexInputPhone } from "../../../utils/validators";
import { ReactComponent as ErrorIcon } from "../../../assets/error.svg";
import { useMutation } from '@apollo/react-hooks';
import { updatePhone } from '../../../services/profileService';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: "20px 20px",
        height: 526,
        width: 282,
        minWidth: 282,
        "& .MuiInputLabel-formControl": {
            top: -6,
            left: -4
        }
    },
    container: {
        textAlign: 'center',
        height: "100%",
        display: 'block'
    },
    icon: {
        cursor: 'pointer',
        color: theme.palette.primary.main
    },
    title: {
        fontWeight: 900
    },
    input: {
        width:'90%'
    },
    button: {
        minWidth: 'unset',
        width: '100%'
    },
    disabledButton: {
        opacity: "40%"
    },
    changeIcon: {
        height:80,
        width:80
    },
    changeIconContainer: {
        height: 80
    },
    titleContainer: {
        height: 70,
        paddingTop: '10%'
    },
    desc: {
        color: '#9C9C9C'
    },
    descContainer: {
        paddingBottom: '10%'
    },
    inputContainer: {
        height: 80
    },
    buttonContainer: {
        paddingTop: '50%'
    }
}));

const ChangePhone = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const [disabledText, setDisabledText] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [phone, setPhone] = useState(
        {
            value: '',
            errorFormat: false,
            errorGeneral: false
        }
    );

    const [saveInfo, {data, loading}] = useMutation(updatePhone,
        { 
            variables: {
                input: {
                    schoolCode: props.profile.schoolCode,
                    phone: phone.value
                }      
            },   
            onCompleted: (profile) => onSuccess(profile),
            onError: (err) => onError(err)
        }
        );

    const onClose = () => {
        props.onClose();
    }

    const onSuccess = (newProfile) => {
        props.onSuccess(newProfile.updateUser)
    }

    const onError = (err) => {
        setPhone({
            ...phone,
            errorGeneral: true
        });
        console.log(err);
    };

    const submitChange = () => {
        setDisabledText(true);
        setDisabledButton(true);
        if(!regexPhone.test(phone.value)) {
            setPhone({
                ...phone, 
                errorFormat: true
            })
        } else {
            saveInfo();
        }

        setDisabledText(false);
    };

    const setValue= val => {
        if(!regexInputPhone.test(val) && val !== '') return;
        setPhone({
            value: val,
            errorFormat: false,
            errorGeneral: false
        });

        if(regexPhone.test(phone.value)) {
            setDisabledButton(false);
        }
    }; 
    
    const checkValue = () => {
        if(regexPhone.test(phone.value)) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
            setPhone({
                ...phone,
                errorFormat: true
            });
        }
    }
 
    return(
            <div className={classes.root}>
                <Grid container justify="space-between" direction="row-reverse">
                    <Grid>
                        <Icon className={classes.icon} onClick={onClose}>close</Icon>
                    </Grid>
                </Grid>
                <Grid container justify="center" className={classes.container}>
                    <Grid item xs={12} className={classes.changeIconContainer}>
                        <ChangeIcon className={classes.changeIcon}/>
                    </Grid>
                    <Grid item xs={12} className={classes.titleContainer}>
                        <Typography className={classes.title}  variant={'h3'} color={'primary'}>Edit your phone number</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.descContainer}>
                        <Typography variant={'h5'} className={classes.desc}>Add your mobile phone number to receive notifications from Wise</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.inputContainer}>
                        <PasswordTextField
                            className={classes.input}
                            label="Add your phone number"
                            placeholder="Add your phone number"
                            variant="outlined"
                            value={phone.value}
                            onChange={e => setValue(e.target.value)}
                            onBlur={checkValue}
                            disabled={disabledText}
                            type="tel"
                            error={phone.errorFormat}
                            helperText={phone.errorFormat ?
                                <label>
                                <ErrorIcon /> <span>The format phone is incorrect. Use +1234567890</span>
                                </label> : phone.errorGeneral ? 
                                <label>
                                <ErrorIcon /> <span>Something happened. Try again.</span>
                                </label> : null

                            }
                            InputProps={{
                                style: {
                                    height: "38px",
                                    fontFamily: "Avenir-medium",
                                    fontSize: 14
                                },
                            }}
                        />
                    </Grid>
                    <Grid item container xs={12} className={classes.buttonContainer}>
                        <Grid item xs={6}>
                            <NoBorderButton onClick={e => props.onClose()} className={classes.button}>Cancel</NoBorderButton>
                        </Grid>
                        <Grid item xs={6}>
                            <BlueButton
                            disabled={disabledButton}
                            onClick={submitChange}
                            className={classes.button}>Save</BlueButton>
                        </Grid>
                    </Grid>
                </Grid>
        </div>
        )
});

export default ChangePhone;