import React, { useState, useRef, useEffect } from 'react';
import { Typography, makeStyles, createStyles, Grid, FormControl, MenuItem } from "@material-ui/core";
import { WiseSelect } from "../common/inputs";
import {Line} from 'react-chartjs-2';


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: 'white',
            height: 250,
            width: '100%',
            "& .MuiOutlinedInput-root": {
                height: 28
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: '#37b5ff'
            },
            "& .MuiInputBase-input": {
                padding: "0 14px"
            },
            position: 'relative'
        },
        selectContainer: {
            width: 116,
            height: 24,
            marginLeft: 33,
            top: 30,
            right: 0,
            marginRight: 28,
            position: 'absolute'
        },
        graphic: {
            height: "220px important",
            width: '90%',
        },
        chartTitle: {
            paddingLeft: 8
        }

    })
);

export default function AttendanceGraphic({onChangePeriod, title, tooltipText, labels, data, isHovering}) {
    const classes = useStyles();
    const chartRef = useRef();
    const [graphicData, setGraphicData] = useState({});

    const [type, setType] = useState('monthly');
    const handleChange = event => {
        setType(event.target.value);
        onChangePeriod(event.target.value)
    };

    useEffect(() => {
        setData()
    }, [data]);

    const setData = () => {
        if(data.length === 1) {
            setGraphicData({
                labels: labels,
                datasets: [
                    {
                    label: data[0].label,
                    fill: false,
                    lineTension: 0,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: '#177acc',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#177acc',
                    pointBackgroundColor: '#177acc',
                    pointBorderWidth: 2,
                    pointHoverRadius: 6,
                    pointHoverBackgroundColor: '#177acc',
                    pointHoverBorderColor: '#177acc',
                    pointHoverBorderWidth: 2,
                    pointRadius: 6,
                    pointStyle: 'circle',
                    pointHitRadius: 10,
                    data: data[0].values
                  }
                ]
            });
        } else if(data.length === 2) {
            setGraphicData({
                labels: labels,
                datasets: [
                    {
                        label: data[0].label,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: 'rgba(75,192,192,0.4)',
                        borderColor: '#37b5ff',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: '#37b5ff',
                        pointBackgroundColor: '#ffffff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 8,
                        pointHoverBackgroundColor: '#FFFFFF',
                        pointHoverBorderColor: '#37b5ff',
                        pointHoverBorderWidth: 2,
                        pointRadius: 6,
                        pointStyle: 'circle',
                        pointHitRadius: 10,
                        data: data[0].values
                    },
                    {
                        label: data[1].label,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: 'rgba(75,192,192,0.4)',
                        borderColor: '#177acc',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: '#177acc',
                        pointBackgroundColor: '#ffffff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 8,
                        pointHoverBackgroundColor: '#FFFFFF',
                        pointHoverBorderColor: '#177acc',
                        pointHoverBorderWidth: 2,
                        pointRadius: 6,
                        pointStyle: 'circle',
                        pointHitRadius: 10,
                        data: data[1].values
                    }
                ]
            });
        }
    }

    const options = {
        maintainAspectRatio: false,
        tooltips: {
            backgroundColor: '#f0b000',
            callbacks: {
                title: function (tooltipItem, data) { 
                    return '' 
                },
                label: function(tooltipItems, data) {
                    return tooltipItems.yLabel + tooltipText;
                },
                footer: (tooltipItems, data) => {
                    return null;
                }
            }
        },
        legend: {
            display: true,
            position: 'right',
            align:'end',
            labels: {
                boxWidth: 10,
                usePointStyle: true
            }
        },
        scales: {
            xAxes:[{
                display:true,
                gridLines: {
                    display: false
                },
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }

    return(
        <Grid container className={classes.root}>
            <Grid item container xs={12}>
                <Grid item xs={9}>
                    <Typography className={classes.chartTitle} variant={'h2'} color={'primary'}><strong>{title}</strong></Typography>
                </Grid>
                    
                <FormControl variant="outlined" className={classes.selectContainer}>
                    <WiseSelect
                        value={type}
                        onChange={handleChange}
                    >
                        <MenuItem value={'monthly'}>
                            <Typography variant={'h6'} color={'secondary'}>Monthly</Typography>
                        </MenuItem>
                        <MenuItem value={'weekly'}>
                            <Typography variant={'h6'} color={'secondary'}>Weekly</Typography>
                        </MenuItem>
                    </WiseSelect>
                </FormControl>                    
            </Grid>
           
            <Grid item xs={12} className={classes.graphic}>
                    <Line height={220} data={graphicData} options={options}/>
            </Grid>
    </Grid>
    )
}