import React, {useEffect, useState} from 'react';
import {createStyles, Grid, makeStyles, Paper, TableBody, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import CircleInfo from '../../common/CircleInfo';
import AttendanceGraphic from '../../graphics/Attendance';
import {getCoursesGraphinfo} from '../../../services/courses';
import {downloadProfessorsStudentsCSV,GET_PROFESSORS_BANNERS, GET_STUDENTS_TABLE, GET_ADMIN_STUDENTS_TABLE} from '../../../services/professors'
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {isEmpty, orderBy, sortBy} from 'lodash';
import ProfessorStudentsTable from './ProfessorStudentsTable'
import AdminStudentsTable from './AdminStudentsTable'

const PAGE_SIZE = 10

const LoadingContainer = (props) => {
    return(<Paper className={props.class}>
        <Grid container alignItems='center'>
            <Typography variant={'h2'} color={'primary'}><strong>Loading...</strong></Typography>
        </Grid>
    </Paper>)
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            maxWidth: 1400,
            marginTop: 26
        },
        dataContainer: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: 5,
            marginBottom: 35,
            padding: 15
        },
        borders: {
            borderLeftWidth: 2,
            borderLeftColor: '#E8E8E8',
            borderStyle: 'solid',
            borderBottom: 0,
            borderTop: 0,
            borderRight: 0,
            textAlign: 'center',
            paddingTop: 15,
            height: 110
        },
        percentageAtRiskAdmin: {
            color: theme.palette.primary.main
        },
        percentageAtRiskProf: {
            color: theme.palette.warning.main
        },
        overallPercentage: {
            color: theme.palette.info.main,
            fontWeight: 900
        },
        warningLabel: {
            color: theme.palette.warning.main
        },
        buttonContainer: {
            textAlign: 'right'
        },
        link: {
            color: theme.palette.secondary.main,
            cursor: 'pointer'
        }
    })
);

export default function Students({role, school}) {
    const classes = useStyles();
    const [blueData, setBlueData] = useState({})
    const [lightBlueData, setLightBlueData] = useState({})
    const [yellowData, setYellowData] = useState({})
    const [professorRightBannerData, setProfessorRightBannerData] = useState({})

    const professorBannersResult = useQuery(GET_PROFESSORS_BANNERS, {
        onCompleted: () => {
            const {professorStudentsBanner} = professorBannersResult.data
            setBlueData({number: professorStudentsBanner.numberOfStudentsEnrolled, text: 'Students are enrolled'})
            setLightBlueData({number: professorStudentsBanner.numberOfClasses, text1: 'Across', text2: 'courses'})
            setYellowData({number: professorStudentsBanner.numberOfMyStudentsAtRisk, text1: '', text2: 'Students with low attendance'})
            setProfessorRightBannerData({percentageOfMyStudentsAtRisk: professorStudentsBanner.percentageOfMyStudentsAtRisk, percentageOfOverallStudentsAtRisk: professorStudentsBanner.percentageOfOverallStudentsAtRisk })
        }
    });

    return (
        <div className={classes.root}>
            {
                isEmpty(blueData) && isEmpty(lightBlueData) && isEmpty(yellowData) && isEmpty(professorRightBannerData) ?
                <LoadingContainer class={classes.dataContainer}></LoadingContainer>
                :
                <Paper elevation={5} className={classes.dataContainer}>
                  <Grid container alignItems='center'>
                      {/* CIRCULOS */}
                      <Grid item xs={6}>
                          <CircleInfo lightBlueData={lightBlueData} yellowData={yellowData} blueData={blueData}/>
                      </Grid>
                      {/* PARTE DERECHA DE LOS DATOS */}
                      <Grid container xs={6} justify="center" className={classes.borders}>
                          <Grid item xs={6}>
                              <Typography variant={'h1'}
                                          className={classes.percentageAtRiskProf}>{professorRightBannerData.percentageOfMyStudentsAtRisk}%</Typography>
                              <Typography variant={'caption'}>Of your students are at risk of <br/> failing based
                                  on attendance</Typography>
                          </Grid>
                          <Grid item xs={6}>
                              <Typography variant={'h6'}>COMPARED TO</Typography>
                              <Typography variant={'h2'} className={classes.overallPercentage}>{professorRightBannerData.percentageOfOverallStudentsAtRisk}%</Typography>
                              <Typography variant={'caption'}>Of the overall school students <br/> that are in the
                                  same situation</Typography>
                          </Grid>
                          <Grid item xs={12}>
                              {professorRightBannerData.percentageOfMyStudentsAtRisk > professorRightBannerData.percentageOfOverallStudentsAtRisk && 
                              <Typography variant={'h3'} className={classes.warningLabel}>You have more students
                                  at risk than the school average</Typography>}
                          </Grid>
                      </Grid>
                  </Grid>
            </Paper>}
        </div>
    )
}