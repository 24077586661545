import React, { useState, useContext } from 'react';
import {  makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Modal, Icon } from '@material-ui/core';
import IntroductionBase from './IntroductionBase';
import { WhiteButton, BlueButton } from '../../../common/buttons';
import { ErrorTitleTypography, DescriptionTypography } from '../../../common/typography';
import { OnboardingContext } from "../../../../context/onboarding-context";
import { useQuery } from "@apollo/react-hooks";
import { GET_TEMPLATE_LINKS } from "../../../../services/school";

const useStyles = makeStyles((theme) => 
    createStyles({
        root: {
            padding: 100,
            textAlign: 'center'
        },
        container: {
            backgroundColor: 'white',
            minHeight: 530,
            display: 'inline-block',
            minWidth: 800,
            maxHeight: 1000,
            maxWidth:1500,
            width: "80%",
            height: "63%",
            overflow: 'scroll'
        },
        buttonContainer: {
            minWidth: 800,
            display: 'inline-block',
            width: "80%",
            height: "63%",     
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        modalRoot: {
            backgroundColor: 'white',
            padding: "20px 20px",
            height: 272,
            width: 450,
            minHeight: 272,
            minWidth: 450
        },
        modalTitle: {
            color: '#37b5ff'
        },
        modalDescription:{
            fontSize: 18,
            paddingTop: 28
        },
        buttonsModal: {
            paddingTop: 80
        } 
    })
);

export default function Introduction(props) {
    const classes = useStyles();
    const [step, setStep] = useState(1);
    const {setOnboardingStep} = useContext(OnboardingContext); 
    const [onboardingLinks, setOnboardingLinks] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    useQuery(GET_TEMPLATE_LINKS, {
        onCompleted: (data) => getLinks(data)
    })

    const getLinks = (data) => {
        setOnboardingLinks(data.getOnBoardingFileLinks);
    };

    const changeStep = (newStep) => {
        if(step === 4 && newStep === 5) return setModalOpen(true);
        setStep(newStep);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const goToSetup = () => {
        setModalOpen(false);
        setOnboardingStep('school-setup');
        props.history.push('/school-setup/info', {firstTime: false});
    }

    return(
        <div className={classes.root}>
            <div className={classes.container}>
               <IntroductionBase 
               step={step}
               onChangeStep={num => changeStep(num)}
               onboardingLinks={onboardingLinks}
               />
            </div>
            <div className={classes.buttonContainer}>
               <Grid container direction='row-reverse'>
                    <Grid item xs={3}>
                        <BlueButton onClick={ e => changeStep(step+1)}>{step !== 4 ? 'Next' : "Let's start"}</BlueButton>
                    </Grid>
                    {
                    step === 1 ? 
                    null :
                    <Grid item xs={3}>
                        <WhiteButton onClick={ e => changeStep(step-1)}>Back</WhiteButton>
                    </Grid>
                    }   
               </Grid>
            </div>

            <Modal
                disableBackdropClick
                aria-labelledby="Confirmation"
                aria-describedby="Confirmation"
                open={isModalOpen}
                onClose={handleClose}
                className={classes.modal}
            > 
                <div className={classes.modalRoot}>
                    <Grid container justify="space-between">
                        <Grid item xs={3}>
                            <ErrorTitleTypography className={classes.modalTitle}>Important</ErrorTitleTypography>
                        </Grid>
                        <Grid>
                            <Icon className={classes.modalTitle} onClick={handleClose}>close</Icon>
                        </Grid>
                    </Grid>
                    <DescriptionTypography className={classes.modalDescription}>Before we continue you must have all your <br/> spreadsheet files filled with your information.</DescriptionTypography>
                    <Grid item container justify="space-between" className={classes.buttonsModal}>
                            <Grid item xs={6}>
                                <WhiteButton onClick={handleClose}>Back to download</WhiteButton>
                            </Grid>
                            <Grid item xs={5}>
                                <BlueButton onClick={goToSetup}>I'm Ready</BlueButton>
                            </Grid>
                    </Grid>
                </div>
            </Modal>
        </div>
    )
}