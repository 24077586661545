import React from 'react';
import {  makeStyles, createStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            textAlign: 'center'
        },
        blueCircle: {
            width: 110,
            height: 110,
            borderRadius: "50%",
            textAlign: 'center',
            padding: 22,
            display: 'inline-block',
            backgroundColor: theme.palette.primary.main
        },
        lightBlueCircle: {
            width: 83,
            height: 85,
            marginLeft: -10,
            borderRadius: "50%",
            textAlign: 'center',
            padding: 4,
            display: 'inline-block',
            backgroundColor: theme.palette.secondary.main
        },
        yellowCircle: {
            width: 110,
            height: 110,
            marginLeft: -10,
            borderRadius: "50%",
            textAlign: 'center',
            padding: 9,
            display: 'inline-block',
            backgroundColor: theme.palette.warning.main
        },
        graphic: {
            height: "250px important"
        },
        text: {
            color: theme.palette.common.white
        },
        title: {
            fontWeight: 900
        }

    })
);

export default function CircleInfo({blueData, lightBlueData, yellowData}) {

    const classes = useStyles();
    return(
        <div className={classes.root}>
            <div className={classes.blueCircle}>
                <Typography variant={'h2'} className={classes.text + ' ' +classes.title}>{blueData.number}</Typography>
                <Typography variant={'caption'} className={classes.text}>{blueData.text}</Typography>
            </div>
            <div className={classes.lightBlueCircle}>
                <div style={{paddingTop: '7px'}}>
                    <Typography variant={'caption'} className={classes.text}>{lightBlueData.text1}</Typography>
                    <Typography variant={'h2'} className={classes.text + ' ' +classes.title}>{lightBlueData.number}</Typography>
                    <Typography variant={'caption'} className={classes.text}>{lightBlueData.text2}</Typography>
                </div>
            </div>
            <div className={classes.yellowCircle}>
                <Typography variant={'caption'} className={classes.text}>{yellowData.text1}</Typography>
                <Typography variant={'h2'} className={classes.text + ' ' +classes.title}>{yellowData.number}</Typography>
                <Typography variant={'caption'} className={classes.text}>{yellowData.text2}</Typography>
            </div>
        </div>
    )
}