import React, { useState } from 'react';
import { TextField, Grid, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";
import {ReactComponent as PasswordIcon} from '../../assets/show_password.svg';
import AuthService from '../../services/auth.js';
import { regexEmail } from "../../utils/validators";


const useStyles = makeStyles(theme => ({
    root: {
      '& .MultiTextField-root': {
          width: 70
      },
      '& .MuiFormLabel-root': {
        fontSize: 14,
        color: "#c3c3c3"
        },
        '& .MuiFormHelperText-root': {
            color: "#f0b000",
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            height: 0
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: "#f0b000"
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: "#29aee6"
        },
        "& .MuiInputLabel-formControl": {
            top: -6,
            left: -4
        },

    }
    }));



function SignIn(props) {
    const classes = useStyles();
    const [incorrectPassword, setIncorrectPassword] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [form, setForm] = useState({
        email: {
            value: ""
        },
        password:{
            value: ""
        } 
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleChange = name => event => {
        let value =  event.target.value;
        setIncorrectPassword(false);
        setForm({ ...form, [name]: {
            value: value
        }  });
      };


    const setPassword = event => {
        let val = event.target.value;
        setIncorrectPassword(false);
        setForm({...form, "password": {
            value: val
        }
        });
    }

    const handlePassword = () => {
        setShowPassword(!showPassword);
    };

    const isInvalidForm = () => {
        let validEmail = !regexEmail.test(form.email.value)
        const newForm = {...form};
        newForm.email.requiredValidator = !form.email.value;
        newForm.email.formatValidator = validEmail;
        newForm.password.requiredValidator = !form.password.value;
        setForm(newForm);
        return form.email.requiredValidator || form.password.requiredValidator || form.email.formatValidator
    }


    const doSignIn = async (e) => {
        e.preventDefault();
        try {
            if (isInvalidForm()){
                 return;
            }
            
            setDisabled(true);
            const user = await AuthService.authSignIn(form.email.value, form.password.value);
            if (user.code === 'NotAuthorizedException' || user.code === 'UserNotFoundException') {
                setIncorrectPassword(true);
                return;
            }
            else if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                props.onChangeMode("activate", user);
            }
            else if (user.signInUserSession){
                props.userHasAuthenticated(true);
                let newRole = AuthService.getRole();
                let newSchoolCode = AuthService.getSchoolCode();
                props.setSchoolCode(newSchoolCode);
                props.setRole(newRole);
                props.history.push("/role-redirect");
           }
        } catch(err) {
            setIncorrectPassword(true);
        } finally {
            setDisabled(false);
        }
        
    };

    const forgotPassword = () => {
        props.onChangeMode("forgot-password", undefined);
    }

    const incorrectPasswordLabel = <label><ErrorIcon /> <span className="error-description" style={{position: 'relative', top: '-5px'}}>Incorrect email or password. Please try again.</span></label>

    return(
        <div className="login-container-small">
                <div className="title">Welcome to Wise</div>
                <form className={classes.root} onSubmit={e => doSignIn(e)} >
                    <Grid container spacing={6}>
                        <Grid item xs={12} >
                        <TextField
                            InputProps={{
                                style: {
                                    height: "38px",
                                    fontFamily: "Avenir-medium",
                                    fontSize: 14
                                }
                            }}
                            className="input"
                            disabled={disabled}
                            error={form.email.requiredValidator || form.email.formatValidator}
                            onChange={handleChange("email")}
                            helperText=
                            {form.email.requiredValidator? 
                            <label>
                                <ErrorIcon /> <span style={{position: 'relative', top: '-5px'}}>Please type your school email.</span>
                            </label> :
                            form.email.formatValidator?
                            <label>
                                <ErrorIcon /> <span style={{position: 'relative', top: '-5px'}}>The email does not match with the format.</span>
                            </label> : 
                            null}
                            id="outlined-required"
                            name="email"
                            label="School email"
                            placeholder="School email"
                            variant="outlined"
                        /> 
                        </Grid>
                        <Grid item xs={12} >
                        <TextField
                            className="input"
                            disabled={disabled}
                            label="Password"
                            placeholder="Password"
                            variant="outlined"
                            onChange={setPassword}
                            type={showPassword? 'text': 'password'}
                            error={form.password.requiredValidator}
                            style={{marginLeft: '5px', width: '70.2%'}}
                            helperText={form.password.requiredValidator ? 
                                <label>
                                <ErrorIcon /> <span style={{position: 'relative', top: '-5px'}}>Please type your password.</span>
                                </label> :
                            null
                            }
                            InputProps={{
                                style: {
                                    height: "38px",
                                    fontFamily: "Avenir-medium",
                                    fontSize: 14
                                }
                            }}
                        /> 
                        <IconButton disabled={disabled} onClick={handlePassword} className="overlay-icon" aria-label="password">
                            <PasswordIcon></PasswordIcon>
                        </IconButton>
                        </Grid>
                        <Grid container className="special-input" style={{marginTop: '-5%'}}>
                            <Grid item md={8} s={8} xs={12} className="incorrect-password">
                                {incorrectPassword && incorrectPasswordLabel}
                            </Grid>
                            <Grid item md={4} s={4} xs={12} style={{ flexWrap: 'wrap-reverse', zIndex: '1000'}}>
                                <a className="underline-link" onClick={forgotPassword} style={{float: 'right'}}>Forgot password</a>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="button-container login-button">
                            <Button disabled={disabled} type="submit" variant="contained" className="button-confirm" style={{textTransform: 'none'}}>Login</Button> 
                        </Grid>
                    </Grid>               
            </form>
        </div>
    )
}

export default SignIn;