import React from "react";
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {AppBar, Toolbar} from "@material-ui/core";
import AttendanceCode from "../dashboard/AttendanceCode";
import OptionsProfile from "../dashboard/OptionsProfile";


const DRAWERWIDTH = 240;
const PROFESSOR_ROLE = 'Professor'

const useStyles = makeStyles((theme) =>
    createStyles({
        appBar: {
            backgroundColor: theme.palette.common.white,
            height: 50,
            boxShadow: '0px 2px 3px 0px rgba(83, 85, 86, 0.11), 0px 2px 9px 0px rgba(71, 76, 81, 0.09)',
        },

        toolbar: {
            minHeight: 'auto',
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
            padding: '0 12px',
        },
    })
);


export default function NavBar({onLogout, role, profile}) {

    const classes = useStyles();

    const handleLogout = () => {
        onLogout();
    };

    return (
        <AppBar className={classes.appBar} position={'relative'}>
            <Toolbar classes={{root: classes.toolbar}}>
                {
                    role === PROFESSOR_ROLE &&
                    <div className={classes.attendanceContainer}>
                        <AttendanceCode/>
                    </div>
                }
                <OptionsProfile profile={profile} onLogout={handleLogout} role={role}/>
            </Toolbar>
        </AppBar>
    )
}; 