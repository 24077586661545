import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    withStyles
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import {BlueButton, WhiteButton} from './buttons';
import {ReactComponent as ErrorIcon} from '../../assets/error.svg'
import {ErrorTitleTypography} from './typography';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        ...theme.typography.h1,
        fontSize: 24,
        color: theme.palette.warning.main
    },
    blueTitle: {
        ...theme.typography.h1,
        fontSize: 18,
        color: theme.palette.primary.main
    },
    blueClose: {
        ...theme.typography.h1,
        fontSize: 18,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        position: 'absolute',
        top: 32,
        right: 24
    }
}));

const StyledDialog = withStyles((theme) => ({
    paper: {
        maxWidth: 453,
        padding: theme.spacing(2)
    }
}))(Dialog);

function WarningDialog({ children, onOk, onCancel, onClose, showDialog = false }) {
    const classes = useStyles()
    return (
        <StyledDialog
            open={showDialog}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <Grid container className={classes.dialogTitle} alignItems="center">
                    <Grid item>
                        <ErrorIcon fontSize="large" /> &nbsp;
                    </Grid>
                    <Grid item>
                        Warning
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container justify="space-between" alignItems="center">
                    <WhiteButton onClick={onCancel} color="primary">
                        Cancel
                    </WhiteButton>
                    <BlueButton onClick={onOk} color="primary" autoFocus>
                        Ok
                    </BlueButton>
                </Grid>
            </DialogActions>
        </StyledDialog>
    );
}

function CautionDialog({ children, onOk, onCancel, onClose, showDialog = false }) {
    const classes = useStyles()
    return (
        <StyledDialog
            open={showDialog}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <Grid container className={classes.dialogTitle} alignItems="center">
                    <Grid item>
                        <ErrorIcon fontSize="large" /> &nbsp;
                    </Grid>
                    <Grid item>
                        Caution
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container justify="space-between" alignItems="center">
                    <WhiteButton onClick={onCancel} color="primary">
                        Cancel
                    </WhiteButton>
                    <BlueButton onClick={onOk} color="primary" autoFocus>
                        Ok
                    </BlueButton>
                </Grid>
            </DialogActions>
        </StyledDialog>
    );
}

function ConfirmDialog({ children, onOk, onCancel, onClose, showDialog = false, title }) {
    const classes = useStyles()
    return (
        <StyledDialog
            open={showDialog}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <Grid container alignItems="center">
                    <Grid item className={classes.dialogTitle}>
                        {title}
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container justify="space-between" alignItems="center">
                    <WhiteButton onClick={onCancel} color="primary">
                        Cancel
                    </WhiteButton>
                    <BlueButton onClick={onOk} color="primary" autoFocus>
                        Agree
                    </BlueButton>
                </Grid>
            </DialogActions>
        </StyledDialog>
    );
}

function SuccessDialog({ children, onOk, onClose, showDialog = false, title }) {
    const classes = useStyles()
    return (
        <StyledDialog
            open={showDialog}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <Grid container alignItems="center">
                    <Grid item className={classes.blueTitle} xs={11}>
                        {title}
                    </Grid>
                    <span item className={classes.blueClose} xs={1} onClick={onClose}>
                        X
                    </span>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container justify="flex-end" alignItems="center">
                    <BlueButton onClick={onOk} color="primary" autoFocus>
                        Ok
                    </BlueButton>
                </Grid>
            </DialogActions>
        </StyledDialog>
    );
}

function ErrorDialog({ children, onOk, onClose, showDialog = false }) {
  const classes = useStyles()
  return (
      <StyledDialog
          open={showDialog}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
              <Grid container alignItems="center">
                  <Grid item>
                      <ErrorTitleTypography className={classes.dialogTitle}><ErrorIcon /> Error</ErrorTitleTypography>
                  </Grid>
              </Grid>
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {children}
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Grid container justify="flex-end" alignItems="center">
                  <BlueButton onClick={onOk} color="primary" autoFocus>
                      Ok
                  </BlueButton>
              </Grid>
          </DialogActions>
      </StyledDialog>
  );
}

export { WarningDialog, ConfirmDialog, SuccessDialog, ErrorDialog, CautionDialog };

