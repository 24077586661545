import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {BlueButton} from "../../../common/buttons";
import React from "react";
import {WiseSelect} from "../../../common/inputs";
import { Grid, MenuItem, FormHelperText } from "@material-ui/core";
import moment from 'moment-timezone'

const useStepStyles = makeStyles({
    textFieldInput: {
        padding: '6px 0 3px 0',
        textAlign: 'center',

        '&::placeholder': {
            color: 'rgb(149, 149, 149)',
            opacity: 1
        }
    },

    textFieldContainer: {
        borderRadius: 8,
        border: '1px solid #e4e4eb',
    },

    textFieldLabel: {
        marginBottom: 13,
        marginTop: 32,
        lineHeight: '16px'
    },
    errorTZ: {
        textAlign: 'left',
        fontFamily: 'Avenir',
        fontWeight: 500,
        lineHeight: '14px',
        color: '#f44336',
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '12px'
    }
});

export const Step1Inputs = ({onChange, value, error, onChangeTimeZone, timeZoneValue ,timeZoneError}) => {
    // const timezones = moment.tz.names();
    const timeZones = moment.tz.names();
    let offsetTmz=[];

    for(let i in timeZones)
    {
        offsetTmz.push({offset: moment.tz(timeZones[i]).format('Z'), value: timeZones[i] });
    }

    const selectedTimeZones = ['Universal','WET','MET','Etc/GMT-3','Etc/GMT-4','Iran',
                                'Indian/Maldives','Asia/Calcutta','Asia/Kathmandu','Etc/GMT-6',
                                'Asia/Rangoon','Etc/GMT-7','Singapore','Australia/Eucla',
                                'Japan','Australia/North','Australia/South','Australia/Sydney',
                                'Australia/LHI','Australia/Lord_Howe','Etc/GMT-11','NZ',
                                'Pacific/Chatham','Etc/GMT-13','Etc/GMT-14',
                                'Atlantic/Cape_Verde','Etc/GMT+1','Etc/GMT+2','Etc/GMT+3',
                                'US/Eastern','EST','US/Mountain','MST','US/Alaska',
                                'Pacific/Gambier','Pacific/Marquesas','HST',
                                'US/Samoa','Etc/GMT+12', 'America/Los_Angeles']



    const timeZoneNames = offsetTmz.filter(tz => selectedTimeZones.includes(tz.value));


    
    const classes = useStepStyles();
    return (
        <Grid container>
            <Grid item xs>
                <Typography variant={'body1'} className={classes.textFieldLabel}>Name</Typography>
                <TextField value={value} onChange={onChange}
                           variant={'outlined'}
                           size={'small'}
                           fullWidth
                           placeholder={'Add school name'}
                           error={!!error}
                           helperText={error}
                           InputProps={{classes: {root: classes.textFieldContainer}}}
                           inputProps={{className: classes.textFieldInput}}/>
                <Typography variant={'body1'} className={classes.textFieldLabel}>School Timezone</Typography>
                <WiseSelect value={timeZoneValue}
                            error={!!timeZoneError}
                           onChange={onChangeTimeZone}
                           style={{width: 320, height: 24 }}>
                    {timeZoneNames.map( tz => <MenuItem value={tz.value} key={tz.value}>
                                                <Typography variant={'h6'} color={'secondary'}>{`GMT${tz.offset}`} {tz.value}</Typography>
                                        </MenuItem> )}
                </WiseSelect>
                    {!!timeZoneError && <p className={classes.errorTZ}>{timeZoneError}</p>}
            </Grid>
        </Grid>
    );
};

export const Step1Actions = ({onNext}) => {
    return (
        <Grid container justify={'flex-end'}>
            <Grid item>
                <BlueButton onClick={onNext}>Next</BlueButton>
            </Grid>
        </Grid>
    )
};

export const Step2Inputs = ({onNameChange, onEmailChange, email, name, emailError, nameError}) => {
    const classes = useStepStyles();
    return (
        <Grid container direction={'column'}>
            <Grid item xs>
                <Typography variant={'body1'} className={classes.textFieldLabel}>Name</Typography>
                <TextField value={name}
                           onChange={onNameChange}
                           variant={'outlined'}
                           size={'small'}
                           fullWidth
                           placeholder={'Add admin name'}
                           error={!!nameError}
                           helperText={nameError}
                           inputProps={{className: classes.textFieldInput}}/>
            </Grid>
            <Grid item xs>
                <Typography variant={'body1'} className={classes.textFieldLabel}>Email</Typography>
                <TextField value={email}
                           onChange={onEmailChange}
                           variant={'outlined'}
                           size={'small'}
                           fullWidth
                           placeholder={'Add admin email'}
                           error={!!emailError}
                           helperText={emailError}
                           inputProps={{className: classes.textFieldInput}}/>
            </Grid>
        </Grid>
    );
};

export const Step2Actions = ({onAddSchool}) => {
    return (
        <Grid container justify={'flex-end'}>
            <Grid item>
                <BlueButton onClick={onAddSchool}>Add school</BlueButton>
            </Grid>
        </Grid>
    )
};
