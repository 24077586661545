import React, {useState, useEffect} from "react";
import { Route, useHistory } from "react-router-dom";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import  AuthService  from "../../services/auth";
import CssBaseline from '@material-ui/core/CssBaseline';
import Sidebar from "../dashboard/Sidebar";
import NavBar from "../dashboard/NavBar";
import { INIT_INFO } from "../../services/school";
import {GET_PROFESSORS_BANNERS} from '../../services/professors'
import {GET_TOTAL_STUDENTS_BANNER} from '../../services/students'
import { useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import AttendanceCodeContextProvider from "../dashboard/AttendanceCodeContextProvider";

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      minWidth: 1024,
      height: "100%",
      width: "100%",
      minHeight: 768
    },
    toolbar: theme.mixins.toolbar,
    content: {
      paddingLeft: drawerWidth,
      backgroundColor: theme.palette.background.default,
    },
    main: {
      height: "calc(100% - 50px)",
      display: 'flex',
      overflow: 'scroll',
      flexGrow: 1,
      padding: '16px 21px',
      flexDirection: 'column'
    }
  })
);

export default function PrivateRoute({ component: C, appProps, ...rest }) {
  let history = useHistory();
  const classes = useStyles();
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(true);
  const [globalStudentsAtRisk, setGlobalStudentsAtRisk] = useState(null);
  const [myStudentsAtRisk, setMyStudentsAtRisk] = useState(null);


  const [getSemester, {loading}] = useLazyQuery(INIT_INFO, {
    variables: {schoolCode: appProps.schoolCode},
    onCompleted: data => {checkSemester(data)},
    onError: () => setIsLoading(false),
    fetchPolicy: 'network-only'
  }
  );

  const [getStudentsData, _] = useLazyQuery(GET_PROFESSORS_BANNERS, {
    onCompleted: data => { setMyStudentsAtRisk(data && data.professorStudentsBanner.numberOfMyStudentsAtRisk);},
    onError: () => {setIsLoading(false);},
    fetchPolicy: 'network-only'
  });

  const [getTotalStudentsData, _a] = useLazyQuery(GET_TOTAL_STUDENTS_BANNER, {
    onCompleted: data => {setGlobalStudentsAtRisk(data && data.totalStudentsBanner.numberOfStudentsAtRisk);},
    onError: () => {setIsLoading(false);},
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
   getSemester();
   getStudentsData();
   getTotalStudentsData();
  },[appProps.hasSemester]);

  if(loading) return 'Loading...';

  const logout = async () => {
    await AuthService.signOut();
    appProps.userHasAuthenticated(false);
    appProps.setRole(null);
    appProps.setSchool({});
    appProps.setProfile({});
    client.resetStore();
    history.push("/login");
  };
  const goToMainPage = () => {
    history.push('/');
  }

  const checkSemester = data => {
    const school = data.getSchool;
    const profile = data.getUser;
    appProps.setProfile(profile);
    appProps.setSchool(school);
    console.log(school);
    if(appProps.role === 'Admin') {
      if(!school.semesterName) {
        appProps.setSemester(false);
        history.push('/introduction');
      } else if(!school.facilitiesFileUploaded || !school.adminsFileUploaded || !school.professorsFileUploaded || !school.studentsFileUploaded || !school.classesFileUploaded) {
        history.push('/school-setup/upload');
      }
    }
    setIsLoading(false);
  };

  const onSetProfile = profile => {
    appProps.setProfile(profile);
  };

  const sendStudentsAtRiskCount = (role) => {
    if (role === 'Admin') return globalStudentsAtRisk;
    if (role === 'Professor') return myStudentsAtRisk;
    return null
  }
  return (
      !isLoading &&
    <div className={classes.root}>
      <CssBaseline />
      <AttendanceCodeContextProvider>

      <Sidebar onLogoClick={goToMainPage} role={appProps.role} school={appProps.school} studentsAtRisk={sendStudentsAtRiskCount(appProps.role)}/>
      <div style={{width: '100%'}}>
        <NavBar onLogout={logout} {...appProps} role={appProps.role}/>
        <main className={classes.main}>
          <Route {...rest} render={props => <C {...props} {...appProps} setProfile={profile => onSetProfile(profile)}/>} />
        </main>
      </div>
      </AttendanceCodeContextProvider>
    </div>
  );
}
