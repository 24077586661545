import {Button, withStyles} from '@material-ui/core';
import React from "react";
import Switch from "@material-ui/core/Switch";

const BaseButtom = withStyles(theme => ({
    root: {
        ...theme.typography.h5,
        borderRadius: 6,
        border: '1px solid',
        textTransform: 'none',
        minWidth: 100,
        padding: '8px 19px 7px',
        fontWeight: 600,
        color: theme.palette.secondary.main
    },

    sizeSmall: {
        ...theme.typography.caption,
        padding: '5px 10px 4px;',
        lineHeight: 'normal'
    }
}))(Button);

const WhiteButton = BaseButtom;

const NoBorderButton = withStyles(() => ({
    root: {
        border: 'none',
    },
}))(BaseButtom);

const BlueButton = withStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
        },
        "&.MuiButton-root.Mui-disabled": {
            color: theme.palette.common.white,
            opacity: "50%",
            backgroundColor: theme.palette.secondary.main
        }
    },
}))(BaseButtom);

const YellowButton = withStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            color: theme.palette.warning.main,
            backgroundColor: theme.palette.common.white,
        },
    },
}))(BaseButtom);

const FlatBlueButton = withStyles(theme => ({
    root: {
        ...theme.typography.h5,
        textTransform: 'none',
        color: theme.palette.secondary.main,
    },
}))(Button);


const IOSSwitch = withStyles(theme => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: theme.palette.secondary.main,
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: theme.palette.secondary.main,
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[200],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({classes, ...props}) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export {WhiteButton, BlueButton, YellowButton, FlatBlueButton, NoBorderButton, IOSSwitch};
