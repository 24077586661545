import React, { useState, useContext } from 'react';
import { Grid, makeStyles, createStyles, MuiThemeProvider, TextField } from '@material-ui/core';
import { TitleTableTypography, InformationTypography } from '../../../common/typography';
import { WiseCenterTextField, WiseDatePicker } from '../../../common/inputs';
import { OnboardingContext } from "../../../../context/onboarding-context";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiPickersToolbar-toolbar" : {
                backgroundColor: '#37b5ff'
            }, 
            "& .MuiOutlinedInput-root": {
                height: 28,
                color: '#959595',
                fontFamily: 'Avenir Roman',
                fontSize: 16,
                borderRadius: 8,
                width: "80%", 
                textAlign: 'center',
                borderColor: '#E4E4EB'
            },
            "& .MuiInputBase-input": {
                fontSize: 16,
                fontFamily: 'Avenir-Roman',
                textAlign: 'center',
                fontWeight: 'normal'
            }
        }, 
        infoContainer: {
            textAlign: 'left',
            padding: '20px 70px'
        },
        title: {
            fontWeight: 900,
            paddingTop:10,
            color: '#585757'
        }
    }));

export default function TermConfiguration(props) {
    const classes = useStyles();
    const {termInfo, setTermName, setEndDate, setStartDate} = useContext(OnboardingContext);
    const [isStartDateOpen, setIsStartDateOpen] = useState(false);
    const [isEndDateOpen, setIsEndDateOpen] = useState(false);
    const today = new Date();
    const handleNameChange = e => {
        let val = e.target.value
        setTermName(val);
      };

    const handleStartChange = date => {
        const formattedDate = moment(date).format('YYYY-MM-DD')
        setStartDate(formattedDate);
      };
    
    const handleEndChange = date => {
        const formattedDate = moment(date).format('YYYY-MM-DD')
        setEndDate(formattedDate);
      };

    return(
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <TitleTableTypography>Review your school information</TitleTableTypography>
                </Grid>
                <Grid className={classes.infoContainer} justify="space-between" alignItems="flex-start" item container xs={12}>
                    <Grid item xs={12}>
                        <InformationTypography>Name</InformationTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <WiseCenterTextField  
                        placeholder="Add period name" 
                        className={classes.input} 
                        onChange={handleNameChange} 
                        value={termInfo.name} 
                        type="text" 
                        variant="outlined"
                        inputProps={{
                            maxLength: 20
                            
                        }}
                        ></WiseCenterTextField>
                    </Grid>
                    <Grid item xs={12}>
                        <InformationTypography>Start of term</InformationTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <WiseDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            format="MM/DD/YYYY"
                            open={isStartDateOpen}
                            inputProps={{
                                className: classes.input,
                                maxLength: 20
                                
                            }}
                            onOpen={() => setIsStartDateOpen(true)}
                            onClose={() => setIsStartDateOpen(false)}
                            value={termInfo.start}
                            onChange={handleStartChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                        ></WiseDatePicker>
                    </Grid>
                    <Grid item xs={12}>
                        <InformationTypography>End of term</InformationTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <WiseDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            format="MM/DD/YYYY"
                            minDate={termInfo.start}
                            minDateMessage="The end date must be after the start date"
                            open={isEndDateOpen}
                            InputProps={{
                                className: classes.input
                            }}
                            onOpen={() => setIsEndDateOpen(true)}
                            onClose={() => setIsEndDateOpen(false)}
                            value={termInfo.end}
                            onChange={handleEndChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                        ></WiseDatePicker>
                    </Grid>
                </Grid>
            </Grid>
    )
}