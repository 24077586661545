import React, {useState, useEffect} from 'react';
import {  TextField, Grid, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";
import {ReactComponent as PasswordIcon} from '../../assets/show_password.svg';
import { validateRegex } from "../../utils/validators";
import AuthService  from "../../services/auth";

const useStyles = makeStyles(theme => ({
    root: {
      '& .MultiTextField-root': {
          width: 70
      },

      '& .MuiFormLabel-root': {
        fontSize: 14,
        color: "#c3c3c3"
        },
        '& .MuiFormHelperText-root': {
            color: "#f0b000",
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            height: 0
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: "#f0b000"
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: "#29aee6"
        },
        '& .MuiButton-contained': {
            fontFamily: 'Avenir-Medium',
            fontSize: 14,
            backgroundColor: "#37B5FF",
            textTransform: "none",
            color: "white",
            fontWeight: 900
        },
        "& .MuiInputLabel-formControl": {
            top: -6,
            left: -4
        },
        "& .MuiButton-root": {
            textTransform: "none"
        }
    },
    confirmPassword: {
        paddingTop: "3% !important"
    },
    activateButton: {
        paddingTop: "4% !important"
    },
    disabledButton: {
        backgroundColor: "#37b5ff",
        opacity: "40%"
    },
    spacing: {
        paddingTop: "16px",
        height: "5%"
    }
    }));

export default function ActivateAccount(props) {
    const classes = useStyles();
    const [disabledButton, setDisabledButton] = useState(true);
    const [disabledText, setDisabledText] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [form, setForm] = useState({
        password: {
            value: '',
            missingValue: {
                minLength: false,
                upperCase: false,
                number: false,
                // consecutiveChars: false
            },
            errorPassword: false,
        },
        confirmPassword: {
            value: '',
            confirmMatchError: false

        }
    });

    const isFormInvalid = () => {
        return checkPasswordValidation(form.password.missingValue) || checkPasswordValidation(form.password.errorPassword) || form.confirmPassword.confirmMatchError || form.confirmPassword.value === '';
    };


    useEffect(() => {
        setDisabledButton(isFormInvalid());
    }, [form]);

    const setPassword = async (event) => {
        let val = event.target.value;
        let isValid = validateRegex(val)
        let error = checkPasswordValidation(isValid)
       

        if(form.confirmPassword.value !== '') {
            let isNotEqual = val !== form.confirmPassword.value;
            setForm({
                password: {
                    value: val,
                    missingValue: isValid,
                    errorPassword: error
                }, 
                confirmPassword: {
                value: form.confirmPassword.value,
                confirmMatchError: isNotEqual
            }});

        } else {
            setForm({...form, password: {
                value: val,
                missingValue: isValid,
                errorPassword: error
                }
            });
        }
         setDisabledButton(isFormInvalid());
    };

    const setConfirmPassword = (event) => {
        let val = event.target.value;
        let isNotEqual = val !== form.password.value;
        setForm({
            password: {
                value: form.password.value,
                missingValue: form.password.missingValue,
                errorPassword: form.password.errorPassword
            }, 
            confirmPassword: {
            value: val,
            confirmMatchError: isNotEqual
        }});
        setDisabledButton(isFormInvalid());
    }
    
    const checkPasswordValidation = obj => {
        for(const prop in obj) {
            if(obj[prop]) return true;
        }
        return false;
    };

    const handlePassword = () => {
            setShowPassword(!showPassword);
    };

    const handleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!form.confirmPassword.value) {
            setForm({...form, confirmPassword: {
                value: '',
                confirmMatchError: true
            }});
            setDisabledButton(isFormInvalid());
            return; 
        }
        setDisabledText(true);
        try {
            if(await AuthService.completeNewPassword(props.user, form.password.value)){
                props.onChangeMode("success-password", undefined);
            }

        } catch(err) {
            console.log('Ups! something happened', err);
        } finally {
            setDisabledText(false);
        }
        
    }
    const email = props.user && props.user.challengeParam.userAttributes.email
    return(
        <div className="login-container-activate">
        <div className="title">Activate your Account</div>
        <form className={classes.root}>
            <Grid container spacing={6}>
                <Grid item xs={12} className="message-container welcome" style={{paddingBottom: '0', lineHeight: '22pt'}}>
                    <label className="message activate-bold">Welcome {email}</label><br></br>
                    <label className="message activate">To activate your account, please set your new password.</label>
                </Grid>
                <Grid item xs={12}>
                        <TextField
                            className="input"
                            disabled={disabledText}
                            label="New password"
                            placeholder="New password"
                            variant="outlined"
                            onChange={setPassword}
                            onBlur={setPassword}
                            type={showPassword? 'text': 'password'}
                            error={form.password.errorPassword}
                            helperText={form.password.errorPassword ? 
                                <label>
                                <ErrorIcon /> <span style={{position: 'relative', top: '-5px'}}>The password you submitted is missing something.</span>
                                </label> :
                            form.confirmPassword.confirmMatchError?
                            <label>
                                <ErrorIcon /> <span style={{position: 'relative', top: '-5px'}}>The password and its confirmation do not match.</span>
                            </label> : 
                            null
                            }
                            InputProps={{
                                style: {
                                    height: "38px",
                                    fontFamily: "Avenir-medium",
                                    fontSize: 14
                                }
                            }}
                        /> 
                        <IconButton disabled={disabledText} onClick={handlePassword} className="overlay-icon" aria-label="password">
                            <PasswordIcon></PasswordIcon>
                        </IconButton>
                        </Grid>
                <Grid item xs={12}  className={classes.confirmPassword}>
                        <TextField
                            className="input"
                            disabled={disabledText}
                            label="Confirm your password"
                            placeholder="Confirm your password"
                            variant="outlined"
                            onChange={setConfirmPassword}
                            type={showConfirmPassword? 'text': 'password'}
                            error={form.confirmPassword.confirmMatchError}
                            InputProps={{
                                style: {
                                    height: "38px",
                                    fontFamily: "Avenir-medium",
                                    fontSize: 14
                                }
                            }}
                        /> 
                        <IconButton disabled={disabledText} onClick={handleConfirmPassword} className="overlay-icon" aria-label="password">
                            <PasswordIcon></PasswordIcon>
                        </IconButton>
                        </Grid>
                <Grid item xs={12} className="message-container padding-message welcome-validation" style={{paddingTop: '0'}}>
                    <label className={"message activate"}>Your password must have at least:</label><br/>
                    <p className={form.password.missingValue.minLength ? 'error-description': 'message activate'} style={{margin: '0px'}}>* 6 characters.</p>
                    <p className={form.password.missingValue.upperCase ? 'error-description': 'message activate'} style={{margin: '0px'}}>* 1 upper case letter.</p>
                    <p className={form.password.missingValue.number ? 'error-description': 'message activate'} style={{margin: '0px'}}>* 1 number.</p>
                    {/* <p className={form.password.missingValue.consecutiveChars ? 'error-description': 'message activate'} style={{margin: '0px'}}>* Do not use sequential characters (e.g. 3333, 1234, abcdf).</p> */}
                </Grid>
                <Grid item xs={12} className={"button-container " + classes.activateButton}>
                    <Button
                        type='submit'
                        classes={{ disabled: classes.disabledButton }}
                        onClick={onSubmit} disabled={disabledButton} variant="contained" >Activate account</Button> 
                </Grid>
            </Grid>               
    </form>
</div>
    )
}