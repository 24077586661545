import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { ProfileNamedEntry } from "./_CardNamedEntry";
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
  root: {
    width: 400,
  },

  content: {
    '&&': {
      padding: '16px 18px 22px',
    }
  },

  infoRow: {
    marginTop: 15
  }
});

export const ProfileSecurity = ({onChangePassword}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Grid container>

          <Grid item className={classes.title} xs={12}>
            <Typography variant={'h4'} color={'primary'}>SECURITY</Typography>
          </Grid>

          <Grid item className={classes.infoRow} xs={12}>
            <ProfileNamedEntry name={'Password'}>
              <Link component={'button'} color={'secondary'} variant={'h5'} onClick={onChangePassword}>Change my password</Link>
            </ProfileNamedEntry>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProfileSecurity;
