import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { ReactComponent as DownloadTemplateIcon } from '../../../assets/download_template.svg';
import { ReactComponent as UploadIcon } from '../../../assets/upload.svg';
import UploadSuccessIcon from '../../../assets/upload_success.svg';
import UploadErrorIcon from '../../../assets/upload_error.svg';
import Papa from 'papaparse';
import { formatDate, formatFacilitySchema } from "../../../utils/format";
import { formatErrors } from "../../../utils/show_errors_schema";
import { schemas } from "../../../utils/data_schemas";
import { InstructionsTypography } from '../../common/typography';

let Ajv = require("ajv");
let ajv = new Ajv({allErrors: true, jsonPointers: true});
ajv.addKeyword('isNotEmpty', {
    type: 'string',
    validate: function (schema, data) {
        if(schema){
            return typeof data === 'string' && data.trim() !== '';
        }else{
            return true;
        }
    },
    errors: false
});
ajv.addKeyword('isAction', {
    type: 'string',
    validate: function (schema, data) {
        if(schema){
            return typeof data === 'string' && ['ADD', 'UPDATE', 'DELETE'].includes(data.trim());
        }else{
            return true;
        }
    },
    errors: false
});
ajv.addKeyword('isEnclosed', {
    type: 'string',
    validate: function (schema, data) {
        if(schema){
            // return RegExp(/^"[^"]*"$/).test(data);

            return (data[0] === '"' && data[data.length -1] === '"') || (data[0] !== '"' && data[data.length -1] !== '"')
        }else{
            return true;
        }
    },
    errors: false
});
require("ajv-errors")(ajv);

const useStyles = makeStyles(theme => ({
    'uploadItem': {
        margin: theme.spacing(2)
    },
    actionButton: {
        textTransform: "none",
        color: theme.palette.info.light,
        fontSize: 13
    },
    input: {
        display: 'none'
    },
    labels: {
        color: '#585757',
        fontFamily: 'Avenir-Heavy',
        fontWeight: 900,
        fontSize: 13,
        letterSpacing: '0px'
    }
}));

function UploadedInfoItem({
                              index,
                              name,
                              templateUrl,
                              uploadId,
                              inputValue,
                              onchange,
                              schoolNameModified,
                              termNameModified,
                              errors,
                              setErrors,
                              filesToSign,
                              setFilesToSign,
                              updateInfo
                          }) {
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);

    let schema = '';
    const fileName = `${schoolNameModified}_${termNameModified}_${uploadId}.csv`;

    const specialFields = [
        'email',
        'lat',
        'lon',
        'schedule',
        'start_date',
        'end_date',
        'action'
    ]

    function handleInputChange(e){
        setLoading(true);
        onchange(true);
        updateInfo(index, e.target.value);
        const fileToUpload = e.target.files[0];
        let filesToSignCopy = {...filesToSign}
        filesToSignCopy[uploadId] = fileToUpload;
        setFilesToSign(filesToSignCopy);
        schema = getSchema(fileToUpload);
        Papa.parse(fileToUpload, {
            complete: validateData,
            header: true,
            quoteChar: "'",
            transformHeader: (header) => {
                return header.replace(/"/g, '');
            },
            transform: (value, header) => {
                if(specialFields.includes(header)){
                    // Only replace the quotes if the field is enclosed
                    // If it is not enclosed, it becomes undefined, triggering an error

                    // New version, since previous one was causing errors with csv files
                    // generated with excel.

                    if((value[0] === '"' && value[value.length -1] === '"') || (value[0] !== '"' && value[value.length -1] !== '"')){
                        return value.replace(/"/g, '')
                    }else{
                        return value
                    }
                }else{
                    return value;
                }
            }
        });
    }

    const getSchema = (newFile) => {
        const schoolData = newFile.name.split('.csv')[0].split('_');
        if (newFile.name !== fileName) {
            setLoading(false);
            let errorsCopy = {...errors};
            errorsCopy[uploadId] = [`Filename should be: ${fileName}`];
            setErrors(errorsCopy);
            return null;
        }
        return schoolData[2] === 'class' ? 'class_schema' : schoolData[2];
    }

    const validateData = (result) => {
        let data = result.data;
        if(!schema) {
            return;
        }
        if(schema === 'class_schema') {
            data.forEach(obj => {
                obj.start_date = formatDate(obj.start_date);
                obj.end_date = formatDate(obj.end_date);
            });
        } else if(schema === 'facility') {
            data = formatFacilitySchema(data);
        }
        let validate = ajv.compile(schemas[schema]);
        let valid = validate(data);
        // console.log('VALID', valid, 'FOR DATA', data, ' WITHOUT ERRORS')
        if (!valid) {
            
            let newErrors = formatErrors(validate.errors);
            // console.log('INVALID', valid, 'FOR DATA', data, 'HAVING', newErrors)
            let errorsCopy = {...errors};
            errorsCopy[uploadId] = newErrors;
            setErrors(errorsCopy);
            setLoading(false);
        } else {
            let errorsCopy = {...errors};
            errorsCopy[uploadId] = [];
            setErrors(errorsCopy);
            setLoading(false);
        }
    };

    return (
        <Grid container alignItems="center" item xs={12} className={classes.uploadItem}>
            <Grid item sm={12} md={4}>
                <Typography className={classes.labels} component="p" variant="body1">
                    {name}
                </Typography>
                <InstructionsTypography>File name should be: </InstructionsTypography>
            </Grid>
            <Grid item sm={12} md={4}>
                <Button href={templateUrl} className={classes.actionButton}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item>
                            <DownloadTemplateIcon />
                        </Grid>
                        <Grid item>
                            &nbsp;Template
                        </Grid>
                    </Grid>
                </Button>
            </Grid>
            <Grid item sm={12} md={4}>
                <input accept="text/*"
                      className={classes.input}
                      onChange={handleInputChange}
                      id={`${uploadId}-input`}
                      name={`${uploadId}-input`}
                      type="file"
                      value={inputValue}
                />
                <label htmlFor={`${uploadId}-input`}>
                    <Button variant="text" className={classes.actionButton} component="span">
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                {!!filesToSign[uploadId].name && errors[uploadId].length === 0 ?
                                    <img src={UploadSuccessIcon} style={{ height: 24, width: 26 }} alt="successful upload icon"/>
                                    :
                                    errors[uploadId].length > 0 ?
                                        <img src={UploadErrorIcon} style={{ height: 24, width: 26 }} alt="upload error icon"/>
                                        :
                                        <UploadIcon />
                                }
                            </Grid>
                            <Grid item>
                                &nbsp;Upload
                            </Grid>
                        </Grid>
                    </Button>
                </label>
            </Grid>
            <Grid item xs={12}>
                <InstructionsTypography>{fileName}</InstructionsTypography>
            </Grid>
        </Grid>
    );
}

export default UploadedInfoItem;