import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const options = {
    tooltips: {enabled: false},
    cutoutPercentage: 83,
    maintainAspectRatio: false
};

const useStyles = makeStyles(theme => ({

    item: {
        marginTop: 3
    },

    card: {
        padding: 23,
        minHeight: 171,
        height: '100%',
        position: 'relative'
    },

    donutContainer: {
        position: 'absolute',
        zIndex: 0,
        marginTop: -12,
        marginLeft: -8,
        height: 140,
        width: 140
    },

    chartCenter: {
        position: 'absolute',
        zIndex: 100,
        width: 126,
        height: 126,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },

    attendancePercent: {
        fontFamily: 'Avenir',
        fontSize: 28,
        color: theme.palette.text.secondary,
        lineHeight: '28px',
        display: 'flex',
        fontWeight: 900
    },

    attendancePercentCaption: {
        fontFamily: 'Avenir',
        fontSize: 13,
        color: theme.palette.text.secondary,
        lineHeight: '14px',
        letterSpacing: 0,
        display: 'flex'
    },

    attendancesContainer: {
        position: 'absolute',
        left: 200
    },

    studenAtRiskContainer: {
        position: 'absolute',
        left: 170,
        top: 90,
        display: 'flex'
    },

    studenAtRiskPercent: {
        height: 60,
        width: 60,
        backgroundColor: '#f0b000',
        borderRadius: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: 18,
        fontFamily: 'Avenir',
        fontWeight: 900,
        lineHeight: '0px'
    },

    studentAtRiskText: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 110,
        paddingTop: 10,
        paddingLeft: 2
    },

    enrolledStudents: {
        lineHeight: '15px'
    }
}));

const WARNING_COLOR = '#f0b000';
const OK_COLOR = '#37b5ff';

const AttendacePercentCard = ({course}) => {
    const classes = useStyles();

    const data = {
        datasets: [{
            data: [course.courseAttendancePercentage, 100 - course.courseAttendancePercentage],
            backgroundColor: [course.courseAttendancePercentage < 75 ? WARNING_COLOR : OK_COLOR, 'rgb(232, 232, 232)'],
            hoverBackgroundColor: [course.courseAttendancePercentage < 75 ? WARNING_COLOR : OK_COLOR, 'rgb(232, 232, 232)']
        }]
    };

    return (
        <Card classes={{root: classes.card}}>
            <div style={{marginLeft:'50px'}}>
                <div className={classes.donutContainer}>
                    <Doughnut data={data} options={options}/>
                </div>
                <div className={classes.chartCenter}>
                    <div className={classes.attendancePercent}>{course.courseAttendancePercentage}%</div>
                    <div className={classes.attendancePercentCaption}>Attendance percentage</div>
                </div>

                <div className={classes.attendancesContainer}>
                    <div>
                        <Typography variant={'h5'}><strong> Max. of possible attendances: </strong>{course.maxAttendancesCount}</Typography>
                    </div>
                    <div>
                        <Typography variant={'h5'}><strong> Number of recorded attendances: </strong>{course.recordedAttendancesCount}</Typography>
                    </div>
                </div>

                <div className={classes.studenAtRiskContainer}>
                    <div className={classes.studenAtRiskPercent} style={{backgroundColor: course.courseAttendancePercentage < 75 ? WARNING_COLOR : OK_COLOR}}>{course.courseAtRiskPercentage}%</div>
                    <div className={classes.studentAtRiskText}>
                        <Typography variant={'h5'}>
                            <strong>Students at risk</strong>
                        </Typography>
                        <Typography variant={'h5'} className={classes.enrolledStudents}>
                            {course.studentsAtRisk} of {course.studentsEnrolled} enrolled students
                        </Typography>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default AttendacePercentCard;