import React from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import * as classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import AddSchoolsSteps from "./_AddSchoolsStepBar";
import {makeStyles} from "@material-ui/core/styles";
import step1Img from "../../../../assets/yourschools/Onboarding-5@1x.png";
import WiseLogoIcon from "../../../../assets/logo_wise_white.png";
import blueImageBackground from "../../../../assets/yourschools/Blue_curved_bg@1x.png";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 100
    },

    paper: {
        height: 465,
        width: 800,
    },

    image: {
        width: 355,
        height: 465,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },

    imageStep1: {
        backgroundImage: `url(${step1Img})`,
    },

    headerImg: {
        height: 105,
        width: '100%',
        backgroundImage: `url(${blueImageBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },

    headerText: {
        paddingTop: 30
    },

    steps: {
        padding: '14px 0'
    }
}));

const _AddSchoolLayout = ({step, headerText, controlsComponent, actionsComponent}) => {
    const classes = useStyles();

    return (
        <Grid container direction={'column'} alignItems={'center'} className={classes.container}>
            <Grid item>
                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid item className={classnames(classes.image, classes.imageStep1)}/>

                        <Grid container xs item direction={'column'} alignItems={'center'}>
                            <Grid item container className={classes.headerImg} alignItems={"center"} justify={'center'}>
                                <img src={WiseLogoIcon} alt="" width={110}/>
                            </Grid>

                            <Grid item className={classes.headerText}>
                                <Typography color={'textSecondary'} variant={'h1'}>{headerText}</Typography>
                            </Grid>

                            <Grid item container xs alignItems={'center'} justify={'center'} style={{width: 290}}>
                                {controlsComponent}
                            </Grid>

                            <Grid item className={classes.steps}>
                                <AddSchoolsSteps step={step}/>
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>

                <div style={{float: "right", marginTop: '40px'}}>
                    {actionsComponent}
                </div>
            </Grid>
        </Grid>
    );
};

export default _AddSchoolLayout;
