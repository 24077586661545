import React, {useState} from 'react';
import {
    Card,
    CardActions,
    CardContent,
    Grid,
    makeStyles
} from '@material-ui/core';
import {TitleTypography, BodyTypography} from "../../common/typography";
import {BlueButton} from "../../common/buttons";
import {ConfirmDialog, SuccessDialog} from "../../common/dialog";
import useDialog from "../../../utils/hooks/useDialog";
import { ACTIVATE_ACCOUNTS } from "../../../services/school";
import { useMutation } from '@apollo/react-hooks';


const useStyles = makeStyles(theme => ({
        root: {
          flexGrow: 1,
          height: '100%'
        },
        card: {
            padding: theme.spacing(2),
            background: 'rgb(255, 255, 255)',
            boxShadow: '0px 2px 3px 0px rgba(83, 85, 86, 0.11), 0px 2px 9px 0px rgba(71, 76, 81, 0.09)',
            borderRadius: '6px',
            height: '100%'
        }
    })
);

export default function ActivateNewAccount({schoolCode, semester}) {
    const classes = useStyles();
    const [totalSent, setTotalSent] = useState(0);

    // Confirmation dialog (send emails)
    const {visible, toggle, onCancel, onClose, onOk} = useDialog(false);
    // Success dialog (emails sent)
    const successDialogHook = useDialog(false);

    function handleOk() {
        activateAccounts().then(() => {
            onOk();
            successDialogHook.toggle();
        });
    }

    function closeWindow(){
        successDialogHook.onClose();
    }

    const [activateAccounts] = useMutation(ACTIVATE_ACCOUNTS, {
        variables: {
            input: {
                school: schoolCode,
                action: 'create',
                semester: semester
            }
        },
        onCompleted: (data) => {
            console.log(data);
            setTotalSent(data.populateCognito.numUsers);
        }
    });

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <form>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TitleTypography>ACTIVATE NEW ACCOUNTS</TitleTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <BodyTypography>
                                    Send an email with temporary credentials to all newly added users (students, professors, or administrators).
                                    This will enable them to access Wise Attend.
                                </BodyTypography>
                            </Grid>
                            
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid
                            justify="space-between" container>
                            <Grid item/>
                            <Grid item>
                                <BlueButton
                                    onClick={toggle}
                                    color="primary">
                                    Send credentials
                                </BlueButton>
                            </Grid>
                        </Grid>
                    </CardActions>
                </form>
            </Card>
            <div>
                <ConfirmDialog title="This action will send activation emails" showDialog={visible} onClose={onClose}
                               onOk={handleOk} onCancel={onCancel}>
                    Send credentials to all new users in the platform so they can activate their account.
                </ConfirmDialog>
            </div>
            <div>
                <SuccessDialog title="Accounts activation emails sent" showDialog={successDialogHook.visible} onClose={successDialogHook.onClose}
                               onOk={closeWindow} >
                    {totalSent > 0 ? 
                        `We've sent an email to ${totalSent} users, with credentials to the platform so they can activate their accounts.` :
                        "No new users to invite were found."
                    }
                </SuccessDialog>
            </div>
        </div>
    )
}