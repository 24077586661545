import React, {useEffect, useState} from 'react';
import image from "../../../assets/onboarding/courses.png";
import AttendanceGraphic from '../../graphics/Attendance';
import {
    createStyles,
    FormControl,
    FormControlLabel,
    Grid,
    makeStyles,
    Paper,
    Switch,
    Typography
} from '@material-ui/core';
import CircleInfo from "../../common/CircleInfo";
import {getCoursesGraphinfo, getCoursesSummary, getProfCourses, getAdminChartsInfo, getAdminCirclesInfo} from "../../../services/courses";
import CourseCard from './CourseCard';
import {IOSSwitch} from "../../common/buttons";
import {getCourses} from "../../../services/coursesMockData";
import _CoursesSummary from "./_CoursesSummary";
import {useQuery} from "@apollo/react-hooks";
import moment from 'moment';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            maxWidth: 1400,
            marginTop: 26
        },
        dataContainer: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: 5,
            marginBottom: 35,
            padding: 15
        },
        borders: {

            borderLeftWidth: 2,
            borderLeftColor: '#E8E8E8',
            borderRightColor: '#E8E8E8',
            borderRightWidth: 2,
            borderStyle: 'solid',
            borderBottom: 0,
            borderTop: 0,
            textAlign: 'center',
            height: 110
        },
        infoTotal: {
            paddingLeft: 15,
            textAlign: 'left'
        },
        textCoursesSummary: {
            color: '#9a9a9a',
            paddingTop: 10
        },
        filterLabel: {
            fontSize: 13,
            fontWeight: 'normal',
            color: '#959595'
        }
    })
);

const LoadingContainer = (props) => {
    return(<Paper className={props.class}>
        <Grid container alignItems='center'>
            <Typography variant={'h2'} color={'primary'}><strong>Loading...</strong></Typography>
        </Grid>
    </Paper>)
}

export default function Courses({role, school}) {
    const classes = useStyles();

    const [allCourses, setAllCourses] = useState([]);

    const tooltip = '% attendance percentage';
    const [typeChart, setTypeChart] = useState('monthly');

    const [isLowAttendance, setIsLowAttendance] = useState(false);
    const [atRiskPercentage, setAtRiskPercentage] = useState(0);

    const [coursesSummary, setCoursesSummary] = useState(null);

    const [adminCoursesGraphInfo, setAdminCoursesGraphInfo] = useState(null);

    useQuery(getProfCourses, {
        pollInterval: 1000 * 60 * 1,
        fetchPolicy: 'cache-and-network',
        onError: (err) => {
            console.log(err);
            setAllCourses([]);
        },
        onCompleted: (data) => {
            let newCourses = data.listCourses.courses;
            let courseInProgress = newCourses.findIndex(el => el.courseInProgress);
            if (courseInProgress > 0 && role === 'Professor') {
                [newCourses[0], newCourses[courseInProgress]] = [newCourses[courseInProgress], newCourses[0]];
            }
            // console.log('NC', newCourses)
            setAllCourses(newCourses);
        }
    });

    useQuery(getAdminChartsInfo, {
        onError: (err) => {
            console.log(err);
        },
        onCompleted: (data) => {
            setAdminCoursesGraphInfo(data)
        }
    });
    
    useQuery(getAdminCirclesInfo, {
        onError: (err) => {
            console.log(err);
        },
        onCompleted: (data) => {
            setCoursesSummary(data.getOverallAttendance)
        }
    });

    const prepareLabels = (data) => {
        if(isEmpty(school)) return
        const resultLabels = []
        if (typeChart === 'weekly'){
            data.forEach((_, index) => resultLabels.push(`Week ${index+1}`))
            return resultLabels
        }
        let date = moment(school.semesterStartDate.split('T')[0]).startOf('month')
        data.forEach(() => {
            resultLabels.push(moment(date).format('MMMM'))
            date = moment(date).clone().add(1, 'month')
        })
        return resultLabels
    }

    const preparePointsData = (pointsData) => {
        return pointsData.map(item => item.y)
    }

    const formatDataForChart = (inputData) => {
        if(!inputData) return

        const data = []

        const label = 'All school students attendance';
        const candidateData  = typeChart === 'weekly' ? inputData.globalAttendancePerWeek : inputData.globalAttendancePerMonth;
        const values = preparePointsData(candidateData)
        values.shift()
        const labels = prepareLabels(values)
        data.push({label, values})

        // TO DO: ADD DATA TO COMPARE STUDENTS DATA WHEN BEING A PROFESSOR

        return {
            labels,
            data
        }
    }

    useEffect(() => {
        if (school) {
            setAtRiskPercentage(school.atRiskPercentage);
        } else {
            setAtRiskPercentage(0);
        }
    }, [typeChart, school]);

    const filterAttendance = () => {
        setIsLowAttendance(!isLowAttendance);
    };

    let filteredCourses = allCourses;
    if(isLowAttendance) {
        filteredCourses = allCourses.filter(course => course.courseAttendancePercentage <= school.atRiskPercentage);
    }
    let chartData = null
    if (adminCoursesGraphInfo){
        chartData = formatDataForChart(adminCoursesGraphInfo)
    }

    return (
        <div className={classes.root}>
            <Typography variant={'h1'} color={'primary'}>{role === 'Admin' ? 'Courses' : 'My Courses'}</Typography>

            {
                role === 'Admin' ? coursesSummary ? <_CoursesSummary coursesSummary={coursesSummary}/> : <LoadingContainer class={classes.dataContainer}></LoadingContainer> : null
            }

            {chartData ?<Paper elevation={3} className={classes.dataContainer}>
                 <AttendanceGraphic
                    onChangePeriod={val => setTypeChart(val)}
                    tooltipText={tooltip}
                    title='Attendance over the term'
                    labels={chartData.labels}
                    data={chartData.data}
                ></AttendanceGraphic>
            </Paper> : <LoadingContainer class={classes.dataContainer}></LoadingContainer>}
            <Grid container justify="space-between">

                <Grid item xs={3}>
                    <Typography variant={'h4'} className={classes.textCoursesSummary}>
                        {allCourses.length} courses are currently active
                    </Typography>
                </Grid>

                <Grid item xs={3} container justify={'flex-end'}>
                    <FormControl style={{marginRight: 10}}>
                        <FormControlLabel
                            value={isLowAttendance}
                            className={classes.filterLabel}
                            onChange={filterAttendance}
                            control={<IOSSwitch/>}
                            label="Low Attendance"
                            labelPlacement="start"
                        >
                        </FormControlLabel>
                    </FormControl>
                </Grid>
            </Grid>
            {
                filteredCourses.map((course, index) => (<CourseCard key={index} course={course} role={role}
                                                                   minimumAttendancePercentage={atRiskPercentage}/>))
            }
        </div>
    )
}
