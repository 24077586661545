import AvenirRoman from './assets/fonts/3B073E_0_0.woff2';
import AvenirHeavy from './assets/fonts/3B073E_1_0.woff2';

import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const fontFamilyRegular = (family) => ({
  fontFamily: family,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '100 500',
  src: `local('Avenir-Roman'), url(${AvenirRoman}) format('woff2')`
});

const fontFamilyBold = (family) => ({
    fontFamily: family,
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '600 1000',
    src: `local('Avenir-Heavy'), url(${AvenirHeavy}) format('woff2')`
});

const FONT_FAMILY_NAMES = ['Avenir', 'Avenir-Medium', 'Avenir-Heavy', 'Avenir-Roman'];

const fontFamilies = FONT_FAMILY_NAMES.reduce((arrayOutput, current) => {
    arrayOutput.push(fontFamilyRegular(current));
    arrayOutput.push(fontFamilyBold(current));
    return arrayOutput
}, []);

const appTheme = createMuiTheme({
  palette: {
    primary: { main: '#177acc' },
    secondary: { main: '#37b5ff' },
    warning: { main: '#f0b000' },
    action: {active: '#5cbbf0'},
    background: { default: '#f6faff', paper: '#ffffff' },
    text: { primary: '#757373', secondary: '#858585' },
    info: {main: '#858585', gray: '#acabab'},
    common: {white: '#fff'}
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': fontFamilies,
      },
    },
  },
  typography: {
    fontFamily: ['Avenir'].join(','),
    h1: {
      fontSize: 26,
      lineHeight: '32px',
      fontWeight: 600,
    },
    h2: {
      fontSize: 20,
      lineHeight: '26px',
      fontWeight: 500,
    },
    h3: {
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 500,
    },
    h4: {
      fontSize: 14,
      lineHeight: 'normal',
      fontWeight: 500,
    },
    h5: {
      fontSize: 13,
      lineHeight: 'normal',
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      lineHeight: '27px',
      fontWeight: 500,
    },
    body2: {
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 500,
    },
    caption: {
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 500,
    },
    info: {
      fontSize: 11,
      fontWeight: 500,
    },
    h6: {
      fontSize: 11,
      fontWeight: 500,
    }
  }
});

export default appTheme;
