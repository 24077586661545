import React, { useState, useEffect } from 'react';
import {Doughnut, Chart} from 'react-chartjs-2';
import { makeStyles, createStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) =>
    createStyles({

        graphic: {
            height: "100%",
            width: '90%'
        }
    })
);
Chart.pluginService.register({
    beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
    //Get ctx from string
    var ctx = chart.chart.ctx;
    
    var centerConfig = chart.config.options.elements.center;
    var fontStyle = centerConfig.fontStyle || 'Avenir-Medium';
    let txt = centerConfig.text1;
    let color = centerConfig.color1 || '#000088';
    
    let fontSizeToUse = centerConfig.fontSize1;

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
    let centerY = (2*(chart.chartArea.top + chart.chartArea.bottom) / 5);
    ctx.font = fontSizeToUse+"px " + fontStyle;
    ctx.fillStyle = color;
    
    ctx.fillText(txt, centerX, centerY);

    txt = centerConfig.text2;
    color = centerConfig.color2 || '#000088';
    
    fontSizeToUse = centerConfig.fontSize2;

            //Set font settings to draw it correctly.
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
    centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
    ctx.font = fontSizeToUse+"px " + fontStyle;
    ctx.fillStyle = color;
    
    ctx.fillText(txt, centerX, centerY);

    txt = centerConfig.text3;
    color = centerConfig.color2 || '#000088';
    
    fontSizeToUse = centerConfig.fontSize2;

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
    centerY = (3*(chart.chartArea.top + chart.chartArea.bottom) / 5);
    ctx.font = fontSizeToUse+"px " + fontStyle;
    ctx.fillStyle = color;
    
    //Draw text in center
    ctx.fillText(txt, centerX, centerY);
        }
    }
});/**/

export default function DoughnutWithText({attendancePercentage, isLowAttendance, atRiskPercentage, studentsEnrolled, studentsAtRisk}) {
    const [graphicData, setGraphicData] = useState({});
    const classes = useStyles();
    useEffect(() => {
        setData();
    }, [attendancePercentage, isLowAttendance, atRiskPercentage, studentsEnrolled, studentsAtRisk]);
    const setData = () => {
        let nonAttendance = 100-attendancePercentage;
        let backgroundColor = isLowAttendance ? '#f0b000' :'#37b5ff'
        setGraphicData({
            labels: ['Attendance percentage', 'Non attendance percentage'],
            datasets: [{
                data: [attendancePercentage, nonAttendance],
                backgroundColor: [backgroundColor, '#e8e8e8'],
                borderWidth: 0
            }]
        })
    };

    const options ={
        cutoutPercentage: 90,
        legend: {
            display: false
        },
        responsive: false,
        elements: {
            center: {
                text1: `${attendancePercentage} %`,
                text2: `Attendance`,
                text3: 'percentage',
                color1: '#848484',
                color2: '#848484',
                fontSize1: 28,
                fontSize2: 14,
                fontStyle: 'Avenir-Medium',
                sidePadding: 20
            }
        },
        tooltips: {
            enabled: false
       },
        animation: {
            duration: 0, // general animation time
        },
    };

    return(
            <Doughnut height={160} width={160} options={options} data={graphicData}></Doughnut>
    )
}