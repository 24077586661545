import React from 'react';
import {Card, Button} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {makeStyles} from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import moment from "moment";
import placeholderImage from '../../../../assets/yourschools/Onboarding1@1x.png';


const useStyles = makeStyles({
    container: {
        height: 152,
    },

    cardContent: {
        '&&': {
            padding: 0,
        }
    },

    schoolImg: {
        width: 153,
        height: 153,
        backgroundImage: `url(${placeholderImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },

    infoContainer: {
        padding: '20px 15px 16px 12px'
    },

    infoTitle: {
        fontWeight: 900,
    },

    deleteBtn: {
        padding: '5px 10px 5px 16px',
        textTransform: 'none',
        fontWeight: 900,
        position: 'absolute',
        right: 20,
        bottom: 20
    }
});


const SchoolCard = ({onSeeAdmins, schoolCode, name, createdAt, onDelete}) => {
    const classes = useStyles();
    return (
        <Card className={classes.container}>
            <CardContent classes={{root: classes.cardContent}}>
                <Grid container>
                    <Grid item className={classes.schoolImg}>
                    </Grid>

                    <Grid item xs container direction={'column'}
                          className={classes.infoContainer} spacing={1}
                          style={{position: 'relative'}}>
                        <Grid item>
                            <Typography variant={'h2'} color={'primary'} className={classes.infoTitle}>
                                {name}
                            </Typography>
                        </Grid>
                        <Grid item><Typography variant={'body2'}>ID {schoolCode}</Typography></Grid>
                        <Grid item><Typography variant={'body2'}>Active
                            since: {moment(createdAt).format('MMMM Do')}</Typography></Grid>
                        <Grid item>
                            <Link component={'button'} onClick={onSeeAdmins} color={'secondary'}>
                                <Typography variant={'body2'}>See Admins</Typography>
                            </Link>
                        </Grid>
                        <Button color={'primary'} variant={'contained'} className={classes.deleteBtn}
                                onClick={onDelete}>
                            <Typography variant={'caption'} color={'inherit'} style={{fontWeight: 900, marginRight: 5}}>Delete</Typography>
                            <DeleteIcon fontSize={"small"} height={14} width={14}/>
                        </Button>
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    );
};

export default SchoolCard;