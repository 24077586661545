import React, { useState, useContext } from 'react';
import Papa from 'papaparse';
import { Paper, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { InstructionsTypography } from '../common/typography';
import UploadBefore from './upload_types/UploadBefore';
import UploadSuccessfull from './upload_types/UploadSuccessfull';
import UploadError from './upload_types/UploadError';
import { formatDate, formatFacilitySchema } from "../../utils/format";
import { schemas } from "../../utils/data_schemas";
import { formatErrors } from "../../utils/show_errors_schema";
import Modal from '@material-ui/core/Modal';
import ViewCSV from "./ViewCSV";
import ErrorComponent from '../common/ErrorComponent';
import ErrorTemplate from "../common/ErrorTemplate";
import { OnboardingContext } from '../../context/onboarding-context';
import { Storage } from 'aws-amplify';
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UPDATE_SCHOOL, GET_SIGNED_URL } from "../../services/school";
import axios from "axios";

let Ajv = require("ajv")
let ajv = new Ajv({allErrors: true, jsonPointers: true});
ajv.addKeyword('isNotEmpty', {
  type: 'string',
  validate: function (schema, data) {
      if(schema){
          return typeof data === 'string' && data.trim() !== '';
      }else{
          return true;
      }
  },
  errors: false
});
ajv.addKeyword('isAction', {
    type: 'string',
    validate: function (schema, data) {
        if(schema){
            return typeof data === 'string' && ['ADD', 'UPDATE', 'DELETE'].includes(data.trim());
        }else{
            return true;
        }
    },
    errors: false
});
ajv.addKeyword('isEnclosed', {
  type: 'string',
  validate: function (schema, data) {
      if(schema){
        // console.log('TESTING ISENCLOSED FOR ', schema, data )
        //   return RegExp(/^"[^"]*"$/).test(data);
        return (data[0] === '"' && data[data.length -1] === '"') || (data[0] !== '"' && data[data.length -1] !== '"')
      }else{
          return true;
      }
  },
  errors: false
});
require("ajv-errors")(ajv)



const useStyles = makeStyles(theme => ({
    root: {
    //   display: 'flex',
    //   flexWrap: 'wrap',
    minHeight: 250,
    minWidth: 300
    },
    input: {
        display: "none"
    },
    container: {
        paddingTop: 20,
        paddingBottom: 8
    },
    containerUpload: {
        padding: "10% 10%"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    backGround: {
        backgroundColor: 'white'
    }
  }));

export default function UploadCSV({name,fileName, warning, type, schoolId, onboardingLinks}) {
    const classes = useStyles();
    const [file, setFile] = useState(null);
    const [fileToSign, setFileToSign] = useState({name: '', type: ''});
    const [openView, setOpenView] = useState(false);
    const [openErrors, setOpenErrors] = useState(false);
    const [openNameError, setOpenNameError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);
    let fileToUpload = null;
    const {setUploadStepsObject ,uploadSteps, termInfo, uploadMode, setNewUploadMode} = useContext(OnboardingContext)

    let schema = '';
    const handleClose = () => {
      setOpenView(false);
      setOpenErrors(false);
      setOpenNameError(false);
    };
    const [update] = useMutation(UPDATE_SCHOOL, 
        {
            onCompleted: data => nextStep(data)
        });

    const [requestSignedURL] = useMutation(GET_SIGNED_URL, {
        onCompleted: (data) => completeUpload(data)
    });

    const  getSchema = (newFile) => {
        const schoolData = newFile.name.split('.csv')[0].split('_');
        if (newFile.name !== fileName) {
          setOpenNameError(true);
          setLoading(false);
          return null;
        }
         return schoolData[2] === 'class' ? 'class_schema' : schoolData[2];
      }

      const specialFields = [
        'email',
        'lat',
        'lon',
        'schedule',
        'start_date',
        'end_date',
        'action'
    ]

    const onChange = (e) => {
        setFileToSign(e);
        setLoading(true);
        fileToUpload = e;
        let reader = new FileReader();
        reader.onload = () => {
            setFile(reader.result)
        };
        reader.readAsBinaryString(e);
        schema = getSchema(e);
        Papa.parse(e, {
            complete: validateData,
            header: true,
            quoteChar: "'",
            transformHeader: (header) => {
                return header.replace(/"/g, '');
            },
            transform: (value, header) => {
                if(specialFields.includes(header)){
                    // Only replace the quotes if the field is enclosed
                    if((value[0] === '"' && value[value.length -1] === '"') || (value[0] !== '"' && value[value.length -1] !== '"')){
                        // console.log('ORIGINAL', value);
                        return value.replace(/"/g, '')
                    }else{
                        return value
                    }
                }else{
                    return value;
                }
            }
        })
    };

    const openPreview = () => {
        setOpenView(true);
    }

    const validateData = (result) => {

        let data = result.data;
        if(!schema) {
          return;
        }
        if(schema === 'class_schema') {
            data.forEach(obj => {
                obj.start_date = formatDate(obj.start_date);
                obj.end_date = formatDate(obj.end_date);
            });
        } else if(schema === 'facility') {
            data = formatFacilitySchema(data);
        }
        let validate = ajv.compile(schemas[schema]);
        let valid = validate(data);
        // console.log('VALID', valid, 'FOR DATA', data, ' WITHOUT ERRORS')
        if (!valid) {
            let newErrors = formatErrors(validate.errors);
            // console.log('INVALID', valid, 'FOR DATA', data, 'HAVING', newErrors)
            setErrors(newErrors);
            setOpenErrors(true);
            setNewUploadMode('error');
            setLoading(false);
        } else {
           uploadFile();
        }
        
      };
    const openErrorsView = () => {
        setOpenErrors(true);
    } 

    const uploadFile = async () => {
        requestSignedURL({
            variables: {
                input: {
                    name: fileToUpload.name,
                    type: fileToUpload.type
                }
            }
        });
        // await Storage.put(fileName, fileToUpload, {level: 'public'});
        
    };

    const completeUpload = data => {
        const options = {
            headers: {
              "Content-Type": "text/csv"
            }
        }; 
        axios.put(data.signURL.signedRequestUrl,fileToSign, options).then(res =>{
            let input = {schoolCode: schoolId};
            if(type === 'facilities') input.facilitiesFileUploaded = true;
            else if(type === 'students') input.studentsFileUploaded =  true;
            else if(type === 'professors') input.professorsFileUploaded =true;
            else if(type === 'courses') input.classesFileUploaded = true;
            else if(type === 'administrators') input.adminsFileUploaded = true;
            update({
                variables: {
                    input: input,
                },
            })
          
        }).catch(err => {
            setLoading(false);
        }) 
            
    };

    const nextStep = data => {
        setUploadStepsObject({...uploadSteps, [type]: true});
        setNewUploadMode('success');
        setLoading(false);
    }
 
    const downloadTemplate = () => {
        window.open(onboardingLinks[type]);
    };
    

    return(<Paper elevation={5} className={classes.root}>
        <Grid className={classes.container} container direction="column" justify="center" alignItems="center" spacing={1}>
            <Grid item xs={12}>
                <InstructionsTypography>Upload the {name} file <br/> containing all the required information.</InstructionsTypography>
            </Grid>
           {
                uploadMode === 'before' ?
                <Grid item xs={12}>
                    <InstructionsTypography>The name of your file must be: </InstructionsTypography>
                    <InstructionsTypography>{fileName}</InstructionsTypography>
               </Grid> : null
           }
            {{
                "before": <UploadBefore onDownloadTemplate={downloadTemplate} type={type} onboardingLinks={onboardingLinks} loading={isLoading} warning={warning} name={name}  onChange={onChange}/>,
                "success": <UploadSuccessfull onChange={openPreview} />,
                "error": <UploadError onOpenErrors={openErrorsView} onChange={onChange} onOpenPreview={openPreview}/>
            }[uploadMode]}
        </Grid>
        
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openView}
            onClose={handleClose}
            className={classes.modal}
        >
        <ViewCSV onClose={handleClose} data={file} header={true}></ViewCSV>
        </Modal>

        <Modal
            disableBackdropClick
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openErrors}
            onClose={handleClose}
            className={classes.modal}
        >
            <ErrorComponent onClose={handleClose} errors={errors}></ErrorComponent>
        </Modal>
        
        <Modal
            disableBackdropClick
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openNameError}
            onClose={handleClose}
            className={classes.modal}
        >
            <ErrorTemplate 
            onClose={handleClose}
            title="The file could not be uploaded"
            desc={`The name of the file should be ${fileName}`}
            >
            </ErrorTemplate>
        </Modal>
    </Paper>)
} 