import React, { useContext } from 'react';
import { Grid, makeStyles, createStyles, InputAdornment } from "@material-ui/core";
import { TitleTableTypography, SubtitleOnboardingTypography, SubtitleTypography } from '../../../common/typography';
import { WiseRightTextField } from '../../../common/inputs';
import { OnboardingContext } from "../../../../context/onboarding-context";

const useStyles = makeStyles((theme) => 
    createStyles({
        input:{
            textAlign:'right'
        },
        infoContainer: {
            textAlign: 'center',
            padding: '20px 70px'
        },
        title: {
            fontWeight: 900,
            paddingTop:10,
            color: '#585757'
        },
        centerAdornment: {
            marginRight: "40%" // or your relevant measure
          },
    })
);

export default function AttendanceConfiguration() {
    const classes = useStyles();
    const {termInfo, setPercentageAndTolerance} = useContext(OnboardingContext);
    const changePercentage = e => {
        let { value, min, max } = e.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setPercentageAndTolerance(value, termInfo.tolerance);
    };
    return(
        <Grid item container xs={12}>
        <Grid item xs={12}>
            <TitleTableTypography>Attendance alert <br/> threshold </TitleTableTypography>
        </Grid>
        <Grid className={classes.infoContainer} justify="space-between" alignItems="flex-start" item container xs={12}>
            <Grid item xs={12}>
                <SubtitleOnboardingTypography>Minimum attendance <br/> percentage</SubtitleOnboardingTypography>
            </Grid>
            <Grid item xs={12}>
                <WiseRightTextField 
                type="number"
                min="0"
                max="100" 
                step="10"
                value={termInfo.minimumPercentage}
                onChange={changePercentage}
                variant="outlined" 
                className={classes.input}
                inputProps={{
                    min:"0",
                    max: "100",
                    step: "10",
                }}
                InputProps={{
                    className: classes.input,
                    endAdornment: (
                        <InputAdornment 
                             position="end"
                            classes={{ positionEnd: classes.centerAdornment}}
                        >
                            %
                        </InputAdornment>
                    )
                }}
                />
            </Grid>
            <Grid item xs={12}>
                <SubtitleTypography>Attendance required to pass</SubtitleTypography>
            </Grid>
        </Grid>
    </Grid>
    )
}