import React, { useState } from 'react';
import {  TextField, Grid, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";
import {ReactComponent as PasswordIcon} from '../../assets/show_password.svg';
import { validateRegex } from "../../utils/validators";
import AuthService from '../../services/auth';

const useStyles = makeStyles(theme => ({
    root: {
      '& .MultiTextField-root': {
          width: 70
      },
      '& .MuiFormLabel-root': {
        fontSize: 14,
        color: "#c3c3c3"
        },
        '& .MuiFormHelperText-root': {
            color: "#f0b000",
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: "#f0b000"
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            height: 0
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: "#29aee6"
        },
        "& .MuiInputLabel-formControl": {
            top: -6,
            left: -4
        },
        "& .MuiButton-root": {
            textTransform: "none",
            width: "auto"
        },
    },
    disabledButton: {
        backgroundColor: "#37b5ff",
        opacity: "40%"
    },
    passwordButton: {
        paddingTop: "2% !important"
    },
    }));


function CreatePassword(props) {

    const classes = useStyles();
    const [form, setForm] = useState({
        code: {
            value: '',
            requiredError: false
        },
        password: {
            value: '',
            missingValue: {
                minLength: false,
                upperCase: false,
                number: false,
                // consecutiveChars: false
            },
            errorPassword: false,
        } 
    });
    const [disabledText, setDisabledText] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [incorrectPassword, setIncorrectPassword] = useState(false);


    const handlePassword = () => {
        setShowPassword(!showPassword);
    };

    const setCode = (e) => {
        setIncorrectPassword(false);
        let val = e.target.value;
        setForm({...form, code: {
            value: val,
            requiredError: val? false: true
        }});
        setDisabledButton(isFormInvalid());
    };

    const setPassword = (event) => {
        let val = event.target.value;
        let isValid = validateRegex(val)
        let error = checkPasswordValidation(isValid)
        setForm({...form, password: {
                value: val,
                missingValue: isValid,
                errorPassword: error
            }
        });

         setDisabledButton(isFormInvalid());
    };

    const isFormInvalid = () => {
        return checkPasswordValidation(form.password.missingValue)|| form.password.errorPassword || form.code.requiredError;
    };

    const checkPasswordValidation = obj => {
        for(const prop in obj) {
            if(obj[prop]) return true;
        }
        return false;
    };

    const onSubmit = async () => {
        try {
            setDisabledText(true);
            const res = await AuthService.submitForgotPassword(props.user, form.code.value, form.password.value)
            if(!res){
                setIncorrectPassword(true);
            }
            else {
                props.onChangeMode("success-password", undefined);
            }
        } catch(err) {
            console.log('Ups, something happened');
        } finally {
            setDisabledText(false);
        }
    };

    const incorrectPasswordLabel = <label><ErrorIcon /> <span className="error-description">Invalid verification code provided. Please try again.</span></label>

    return (
    <div className="login-container-password">
        <div className="title">Create a new password</div>
        <form className={classes.root}>
            <Grid container spacing={6}>
                <Grid item xs={12} style={{paddingBottom: '0'}}>
                    <label className="message">Please check your email</label><br></br>
                    <label className="message">We've sent you a code to verify your identity</label>
                </Grid>
                <Grid item xs={12}>
                <TextField
                    InputProps={{
                        style: {
                            height: "38px",
                            fontFamily: "Avenir-medium",
                            fontSize: 14
                        }
                    }}
                    className="input"
                    type="number"
                    onChange={setCode}
                    onBlur={setCode}
                    error={form.code.requiredError}
                    helperText={form.code.requiredError ? 
                        <label>
                            <ErrorIcon /> <span style={{position: 'relative', top: '-5px'}}>Type your code</span>
                        </label> : null
                    }   
                    id="outlined-required"
                    label="Verification code"
                    placeholder="Verification code"
                    variant="outlined"
                /> 
                </Grid>
                <Grid item xs={12}>
                        <TextField
                            className="input"
                            disabled={disabledText}
                            label="New Password"
                            placeholder="New Password"
                            variant="outlined"
                            onChange={setPassword}
                            onBlur={setPassword}
                            type={showPassword? 'text': 'password'}
                            error={form.password.errorPassword}
                            helperText={form.password.errorPassword ? 
                                <label>
                                <ErrorIcon /> <span style={{position: 'relative', top: '-5px'}}>The password you submitted is missing something</span>
                                </label> : null
                            }
                            InputProps={{
                                style: {
                                    height: "38px",
                                    fontFamily: "Avenir-medium",
                                    fontSize: 14
                                }
                            }}
                        /> 
                        <IconButton disabled={disabledText} onClick={handlePassword} className="overlay-icon" aria-label="password">
                            <PasswordIcon></PasswordIcon>
                        </IconButton>
                </Grid>
                <Grid container className="special-input">
                            <Grid item xs={9}>
                                {incorrectPassword && incorrectPasswordLabel}
                            </Grid>
                        </Grid>
                <Grid item xs={12} className="message-container padding-message">
                    <label className={"message activate"}>Your password must have at least:</label><br/>
                    <p className={form.password.missingValue.minLength? 'error-description': 'message activate'} style={{margin: '0px'}}>* 6 characters.</p>
                    <p className={form.password.missingValue.upperCase? 'error-description': 'message activate'} style={{margin: '0px'}}>* 1 upper case letter</p>
                    <p className={form.password.missingValue.number? 'error-description': 'message activate'} style={{margin: '0px'}}>* 1 number</p>
                    {/* <p className={form.password.missingValue.consecutiveChars ? 'error-description': 'message activate'} style={{margin: '0px'}}>* Do not use sequential characters (e.g. 3333, 1234, abcdf).</p> */}
                </Grid>
                <Grid item xs={12} className={"button-container-create " + classes.passwordButton}>
                    <Button
                        classes={{ disabled: classes.disabledButton }}
                        onClick={onSubmit} disabled={disabledButton} variant="contained" className="button-confirm">Create New Password</Button> 
                </Grid>
            </Grid>               
    </form>
</div>
    )
}

export default CreatePassword;