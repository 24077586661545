const formatTime = (time) => {
    try {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if(hours<10) sHours = "0" + sHours;
      if(minutes<10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes + ":00";
    }
    catch(err) {
      console.log(err);
    } 
}

const formatDate = (date) => {
  try {
    date = date.replace(/\//g, "-").split("-");
    if (date[1].length < 2) 
      date[1] = '0' + date[1];
    if (date[2].length < 2) 
      date[2] = '0' + date[2];
    return date.join("-");
  }  catch(err) {
    date = '';
    return date;
  }
}

const formatFacilitySchema = (data) => {
  return data.map(el => {
    return {
      lat: isNaN(+el.lat)? '': +el.lat,
      lon: isNaN(+el.lon)? '': +el.lon,
      rad: isNaN(+el.rad)? '': +el.rad,
      floor: isNaN(+el.floor)? '': +el.floor,
      code: el.code,
      name: el.name,
      type: el.type,
      action: el.action
    }
  });
} 

module.exports ={
    formatDate, formatTime,
    formatFacilitySchema
}  