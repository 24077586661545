import  gql from 'graphql-tag';

export const getProfile = gql `
  query getUser {
    getUser {
      email 
      schoolCode 
      name 
      nickname 
      code 
      phone 
      semester 
      role 
      academic_rank 
    }
  }
`

export const updatePhone = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input){
      email 
      schoolCode 
      name 
      nickname 
      code 
      phone 
      semester 
      role 
      academic_rank 
    }
  }
`
export const getProfileInfo = async () => {
  return {
    name: 'Judith Smith',
    email: 'judith.smith@ucla.edu',
    phone: '+1 323-570-1232',
  };
};
