import { makeStyles, Paper } from '@material-ui/core';
import React, { Fragment } from 'react';
import useDialog from '../../../utils/hooks/useDialog';
import { YellowButton } from '../../common/buttons';
import { WarningDialog, SuccessDialog } from '../../common/dialog';
import { BodyTypography, DialogBodyTypography, TitleTypography } from '../../common/typography';
import { useHistory } from 'react-router-dom';
import { CLOSE_SEMESTER } from "../../../services/school";
import { useMutation } from '@apollo/react-hooks';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

function ClosePeriod({schoolCode, semester}) {
    let history = useHistory();

    const classes = useStyles();
    // Warning dialog
    const { visible, toggle, onCancel, onClose, onOk } = useDialog(false);
    // Success dialog
    const successDialogHook = useDialog(false);

    function handleOk() {
        onClose();
        closeSemester().then(() => {
            successDialogHook.toggle();
        });
    }

    function closeWindow(){
        successDialogHook.onOk();
        history.push('/school-setup');
    }

    const [closeSemester] = useMutation(CLOSE_SEMESTER, {
        variables: {
            input: {
                schoolCode: schoolCode,
                semester: semester
            }
        },
        onCompleted: (data) => console.log('Success closing active term')
    });

    return (
        <Fragment>
            <Paper className={classes.paper}>
                <TitleTypography className={classes.title} component="h3" variant="h6" align="left">
                    CLOSE TERM
                </TitleTypography>
                <BodyTypography>
                    To configure a new term, you need to close current one first. This will archive all the current term information (including courses and students).
                </BodyTypography>
                <div className={classes.buttons}>
                    {<YellowButton onClick={toggle}>Close term</YellowButton>}
                </div>
            </Paper>
            <div>
                <WarningDialog showDialog={visible} onClose={onClose} onOk={handleOk} onCancel={onCancel}>
                    <DialogBodyTypography component="span">
                        This action will <span style={{ fontWeight: 'bold' }}>end the current school term</span> and archive your information.
                    </DialogBodyTypography>
                    <br/><br/>
                    <DialogBodyTypography component="span">
                        Students and professors will not be able to  access the platform until a new term is configured.
                    </DialogBodyTypography>
                </WarningDialog>
            </div>
            <div>
                <SuccessDialog title="Term closed successfully" showDialog={successDialogHook.visible} onClose={successDialogHook.onClose}
                               onOk={closeWindow} >
                    To continue using the platform you need to configure a new term.
                </SuccessDialog>
            </div>
        </Fragment>
    );
}

export default ClosePeriod;