import React from 'react';
import Login from './components/login/Login';
import {Redirect, Switch} from "react-router-dom";
import PublicRoute from "./components/routes/PublicRoute";
import PrivateRoute from './components/routes/PrivateRoute';
import Courses from "./components/pages/courses/Courses";
import Faq from "./components/pages/faq/Faq";
import MyProfile from "./components/pages/my-profile/MyProfile";
import Professors from "./components/pages/professors/Professors";
import SchoolSettings from "./components/pages/school-settings/SchoolSettings";
import Students from "./components/pages/students/Students";
import OnboardingRoute from './components/routes/OnboardingRoute';
import Introduction from './components/pages/onboarding/introduction/Introduction';
import SchoolSetup from './components/pages/onboarding/school-setup/SchoolSetup';
import TermConfiguration from './components/pages/onboarding/school-setup/TermConfiguration';
import AttendanceConfiguration from './components/pages/onboarding/school-setup/AttendanceConfiguration';
import AttendanceTime from './components/pages/onboarding/school-setup/AttendanceTime';
import SchoolInformation from './components/pages/onboarding/school-setup/SchoolInformation';
import UploadDataSchool from './components/pages/onboarding/school-setup/UploadDataSchool';
import YourSchools from "./components/pages/your-schools/YourSchools";
import AddSchool from "./components/pages/your-schools/AddSchool";
import CourseDetails from "./components/pages/courseDetails/CourseDetails";
import StudentDetail from "./components/pages/studentDetails/StudentDetail";
import ProfessorDetail from './components/pages/professorDetails/ProfessorDetail';

const RoleRedirection = ({role}) => {
  const rolesDefaultsUrls = {
    'SuperAdmin' : '/',
    'Admin': '/',
    'Professor': '/',
    'Student': '/'
  };

  return <Redirect to={rolesDefaultsUrls[role]}/>;
};


export default function Routes({appProps}) {
  const handleAuthentication = (val) => {
    appProps.userHasAuthenticated(val);
};
  const setIsAuthenticating = (val) =>{
    appProps.setIsAuthenticating(val);
  } ;

  const setRole = (val) => {
    appProps.setRole(val);
  }

  const setProfile = (val) => {
    appProps.setProfile(val);
  };

  const setSchoolCode = (val) => {
    appProps.setSchoolCode(val);
  }

  const setSemester = (val) => {
    appProps.setSemester(val);
  }

  const setSchool = (val) => {
    appProps.setSchool(val);
  };

  const props = {
    isAuthenticating: appProps.isAuthenticating,
    isAuthenticated: appProps.isAuthenticated,
    setIsAuthenticating: setIsAuthenticating,
    role: appProps.role,
    userHasAuthenticated: handleAuthentication,
    setRole: setRole,
    profile: appProps.profile,
    setProfile: setProfile,
    setSchoolCode: setSchoolCode,
    schoolCode: appProps.schoolCode,
    school: appProps.school,
    setSchool: setSchool,
    hasSemester: appProps.hasSemester,
    setSemester: setSemester
  }

  const routes = [
    {
        path: "/introduction",
        exact: true,
        component: Introduction
    },
    {
      path: "/school-setup",
      component: SchoolSetup,
      redirect: '/school-setup/info',
      routes: [
        {
          path: '/school-setup/info',
          component: SchoolInformation,
          exact: true
        },
        {
          path: '/school-setup/term',
          component: TermConfiguration,
          exact: true
        },
        {
          path: '/school-setup/attendance',
          exact: true,
          component: AttendanceConfiguration
        },
        {
          path: '/school-setup/attendance-time',
          exact: true,
          component: AttendanceTime
        },
        {
          path: '/school-setup/upload',
          component: UploadDataSchool,
          exact: true
        }
      ]
    }

  ];

  const isUserSuperAdmin = () => props.role && props.role === 'SuperAdmin'

    return(
      <Switch>
        <PublicRoute path="/login" exact component={Login} appProps={props} />
        <PrivateRoute path="/" exact component={isUserSuperAdmin() ? YourSchools : Courses} appProps={props}/>
        <PrivateRoute path="/courses/:code" exact component={CourseDetails} appProps={props}/>
        <PrivateRoute path="/professors/:code" exact component={ProfessorDetail} appProps={props}/>
        <PrivateRoute path="/role-redirect" exact component={RoleRedirection} appProps={props}/>
        <PrivateRoute path="/my-profile" exact component={MyProfile} appProps={props}/>
        <PrivateRoute path="/students" exact component={Students} appProps={props}/>
        <PrivateRoute path="/students/:code" exact component={StudentDetail} appProps={props}/>
        <PrivateRoute path="/professors" exact component={Professors} appProps={props}/>
        <PrivateRoute path="/faq" exact component={Faq} appProps={props}/>
        <PrivateRoute path="/settings" exact component={SchoolSettings} appProps={props}/>
        <PrivateRoute path="/schools" exact component={YourSchools} appProps={props}/>
        <PrivateRoute path="/schools/create" exact component={AddSchool} appProps={props}/>
        {
          routes.map((route, i) => (
            <OnboardingRoute key={i} {...route} appProps={props} />
            ))
        }
      </Switch>

    )
}
