import React, {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Card, Grid, Typography} from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {daysOfWeek} from "../../../utils/dictionaries";
import DoughnutWithText from '../../graphics/DoughnutWithText';
import {AttendanceCodeContext} from "../../dashboard/AttendanceCodeContextProvider";
import classNames from 'classnames';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            cursor: 'pointer',
            height: 200,
            marginBottom: 22
        },
        inProgress: {
            backgroundColor: theme.palette.secondary.main,
            height: 6,
            width: '100%'
        },
        inProgressLabel: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            width: 44
        },
        borders: {
            borderRightColor: '#E8E8E8',
            borderRightWidth: 2,
            borderStyle: 'solid',
            borderBottom: 0,
            borderLeft: 0,
            borderTop: 0,
            textAlign: 'center',
        },
        cardAction: {
            height: '100%'
        },
        button: {
            color: "white",
            fontSize: 12,
            fontWeight: 900,
            padding: 5,
            height: 30,
            width: 150,
            textTransform: 'none',
            backgroundColor: "#177acc"
        },
        backgroundImage: {
            backgroundSize: 'cover',
            width: '100%',
            backgroundRepeat: 'no-repeat'
        },
        warningAttendance: {
            color: theme.palette.common.white,
            height: 23,
            padding: '0 5px',
            marginLeft: 3,
            borderRadius: 5,
            backgroundColor: theme.palette.warning.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        warningIcon: {
            color: theme.palette.common.white,
            fontSize: 15,
            marginRight: 3,
            marginBottom: 2
        },
        warningDiv: {
            backgroundColor: theme.palette.warning.main,
            height: "100%",
            width: 5,
            borderRadius: 5,
            marginLeft: 6
        },
        warningContainerIP: {
            display: 'flex',
            height: '88%'
        },
        warningContainer: {
            display: 'flex',
            height: '100%',
            padding: '5px 0'
        },
        title: {
            padding: 10,
            textAlign: 'left'
        },
        bold: {
            fontWeight: 900
        },
        schedule: {
            textAlign: 'left',
            paddingLeft: 10
        },
        day: {
            color: theme.palette.info.main,
            display: 'inline-block',
            width: 35
        },
        hour: {
            paddingLeft: 5,
            color: theme.palette.info.gray,
            display: 'inline-block'
        },
        downContainer: {
            marginTop: 100,
            marginLeft: 150,
            position: 'absolute',
            width: 'fit-content',
            display: 'inline-block',
        },
        blueCircle: {
            alignItems: 'center',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
            width: 61,
            height: 61,
            display: 'inline-flex',
            borderRadius: "50%",
            textAlign: 'center',
            backgroundColor: theme.palette.primary.main
        },
        orangeCircle: {
            backgroundColor: theme.palette.warning.main
        },
        graphic: {
            paddingTop: 22,
            paddingLeft: 30
        },
        textCircle: {
            color: theme.palette.common.white,
            padding: 12
        },
        infoStudents: {
            display: 'inline-block',
            margin: -8,
            paddingLeft: 23
        },
        nonGradedAttendance: {
            height: 22,
            width: 170,
            position: 'absolute',
            top: 2,
            right: 2,
            textAlign: 'center',
            backgroundColor: '#f4f3f3'
        },
        gradedAttendance: {
            height: 22,
            width: 170,
            position: 'absolute',
            top: 2,
            textAlign: 'center',
            right: 2,
            backgroundColor: 'rgba(23,122,204,0.1)',
        },
        gradedText: {
            color: '#555555',
        }
    })
);


export default function CourseCard({course, role, minimumAttendancePercentage, isHovering = false}) {
    const history = useHistory();
    const [elevation, setElevation] = useState(1);
    const classes = useStyles();
    let {id, name, schedule, graded, image, courseInProgress, courseAttendancePercentage, courseAtRiskPercentage, studentsEnrolled, studentsAtRisk} = course;
    const isLowAttendance = courseAttendancePercentage <= minimumAttendancePercentage;
    schedule = schedule.replace(/=/g, ':').replace(/\s/g, '');
    const correctJson = schedule.replace(/(['":])?(day|startTime|endTime)(['"])?:/g, '"$2":').replace(/:?(Mo|Tu|We|Th|Fr|Sa|Su),/g, ':"$1",').replace(/['"]?([0-9]{1,2}:[0-9][0-9])/g, '"$1"');
    const newSchedules = JSON.parse(correctJson);
    course.newSchedule = newSchedules;
    const attendanceCodeContext = useContext(AttendanceCodeContext);

    const goToDetails = () => {
        history.push(`/courses/${encodeURIComponent(course.id)}`, {course: course})
    };

    const handleShowAttendanceCode = (e) => {
        attendanceCodeContext.setVisibility(!attendanceCodeContext.isVisible);
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <Card className={classes.root} elevation={elevation} onMouseMove={e => setElevation(10)}
              onMouseLeave={e => setElevation(1)} onClick={goToDetails}>
            {
                (role === 'Professor') && courseInProgress ?
                    <div className={classes.inProgress}></div>
                    : null
            }
            <Grid container className={classes.cardAction}>
                <Grid item container xs={6} className={classes.borders}>
                    <Grid item xs={5} style={{backgroundImage: `url(${image})`}} className={classes.backgroundImage}>
                        {
                            (role === 'Professor') && courseInProgress ?
                                <div className={classes.inProgressLabel}>
                                    <Typography variant={'h4'}>NOW</Typography>
                                </div>
                                : null
                        }
                        {
                            isLowAttendance ?
                                <div
                                    className={courseInProgress ? classes.warningContainerIP : classes.warningContainer}>
                                    <div className={classes.warningDiv}/>
                                    <div className={classes.warningAttendance}>
                                        <WarningRoundedIcon className={classes.warningIcon}/>
                                        <Typography variant={'h4'}><strong>Low attendance</strong></Typography>
                                    </div>
                                </div>
                                : null
                        }
                    </Grid>
                    <Grid item xs={7}>
                        <div className={classes.title}>
                            <Typography className={classes.bold} variant={'h2'} color={'primary'}>{name}</Typography>
                        </div>
                       { (role === 'Admin') && <div style={{marginLeft: '-38px'}}>
                            <Typography style={{color: '#858585'}}>ID {id}</Typography>
                        </div>}
                        {
                            (role === 'Professor') && courseInProgress && graded ?
                                <div className={classes.title}>
                                    <Button className={classes.button} variant="contained"
                                            onClick={handleShowAttendanceCode}>Show attendance code</Button>
                                </div>
                                : null
                        }
                        {newSchedules.map((day, key) => (
                            <div key={key} className={classes.schedule}>
                                <Typography variant={'h4'} className={classes.day}>{daysOfWeek[day.day]}</Typography>
                                <Typography variant={'h5'}
                                            className={classes.hour}>{day.startTime} - {day.endTime}</Typography>
                            </div>
                        ))}
                    </Grid>
                </Grid>
                <Grid item container xs={6} style={{position: 'relative'}}>
                    {
                        graded ?
                            <div className={classes.gradedAttendance}>
                                <Typography className={classes.gradedText} variant={'caption'}>Graded
                                    attendance</Typography>
                            </div> :
                            <div className={classes.nonGradedAttendance}>
                                <Typography className={classes.gradedText} variant={'caption'}>Nongraded
                                    attendance</Typography>
                            </div>
                    }
                    <div className={classes.graphic}>
                        <DoughnutWithText
                            isLowAttendance={isLowAttendance}
                            attendancePercentage={courseAttendancePercentage}
                            atRiskPercentage={courseAtRiskPercentage}
                            studentsEnrolled={studentsEnrolled}
                            studentsAtRisk={studentsAtRisk}
                        >
                        </DoughnutWithText>
                    </div>

                    <div className={classes.downContainer}>
                        <div
                            className={classNames(classes.blueCircle, {[classes.orangeCircle]: isLowAttendance})}>
                            <Typography variant={'h2'}
                                        className={classes.textCircle}>{courseAtRiskPercentage}%</Typography>
                        </div>
                        <div className={classes.infoStudents}>
                            <Typography variant={'h4'}>Students at risk</Typography>
                            <Typography variant={'h4'}>{studentsAtRisk} of {studentsEnrolled} enrolled
                                students</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Card>
    );
}