import { Typography, withStyles } from "@material-ui/core";

const blue = '#177acc';

const commonAttributes = {
  fontFamily: 'Avenir-Roman'
};

const HeaderTypography = withStyles({
  root: {
    ...commonAttributes,
    color: blue,
    fontSize: 28,
    fontWeight: 900,
    letterSpacing: 0,
    lineHeight: '18px'
  },
})(Typography);

const SectionTypography = withStyles({
  root: {
    ...commonAttributes,
    color: blue,
    fontSize: 20,
    fontWeight: 900,
    letterSpacing: 0,
    lineHeight: '18px'
  },
})(Typography);

const LabelTypography = withStyles({
  root: {
    ...commonAttributes,
    color: '#757373',
    fontSize: 13,
    fontWeight: 'normal',
    letterSpacing: 0,
    lineHeight: '18px'
  },
})(Typography);

const QuestionTypography = withStyles({
  root: {
    ...commonAttributes,
    color: '#37b5ff',
    fontSize: 16,
    fontWeight: 'normal',
    letterSpacing: 0,
    lineHeight: '18px'
  },
})(Typography);

const TitleTypography = withStyles({
  root: {
    ...commonAttributes,
    color: blue,
    fontWeight: 500,
    letterSpacing: '0.3px',
    fontSize: 14
  },
})(Typography);

const BodyTypography = withStyles({
  root: {
    ...commonAttributes,
    color: '#959595',
    fontSize: 14,
    fontWeight: 'normal',
    letterSpacing: '0.3px'
  },
})(Typography);

const DialogBodyTypography = withStyles({
  root: {
    ...commonAttributes,
    color: '#b2b2b2',
    fontSize: 18,
    fontWeight: 'normal',
    letterSpacing: '0px'
  },
})(Typography);

const BlueLabelTypography = withStyles({
  root: {
    ...commonAttributes,
    color: blue,
    fontSize: 13,
    fontFamily: 'Avenir-Heavy',
    fontWeight: 900,
    letterSpacing: 0,
    lineHeight: '18px'
  },
})(Typography);

const TitleTableTypography = withStyles({
  root: {
    fontFamily: 'Avenir-Heavy',
    color: blue,
    fontWeight: 500,
    letterSpacing: '0px',
    fontSize: 26
  },
})(Typography);

const OnboardingTypography = withStyles({
  root: {
    fontFamily: 'Avenir-Heavy',
    color: '#9c9c9c',
    fontWeight: 900,
    letterSpacing: '0px',
    fontSize: 20
  },
})(Typography);

const InstructionsTypography = withStyles({
  root: {
    ...commonAttributes,
    color: "#959595",
    letterSpacing: '0px',
    fontSize: 13
  },
})(Typography);

const SubtitleTypography = withStyles({
  root: {
    fontFamily: 'Avenir-Heavy',
    color: "#959595",
    letterSpacing: '0px',
    fontSize: 14,
    textAlign: 'center'
  },
})(Typography);

const ErrorTitleTypography = withStyles({
  root: {
    fontFamily: 'Avenir-Heavy',
    color: "#f0b000",
    letterSpacing: '0px',
    fontSize: 26,
  },
})(Typography);

const ErrorTypography = withStyles({
  root: {
    fontFamily: 'Avenir-Heavy',
    color: "#f0b000",
    letterSpacing: '-0.13px',
    fontWeight: 900,
    fontSize: 14,
  },
})(Typography);

const DescriptionTypography = withStyles({
  root: {
    fontFamily: 'Avenir-Heavy',
    color: "#9a9a9a",
    letterSpacing: '-0.13px',
    fontSize: 14,
    fontWeight: 'normal'
  },
})(Typography);

const InformationTypography = withStyles({
  root: {
    fontFamily: 'Avenir-Heavy',
    color: "#858585",
    fontSize: 16,
    fontWeight: 'normal'
  },
})(Typography);

const SubtitleOnboardingTypography = withStyles({
    root: {
        fontFamily: 'Avenir-Roman',
        color: "#757373",
        fontSize: 16,
        fontWeight: 'normal'
    },
})(Typography);

export {
  SectionTypography,
  SubtitleOnboardingTypography,
  OnboardingTypography,
  InformationTypography,
  SubtitleTypography,
  TitleTableTypography,
  TitleTypography,
  LabelTypography,
  BodyTypography,
  DialogBodyTypography,
  BlueLabelTypography,
  HeaderTypography,
  QuestionTypography,
  InstructionsTypography,
  ErrorTitleTypography,
  ErrorTypography,
  DescriptionTypography
};
