import React, {useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Grid, Paper} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CourseInformationCard from "./_CourseInformationCard";
import AttendacePercentCard from "./_AttendacePercentCard";
import AttendanceConfigurationCard from "./_AttendanceConfigurationCard";
import CourseProgress from "./_CourseProgress";
import AttendanceChart from "./_AttendanceChart";
import AdminCourseStudentsCard from "./_AdminCourseStudentsCard";
import ProfessorCourseStudentsCard from "./_ProfessorCourseStudentsCard";
import { GET_COURSE_DETAIL_BANNER } from '../../../services/courses';
import { useQuery } from "@apollo/react-hooks";


const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: 1400
    },

    link: {
        textDecoration: 'none',
    },

    endLink: {
        color: theme.palette.info.gray
    },

    breadcrumbSeparator: {
        height: 10,
        width: 10,
        color: theme.palette.info.gray,
        marginBottom: 2
    },

    title: {
        padding: '33px 0 12px 16px'
    },

    dataContainer: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        marginBottom: 35,
        padding: 15
    },
}));

const LoadingContainer = (props) => {
    return(<Paper className={props.class}>
        <Grid container alignItems='center'>
            <Typography variant={'h2'} color={'primary'}><strong>Loading...</strong></Typography>
        </Grid>
    </Paper>)
}

const CourseDetails = (props) => {
    const {code: codeParam} = useParams();
    const school = props.school
    const role = props.role
    const code = decodeURIComponent(codeParam);
    const classes = useStyles();
    const separator = <ArrowForwardIosIcon color={'disabled'} className={classes.breadcrumbSeparator}/>;

    const [_, setProfessorInfo] = useState({})
    const courseInfoResult = useQuery(GET_COURSE_DETAIL_BANNER, {
        variables: {
            id: code
        },
        fetchPolicy: 'network-only',
        onCompleted: () => {
            // const studentData = studentInfoResult && studentInfoResult.data && studentInfoResult.data.getStudentInfo
        }
    });

    const courseInfo = courseInfoResult && courseInfoResult.data && courseInfoResult.data.courseDetailBanner
    const course = courseInfo


    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Breadcrumbs separator={separator} aria-label="breadcrumb">
                    <Link to="/" className={classes.link}>
                        <Typography variant={'body2'} color={'secondary'}>
                            All Courses
                        </Typography>
                    </Link>
                    <Typography variant={'body2'} className={classes.endLink}>Course details</Typography>
                </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
                {course && <Typography variant={'h1'} color={'primary'} className={classes.title}>{course.name}</Typography>}
            </Grid>

            <Grid item container spacing={2}>

                <Grid item xs={6}>
                    {course ? <CourseInformationCard course={course}/> : <LoadingContainer class={classes.dataContainer}></LoadingContainer>}
                </Grid>

                <Grid item xs={6}>
                    {course ? <AttendacePercentCard course={course}/> : <LoadingContainer class={classes.dataContainer}></LoadingContainer>}
                </Grid>

                <Grid item xs={12}>
                    
                    {course ? <AttendanceChart courseId={course.id} school={school}/> : <LoadingContainer class={classes.dataContainer}></LoadingContainer>}
                </Grid>

                <Grid item xs={6}>
                    {course ? <AttendanceConfigurationCard role={props.role} course={course} schoolCode={props.schoolCode}/> : <LoadingContainer class={classes.dataContainer}></LoadingContainer>}
                </Grid>
                <Grid item xs={6}>
                    {course ? <CourseProgress data={course.progressBar}/> : <LoadingContainer class={classes.dataContainer}></LoadingContainer>}
                </Grid>

                <Grid item xs={12}>
                    {course ? role=== 'Admin' ? <AdminCourseStudentsCard course={course}/> : <ProfessorCourseStudentsCard course={course}/>  : null}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CourseDetails;