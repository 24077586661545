import React, {Fragment} from 'react';
import {Grid, makeStyles} from "@material-ui/core";
import {SectionTypography} from "../../common/typography";
import SchoolInformation from "./SchoolInformation";
import ActivateNewAccount from "./ActivateNewAccount";

const useStyles = makeStyles(theme => ({
        container: {
            marginTop: 15
        }
    })
);

function GeneralInformation({school}) {
    const classes = useStyles();
    const schoolInformation = {
        id: school.schoolCode,
        name: school.name
    };
    return (
        <Fragment>
            <Grid item xs={12}>
                <SectionTypography>General Information</SectionTypography>
            </Grid>
            <Grid item xs={6} className={classes.container}>
                <SchoolInformation information={schoolInformation}/>
            </Grid>
            <Grid item xs={6} className={classes.container}>
                <ActivateNewAccount {...school}/>
            </Grid>
        </Fragment>
    );
}

export default GeneralInformation;