import React, {useState} from 'react';
import {createStyles, Grid, makeStyles, Paper, TableBody, Typography} from "@material-ui/core";
import ProfessorStudentsTable from './ProfessorStudentsTable'
import AdminStudentsTable from './AdminStudentsTable'
import AdminStudentsBanner from './AdminStudentsBanner'
import ProfessorStudentsBanner from './ProfessorStudentsBanner'
import AdminStudentsAttendanceGraph from './AdminStudentsAttendanceGraph'


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            maxWidth: 1400,
            marginTop: 26
        }
    })
);

export default function Students({role, school}) {
    const classes = useStyles();
    
    const [title, setTitle] = useState(role === 'Admin' ? 'Students' : 'My Students');

    return (
        <div className={classes.root}>
            <Typography variant={'h1'} color={'primary'}>{title}</Typography>
            { role === 'Admin' ?  <AdminStudentsBanner></AdminStudentsBanner> : <ProfessorStudentsBanner></ProfessorStudentsBanner>}
            {
                // GRAFICA DE ATTENDANCE PARA ESTUDIANTES
                role === 'Admin' ? <AdminStudentsAttendanceGraph school={school}></AdminStudentsAttendanceGraph> : null
            }
            {role === 'Admin' ? <AdminStudentsTable></AdminStudentsTable> : <ProfessorStudentsTable></ProfessorStudentsTable>}
        </div>
    )
}