import React from 'react';
import {ReactComponent as ProfileIcon} from '../../assets/profile.svg'
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, MenuItem} from '@material-ui/core';
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme =>
    createStyles({
        profileName: {
            fontSize: 12,
            color: "#888888",
        },
        nameContainer: {
            paddingRight: 10,
        },
        icon: {
            margin: "auto",
            height: 22,
            width: 26,
            paddingTop: 4
        },
        iconContainer: {
            border: '1px solid rgb(216, 216, 216)',
            boxShadow: "0px 2px 2px 0px rgba(83, 85, 86, 0.11), 0px 2px 7px 0px rgba(71, 76, 81, 0.09)",
            height: 28,
            width: 28,
            borderRadius: "50%"
        },

        menuList: {
            padding: '0px 0',
            width: 125,

        },

        menuItem: {
            ...theme.typography.caption,
            padding: '8px 0',
            justifyContent: 'center',
            color: theme.typography.body2
        },
    })
);

export default function OptionsProfile({onLogout, profile, role}) {
    const classes = useStyles();
    const history = useHistory();

    if (!profile) profile = {name: ''};

    const goToMyProfile = () => {
        handleClose();
        history.push('/my-profile');
    };

    const logout = () => {
        handleClose();
        onLogout();
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setOpacityColor = (event) => {
        event.target.style.backgroundColor = "#37b5FF20";
    };

    const setWhiteColor = event => {
        event.target.style.backgroundColor = "white";
    };

    /**
     * Created this function since we want to display the name
     * of the user only if its not super admin.
     */
    const isNotSuperAdmin = () => role && role !== 'SuperAdmin'

    return (
        <>
            <Button variant={'text'} onClick={handleClick} style={{marginLeft: 11}}>
                <span className={classes.nameContainer}>
                    {isNotSuperAdmin() && <label className={classes.profileName}>{profile.name || ''}</label>}
                </span>
                <span className={classes.iconContainer}>
                    <ProfileIcon className={classes.icon}></ProfileIcon>
                </span>
            </Button>

            <Menu anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  classes={{list: classes.menuList}}
                  anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                  transformOrigin={{vertical: "top", horizontal: "right"}}
                  getContentAnchorEl={null}>

                {
                    role !== 'SuperAdmin' &&
                    <MenuItem classes={{root: classes.menuItem}}
                              onClick={goToMyProfile}
                              onMouseEnter={setOpacityColor}
                              onMouseLeave={setWhiteColor}>
                        My profile
                    </MenuItem>
                }

                <MenuItem classes={{root: classes.menuItem}}
                          onClick={logout}
                          onMouseEnter={setOpacityColor}
                          onMouseLeave={setWhiteColor}>
                    Log out
                </MenuItem>
            </Menu>
        </>
    )
}