const ERROR_MSGS = {
    'empty': 'Field is required.',
    'minLength': 'The length should be larger than {value}.',
    'maxLength': 'The length should be less than {value}.'
};

const DEFAULT_ERROR_MSG = 'Please check your field';

export const v8nResultParser = (validationResults, customMessages = {}) => {
    const output = [];

    const errorMsgs = {...ERROR_MSGS, ...customMessages};
    for (let validationError of validationResults) {
        const errorMsg = errorMsgs[validationError.rule.name];
        output.push(errorMsg ? errorMsg.replace('{value}', validationError.rule.args[0]) : DEFAULT_ERROR_MSG);
    }

    return output;
};
