import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {WhiteButton} from "../../../common/buttons";

const useStyles = makeStyles(theme => ({
        pageButton: {
            margin: 0,
            fontSize: 12,
            height: 24,
            padding: '0 16px',
            minWidth: 'auto',
            lineHeight: 0
        },
    })
);

const SchoolsPaginator = ({page, count, itemsInPage, isNextAvailable, toStart, onNext, onStart}) => {
    const classes = useStyles();
    return (
        <Grid container alignItems={'center'} spacing={1}>
            <Grid item>
                <Typography variant={'caption'}>Page {page + 1}</Typography>
            </Grid>
            <Grid item>
                <Typography variant={'caption'}>{itemsInPage}/{count} Schools</Typography>
            </Grid>
            <Grid item>
                <WhiteButton color={'primary'} className={classes.pageButton} onClick={onStart} disabled={!toStart}>
                    Go to first
                </WhiteButton>
            </Grid>
            <Grid item>
                <WhiteButton color={'primary'} className={classes.pageButton} onClick={onNext}
                             disabled={!isNextAvailable}>Next</WhiteButton>
            </Grid>
        </Grid>
    );
};

export default SchoolsPaginator;