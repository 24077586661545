import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import useDialog from '../../../utils/hooks/useDialog';
import { BlueButton, WhiteButton } from '../../common/buttons';
import { WarningDialog, ErrorDialog } from '../../common/dialog';
import { BodyTypography, DialogBodyTypography, TitleTypography, SubtitleTypography, DescriptionTypography } from '../../common/typography';
import UploadedInfoItem from './UploadedInfoItem';
import { GET_SIGNED_URL } from '../../../services/school';
import axios from "axios";
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import { GET_TEMPLATE_LINKS } from "../../../services/school";


const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        height: '100%'
    },
    buttons: {
        display: 'flex',
        padding: theme.spacing(2),
        justifyContent: 'flex-end',
    }
}));

function UploadedInformation({ uploadedInfo: initialState, schoolCode, semester }) {
    const classes = useStyles();
    const [uploadInfo, setUploadInfo] = useState(initialState);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [onboardingLinks, setOnboardingLinks] = useState(initialState);
    const { visible, toggle, onCancel, onClose, onOk } = useDialog(false);
    const errorDialog = useDialog(false);
    const uploadErrorDialog = useDialog(false);
    const [errors, setErrors] = useState({
        facility: [],
        student: [],
        professor: [],
        class: [],
        admin: []
    });

    useQuery(GET_TEMPLATE_LINKS, {
        onCompleted: (data) => getLinks(data)
    })

    const getLinks = (data) => {
        setOnboardingLinks(data.getOnBoardingFileLinks);
    };

    const [filesToSign, setFilesToSign] = useState({
        facility: {},
        student: {},
        professor: {},
        class: {},
        admin: {}
    });

    const schoolNameModified = schoolCode.split(' ').join('-');
    const termNameModified = semester.split(' ').join('-');

    const [requestSignedURL] = useMutation(GET_SIGNED_URL);

    const completeUpload = (data, fileToSign) => {
        const options = {
            headers: {
              "Content-Type": "text/csv"
            }
        };
        axios.put(data.signURL.signedRequestUrl, fileToSign, options).catch(err => {
            console.log(err);
            setLoading(false);
            uploadErrorDialog.toggle();
        });
    };

    const uploadFile = async (fileToUpload) => {
        requestSignedURL({
            variables: {
                input: {
                    name: fileToUpload.name,
                    type: fileToUpload.type
                }
            }
        }).then((data) => {
            completeUpload(data.data, fileToUpload);
        })
    };

    function cancelEdit(){
        setUploadInfo(initialState);
        setErrors({
            facility: [],
            student: [],
            professor: [],
            class: [],
            admin: []
        });
        setFilesToSign({
            facility: {},
            student: {},
            professor: {},
            class: {},
            admin: {}
        });
        setSaveEnabled(false);
    }

    function updateInfo(key, newValue){
        const infoCopy = Array.from(uploadInfo);
        infoCopy[key].value = newValue;
        setUploadInfo(infoCopy);
    }

    function hasErrors(){
        let hasError = false;
        Object.keys(errors).forEach(key => {
            if(errors[key].length > 0){ hasError = true; }
        });

        return hasError;
    }

    function uploadFiles(){
        let uploadError = false;
        setLoading(true);
        onOk();
        if(hasErrors()){
            setLoading(false);
            errorDialog.toggle();
        } else {
            Object.keys(filesToSign).forEach((key) => {
                if(filesToSign[key].name !== undefined){
                    uploadFile(filesToSign[key]).then(() => {
                        if(key === Object.keys(filesToSign).length - 1){
                            setLoading(false);
                        }
                    }).catch((err) => {
                        console.log(err);
                        uploadError = true;
                    });
                }
            });
            cancelEdit();
            if(uploadError){
                uploadErrorDialog.toggle();
            }
        }
    }

    function handleErrorClose(){
        errorDialog.onOk();
    }

    const downloadClick = (type) => {
        window.open(onboardingLinks[type]);
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <TitleTypography align="left">
                        UPLOAD INFORMATION
                    </TitleTypography>
                    <BodyTypography>
                        Upload a new file to edit your school information.
                        <br/>
                        <TitleTypography component={'span'} onClick={e => downloadClick('howToTemplates')}>Download the instructions of how to fill the CSV files.</TitleTypography>
                    </BodyTypography>
                    <Grid container item xs={12} className={classes.table}>
                        {uploadInfo.map((item, i) => <UploadedInfoItem
                                                            onchange={setSaveEnabled}
                                                            key={i}
                                                            index={i}
                                                            schoolNameModified={schoolNameModified}
                                                            termNameModified={termNameModified}
                                                            setErrors={setErrors}
                                                            errors={errors}
                                                            filesToSign={filesToSign}
                                                            setFilesToSign={setFilesToSign}
                                                            updateInfo={updateInfo}
                                                            {...item}
                                                        />)}
                    </Grid>
                    <Grid container className={classes.buttons} spacing={4}>
                        <Grid item>
                            {saveEnabled &&
                                <WhiteButton variant="outlined" color="primary" onClick={() => cancelEdit()} >Cancel</WhiteButton>
                            }
                        </Grid>
                        <Grid item>
                            <BlueButton disabled={!saveEnabled} onClick={toggle}>Save</BlueButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <WarningDialog showDialog={visible} onClose={onClose} onOk={uploadFiles} onCancel={onCancel}>
                <DialogBodyTypography component='span'>
                    Uploading this files <span style={{ fontWeight: 'bold' }}>will permanently modify </span>the data.
                </DialogBodyTypography>
            </WarningDialog>
            <ErrorDialog showDialog={errorDialog.visible} onClose={errorDialog.onClose} onOk={handleErrorClose}>
                <DialogBodyTypography component='span'>
                    {Object.keys(errors).map((file, i) => (
                        errors[file].length > 0 &&
                        <Fragment key={i}>
                          <SubtitleTypography component='span' className={classes.subtitle}>Your {file} file has the following errors:</SubtitleTypography>
                          {errors[file].map((err, index) => (
                              <li key={index}>
                                  <DescriptionTypography component='span' className={classes.typography}>{err}</DescriptionTypography>
                              </li>
                          ))}
                        </Fragment>
                    ))}
                </DialogBodyTypography>
            </ErrorDialog>
            <ErrorDialog showDialog={uploadErrorDialog.visible} onClose={uploadErrorDialog.onClose} onOk={uploadErrorDialog.onOk}>
                <DialogBodyTypography component='span'>
                    <DescriptionTypography component='span' className={classes.typography}>Error uploading files, please try again later.</DescriptionTypography>
                </DialogBodyTypography>
            </ErrorDialog>
        </Fragment>
    );
}

export default UploadedInformation;