import React from 'react';
import {Grid, makeStyles, withStyles} from "@material-ui/core";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {blue} from "@material-ui/core/colors";
import { QuestionTypography} from "../../common/typography";

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0'
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles(theme => ({
        root: {flexGrow: 1},
        card: {
            background: 'rgb(255, 255, 255)',
            boxShadow: '0px 2px 3px 0px rgba(83, 85, 86, 0.11), 0px 2px 9px 0px rgba(71, 76, 81, 0.09)',
            borderRadius: '6px'
        },
        administratorGuide: {
            display: 'block'
        }
    })
);

function Question({id, question, answer, type}) {

    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <ExpansionPanel className={classes.card}>
                <ExpansionPanelSummary
                    expandIcon={<ArrowDropDownIcon style={{ color: blue[500] }}/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <QuestionTypography className={classes.heading}>{question}</QuestionTypography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={type==='guide' ? classes.administratorGuide : null} style={{ paddingLeft: '3%'}}>
                        {answer}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Grid>
    );
}

export default Question;