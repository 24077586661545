import React from 'react';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {BlueButton} from "../../common/buttons";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {GET_SCHOOL_ADMINS} from "../../../services/school";
import {useLazyQuery} from '@apollo/react-hooks';
import { CautionDialog } from '../../common/dialog';
import {isEmpty} from 'lodash'


const deleteSchoolDialog = ({schoolName, onOk, onCancel, onClose, showDialog = false}) => {
    return (
        <CautionDialog showDialog={!isEmpty(showDialog)} onClose={onClose} onOk={onOk} onCancel={onCancel}>This action <strong>will delete</strong> the following account Are you sure you want to delete the <strong>{schoolName}</strong> information?</CautionDialog>
    );
};

export default deleteSchoolDialog;