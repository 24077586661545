import React, {useState, useEffect} from 'react';
import ApolloClient from "apollo-boost";
import CssBaseline from "@material-ui/core/CssBaseline";
import './App.css';
import  Amplify from "aws-amplify";
import appTheme from "./App.theme";
import AuthService from "./services/auth";
import awsmobile from "./aws-exports";
import  Routes from "./Routes";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core'

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { ApolloProvider } from '@apollo/react-hooks';


Amplify.configure(awsmobile);
const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  request: (operation) => {
    operation.setContext({
      headers: {
        authorization: AuthService.getIdToken(),
      },
    });
  },
});

function App(props) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [role, setRole] = useState(null);
  const [profile, setProfile] = useState(null);
  const [hasSemester, setSemester] = useState(false);
  const [school, setSchool] = useState(null);
  const [schoolCode, setSchoolCode] = useState(null);
  let history = useHistory();

  useEffect(() => {
    onLoad();

  }, []);



  async function onLoad() {
    const checkForAuthentication = await AuthService.isUserAuthenticated();
    let isUserAuthenticated = checkForAuthentication.isAuthenticated;
    let newRole = checkForAuthentication.role;
    let newSchoolCode = checkForAuthentication.schoolCode;
    userHasAuthenticated(isUserAuthenticated);
    setRole(newRole);
    setSchoolCode(newSchoolCode);
    setIsAuthenticating(false);
    if(!isUserAuthenticated) {

      history.push('/login');
    }
  }

  return (
    !isAuthenticating && 
    <ApolloProvider client={client}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="es">
      <div className="App">
        <Routes appProps={{ isAuthenticated, hasSemester, setSemester ,isAuthenticating, setIsAuthenticating ,userHasAuthenticated, role, setRole, schoolCode, setSchoolCode, profile, setProfile, school, setSchool }} />
      </div>
      </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
