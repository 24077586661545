import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Icon } from "@material-ui/core";
import { ErrorTitleTypography, ErrorTypography, SubtitleTypography, DescriptionTypography } from './typography';
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";
import { forwardRef } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: "20px 20px",
        width: "80%",
        overflow: 'auto',
        maxHeight: "80%"
    },
    typography: {
        display: 'inline'
    },
    errorsList: {
        paddingTop: 21
    },
    subtitle: {
        paddingBottom: 10,
        textAlign: 'left'
    },
    icon: {
        cursor: 'pointer'
    }
}));

const ErrorComponent = forwardRef((props, ref) => {
    const classes = useStyles();
    const onClose = () => {
        props.onClose();
    };
    return(
        <div className={classes.root}>
            <Grid container justify="space-between">
                <Grid item xs={3}>
                    <ErrorTitleTypography>Error</ErrorTitleTypography>
                </Grid>
                <Grid>
                    <Icon className={classes.icon} onClick={onClose}>close</Icon>
                </Grid>
            </Grid>
            <label>
                <ErrorIcon /> <ErrorTypography className={classes.typography}>The file could not be updated</ErrorTypography>
            </label>
            <div className={classes.errorsList}>
                <SubtitleTypography className={classes.subtitle}>Your file needs to be reviewed in the following cells.</SubtitleTypography>
                {props.errors.map((err, index) => (
                    <li key={index}>
                        <DescriptionTypography className={classes.typography}>{err}</DescriptionTypography>
                    </li>
                ))}
            </div>

        </div>
    )
});

export default ErrorComponent;