import React from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    dataContainer: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        marginBottom: 35,
        padding: 15
    },
    borders: {
        borderLeftWidth: 2,
        borderLeftColor: '#E8E8E8',
        borderRightColor: '#E8E8E8',
        borderRightWidth: 2,
        borderStyle: 'solid',
        borderBottom: 0,
        borderTop: 0,
        textAlign: 'center',
        height: 110
    },
    infoTotal: {
        paddingLeft: 15,
        textAlign: 'left'
    },
    textCoursesSummary: {
        color: '#9a9a9a',
        paddingTop: 10
    },
    filterLabel: {
        fontSize: 13,
        fontWeight: 'normal',
        color: '#959595'
    },

    circleContainer: {
        display: 'flex',
        position: 'relative',
        height: 100,
        width: 260,
        alignItems: 'center'
    },

    lowAttendanceInfo: {
        position: 'absolute',
        height: 100,
        width: 100,
        backgroundColor: '#177acc',
        borderRadius: 50,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 20px'
    },

    activeCourses: {
        position: 'absolute',
        height: 77,
        width: 77,
        backgroundColor: '#37b5ff',
        borderRadius: 50,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 15px',
        left: 87,
    },

    coursesAtRisk: {
        position: 'absolute',
        height: 100,
        width: 100,
        backgroundColor: '#f0b000',
        borderRadius: 50,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 15px',
        left: 157,
    },

    text: {
        lineHeight: 'normal',
        color: theme.palette.common.white
    },
}));

const _CoursesSummary = ({coursesSummary}) => {

    const classes = useStyles();

    return (
        <Paper className={classes.dataContainer}>
            <Grid container alignItems='center'>
                <Grid item xs container alignItems={'center'} justify={'center'}>
                    <div className={classes.circleContainer}>
                        <span className={classes.lowAttendanceInfo}>
                            <Typography variant={'h1'}
                                        className={classes.text}
                                        style={{lineHeight: '24px'}}>{coursesSummary.coursesAtRisk}</Typography>
                            <Typography variant={'caption'}
                                        className={classes.text} style={{fontSize: '11px'}}>courses with low attendance</Typography>
                        </span>
                        <span className={classes.activeCourses}>
                            <Typography variant={'caption'} className={classes.text} style={{marginBottom: '3px', fontSize: '11px', paddingTop: '2px'}}>over my</Typography>
                            <Typography variant={'h1'}
                                        className={classes.text}
                                        style={{lineHeight: '22px'}}>
                               <strong>{coursesSummary.coursesTotal}</strong>
                            </Typography>
                            <Typography variant={'caption'} className={classes.text} style={{fontSize: '11px'}}>active courses</Typography>
                        </span>
                        <span className={classes.coursesAtRisk}>
                            <Typography variant={'h2'}
                                        className={classes.text}
                                        style={{paddingTop: '10px'}}><strong>{coursesSummary.coursesAtRiskPercentage}%</strong></Typography>
                            <Typography variant={'caption'}
                                        className={classes.text} style={{lineHeight: '13px', marginTop: '2px'}}>of your courses are at-risk</Typography>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.borders}>
                    <Typography variant={'h1'}
                                color={'primary'} style={{marginTop: '10px'}}>{coursesSummary.coursesAtRiskPercentage} %</Typography>
                    <Typography variant={'caption'} style={{display: 'inline-block', marginTop: '15px', width: '70%'}}>Of your courses are at risk because of lack of students
                        attendance.</Typography>
                </Grid>
                <Grid item xs className={classes.infoTotal}>
                    <Typography variant={'caption'} color={'primary'}>Total attendance recorded </Typography>
                    <br/>
                    <Typography variant={'caption'}>Max. of possible
                        attendance: {coursesSummary.totalCourseEventsToDate}</Typography> <br/>
                    <Typography variant={'caption'}>Number of attendances
                        recorded: {coursesSummary.studentsTotalRecordedAttendance}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default _CoursesSummary;