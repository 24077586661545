import image from "../assets/onboarding/courses.png";

const course = {
    "id": "WISE-AfeILxHjlW#Winter20FiMeS#juo4e",
    "name": "History 120A",
    "schedule": "[{day=Mo, startTime=10:00, endTime=11:00}, {day=Tu, startTime=10:00, endTime=11:00}, {day=We, startTime=10:00, endTime=11:00}]",
    "graded": true,
    "classInProgress": true,
    "classCode": 'AVSF5',
    "courseAttendancePercentage": 80,
    "courseAtRiskPercentage": 10,
    "studentsEnrolled": 50,
    "image": image,
    "studentsAtRisk": 5
};
const course2 = {
    "id": "WISE-AfeILxHjlW#Winter20FiMeS#ijo32",
    "name": "Biology 203",
    "schedule": "[{day=Mo, startTime=10:00, endTime=12:00}, {day=We, startTime=10:00, endTime=12:00}, {day=Fr, startTime=10:00, endTime=12:00}]",
    "graded": false,
    "classInProgress": false,
    "classCode": null,
    "courseAttendancePercentage": 91,
    "courseAtRiskPercentage": 0,
    "studentsEnrolled": 34,
    "image": image,
    "studentsAtRisk": 0
};
const course3 = {
    "id": "WISE-AfeILxHjlW#Winter20FiMeS#kvn50",
    "name": "Math 301B",
    "schedule": "[{day=Mo, startTime=10:00, endTime=11:00}, {day=Tu, startTime=10:00, endTime=11:00}]",
    "graded": false,
    "classInProgress": false,
    "classCode": null,
    "courseAttendancePercentage": 67,
    "courseAtRiskPercentage": 33,
    "studentsEnrolled": 45,
    "image": image,
    "studentsAtRisk": 15
};


const courses = [course, course2, course3];

export const getCourses = () => {
    return courses;
};

export const getCourseById = (id) => {
    return courses.find(item => item.id === id);
};