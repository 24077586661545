import React, {useEffect, useState} from 'react';
import {Card, Grid, makeStyles, createStyles, Paper, Typography} from "@material-ui/core";
import AttendanceGraphic from "../../graphics/Attendance";
import { useQuery } from "@apollo/react-hooks";
import {isEmpty} from 'lodash'
import moment from 'moment';
import { getAdminChartsInfo } from "../../../services/courses";
import { GET_PROFESSORS_ATTENDANCE } from '../../../services/professors';

const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            padding: '12px 15px',
            height: '100%'
        },
        root: {
            maxWidth: 1400,
            marginTop: 26
        },
        dataContainer: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: 5,
            marginBottom: 35,
            padding: 15
        },
        borders: {

            borderLeftWidth: 2,
            borderLeftColor: '#E8E8E8',
            borderRightColor: '#E8E8E8',
            borderRightWidth: 2,
            borderStyle: 'solid',
            borderBottom: 0,
            borderTop: 0,
            textAlign: 'center',
            height: 110
        },
        infoTotal: {
            paddingLeft: 15,
            textAlign: 'left'
        },
        textCoursesSummary: {
            color: '#9a9a9a',
            paddingTop: 10
        },
        filterLabel: {
            fontSize: 13,
            fontWeight: 'normal',
            color: '#959595'
        }
    })
);

const LoadingContainer = (props) => {
    return(<Paper className={props.class}>
        <Grid container alignItems='center'>
            <Typography variant={'h2'} color={'primary'}><strong>Loading...</strong></Typography>
        </Grid>
    </Paper>)
}

const ProfAttendanceChart = (props) => {
    const classes = useStyles();
    const school = props.school
    const professorEmail= props.professorEmail

    const tooltip = '% attendance percentage';
    const [typeChart, setTypeChart] = useState('monthly');

    const [adminCoursesGraphInfo, setAdminCoursesGraphInfo] = useState(null);
    const [professorGraphInfo, setProfessorGraphInfo] = useState(null);

    useQuery(getAdminChartsInfo, {
        onError: (err) => {
            console.log(err);
        },
        onCompleted: (data) => {
            setAdminCoursesGraphInfo(data)
        }
    });

    useQuery(GET_PROFESSORS_ATTENDANCE, {
        variables: {
            professorEmail: professorEmail
        },
        onError: (err) => {
            console.log(err);
        },
        onCompleted: (data) => {
            setProfessorGraphInfo(data)
        }
    });

    const prepareLabels = (data) => {
        if(isEmpty(school)) return
        const resultLabels = []
        if (typeChart === 'weekly'){
            data.forEach((_, index) => resultLabels.push(`Week ${index+1}`))
            return resultLabels
        }
        let date = moment(school.semesterStartDate.split('T')[0]).startOf('month')
        data.forEach(() => {
            resultLabels.push(moment(date).format('MMMM'))
            date = moment(date).clone().add(1, 'month')
        })
        return resultLabels
    }

    const preparePointsData = (pointsData) => {
        return pointsData.map(item => item.y)
    }

    const formatDataForChart = (adminData, professorData) => {
        if(!adminData) return

        const data = []

        // format data for students
        const professorLabel = 'Professor\'s students attendance'
        const professorCandidateData  = typeChart === 'weekly' ? professorData.professorAttendancePerWeek : professorData.professorAttendancePerMonth;
        const professorValues = preparePointsData(professorCandidateData)
        professorValues.shift()
        data.push({label: professorLabel, values: professorValues})

        // format data for admins
        const adminLabel = 'All school students attendance';
        const adminCandidateData  = typeChart === 'weekly' ? adminData.globalAttendancePerWeek : adminData.globalAttendancePerMonth;
        const adminValues = preparePointsData(adminCandidateData)
        adminValues.shift()
        const labels = prepareLabels(adminValues)
        data.push({label: adminLabel, values: adminValues})


        return {
            labels,
            data
        }
    }

    let chartData = null
    if (adminCoursesGraphInfo && professorGraphInfo){
        chartData = formatDataForChart(adminCoursesGraphInfo, professorGraphInfo)
    }

    return (
        !isEmpty(adminCoursesGraphInfo) && !isEmpty(professorGraphInfo) ? <Card classes={{root: classes.card}}>
            <Grid container spacing={1} direction={'column'}>
                <Grid container item xs={12}>
                    <AttendanceGraphic onChangePeriod={val => setTypeChart(val)}
                                       tooltipText={tooltip}
                                       title='Attendance over the active period'
                                       labels={chartData.labels}
                                       data={chartData.data}/>
                </Grid>
            </Grid>
        </Card> :  <LoadingContainer class={classes.dataContainer}></LoadingContainer>
    );
};

export default ProfAttendanceChart;