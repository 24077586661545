import moment from 'moment';

function validateRegex(string) {
    return {
        minLength: string.length < 6,
        upperCase: !RegExp(/.*[A-Z].*/).test(string),
        number: !RegExp(/.*\d.*/).test(string),
        // consecutiveChars: checkSequentialCharacters(string)
    }
};

function checkSequentialCharacters(s) {
    if(s.length === 0) return true
    // Check for sequential numerical characters
    for (const i in s){
        // look for the next value in the next place first
        // look for the same value in the next place second
        if (+s[+i + 1] === +s[i] + 1 || +s[+i + 1] === +s[+i]) {
                return true;
            } 
    }
        
        
    // Check for sequential alphabetical characters
    for (const i in s){
        // look for the next value in the next place first
        // look for the same value in the next place second
        if (String.fromCharCode(s.charCodeAt(i) + 1) === s[+i + 1] || s.charCodeAt(i +1) === s.charCodeAt(i)){
                return true;
            }
    }
    return false;
}

const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexPhone = /(\+)[0-9]{10,}$/
const regexInputPhone = /([0-9]|\+)+$/

function regexValidator(regex, errorMessage) {
    return (fieldName, state) => RegExp(regex).test(state[fieldName]) ? null : errorMessage;
}

function numberComparisonValidator(otherValue, otherField, errorMessage, { comparisonOperator = '<' }) {
    return (fieldName, state) => {
        let value = state[fieldName];
        let other = otherValue || state[otherField];
        const validTypes = ['number', 'string'];
        if (!isValidType(value, validTypes) || !isValidType(value, validTypes)) {
            console.warn('number comparison validator checked against non valid type');
            return errorMessage;
        }
        if (typeof value === 'string') {
            value = parseFloat(value);
        }
        if (typeof other === 'string') {
            other = parseFloat(other);
        }
        switch (comparisonOperator) {
            case '<':
                return value < other ? null : errorMessage;
            case '<=':
                return value <= other ? null : errorMessage;
            case '>':
                return value > other ? null : errorMessage;
            case '>=':
                return value >= other ? null : errorMessage;
            case '==':
                return value === other ? null : errorMessage;
            default:
                return value < other ? null : errorMessage;
        }
    }
}

function numberGreaterThanValidator(otherValue, otherField, errorMessage) {
    return numberComparisonValidator(otherValue, otherField, errorMessage, { comparisonOperator: '>' })
}

function numberLessThanValidator(otherValue, otherField, errorMessage) {
    return numberComparisonValidator(otherValue, otherField, errorMessage, { comparisonOperator: '<' })
}

function dateComparisonValidator(otherValue, otherField, errorMessage, { dateFormat = 'DD/MM/YYYY', operator = '<' }) {
    return (fieldName, state) => {
        let value = state[fieldName];
        let other = otherValue || state[otherField]
        const validTypes = [moment, 'string'];
        if (!isValidType(value, validTypes) || !isValidType(other, validTypes)) {
            console.warn('date comparison validator checked against non valid type');
            return errorMessage;
        }
        if (typeof value === 'string') {
            value = moment(value, dateFormat);
        }
        if (typeof other === 'string') {
            other = moment(other, dateFormat);
        }
        if (!other instanceof moment || !value instanceof moment) {
            console.warn("date validator was called with non date values")
            return errorMessage;
        }
        if (!other.isValid() || !value.isValid()) {
            console.warn("date validator was called with invalid date values")
            return errorMessage;
        }

        switch (operator) {
            case '<':
                return value.isBefore(other) ? null : errorMessage;
            case '<=':
                return value.isSameOrBefore(other) ? null : errorMessage;
            case '>':
                return value.isAfter(other) ? null : errorMessage;
            case '>=':
                return value.isSameOrAfter(other) ? null : errorMessage;
            case '==':
                return value.isSame(other) ? null : errorMessage;
            default:
                return value.isBefore(other) ? null : errorMessage;
        }
    }

}

function isValidType(obj, validTypes) {
    return validTypes.includes(typeof obj) || validTypes.filter(type => typeof type === 'object' || typeof type === 'function').some(type => (obj instanceof type));
}

function afterDateValidator(otherValue, otherField, errorMessage, dateFormat = 'DD/MM/YYYY', acceptEqual = false) {
    return dateComparisonValidator(otherValue, otherField, errorMessage, { dateFormat, operator: acceptEqual ? '>=' : '>' })
}

function beforeDateValidator(otherValue, otherField, errorMessage, dateFormat = 'DD/MM/YYYY', acceptEqual = false) {
    return dateComparisonValidator(otherValue, otherField, errorMessage, { dateFormat, operator: acceptEqual ? '<=' : '<' })
}

function requiredFieldValidator(errorMessage) {
    return (fieldName, state) => {
        if (typeof state[fieldName] === 'number') {
          return !Number.isNaN(state[fieldName]) ? null : errorMessage;
        }
        return (state[fieldName] && !!state[fieldName].trim()) ? null : errorMessage
    };
}

function onlyNumbersIntValidator(errorMessage) {
    return regexValidator(/^[0-9]+$/, errorMessage);
}

function maxLengthValidator(value, errorMessage) {
    return (fieldName, state) => {
        if (state[fieldName]['length'] !== undefined) {
            return (state[fieldName].length <= value) ? null : errorMessage;
        }
        console.warn('max length validator checked against non sizeable object');
        return errorMessage;
    }
}

function minLengthValidator(value, errorMessage) {
    return (fieldName, state) => {
        if (state[fieldName]['length'] !== undefined) {
            return (state[fieldName].length >= value) ? null : errorMessage;
        }
        console.warn('max length validator checked against non sizeable object');
        return errorMessage;
    }
}

function equalsOtherFieldValidator(otherField, errorMessage) {
    return (fieldName, state) => {
        const value = state[fieldName];
        const other = state[otherField];
        if (typeof value === 'object') {
            return JSON.stringify(value) === JSON.stringify(other) ? null : errorMessage;
        }
        return value === other ? null : errorMessage;
    }
}

export {
    validateRegex,
    regexEmail,
    regexPhone,
    regexInputPhone,
    regexValidator,
    numberComparisonValidator,
    numberGreaterThanValidator,
    numberLessThanValidator,
    requiredFieldValidator,
    onlyNumbersIntValidator,
    dateComparisonValidator,
    afterDateValidator,
    beforeDateValidator,
    maxLengthValidator,
    equalsOtherFieldValidator,
    minLengthValidator
};
