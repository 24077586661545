import React from 'react';
import {Dialog, DialogContent} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {ReactComponent as ErrorIcon} from "../../../../assets/check_mark.svg";
import {BlueButton} from "../../../common/buttons";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    dialogRoot: {
        '&&': {
            padding: '40px 25px 25px 43px',
            maxWidth: 453
        }
    },

    errorIcon: {
        marginLeft: 15
    },

    header: {
        color: theme.palette.secondary.main,
        marginBottom: 22
    },

    bodyText: {
        color: '#b2b2b2',
        marginBottom: 45
    }

}));

const CongratulationsDialog = ({open, goToSchools, schoolName, schoolId}) => {
    const classes = useStyles();

    return (
        <Dialog open={open}>
            <DialogContent classes={{root: classes.dialogRoot}}>
                <Grid container direction={'column'}>

                    <Grid item container alignItems={'center'} direction={'row'} className={classes.header}>
                        <Typography variant={'h1'}>
                            Congratulations
                        </Typography>
                        <ErrorIcon className={classes.errorIcon}/>
                    </Grid>

                    <Grid item xs>
                        <Typography variant={'h3'} className={classes.bodyText}>
                            Your new school <strong>{schoolName}</strong> has been added to your list with the following
                            ID <strong>{schoolId}</strong>
                        </Typography>
                    </Grid>

                    <Grid item container justify={'flex-end'}>
                        <Grid item>
                            <BlueButton onClick={goToSchools}>Go to schools</BlueButton>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default CongratulationsDialog;
