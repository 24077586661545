import React from 'react';
import { Container, Grid, makeStyles } from "@material-ui/core";
import FrequentlyQuestion from "./FrequentlyQuestion";
import { HeaderTypography } from "../../common/typography";
import ReportProblem from './ReportProblem';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '3% 0'
    },
    reportProblem: {
        margin: theme.spacing(2, 0)
    }
})
);


export default function Faq({role}) {
    const classes = useStyles();
    return (
        <Container>
            <section className={classes.root}>
                <Grid container direction="column" spacing={4}>
                    <Grid item xs={12}><HeaderTypography variant="h1">Help</HeaderTypography></Grid>
                </Grid>
            </section>
            <section className={classes.reportProblem}>
                <Grid container spacing={2}>
                    <ReportProblem />
                </Grid>
            </section>
            <section>
                <Grid container spacing={2}>
                    <FrequentlyQuestion role={role}/>
                </Grid>
            </section>
        </Container>
    )
}