import React, {useEffect, useState} from 'react';
import { Grid, makeStyles, Paper, TableBody, Typography, Table, TableRow, TableSortLabel} from "@material-ui/core";
import { WhiteButton } from "../../common/buttons";
import { WTableContainer, WTableHead, WTableCell } from '../../graphics/WiseTable';
import {GET_PROFESSORS_CLASSES, downloadProfessorsClassesCSV} from '../../../services/professors'
import { useLazyQuery } from "@apollo/react-hooks";
import {orderBy} from 'lodash'
import DownloadButton from '../../common/DownloadButton'
import WPaginator from "../../graphics/WisePaginator";

const PAGE_SIZE = 10

const useStyles = makeStyles(theme => ({

    item: {
        marginTop: 3
    },
    card: {
        padding: 16,
        height: '100%'
    },
    dataContainer: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        marginBottom: 35,
        padding: 15
    },
    lowAttendance: {
        color: theme.palette.warning.main
    },
    buttonContainer: {
        textAlign: 'right'
    },
    link: {
        color: theme.palette.secondary.main,
        cursor: 'pointer'
    },
    title: {
        fontWeight: 900
    }

}));


const ProfessorCoursesTable = (props) => {

    const onLinkClick = (student) => {
        props.onNameClick(student);
    };
    const [page, setPage] = React.useState(0);
    const [getProfessorClasses, professorClassesResult] = useLazyQuery(GET_PROFESSORS_CLASSES);
    const mutationParams={
        variables: {
            professorEmail: props.professorEmail
        }
    }
    const data = professorClassesResult && professorClassesResult.data && professorClassesResult.data.professorClassesTable
    const totalCourses = data && data.totalCourses
    const courses = data && data.classes

    // multi filters
    const [nameFilter, setNameFilter] = useState('desc')
    const [codeFilter, setCodeFilter] = useState('desc')
    const [attendanceConfigFilter, setAttendanceConfigFilter] = useState('desc')
    const [attendancePercentageFilter, setAttendancePercentageFilter] = useState('desc')
    const [enrolledStudentsFilter, setEnrolledStudentsFilter] = useState('desc')
    const [studentsWithLowAttendanceFilter, setStudentsWithLowAttendanceFilter] = useState('desc')

    // single filter
    const [defaultFilterKey, setDefaultFilterKey] = useState('name')
    const [defaultFilterValue, setDefaultFilterValue] = useState('asc')

    const refreshList = () => {
        const nextToken = page === 0 ? '' : data && data.nextToken;
        getProfessorClasses({
            variables: {
                input: {
                    professorEmail: props.professorEmail,
                    limit: PAGE_SIZE,
                    nextToken: nextToken
                }
            }
        });
    };

    const selectDirection = (value) => {
        const res = value === 'asc' ? 'desc' : 'asc'
        setDefaultFilterValue(res)
        return res
    }

    const toggleSortDirection = (key) => {
        setDefaultFilterKey(key)
        switch(key) {
            case 'name':
                setNameFilter(selectDirection(nameFilter))
                break;
            case 'code':
                setCodeFilter(selectDirection(codeFilter))
                break;
            case 'attendanceConfig':
                setAttendanceConfigFilter(selectDirection(attendanceConfigFilter))
                break;
            case 'attendancePercentage':
                setAttendancePercentageFilter(selectDirection(attendancePercentageFilter))
                break;
            case 'enrolledStudents':
                setEnrolledStudentsFilter(selectDirection(enrolledStudentsFilter))
                break;
            case 'studentsWithLowAttendance':
                setStudentsWithLowAttendanceFilter(selectDirection(studentsWithLowAttendanceFilter))
                break;
        }
    }

    const orderCourses = (courses) => {
        if (!courses) return;

        //singlefilter
        return orderBy(courses, [defaultFilterKey], [defaultFilterValue])

        // multifilter
        // return orderBy(students,
        //     ['name', 'email', 'classEnrolled', 'attendancePercentage', 'absences'],
        //     [nameFilter, emailFilter, classEnrolledFilter, attendancePercentageFilter, absencesFilter]
        // );
    }

    const isFilterSelected = (key) => {
        return key === defaultFilterKey
    }

    React.useEffect(() => {
        refreshList();
    }, [page])

    const classes = useStyles();
    return(
        <Paper elevation={3} className={classes.dataContainer}>

        <Grid container>
        <Grid item xs={12}>
            <Typography className={classes.title} variant={'h2'} color={'primary'}>Courses</Typography>
        </Grid>
    </Grid>
    <Grid container justify="space-between" alignItems='center'>
        <Grid item xs={3}>
            <Typography variant={'h5'}>{totalCourses} Courses</Typography>
        </Grid>
        <Grid item xs={3} className={classes.buttonContainer}>
            <DownloadButton mutation={downloadProfessorsClassesCSV} mutationParams={mutationParams} lookupKey={'professorClassesTableCSV'}></DownloadButton>
        </Grid>
    </Grid>
        <WTableContainer>
            <Table>
                <WTableHead>
                    <TableRow>
                        <WTableCell onClick={() => toggleSortDirection('name')}>
                            <TableSortLabel active={true}
                                            direction={nameFilter}
                                            style={{color: isFilterSelected('name') ?'#177acc' : '#757373'}}>
                                Course Name
                            </TableSortLabel>
                        </WTableCell>
                        <WTableCell onClick={() => toggleSortDirection('code')}>
                            <TableSortLabel active={true}
                                            direction={codeFilter}
                                            style={{color: isFilterSelected('code') ?'#177acc' : '#757373'}}>
                                Course ID
                            </TableSortLabel>
                        </WTableCell>
                        <WTableCell onClick={() => toggleSortDirection('attendanceConfig')}>
                            <TableSortLabel active={true}
                                            direction={attendanceConfigFilter}
                                            style={{color: isFilterSelected('attendanceConfig') ?'#177acc' : '#757373'}}>
                                Attendance configuration
                            </TableSortLabel>
                        </WTableCell>
                        <WTableCell onClick={() => toggleSortDirection('attendancePercentage')}>
                            <TableSortLabel active={true}
                                            direction={attendancePercentageFilter}
                                            style={{color: isFilterSelected('attendancePercentage') ?'#177acc' : '#757373'}}>
                                Attendance %
                            </TableSortLabel>
                        </WTableCell>
                        <WTableCell onClick={() => toggleSortDirection('enrolledStudents')}>
                            <TableSortLabel active={true}
                                            direction={enrolledStudentsFilter}
                                            style={{color: isFilterSelected('enrolledStudents') ?'#177acc' : '#757373'}}>
                                Enrolled students
                            </TableSortLabel>
                        </WTableCell>
                        <WTableCell onClick={() => toggleSortDirection('studentsWithLowAttendance')}>
                            <TableSortLabel active={true}
                                            direction={studentsWithLowAttendanceFilter}
                                            style={{color: isFilterSelected('studentsWithLowAttendance') ?'#177acc' : '#757373'}}>
                                Students with Low Attendance
                            </TableSortLabel>
                        </WTableCell>
                    </TableRow>
                </WTableHead>
                <TableBody>
                    {
                        orderCourses(courses) && orderCourses(courses).map((r, index) => {
                            return <TableRow key={index}>
                                <WTableCell onClick={e => onLinkClick(r)} className={classes.link}>{r.name}</WTableCell>
                                <WTableCell>{r.code}</WTableCell>
                                <WTableCell align='center'>{r.attendanceConfig}</WTableCell>
                                <WTableCell align='center'>{r.attendancePercentage}</WTableCell>
                                <WTableCell align='center'>{r.enrolledStudents}</WTableCell>
                                <WTableCell align='center'>{r.studentsWithLowAttendance}</WTableCell>
                            </TableRow>;
                        })
                    }
                </TableBody>
            </Table>
        </WTableContainer>
        <Grid item container justify={'flex-end'}>
            <Grid item>
                <WPaginator total={totalCourses}
                            page={page}
                            itemsInPage={courses && courses.length}
                            onNext={() => setPage(page + 1)}
                            onStart={() => setPage(0)}
                            startDisabled={page === 0}
                            nextDisabled={!((page + 1) * PAGE_SIZE < totalCourses)}/>
            </Grid>
        </Grid>
    </Paper>
    )
};

export default ProfessorCoursesTable
